import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const startQueueAgainAction = createAsyncThunk('START_QUEUE_AGAIN_ACTION', async (
    {
        userId,
        queueId,
        startDate,
    }, {rejectWithValue}) => {
    try {
        const endpoint = `${userId }/queue/start-again/${queueId}`;
        const body = {startDate: startDate}
        return await HttpApi.PostAsync(endpoint, true, body);
    } catch (err) {
        return rejectWithValue(err);
    }
})

const startQueueAgainSlice = createSlice({
    name: 'start-queue-again',
    initialState,
    reducers: {
        resetStartQueueAgain: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(startQueueAgainAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(startQueueAgainAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(startQueueAgainAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {startQueueAgainAction};
export const { resetStartQueueAgain } = startQueueAgainSlice.actions;

export default startQueueAgainSlice.reducer;
