import { useEffect, useState } from 'react'
import AddExtraPhoneModal from '../../Modals/AddExtraPhoneModal';
import { useDispatch, useSelector } from 'react-redux';
import { isFailure, isLoading, isSuccess } from '../../../utilities/reduxStateHelper';
import { fireToast } from '../../../utilities/toastHelper';
import { addExtraPhoneNumberAction, resetAddExtraPhoneNumber } from '../../../services/redux/profile/addExtraPhoneNumber';
import { get_local_storage_item } from '../../../utilities';
import { formatPhoneNumber } from '../../../utilities/stringHelper';
import { userDetailsAction } from '../../../services/redux/profile/user-details';


const ExtraPhoneNumbers = ({ phonesData, theme }) => {
    //const [extraPhoneNumber, setExtraPhoneNumber] = useState('');
    const [user, setUser] = useState(get_local_storage_item("user"));
    const dispatch = useDispatch();
    const extraPhoneNumberState = useSelector((state) => state.profile.addExtraPhoneNumber);
    const [isShowAddExtraPhoneNumberModal, setIsShowExtraPhoneNumberModal] = useState(false);
    const [isInternational, setIsInternational] = useState(false);

    const handleSubmitExtraPhoneNumber = (phoneNumber, areaCode) => {
        console.log('phone number => ', phoneNumber, 'area code => ', areaCode);
        if (phoneNumber === "") {
            return alert("Telefon numarası eklemek için inputu doldurunuz!");
        }
    
        // Telefon numarasının başında "90" veya "+" varsa veya kullanıcı zaten uluslararası bir numara girdiyse sadece numarayı kullan
        const fullPhoneNumber = phoneNumber.startsWith("90") || phoneNumber.startsWith("+") || isInternational
            ? phoneNumber
            : `90${phoneNumber.replace(/^0+/, '')}`;
    
        if (isValidPhoneNumber(fullPhoneNumber)) {
            dispatch(addExtraPhoneNumberAction({
                user: user.id,
                phoneNumber: fullPhoneNumber
            }));
        } else {
            return;
        }
    };
    
    

    function isValidPhoneNumber(phoneNumber) {
        if (phoneNumber.length >= 10 && phoneNumber.length <= 15) {
            return true;
        }
        return false;
    }



    useEffect(() => {
        if (isSuccess(extraPhoneNumberState)) {
            fireToast(extraPhoneNumberState.data.message, "SUCCESS", theme.theme);
            setIsShowExtraPhoneNumberModal(false);
            dispatch(userDetailsAction());
        }
        if (isLoading(extraPhoneNumberState)) {
            // İşlem yükleniyor
        }
        if (isFailure(extraPhoneNumberState)) {
            fireToast(extraPhoneNumberState.error.message, "FAIL", theme.theme);
        }
        return () => {
            dispatch(resetAddExtraPhoneNumber());
        }
    }, [extraPhoneNumberState, dispatch, theme.theme]);

    return (
        <div className='card mb-10'>
            <div
                className='card-header border-0'
                data-bs-target='#kt_extra_phone_data'
                aria-expanded='true'
                aria-controls='kt_extra_phone_data'
            >
                <div className='card-title d-flex justify-content-between m-0 w-100'>
                    <h3 className='fw-bolder m-0'>Ekstra Telefon Numaraları</h3>
                    <h3>
                        {user.role === "User" && (<>
                            {phonesData?.usagePhoneCount} / {phonesData?.totalPhoneCount}
                        </>)}
                    </h3>
                </div>
            </div>

            <div id='kt_extra_phone_data' className='collapse show'>
                {Array.isArray(phonesData?.phones) ? (
                    phonesData.phones.length === 0 ? (
                        <div className='card-body border-top px-9 pt-5 pb-4 fw-bold'>
                            Su anda eklenmis fazladan telefon numaraniz bulunmamaktadir.
                        </div>
                    ) : (
                        phonesData.phones.map((phone, index) => (
                            <div key={index} className='card-body border-top px-9 pt-3 pb-4'>
                                <div className='row my-3'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Ekstra Telefon {index + 1}</span>
                                    </label>

                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            disabled={true}
                                            type='text'
                                            value={formatPhoneNumber(phone.phone)}
                                            className='form-control form-control-lg form-control-solid disabled'
                                        />
                                    </div>
                                </div>
                            </div>
                        ))
                    )

                ) : (
                    <div className='card-body border-top px-9 pt-3 pb-4'>
                        Herhangi bir fazladan tel no eklenmemis
                    </div>
                )}
                <div className='d-flex justify-content-end my-3 me-3'>
                    <button
                        className='btn btn-primary mx-5'
                        onClick={() => {
                            setIsShowExtraPhoneNumberModal(true)
                        }}
                        disabled={
                            user && user.role === "Admin"
                                ? false
                                : phonesData?.usagePhoneCount < phonesData?.totalPhoneCount
                                    ? false
                                    : true
                        }
                    >
                        Numara Ekle
                    </button>
                </div>
            </div>

            {
                isShowAddExtraPhoneNumberModal && (
                    <AddExtraPhoneModal
                        setIsShowExtraPhoneNumberModal={setIsShowExtraPhoneNumberModal}
                        handleSubmitExtraPhoneNumber={handleSubmitExtraPhoneNumber}
                        isInternational={isInternational}
                        setIsInternational={setIsInternational}
                    />
                )
            }
            {isShowAddExtraPhoneNumberModal && <div className='modal-backdrop fade show'></div>}
        </div>
    )
}

export default ExtraPhoneNumbers