import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLogsAction } from "../../services/redux/admin/get-logs";
import { isFailure, isLoading, isSuccess } from "../../utilities/reduxStateHelper";
import { formatDate } from "../../utilities/stringHelper";
import { useTheme } from "../../services/themeService";
import { ConfigProvider, Pagination } from "antd";

const tabButtons = [
    {
        id: 1,
        title: "Bilgi",
        tabName: "info"
    },
    {
        id: 2,
        title: "Uyarı",
        tabName: "warning"
    },
    {
        id: 3,
        title: "Hata",
        tabName: "error"
    },
]

const Events = () => {
    const { theme } = useTheme();
    const dispatch = useDispatch()
    const selector = useSelector((state) => state.admin.getLogs)
    const [currentTab, setCurrentTab] = useState("info")
    const [firstReq, setFirstReq] = useState(false)
    const [logsData, setLogsData] = useState([])
    const [paginateData, setPaginateData] = useState({})
    const [loading, setLoading] = useState(false)
    const [defaultPerPage, setDefaultPerPage] = useState(10)

    const handleChangedTab = (tabName) => {
        setCurrentTab(tabName)
        dispatch(getLogsAction({ type: tabName && tabName, page: 1, perPage: defaultPerPage }))
    }

    const fetchNextData = useCallback((page, perPage) => {
        dispatch(getLogsAction({
            type: currentTab && currentTab,
            page: page,
            perPage: perPage ? perPage : defaultPerPage
        }))
    })

    useEffect(() => {
        if (!firstReq) {
            setFirstReq(true)
            dispatch(getLogsAction({ type: currentTab && currentTab, page: 1, perPage: defaultPerPage }))
        }
    }, [currentTab, dispatch, firstReq, defaultPerPage])

    useEffect(() => {
        if (isSuccess(selector)) {
            setLoading(false)
            setPaginateData(selector.data.meta)
            setLogsData(selector.data.data)
        }
        if (isFailure(selector)) {
            setLoading(false)
        }
        if (isLoading(selector)) {
            setLoading(true)
        }
    }, [selector, setLoading, currentTab])

    return (
        <div className={"tab-content"}>
            <div className={"card pt-4 mb-6 mb-xl-9"}>
                <div className={"card-header border-0"}>
                    <div className={"card-title w-100 d-flex flex-column flex-sm-row justify-content-between align-items-center"}>
                        <h2>Sistem Logları</h2>
                        <div className="d-flex justify-content-center w-100 w-sm-auto mt-3 mt-sm-0" >
                            {
                                tabButtons.map((tab) => (
                                    <button
                                        className={`${tab.tabName === currentTab
                                            ? "btn btn-primary mx-2 px-4 py-2 my-2 my-sm-0"
                                            : "btn btn-light mx-2 px-4 py-2 my-2 my-sm-0"
                                            }`}
                                        onClick={() => {
                                            handleChangedTab(tab.tabName)
                                        }}
                                        key={tab.id}
                                    >
                                        {tab.title}
                                    </button>
                                ))
                            }
                        </div>
                    </div>
                </div>
                <div className={"card-body py-0"}>
                    <div className="table-responsive">
                        <table className={"table align-middle table-row-dashed fs-6 text-gray-600 fw-semibold gy-5"}>
                            <tbody>
                                {Array.isArray(logsData) && logsData.map((log) => (
                                    <tr key={log._id}>
                                        <td className={"min-w-400px"}>
                                            <span
                                                className={`badge me-1 fs-7 ${log.level === 'info'
                                                    ? 'badge-light-primary'
                                                    : log.level === 'warning' ? 'badge-light-warning' : 'badge-light-danger'
                                                    }`}
                                            >
                                                {log.level}
                                            </span>
                                            {" "}
                                            {log.message}
                                        </td>
                                        <td className={"pe-0 text-gray-600 text-end min-w-200px"}>{formatDate(log.timestamp)}</td>
                                    </tr>
                                ))}
                                {
                                    logsData.length === 0 && (
                                        <tr>
                                            <td colSpan={2}>
                                                İlgili Log Datası bulunmamaktadır.
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: 10,
                            marginBottom: 20
                        }}
                    >
                        <ConfigProvider
                            theme={{
                                token: {
                                    colorBgContainer: theme.backgroundColor,
                                    colorText: theme.color
                                }
                            }}
                        >
                            <Pagination
                                locale={{ items_per_page: '/ sayfa' }}
                                showSizeChanger
                                onShowSizeChange={(current, pageSize) => {
                                    fetchNextData(1, pageSize)
                                }}
                                pageSizeOptions={[10, 50, 500, 1000, 5000]}
                                defaultCurrent={1}
                                total={paginateData ? paginateData.totalItems : 1}
                                pageSize={paginateData ? paginateData.perpage : defaultPerPage}
                                className={theme.theme === "light" ? "light-theme" : "dark-theme"}
                                onChange={(page, pageSize) => {
                                    fetchNextData(page, pageSize);
                                }}
                            />
                        </ConfigProvider>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Events;