import ModalHeader from './ModalHeader'

const UserInfoModal = ({ setIsShowUserInfoModal, selectedUser, selectedTab, deleteGrayListCustomer, deleteBlackListCustomer }) => {

    return (
        <div
            className='modal fade show d-block'
            id='kt_modal_user_info'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
        >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-700px'>
                {/* begin::Modal content */}
                <div className='modal-content' style={{ display: 'flex', justifyContent: 'center' }}>
                    <ModalHeader
                        title="Kullanıcı Bilgileri"
                        handleCloseModal={() => setIsShowUserInfoModal(false)}
                    />

                    {/* begin::Modal body */}
                    <div className={"modal-body scroll-y mx-5 mx-xl-15 my-7"}>

                        <div className='d-flex flex-column'>
                            <h2>Ad: {selectedUser.name}</h2>
                            <h2>Soyad: {selectedUser.lastName}</h2>
                        </div>

                        {/* begin::buttons */}
                        <div className={"text-center pt-10"}>
                            <button
                                className={"btn btn-light me-5"}
                                onClick={() => setIsShowUserInfoModal(false)}
                            >
                                İptal
                            </button>
                            {
                                selectedTab === "grayList"
                                    ? (
                                        <button
                                            className={`btn btn-primary`}
                                            onClick={() => {
                                                deleteGrayListCustomer(selectedUser.id)
                                                setIsShowUserInfoModal(false)
                                            }}
                                        >
                                            Kullanıcıyı Gri Listeden Kaldır
                                        </button>
                                    )
                                    : (
                                        <button
                                            className={`btn btn-primary`}
                                            onClick={() => {
                                                deleteBlackListCustomer(selectedUser.id)
                                                setIsShowUserInfoModal(false)
                                            }}
                                        >
                                            Kullanıcıyı Kara Listeden Çıkarma
                                        </button>
                                    )
                            }

                        </div>
                        {/* end::buttons */}

                    </div>
                    {/* end::Modal body */}

                </div>
                {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}

        </div>
    )
}

export default UserInfoModal