import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";


const initialState = {
  isLoading: false,
  isSuccess: false,
  isFailure: false,
  data: {},
  error: {}
};

const activeSessionsAction = createAsyncThunk('ACTIVE_SESSIONS_ACTION', async ({
  userId
}, { rejectWithValue }) => {
  try {
    let endpoint = `${userId}/profile/active-session`
    return await HttpApi.GetAsync(endpoint, true);
  }
  catch (err) {
    return rejectWithValue(err);
  }
})


const activeSessionsSlice = createSlice({
  name: 'active-sessions',
  initialState,
  reducers: {
    resetActiveSessions: (state) => {return initialState;}
  },
  extraReducers: (builder) => {
    builder.addCase(activeSessionsAction.pending, (state) => {
      state.isFailure = false;
      state.isLoading = true;
      state.isSuccess = false;
      state.data = {};
    })
      .addCase(activeSessionsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isFailure = false;
        state.data = action.payload;
      })
      .addCase(activeSessionsAction.rejected, (state, action) => {
        state.isFailure = true;
        state.isSuccess = false;
        state.isLoading = false;
        state.error = action.payload;
      })
  }
})

export { activeSessionsAction };
export const { resetActiveSessions } = activeSessionsSlice.actions;
export default activeSessionsSlice.reducer;
