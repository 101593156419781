import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";
import { HEADER_TYPES, RequestBuilder, get_cookie } from "../../../utilities";

const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const removeCustomerQueueAction = createAsyncThunk('REMOVE_CUSTOMERS_QUEUE_ACTION', async (
    {
        user,
        queue,
        customer,
        
    }, {rejectWithValue}) => {
        try {
            const endpoint = `${user}/queue/${queue}/remove-customer`;
            const body = {customer}
            return await HttpApi.DeleteAsync(endpoint, true, body);
        } catch (err) {
            return rejectWithValue(err);
        }


})

const removeCustomerQueueSlice = createSlice({
    name: 'remove-customer-queue',
    initialState,
    reducers: {
        resetRemoveCustomerQueue: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(removeCustomerQueueAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(removeCustomerQueueAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(removeCustomerQueueAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {removeCustomerQueueAction};
export const { resetRemoveCustomerQueue } = removeCustomerQueueSlice.actions;

export default removeCustomerQueueSlice.reducer;
