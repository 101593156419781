import React, { useEffect, useState } from 'react';
import ModalHeader from "../Apps/draft-management/modal/ModalHeader";
import "../Apps/queue-management/index.css"
import RichTextEditor from "../Editor/RichTextEditor";
import { KTIcon, toAbsoluteUrl } from "../../_metronic/helpers";
import StartQueueTimePickerModal from "./StartQueueTimePickerModal";
import { useDispatch, useSelector } from 'react-redux';
import { getByIdQueueAction, resetByIdQueue } from '../../services/redux/queues/get-by-id';
import { get_local_storage_item } from '../../utilities';
import { isFailure, isLoading, isSuccess } from '../../utilities/reduxStateHelper';
import { getAllQueueItemsAction } from '../../services/redux/queues/get-queue-items';
import { formatDate, formatPhoneNumber, getStatusClassFontColor, getStatusText } from '../../utilities/stringHelper';
import { pauseQueueAction, resetPauseQueue } from '../../services/redux/queues/pause-queue';
import { fireToast } from '../../utilities/toastHelper';
import { startQueueAgainAction } from '../../services/redux/queues/start-queue-again';
import { handleDateForSubmit } from '../../utilities/dateHelper';
import HistoryTable from '../Tables/HistoryTable';
import SmallCustomerTable from '../Tables/SmallCustomerTable';
import { getAllQueueAction } from '../../services/redux/queues/get-all-queue';
import { HttpApi } from '../../services/api';
import { requestHistoryExport } from '../../services/redux/queues/export-history';

const tabs = [
    {
        id: 1,
        title: "Genel Bilgiler"
    },
    {
        id: 2,
        title: "Mesajlar",
    },
    {
        id: 3,
        title: "Müşteriler ve Dosyalar"
    },
];
const columns = [
    {
        title: 'Ad',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Soyad',
        dataIndex: 'lastName',
        key: 'lastName',
    },
];


const QueueInfoModal = ({ setIsShowQueueInfoModal, selectedQueue, setIsQueueStartedAgain, customTheme, state, id }) => {
    const dispatch = useDispatch();
    const queueState = useSelector((state) => state.queue.getQueueById);
    const queueItemsState = useSelector((state) => state.queue.getQueueItems);
    const queuePauseState = useSelector((state) => state.queue.pauseQueue);
    const queueStartState = useSelector((state) => state.queue.startQueueAgain);
    const getAllQueueState = useSelector((state) => state.queue.getAllQueue);

    const [selectedTab, setSelectedTab] = useState(1);
    const [userId,] = useState(id ? id : get_local_storage_item('user').id);
    const [queueInfo, setQueueInfo] = useState({});
    const [queueFiles, setQueueFiles] = useState([]);
    const [queueItems, setQueueItems] = useState([]);
    const [requested, setRequested] = useState(false);
    const [defaultPerPage, setDefaultPerPage] = useState(10);
    const [queueItemsPaginateData, setQueueItemsPaginateData] = useState();
    const [isShowStartQueueModal, setIsShowStartQueueModal] = useState(false);

    const [itemsKey, setItemKeys] = useState(columns);
    const [dataSource, setDataSource] = useState([]);

    const fetchNextPageItems = (page, perPage) => {
        dispatch(getAllQueueItemsAction({
            userId: userId,
            queue: selectedQueue._id,
            page: page ? page : 1,
            perPage: perPage ? perPage : defaultPerPage
        }
        ))
    }

    const handleSelectTimePicker = (hour = 0, minute = 0) => {
        dispatch(startQueueAgainAction({
            userId: userId, queueId: selectedQueue._id,
            startDate: handleDateForSubmit(hour, minute)
        }))
        setIsQueueStartedAgain(true)
    }

    const handleStopQueue = () => {
        const confirm = window.confirm('Kuyruğu duraklatmak istiyor musunuz?');

        if (confirm) {
            dispatch(pauseQueueAction({
                userId: userId,
                queueId: selectedQueue._id
            }))
            // Kuyruk burada durdurulacak.
        } else {

        }
    }

    const getAllQueueFetch = () => {
        dispatch(getAllQueueAction({
            userId: userId && userId,
            page: 1,
            perPage: defaultPerPage,
            state: state
        }))
        setIsQueueStartedAgain(true)
    }

    useEffect(() => {
        if (!requested) {
            dispatch(getByIdQueueAction({ userId: userId, queueId: selectedQueue._id }))
            setRequested(true);
        }
    }, [dispatch, requested, selectedQueue._id, userId])

    useEffect(() => {
        if (isSuccess(queueState)) {

            setQueueFiles(queueState.data.data.files);
            setQueueInfo(queueState.data.data.queueDetail);
            fetchNextPageItems()
        }
        if (isFailure(queueState)) {

        }
        if (isLoading(queueState)) {
        }
        return () => {
            resetByIdQueue()
        }
    }, [dispatch, queueState]);

    useEffect(() => {
        if (isSuccess(queueItemsState)) {
            setQueueItems(queueItemsState.data.data)
            setQueueItemsPaginateData(queueItemsState.data.meta)
            const firstOBj = queueItemsState.data.data[0];
            // eslint-disable-next-line array-callback-return
            let tempDataSource = [];
            queueItemsState.data.data.map((item) => {

                const data = {
                    id: item._id,
                    name: item.name,
                    phone: item.phone
                };
                tempDataSource.push(data);
            })
            setDataSource(tempDataSource);
        }
        if (isFailure(queueItemsState)) {
        }
        if (isLoading(queueItemsState)) {
        }
    }, [dispatch, queueItemsState]);

    useEffect(() => {
        if (isSuccess(queuePauseState)) {
            fireToast(queuePauseState.data.message, "SUCCESS", customTheme.theme)
            window.location.reload();
        }
        if (isFailure(queuePauseState)) {
            fireToast(queuePauseState.error.message, "FAIL", customTheme.theme)
        }
        if (isLoading(queuePauseState)) {

        }
        return () => {
            dispatch(resetPauseQueue());
        }
    }, [customTheme.theme, dispatch, queuePauseState])

    useEffect(() => {
        if (isSuccess(queueStartState)) {
            //dispatch(getByIdQueueAction({ userId: userId, queueId: selectedQueue._id }))
            getAllQueueFetch()
            fireToast(queueStartState.data.message, "SUCCESS", customTheme.theme)
            //window.location.reload();
        }
        if (isFailure(queueStartState)) {
            fireToast(queueStartState.error.message, "FAIL", customTheme.theme)
        }
        if (isLoading(queueStartState)) {

        }
        return () => {
            dispatch(resetPauseQueue());
        }
    }, [customTheme.theme, dispatch, queueStartState])

    const truncateFileName = (fileName, maxLength) => {
        const fileExtension = fileName.split('.').pop(); // Dosya uzantısını al
        const fileNameWithoutExtension = fileName.replace(`.${fileExtension}`, ''); // Dosya adını uzantısız al
        let truncatedName = fileNameWithoutExtension.substring(0, maxLength); // Belirli bir uzunluğa kadar kısalt

        // Eğer kısaltılan isim, asıl dosya adından daha kısa ise uzantıyı tekrar ekle
        if (truncatedName.length < fileNameWithoutExtension.length) {
            truncatedName += `...${fileExtension}`;
        } else {
            truncatedName += `.${fileExtension}`;
        }

        return truncatedName;
    }

    const getFileExtension = (fileName) => {
        const parts = fileName.split('.');
        const iconPart = parts[parts.length - 1].toLowerCase();
        const showToIcon = getIconByExtension(iconPart);
        return showToIcon;
    };

    const getIconByExtension = (fileExtension) => {
        switch (fileExtension) {
            case 'mp3':
                return '/media/svg/files/mp3.svg';
            case 'jpg':
                return '/media/svg/files/jpg.svg';
            case 'jpeg':
                return '/media/svg/files/jpg.svg';
            case 'png':
                return '/media/svg/files/png-file.svg';
            case 'mp4':
                return '/media/svg/files/mp4.svg';
            case 'pdf':
                return '/media/svg/files/pdf.svg';
            case 'ogg':
                return '/media/svg/files/mp3.svg';
            case 'waptt':
                return '/media/svg/files/mp3.svg';
            default:
                return '/media/svg/files/folder-document-dark.svg'; // Varsayılan ikon
        }
    };

    return (
        <div
            className='modal fade show d-block'
            id='kt_modal_add_draft'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
        >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-1000px'>
                {/* begin::Modal content */}
                <div className='modal-content' style={{ display: 'flex', justifyContent: 'center' }}>
                    <ModalHeader
                        title={"Kuyruk Bilgisi"}
                        handleCloseModal={() => setIsShowQueueInfoModal(false)}
                    />

                    {/* begin::Modal body */}
                    <div className={"d-flex flex-column p-8"}>
                        <div className={"d-flex justify-content-center mb-5"}>
                            {
                                tabs.map((item) => (
                                    <button
                                        className={selectedTab === item.id
                                            ? "btn btn-primary fw-bold border me-3 px-3 py-2 fs-7 fs-sm-4"
                                            : "btn btn-light px-3 py-2 me-3 fs-7 fs-sm-4"
                                        }
                                        key={item.id}
                                        onClick={() => setSelectedTab(item.id)}
                                    >
                                        {item.title}
                                    </button>
                                ))
                            }
                            {
                                 state !== "IN_PROGRESS" && (
                                    <button
                                        className={selectedTab === 4
                                            ? "btn btn-primary fw-bold border me-3 px-3 py-2 fs-7 fs-sm-4"
                                            : "btn btn-light px-3 py-2 me-3 fs-7 fs-sm-4"
                                        }
                                        onClick={() => setSelectedTab(4)}
                                    >
                                        Tarih
                                    </button>
                                    )
                            }
                        </div>
                        <div className={"card p-3 border rounded d-flex"}>
                            {
                                selectedTab === 1
                                    ? (
                                        <div className={"mt-5"}>
                                            <div className={`d-flex justify-content-center mb-5`}>
                                                <h1>{selectedQueue.queueTitle}</h1>
                                            </div>
                                            <div className={`d-flex pt-5 pb-5 ps-5 pe-3 w-100`}>
                                                <h5 className={`mb-0 w-25`}>Oturum:</h5>
                                                <h5 className={` mb-0 w-100`}>{formatPhoneNumber(selectedQueue.sessionId.split(':')[1])}</h5>
                                            </div>

                                            <div className={`d-flex pt-5 pb-5 ps-5 pe-3 w-100`}>
                                                <h5 className={`mb-0 w-25`}>Kuyruk Durumu:</h5>
                                                <h5
                                                    className={`mb-0 w-100 ${getStatusClassFontColor(selectedQueue.status)}`}
                                                >
                                                    {
                                                        (selectedQueue.status === "IN_PROGRESS" || 
                                                        selectedQueue.status === "COMPLETED" || 
                                                        selectedQueue.status === "PENDING" || 
                                                        selectedQueue.status === "PAUSED") ?
                                                        getStatusText(selectedQueue.status) : selectedQueue.status.split("|")[1]
                                                    }
                                                </h5>
                                            </div>
                                            <div className={`d-flex pt-5 pb-5 ps-5 pe-3 w-100`}>
                                                <h5 className={`mb-0 w-25`}>Başlangıç Zamanı:</h5>
                                                <h5 className={` mb-0 w-100`}>{formatDate(selectedQueue.startDate)}</h5>
                                            </div>
                                            <div className={`d-flex pt-5 pb-5 ps-5 pe-3 w-100`}>
                                                <h5 className={`mb-0 w-25`}>Oluşturulduğu Zaman:</h5>
                                                <h5 className={` mb-0 w-100`}>{formatDate(selectedQueue.createdAt)}</h5>
                                            </div>
                                            <div className={"d-flex justify-content-end mt-5"}>
                                                {
                                                    (selectedQueue.status === "IN_PROGRESS" || selectedQueue.status === "PENDING") && (
                                                        <button
                                                            className={"btn btn-danger"}
                                                            onClick={handleStopQueue}
                                                        >
                                                            Kuyruğu Duraklat
                                                        </button>
                                                    )
                                                }
                                                {
                                                    selectedQueue.status === "PAUSED" && (
                                                        <button
                                                            className={"btn btn-primary"}
                                                            onClick={() => {
                                                                setIsShowStartQueueModal(true)
                                                            }}
                                                        >
                                                            Kuyruğu Başlat
                                                        </button>
                                                    )
                                                }
                                            </div>

                                            {
                                                isShowStartQueueModal && (
                                                    <StartQueueTimePickerModal
                                                        setIsShowStartQueueModal={setIsShowStartQueueModal}
                                                        setIsShowQueueInfoModal={setIsShowQueueInfoModal}
                                                        handleSelectTimePicker={handleSelectTimePicker}
                                                    />
                                                )
                                            }
                                            {isShowStartQueueModal && <div className='modal-backdrop fade show'></div>}
                                        </div>
                                    ) : selectedTab === 2
                                        ? (
                                            <div className={""}>
                                                <RichTextEditor message={queueInfo.queueMessage} isDisabled={true}
                                                    customTheme={customTheme} />
                                            </div>
                                        ) : selectedTab === 3
                                            ? (
                                                <div className={"mt-1"}>
                                                    <div className={"px-5"}>

                                                        <SmallCustomerTable
                                                            dataSource={dataSource}
                                                            paginateData={queueItemsPaginateData}
                                                            fetchNextData={fetchNextPageItems}
                                                            customTheme={customTheme}
                                                            tableType="Info"
                                                        />

                                                    </div>
                                                    <div className={"card mt-10 mb-10 px-5"}>
                                                        <h2 className={"my-3"}>Dosyalar</h2>
                                                        <div className={"d-flex flex-column flex-md-row gap-3"}>
                                                            {
                                                                queueFiles && queueFiles.map((file, index) => (
                                                                    <div
                                                                        className={`d-flex flex-column align-items-center w-100 border py-5 px-2 rounded`}
                                                                        style={
                                                                            customTheme.theme === "light"
                                                                                ? { backgroundColor: customTheme.itemBg }
                                                                                : { backgroundColor: customTheme.itemBg }
                                                                        }
                                                                        key={`${file.file_type}-${file.file_name}-${index}`}
                                                                    >
                                                                        <img
                                                                            src={`${toAbsoluteUrl(getFileExtension(file.file_type))}`}
                                                                            alt={`svg icons`}
                                                                            style={{
                                                                                maxWidth: '50px',
                                                                                maxHeight: '50px',
                                                                                marginBottom: 10,
                                                                                objectFit: 'contain'
                                                                            }}
                                                                        />
                                                                        <h6 className={"mb-0"}>{truncateFileName(file.file_name, 10)}</h6>

                                                                        {/* Delete Button */}
                                                                        {/*<button
                                                                        className={"btn btn-danger py-2 mt-3"}
                                                                        onClick={() => deleteFile(file.file_name)}
                                                                    >
                                                                        Delete
                                                                    </button>*/}
                                                                    </div>
                                                                ))
                                                            }
                                                            {
                                                                queueFiles.length === 0 && (
                                                                    <div
                                                                        className={"d-flex p-20 w-100 justify-content-center align-items-center border rounded"}
                                                                    >
                                                                        <h1>Ekli dosya yok!</h1>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            : (
                                                <div className={'mt-5 d-flex flex-column'}>
                                                    <div style={{display: "flex", justifyContent: "center"}}>
                                                        <button
                                                            onClick={async () => {
                                                                await requestHistoryExport(userId, selectedQueue._id.toString())

                                                            }}
                                                            className="btn btn-success p-2 p-md-3 me-3 mb-4"
                                                            style={{
                                                                marginBottom: "10px"
                                                            }}
                                                        >
                                                            <KTIcon iconName='plus' className='fs-2'/>
                                                            {
                                                                "Excel Dışa Aktarma"
                                                            }
                                                        </button>
                                                    </div>
                                                    {/*<h1 className='mb-5 text-center'>Tarih Tablosu</h1>*/}
                                                    <HistoryTable queueId={selectedQueue._id.toString()} userId={userId}/>
                                                </div>
                                            )
                            }
                        </div>
                    </div>
                    {/* end::Modal body */}

                </div>
                {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}

        </div>
    )
}

export default QueueInfoModal;