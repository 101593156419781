import { ConfigProvider, Pagination } from "antd"
import { KTIcon } from "../../../../_metronic/helpers"

const CustomerTable = ({
    customers,
    paginateData,
    fetchNextData,
    handleSelectAllCheckbox,
    handleSingleCheck,
    selectedUsers,
    selectAll,
    customTheme
}) => {

    return (
        <div className={"card card-xl-stretch mb-xl-8"}>

            {/* begin::Header */}
            <div className='card-header align-items-center border-0 mt-4 w-100'>
                <div className={"card-title d-flex justify-content-between w-100"}>
                    <h2>Müşteriler</h2>
                    <h2>Secili Kisi: {selectedUsers ? selectedUsers.length : 0}</h2>
                </div>
            </div>
            {/* end::Header */}

            {/* begin::Body */}

            <div className={"card-body py-2"}>
                <div className={"dataTables_wrapper dt-bootstrap4 no-footer"}>
                    <div className={"table-responsive"}>
                        <table className={"table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"}>
                            <thead>
                                <tr className={"text-start text-muted fw-bold fs-7 text-uppercase gs-0"}>
                                    <th className={"w-10px pe-2 sorting_disabled"} rowSpan="1" colSpan="1" style={{ width: "27.6px" }}>
                                        <div className={"form-check form-check-sm form-check-custom form-check-solid me-3"}>
                                            <input
                                                className={"form-check-input"}
                                                type="checkbox"
                                                value="1"
                                                onChange={handleSelectAllCheckbox}
                                                checked={selectAll}
                                            />
                                        </div>
                                    </th>
                                    <th
                                        className={"min-w-125px sorting"}
                                        tabIndex="0"
                                        rowSpan="1"
                                        colSpan="1"
                                        style={{ width: "261.138px" }}
                                    >
                                        İsim
                                    </th>
                                    <th
                                        className={"min-w-125px sorting"}
                                        tabIndex="0"
                                        rowSpan="1"
                                        colSpan="1"
                                        style={{ width: "261.138px" }}
                                    >
                                        Soyisim
                                    </th>
                                </tr>
                            </thead>
                            <tbody className={"text-gray-600 fw-semibold"}>
                                {
                                    customers.map((user) => (
                                        <tr key={user.id}>
                                            <td>
                                                <div
                                                    className={"form-check form-check-sm form-check-custom form-check-solid"}
                                                >
                                                    <input
                                                        className={"form-check-input"}
                                                        type="checkbox"
                                                        value={user.id}
                                                        onChange={() => handleSingleCheck(user.id)}
                                                        checked={selectedUsers.includes(user.id)}
                                                    />
                                                </div>
                                            </td>
                                            <td>{user.name}</td>
                                            <td>{user.lastName}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                {
                    customers.length !== 0 && (
                        <div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: 25
                        }}>
                            <ConfigProvider
                                theme={{
                                    token: {
                                        colorBgContainer: customTheme.backgroundColor,
                                        colorText: customTheme.color
                                    }
                                }}
                            >
                                <Pagination
                                    locale={{ items_per_page: '/ sayfa' }}
                                    showSizeChanger
                                    onShowSizeChange={(current, pageSize) => {
                                        fetchNextData(1, pageSize)
                                    }}
                                    pageSizeOptions={[10, 50, 100, 250, 500]}
                                    defaultCurrent={1}
                                    total={paginateData.totalItems}
                                    pageSize={paginateData.perpage}
                                    className={customTheme.theme === "light" ? "light-theme" : "dark-theme"}
                                    onChange={(page, pageSize) => {
                                        fetchNextData(page, pageSize);
                                    }}
                                />
                            </ConfigProvider>
                        </div>
                    )
                }

            </div>

            {
                customers.length === 0 && (
                    <div className="w-100 d-flex flex-column align-items-center justify-content-center px-5">
                        <img
                            className="w-250px h-200px object-fit-contain"
                            src={"/media/illustrations/sigma-1/20.png"}
                            alt=""
                        />
                        <div className="d-flex align-items-center">
                            <KTIcon iconName="cross-circle" className="fs-2" />
                            <h3 className="fs-2 fw-bold mb-0 ms-3">Boş Veri</h3>
                        </div>
                    </div>
                )
            }


            {/* end::Body */}
        </div>
    )
}

export default CustomerTable