import { Route, Routes } from 'react-router-dom'
import {AuthLayout, VerifyLayout} from "../layouts";
import {
    VerifyEmail,
    Login,
    ForgotPassword,
    ForgotPasswordConfirm,
    Registration
} from '../components/Auth';
import { ReactivateAccount } from '../components/Auth/ReactivateAccount';
export const AuthPage = () => (
    <Routes>
        <Route element={<AuthLayout />}>
            <Route path='login' element={<Login  />} /> {/**ok */}
            <Route path='register' element={<Registration />} />
            <Route path='forgot-password' element={<ForgotPassword />} />
            <Route path='forgot-password-confirm/:token' element={<ForgotPasswordConfirm />} />
        </Route>
        <Route element={<VerifyLayout />}>
            <Route path=':id/verify-email/:token' element={<VerifyEmail />} />
            <Route path='forgot-password-confirm/:token' element={<ForgotPasswordConfirm />} />
            <Route path=':id/account/reactivate-account/:code' element={<ReactivateAccount />} />
        </Route>
    </Routes>
)
