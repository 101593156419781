import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";
import { remove_cookie, remove_local_storage_item } from "../../../utilities";


const initialState = {
  isLoading: false,
  isSuccess: false,
  isFailure: false,
  data: {},
  error: {}
};

const logoutAction = createAsyncThunk('LOGOUT_ACTION', async (_, { rejectWithValue }) => {
  try {
    const endpoint = `auth/logout`;
    const response =  await HttpApi.PostAsync(endpoint, true, {});
    window.location.href = "/auth/login"
    remove_cookie('session');
    remove_local_storage_item("user");
    return response;
  }
  catch (err) {
    return rejectWithValue(err);
  }
})

const logoutSlice = createSlice({
  name: 'logout',
  initialState,
  reducers: {
    resetLogoutState: (state) => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(logoutAction.pending, (state) => {
      state.isFailure = false;
      state.isLoading = true;
      state.isSuccess = false;
      state.data = {};
    })
      .addCase(logoutAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isFailure = false;
        state.data = action.payload;
      })
      .addCase(logoutAction.rejected, (state, action) => {
        state.isFailure = true;
        state.isSuccess = false;
        state.isLoading = false;
        state.error = action.payload;
      })
  }
});
export const { resetLogoutState } = logoutSlice.actions;
export { logoutAction };
export default logoutSlice.reducer;
