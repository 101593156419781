import { ProfileDetails } from "./ProfileDetails";
import { Notifications } from "./Notifications";
import { DeactivateAccount } from "./DeativateAccount";
import { DeleteAccount } from "./DeleteAccount";
import { useTheme } from "../../../services/themeService";
import PasswordChanges from "./PasswordChanges";
import { useState } from "react";
import { get_local_storage_item } from "../../../utilities";

export function Settings() {
    const { theme } = useTheme();
    const [user, setUser] = useState(get_local_storage_item("user"));

    return (
        <>
            <ProfileDetails customTheme={theme} />
            <Notifications isRenderingInAdmin={user.id}/>
            <PasswordChanges />
            {
                user && user.role === "User" && (
                    <div className={"d-flex flex-column flex-lg-row gap-lg-3"}>
                        <DeactivateAccount />
                        <DeleteAccount />
                    </div>
                )
            }
        </>
    )
}
