import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";

const initialState = {
    isLoading: false,
    isFailure: false,
    isSuccess: false,
    data: {},
    error: {}
}

const extraPhonePermissionAction = createAsyncThunk("EXTRA_PHONE_PERMISSION_ACTION", async (
    {
        userId,
        phoneAmount
    }, { rejectWithValue }) => {
    try {
        let endpoint = `admin/users/${userId}/extra-phone-permission`;
        const response = await HttpApi.PostAsync(endpoint, true, {phoneAmount: phoneAmount});
        return response;
    } catch (error) {
        return rejectWithValue(error)
    }
})

const extraPhonePermissionSlice = createSlice({
    name: 'extra-phone-permission',
    initialState,
    reducers: {
        resetExtraPhonePermission: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(extraPhonePermissionAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
        .addCase(extraPhonePermissionAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
        })
        .addCase(extraPhonePermissionAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
        })
    }
});

export {extraPhonePermissionAction};
export const {resetExtraPhonePermission} = extraPhonePermissionSlice.actions;
export default extraPhonePermissionSlice.reducer;