import cookie from "cookiejs";
import { get_env_data, is_production } from "./envHelper";

const set_cookie = (name, value, expireDate) => {
  //const domain = is_production() ? process.env.REACT_APP_WEB_URL : "localhost"
  sessionStorage.setItem(name, value)
  //cookie(name, value, { expires: 7, sameSite: 'strict', path: '', domain: domain, secure: true,  })
}

const get_cookie = (name) => {
  return sessionStorage.getItem(name)
  //return cookie.get(name) ? cookie.get(name) : undefined;
}

const remove_cookie = (name) => {
  sessionStorage.removeItem(name)
  //cookie.remove(name);
}

const get_local_storage_item = (name) => {
  return JSON.parse(localStorage.getItem(name));
}

const set_local_storage_item = (name, value) => {
  localStorage.setItem(name, JSON.stringify(value));
}

const remove_local_storage_item = (name) => {
  sessionStorage.removeItem(name);
}

export {
  get_local_storage_item,
  set_local_storage_item,
  remove_local_storage_item,
  set_cookie,
  get_cookie,
  remove_cookie
};
