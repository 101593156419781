/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import { useEffect, useState } from 'react'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import { get_local_storage_item } from '../../../../utilities'
export function AsideMenuMain() {
  const [role, setRole] = useState(get_local_storage_item("user").role)
  const intl = useIntl()
  
  useEffect(() => {
    // Belirli bir süre sonra sayfayı yeniden yükle
    setTimeout(() => {
      setRole(get_local_storage_item("user").role)
    }, 500); 

 
  }, []);

  return (
    <>
        {role && role === "Admin" && (
          <AsideMenuItem
            to='/admin/panel'
            icon='security-user'
            title="Yönetici Paneli"
          />
        )}
        
        <AsideMenuItem
          to='/dashboard'
          icon='element-11'
          title={"Planlama"}
        />
        <AsideMenuItemWithSub to='/settings/' title='Ayarlar' icon='profile-circle'>
            <AsideMenuItem to='/settings/overview' title='Genel Bakış' hasBullet={true} />
            <AsideMenuItem to='/settings/settings' title='Ayarlar' hasBullet={true} />
            <AsideMenuItem to='/settings/automation' title='Otomasyon' hasBullet={true} />
            <AsideMenuItem to='/settings/add-credit' title='Kredi Ekleme' hasBullet={true} />
            {role && role === "Admin" && (
              <AsideMenuItem to='/settings/configuration' title='Konfigürasyon' hasBullet={true} />
            )}
              {role && role === "Admin" && (
              <AsideMenuItem to='/settings/notifications' title='Bildirim Ayarları' hasBullet={true} />
            )}
        </AsideMenuItemWithSub>
    </>
  )
}
