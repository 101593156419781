import React, {createContext, useState, useContext} from 'react';

export const ThemeContext = createContext();

const defaultThemes = {
    dark: {
        backgroundColor: '#1E1E2D',
        color: 'white',
        itemBg: "#151521",
        theme: 'dark'
    },
    light: {
        backgroundColor: 'white',
        color: 'black',
        itemBg: "#F8F8F8",
        theme: 'light'
    }
};

export const ThemeProvider = ({children}) => {
    const [currentTheme, setCurrentTheme] = useState('light'); // Başlangıçta varsayılan tema

    // Tema değiştirme fonksiyonu
    const toggleTheme = (theme) => {
        setCurrentTheme(theme);
    };

    return(
        <ThemeContext.Provider
            value={{
                theme: defaultThemes[currentTheme],
                toggleTheme,
            }}
        >
            {children}
        </ThemeContext.Provider>
    )
}

export const useTheme = () => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme hook must be used within a ThemeProvider');
    }
    return context;
};