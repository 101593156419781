import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const getAllQueueItemsAction = createAsyncThunk('GET_ALL_QUEUE_ITEMS_ACTION', async (
    {
        userId,
        queue,
        page,
        perPage,
    }, {rejectWithValue}) => {
    try {
        const endpoint = !(page && perPage) ? `${userId}/queue/${queue}/items` : `${userId}/queue/${queue}/items?page=${page}&perpage=${perPage}`;

        return await HttpApi.GetAsync(endpoint, true);
    } catch (err) {
        return rejectWithValue(err);
    }


})

const getAllQueueItemsSlice = createSlice({
    name: 'all-queue-items',
    initialState,
    reducers: {
        resetAllQueueItems: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllQueueItemsAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(getAllQueueItemsAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(getAllQueueItemsAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {getAllQueueItemsAction};
export const { resetAllQueueItems } = getAllQueueItemsSlice.actions;

export default getAllQueueItemsSlice.reducer;
