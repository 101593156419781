import React from "react";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import "../index.css";
import ModalHeader from "./ModalHeader";

const DraftEmojiModal = ({setShowEmojiModal, setSelectedEmoji, customTheme}) => {
    return(
        <div
            className='modal fade show d-block'
            id='kt_modal_add_draft'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
        >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-450px'>
                {/* begin::Modal content */}
                <div className='modal-content' style={{display: 'flex', justifyContent: 'center'}}>
                    <ModalHeader
                        title={"Emoji Seçin"}
                        handleCloseModal={() => setShowEmojiModal(false)}
                    />

                    {/* begin::Modal body */}
                    <div className={"d-flex justify-content-center p-8"}>
                        <Picker data={data} onEmojiSelect={setSelectedEmoji} theme={customTheme.theme} />
                    </div>
                    {/* end::Modal body */}

                </div>
                {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}
        </div>
    )
}

export default DraftEmojiModal;