import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { fireToast } from "../../../../utilities/toastHelper";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";

const Step2 = ({ nextStep, prevStep, handleFiles, handleEditFiles, prevButtonShow = false, saveButtonShow = false, customTheme, formData }) => {
    const [uploadedFiles, setUploadedFiles] = useState(formData && formData.files ? formData.files : []);
    const maxSize = 200 * 1024 * 1024;
    const filesPerRowMobile = 2; // Mobilde her satırda gösterilecek dosya sayısı
    const filesPerRowDesktop = 5; // Büyük ekranlarda her satırda gösterilecek dosya sayısı

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/jpg': ['.jpg'],
            'image/jpeg': ['.jpeg'],
            'image/webp': ['.webp'],
            'image/png': ['.png'],
            'audio/mpeg': ['.mp3'],
            'video/mp4': ['.mp4'],
            'application/pdf': ['.pdf'],
            'audio/waptt': ['.waptt'],
            'audio/ogg': ['.ogg'],
        },
        onDrop: (acceptedFiles) => {
            if (uploadedFiles.length + acceptedFiles.length > 10) {
                fireToast("En fazla 10 dosya yüklenebilir!", "FAIL", customTheme.theme);
                return;
            }

            if (acceptedFiles[0].size > maxSize) {
                fireToast("Bir dosyanın boyutu en fazla 100 MB olabilir!", "FAIL", customTheme.theme);
                return;
            }

            setUploadedFiles([...uploadedFiles, ...acceptedFiles]);
        },
        maxFiles: 10,
    });

    const truncateFileName = (fileName, maxLength) => {
        const fileExtension = fileName.split('.').pop(); 
        const fileNameWithoutExtension = fileName.replace(`.${fileExtension}`, ''); 
        let truncatedName = fileNameWithoutExtension.substring(0, maxLength); 
        if (truncatedName.length < fileNameWithoutExtension.length) {
            truncatedName += `...${fileExtension}`;
        } else {
            truncatedName += `.${fileExtension}`;
        }
        return truncatedName;
    }

    const getFileExtension = (fileName) => {
        const parts = fileName.split('.');
        const iconPart = parts[parts.length - 1].toLowerCase();
        const showToIcon = getIconByExtension(iconPart);
        return showToIcon;
    };

    const getIconByExtension = (fileExtension) => {
        switch (fileExtension) {
            case 'mp3':
                return '/media/svg/files/mp3.svg';
            case 'jpg':
            case 'jpeg':
                return '/media/svg/files/jpg.svg';
            case 'png':
                return '/media/svg/files/png-file.svg';
            case 'mp4':
                return '/media/svg/files/mp4.svg';
            case 'pdf':
                return '/media/svg/files/pdf.svg';
            case 'ogg':
            case 'waptt':
                return '/media/svg/files/mp3.svg';
            default:
                return '/media/svg/files/folder-document-dark.svg'; 
        }
    };

    const renderUploadedFiles = () => {
        const filesPerRow = window.innerWidth <= 768 ? filesPerRowMobile : filesPerRowDesktop;
        const rows = [];
        for (let i = 0; i < uploadedFiles.length; i += filesPerRow) {
            const rowFiles = uploadedFiles.slice(i, i + filesPerRow);
            rows.push(
                <div className="row justify-content-center" key={i}>
                    {rowFiles.map((file, index) => (
                        <div className="col-md-2" key={`${file.type}-${file.name}-${index}`}>
                            <div className="d-flex flex-column align-items-center border py-3 px-2 rounded">
                                <img
                                    src={toAbsoluteUrl(getFileExtension(file.path))}
                                    alt="file-icon"
                                    style={{ maxWidth: '50px', maxHeight: '50px', marginBottom: 10, objectFit: 'contain' }}
                                />
                                <h6 className="mb-0">{truncateFileName(file.name, 10)}</h6>
                                <button
                                    className="btn btn-danger py-2 mt-3"
                                    onClick={() => removeFile(file.name)}
                                    type="button"
                                >
                                    Kaldır
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            );
        }
        return rows;
    };

    const removeFile = (fileName) => {
        const newFiles = uploadedFiles.filter(file => file.name !== fileName);
        setUploadedFiles(newFiles);
        fireToast("Dosya silindi.", "SUCCESS", customTheme.theme);
    };

    useEffect(() => {
        const handleResize = () => {
            // Yeniden boyutlandırma işlemleri
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="w-100 pe-5 ps-5">
            <div className="pb-5 pb-lg-10">
                <h2 className="fw-bolder d-flex align-items-center text-dark">
                    Dosya Yükleme
                    <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Dosya Yükleme" />
                </h2>
            </div>

            <div className="fv-row">
                <div className="row">
                    <div className="col-lg-12">
                        <div {...getRootProps()} className={`dropzone ${isDragActive ? "active" : ""} d-flex align-items-center justify-content-center mb-10`}>
                            <input {...getInputProps()} />
                            {isDragActive ? (
                                <p>Dosyaları buraya bırakın ...</p>
                            ) : (
                                <p>Bazı dosyaları buraya sürükleyip bırakın veya dosyaları seçmek için tıklayın</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {uploadedFiles.length > 0 && (
                <div>
                    <h3>Yüklenen Dosyalar</h3>
                    {renderUploadedFiles()}
                </div>
            )}

            <div className={`d-flex ${!saveButtonShow ? "justify-content-between" : "justify-content-end"} mt-5`}>
                {!prevButtonShow && (
                    <button
                        style={{
                            border: '1px solid',
                            padding: "8px 15px",
                            borderRadius: 10,
                            borderColor: '#0095E8',
                            backgroundColor: 'white',
                            color: '#0095E8'
                        }}
                        onClick={prevStep}
                    >
                        Önceki
                    </button>
                )}
                {!saveButtonShow ? (
                    <button
                        style={{
                            border: 'none',
                            padding: "8px 15px",
                            borderRadius: 10,
                            backgroundColor: '#0095E8',
                            color: 'white'
                        }}
                        onClick={() => {
                            handleFiles(uploadedFiles);
                            nextStep();
                        }}
                    >
                        Sonraki
                    </button>
                ) : (
                    <button
                        style={{
                            border: 'none',
                            padding: "8px 15px",
                            borderRadius: 10,
                            backgroundColor: '#0095E8',
                            color: 'white'
                        }}
                        onClick={() => handleEditFiles(uploadedFiles)}
                    >
                        Kaydet
                    </button>
                )}
            </div>
        </div>
    );
};

export { Step2 };
