import React from 'react'
import ModalHeader from '../ModalHeader'
import { formatPhoneNumber } from '../../../utilities/stringHelper'

const CustomerInfoModal = ({setIsShowCustomerInfoModal, selectedCustomer}) => {
    return (
        <div
            className='modal fade show d-block'
            id='kt_modal_user_info'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
        >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-700px'>
                {/* begin::Modal content */}
                <div className='modal-content' style={{ display: 'flex', justifyContent: 'center' }}>
                    <ModalHeader
                        title="Musteri Bilgileri"
                        handleCloseModal={() => setIsShowCustomerInfoModal(false)}
                    />

                    {/* begin::Modal body */}
                    <div className={"modal-body scroll-y mx-5 mx-xl-15 my-7"}>

                        <div className='d-flex flex-column'>
                            <h2>Ad: {selectedCustomer.name}</h2>
                            <h2>Soyad: {selectedCustomer.lastName}</h2>
                            <h2>Phone: {formatPhoneNumber(selectedCustomer.phone)}</h2>
                        </div>
                    </div>
                    {/* end::Modal body */}

                </div>
                {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}

        </div>
    )
}

export default CustomerInfoModal