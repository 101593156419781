import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";

const initialState = {
  isLoading: false,
  isSuccess: false,
  isFailure: false,
  data: {},
  error: {},
};

const updateNotificationSettingsAction = createAsyncThunk(
  "UPDATE_NOTIFICATION_SETTINGS_ACTION",
  async (
    {
      userId,
      uploadCreditObj,
      newQueueObj,
      queueBeginObj,
      queueErrorObj,
      queuePausedObj,
      queueFinishedObj,
      queueStartedAgainObj,
    },
    { rejectWithValue }
  ) => {
    try {
      const endpoint = `${userId}/profile/settings/update-notification-settings/`;
      const body = {
        uploadCreditObj: JSON.stringify(uploadCreditObj),
        newQueueObj: JSON.stringify(newQueueObj),
        queueBeginObj: JSON.stringify(queueBeginObj),
        queueErrorObj: JSON.stringify(queueErrorObj),
        queuePausedObj: JSON.stringify(queuePausedObj),
        queueFinishedObj: JSON.stringify(queueFinishedObj),
        queueStartedAgainObj: JSON.stringify(queueStartedAgainObj),
      };
      return await HttpApi.PatchAsync(endpoint, true, body);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const updateNotificationSettingsSlice = createSlice({
  name: "update-user-notification-settings",
  initialState,
  reducers: {
    resetUpdateNotificationSettings: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateNotificationSettingsAction.pending, (state) => {
        state.isFailure = false;
        state.isLoading = true;
        state.isSuccess = false;
        state.data = {};
      })
      .addCase(updateNotificationSettingsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isFailure = false;
        state.data = action.payload;
      })
      .addCase(updateNotificationSettingsAction.rejected, (state, action) => {
        state.isFailure = true;
        state.isSuccess = false;
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export { updateNotificationSettingsAction };
export const { resetUpdateNotificationSettings } =
  updateNotificationSettingsSlice.actions;
export default updateNotificationSettingsSlice.reducer;
