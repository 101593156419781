import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";
import { set_cookie, set_local_storage_item } from "../../../utilities";


const initialState = {
  isLoading: false,
  isSuccess: false,
  isFailure: false,
  data: {},
  error: {}
};

const resetSessionAction = createAsyncThunk('RESET_SESSION_LOGIN_ACTION', async ({
  id,
}, { rejectWithValue }) => {
  try {
    const endpoint = 'auth/login/reset-session';
    const body = { id };
    const response = await HttpApi.PostAsync(endpoint, false, body);
    set_cookie('session', response.data.token);
    set_local_storage_item("user", {id: response.data.id});
    window.location.href = "/dashboard";
    return response;
  }
  catch (err) {
    return rejectWithValue(err);
  }
})

const resetSessionLoginSlice = createSlice({
  name: 'reset-session-login',
  initialState,
  reducers: {
    resetReSessionState: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(resetSessionAction.pending, (state) => {
      state.isFailure = false;
      state.isLoading = true;
      state.isSuccess = false;
      state.data = {};
    })
      .addCase(resetSessionAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isFailure = false;
        state.data = action.payload;
      })
      .addCase(resetSessionAction.rejected, (state, action) => {
        state.isFailure = true;
        state.isSuccess = false;
        state.isLoading = false;
        state.error = action.payload;
      })
  }
});
export const {resetReSessionState} = resetSessionLoginSlice.actions;
export { resetSessionAction };
export default resetSessionLoginSlice.reducer;
