import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const getGroupByIdAction = createAsyncThunk('GET_CUSTOMER_ACTION', async (
    {
        userId,
        groupId,
    }, {rejectWithValue}) => {
    try {
        let endpoint = `${userId}/groups/single/${groupId}`;
        return await HttpApi.GetAsync(endpoint, true);
    } catch (err) {
        return rejectWithValue(err);
    }
})

const getGroupByIdSlice = createSlice({
    name: 'get-group-by-id',
    initialState,
    reducers: {
        resetGetGroupById: (state) => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(getGroupByIdAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(getGroupByIdAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(getGroupByIdAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {getGroupByIdAction};
export const { resetGetGroupById } = getGroupByIdSlice.actions;
export default getGroupByIdSlice.reducer;
