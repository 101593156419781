import React, { useCallback, useEffect, useState } from "react";
import { KTIcon, toAbsoluteUrl } from "../../_metronic/helpers";
import { Link } from "react-router-dom";
import UserBannedModal from "../Modals/UserBannedModal";
import { useDispatch, useSelector } from "react-redux";
import {
  isFailure,
  isLoading,
  isSuccess,
} from "../../utilities/reduxStateHelper";
import {
  getUsersAction,
  resetGetUsers,
} from "../../services/redux/admin/get-users";
import {
  blockUserAction,
  resetAdminBlockUser,
} from "../../services/redux/admin/block-user";
import {
  resetAdminUnblockUser,
  unblockUserAction,
} from "../../services/redux/admin/unblock-user";
import { fireToast } from "../../utilities/toastHelper";
import { useTheme } from "../../services/themeService";
import { ConfigProvider, Pagination } from "antd";
import { formatDate } from "../../utilities/stringHelper";
import { requestUserExport } from "../../services/redux/admin/export-user-data";

const UserTableWidget = () => {
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const getUsersState = useSelector((state) => state.admin.getAllUsers);
  const blockUserState = useSelector((state) => state.admin.blockUser);
  const unblockUserState = useSelector((state) => state.admin.unblockUser);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [filteredData, setFilteredData] = useState(users && users);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);  // Secili checkboxlarin tutuldugu dizi.
  const [isShowUserBannedModal, setIsShowUserBannedModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [paginateData, setPaginateData] = useState({});
  const [loading, setLoading] = useState(false);
  const [defaultPerPage, setDefaultPerPage] = useState(10)
  const [requested, setRequested] = useState(false);

  const handleSearch = (event) => {
    event.preventDefault()
    const searchTerm = event.target.value;

    setSearchTerm(searchTerm);

    const filterdData = users.filter((item) =>
      item.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.lastName.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilteredData(filterdData);
  };

  useEffect(() => {
    if (searchTerm != "" && searchTerm.length >= 1) {
      dispatch(getUsersAction({
        page: 1,
        perPage: defaultPerPage,
        search: searchTerm
      }))
    }
    else if (searchTerm == "") {
      dispatch(getUsersAction({
        page: 1,
        perPage: defaultPerPage
      }))
    }
  }, [dispatch, searchTerm]);

  // Checkboxlarin hepsini sec butonuna basildiginda calisacak fonksiyon.
  const handleSelectAllCheckbox = () => {
    const allUserIds = users && users.map((user) => user.id);
    if (!selectAll) {
      setSelectedUsers(allUserIds);
    } else {
      setSelectedUsers([]);
    }
    setSelectAll(!selectAll);
  };

  // Tek tek checkboxlarin secildigi fonksiyon.
  const handleSingleCheck = (userId) => {
    let updatedSelectedUsers = [...selectedUsers];

    if (updatedSelectedUsers.includes(userId)) {
      updatedSelectedUsers = updatedSelectedUsers.filter((id) => id !== userId);
    } else {
      updatedSelectedUsers.push(userId);
    }

    setSelectedUsers(updatedSelectedUsers);
  };

  const fetchGetAllUsers = () => {
    dispatch(
      getUsersAction({
        page: 1,
        perPage: defaultPerPage,
      })
    );
  };

  const blockTheUser = (userId) => {
    dispatch(
      blockUserAction({
        userId: userId,
      })
    );
    fetchGetAllUsers();
  };

  const unblockTheUser = (userId) => {
    dispatch(
      unblockUserAction({
        userId: userId,
      })
    );
    fetchGetAllUsers();
  };

  const fetchNextData = useCallback((page, perPage) => {
    dispatch(
      getUsersAction({
        page: page,
        perPage: perPage ? perPage : defaultPerPage,
      })
    );
  });


  useEffect(() => {
    if (!requested) {
      fetchGetAllUsers();
    }
    setRequested(true);
  }, [defaultPerPage, dispatch, requested]);

  useEffect(() => {
    if (isSuccess(getUsersState)) {
      setLoading(false);
      setUsers(getUsersState.data.data);
      setFilteredData(getUsersState.data.data);
      setPaginateData(getUsersState.data.meta);
    }
    if (isLoading(getUsersState)) {
      setLoading(true);
    }
    if (isFailure(getUsersState)) {
      setLoading(false);
    }
    return () => {
      dispatch(resetGetUsers());
    };
  }, [
    dispatch,
    getUsersState,
    defaultPerPage,
    blockUserState,
    unblockUserState,
  ]);

  useEffect(() => {
    if (isSuccess(blockUserState)) {
      fireToast(blockUserState.data.message, "SUCCESS", theme.theme);
    }
    if (isFailure(blockUserState)) {
      fireToast(blockUserState.data.message, "FAIL", theme.theme);
    }
    return () => {
      dispatch(resetAdminBlockUser());
    };
  }, [blockUserState, dispatch]);

  useEffect(() => {
    if (isSuccess(unblockUserState)) {
      fireToast(unblockUserState.data.message, "SUCCESS", theme.theme);
    }
    if (isFailure(unblockUserState)) {
      fireToast(unblockUserState.data.message, "FAIL", theme.theme);
    }
    return () => {
      dispatch(resetAdminUnblockUser());
    };
  }, [unblockUserState, dispatch]);

  return (
    <div className="card card-xl-stretch mb-xl-8">
      {/* begin::Header */}
      <div className="card-header align-items-center border-0 mt-4">
        <div className={"card-title w-100"}>
          <div
            className={
              "d-flex flex-column flex-sm-row align-items-sm-center justify-content-between w-100 my-1"
            }
          >
            <h2 className="mb-3 mb-sm-0">Kullanıcı Tablosu</h2>
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center me-3">
                <KTIcon
                  className={
                    "ki-duotone ki-magnifier fs-3 position-absolute ms-5"
                  }
                  iconName="magnifier"
                />
                <input
                  type="text"
                  className={"form-control form-control-solid w-250px ps-13 "}
                  placeholder={"Kullanıcı ara.."}
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>

              <button
                onClick={async () => {
                  await requestUserExport();
                }}
                className="btn btn-success p-2 p-md-3"
                style={{ marginLeft: '10px' }}
              >
                <KTIcon iconName="plus" className="fs-2"/>
                {"Excel Dışa Aktarma"}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* end::Header */}

      {/* begin::Body */}
      <div className={"card-body py-4"}>
        <div className={"dataTables_wrapper dt-bootstrap4 no-footer"}>
          <div
            className={"table-responsive"}
            style={{
              maxHeight: 900,
              overflowY: "auto",
            }}
          >
            <table
              className={
                "table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
              }
            >
              <thead>
                <tr
                  className={
                    "text-start text-muted fw-bold fs-7 text-uppercase gs-0"
                  }
                >
                  <th
                    className={"w-10px pe-2 sorting_disabled"}
                    rowSpan="1"
                    colSpan="1"
                    style={{ width: "20px" }}
                  >
                    <div
                      className={
                        "form-check form-check-sm form-check-custom form-check-solid me-3"
                      }
                    >
                      <input
                        className={"form-check-input"}
                        type="checkbox"
                        value="1"
                        onChange={handleSelectAllCheckbox}
                        checked={selectAll}
                      />
                    </div>
                  </th>
                  <th
                    className={"min-w-125px sorting"}
                    tabIndex="0"
                    rowSpan="1"
                    colSpan="1"
                    style={{ width: "261.138px" }}
                  >
                    Kullanıcı Bilgisi
                  </th>
                  <th
                    className={"min-w-125px sorting"}
                    tabIndex="0"
                    rowSpan="1"
                    colSpan="1"
                    style={{ width: "175px" }}
                  >
                    Kayıt Olma Tarihi
                  </th>
                  <th
                    className={"min-w-125px sorting"}
                    tabIndex="0"
                    rowSpan="1"
                    colSpan="1"
                    style={{ width: "152.663px" }}
                  >
                    Kredi Bilgisi
                  </th>
                  <th
                    className={"text-end min-w-100px sorting_disabled"}
                    tabIndex="0"
                    rowSpan="1"
                    colSpan="1"
                    style={{ width: "122.575px" }}
                  >
                    Aksiyonlar
                  </th>
                </tr>
              </thead>
              <tbody className={"text-gray-600 fw-semibold"}>
                {filteredData &&
                  filteredData.map((user) => (
                    <tr key={user.id}>
                      <td>
                        <div
                          className={
                            "form-check form-check-sm form-check-custom form-check-solid"
                          }
                        >
                          <input
                            className={"form-check-input"}
                            type="checkbox"
                            value={user.id}
                            onChange={() => handleSingleCheck(user.id)}
                            checked={selectedUsers.includes(user.id)}
                          />
                        </div>
                      </td>
                      <td className={"d-flex align-items-center"}>
                        {/* begin::Avatar */}
                        <div
                          className={
                            "symbol symbol-circle symbol-50px overflow-hidden me-3"
                          }
                        >
                          <div className={"symbol-label"}>
                            <img
                              src={
                                user.avatar === "blank"
                                  ? toAbsoluteUrl("/media/avatars/blank.png")
                                  : user.avatar
                              }
                              alt={user.firstName + user.lastName}
                              className={"w-100"}
                            />
                          </div>
                        </div>
                        {/* end::Avatar */}

                        {/* begin::Avatar */}
                        <div className={"d-flex flex-column"}>
                          <Link to={`user/${user.id}`}>
                            {user.firstName} {user.lastName}
                          </Link>
                        </div>
                        {/* end::Avatar */}
                      </td>
                      <td>{formatDate(user.registerDate)}</td>
                      <td>{user.isSuspend ? "Banlandi" : user.creditAmount}</td>
                      <td className={"text-end"}>
                        <div>
                          <Link
                            className={
                              "btn btn-icon btn-active-light-primary w-30px h-30px ms-auto me-2"
                            }
                            to={`user/${user.id}`}
                          >
                            <KTIcon iconName="pencil" className={"fs-3"} />
                          </Link>
                          <button
                            className={
                              "btn btn-icon btn-active-light-primary w-30px h-30px ms-auto"
                            }
                            type="button"
                            onClick={() => {
                              setSelectedUser(user);
                              setIsShowUserBannedModal(true);
                            }}
                          >
                            <KTIcon
                              iconName="minus-circle"
                              className={"text-danger fs-3"}
                            />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 10,
          }}
        >
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: theme.backgroundColor,
                colorText: theme.color,
              },
            }}
          >
            <Pagination
              locale={{ items_per_page: "/ sayfa" }}
              showSizeChanger
              onShowSizeChange={(current, pageSize) => {
                fetchNextData(1, pageSize);
              }}
              pageSizeOptions={[10, 50, 100, 250, 500]}
              defaultCurrent={1}
              total={paginateData.totalItems}
              pageSize={paginateData.perpage}
              className={theme.theme === "light" ? "light-theme" : "dark-theme"}
              onChange={(page, pageSize) => {
                fetchNextData(page, pageSize);
              }}
            />
          </ConfigProvider>
        </div>
      </div>
      {/* end::Body */}

      {isShowUserBannedModal && (
        <UserBannedModal
          setIsShowUserBannedModal={setIsShowUserBannedModal}
          selectedUser={selectedUser}
          blockTheUser={blockTheUser}
          unblockTheUser={unblockTheUser}
        />
      )}

      {isShowUserBannedModal && (
        <div className="modal-backdrop fade show"></div>
      )}
    </div>
  );
};

export default UserTableWidget;
