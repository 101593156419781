import React, { useState } from 'react';
import * as Yup from 'yup'
import { useFormik } from 'formik'

const registerTextSchema = Yup.object().shape({
    registerText: Yup.string().required('Mail Doğrulama metnini boş bırakamazsınız!'),
})

const RegisterText = ({defaultPrivacyText, onSubmitPrivacyText}) => {
    const [loadingUpdate, setLoadingUpdate] = useState(false)

    const initialValues = {
        registerText: defaultPrivacyText || ""
    }

    const [data, setData] = useState(initialValues)

    const updateData = (fieldsToUpdate) => {
        const updatedData = Object.assign(data, fieldsToUpdate)

        onSubmitPrivacyText(updatedData)
    }

    const formik = useFormik({
        initialValues,
        validationSchema: registerTextSchema,
        onSubmit: (values) => {
            //update profile details post request
            updateData(values)
        },
    })

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0'
                data-bs-target='#kt_kvkk_text'
                aria-expanded='true'
                aria-controls='kt_default_user_settings'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Doğrulama Mail Metni</h3>
                </div>
            </div>

            <div id='kt_account_profile_details' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Doğrulama Mail Alanı</label>
                            <div className='fv-row'>
                                <textarea
                                    type='text'
                                    rows={7}
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Mail adresi doğrulama yazısını buraya yazını...'
                                    {...formik.getFieldProps('registerText')}
                                />
                                {formik.touched.registerText && formik.errors.registerText && (
                                    <div className='fv-plugins-message-container' placeholder='Mail Doğrulama Metni...'>
                                        <div className='fv-help-block'>{formik.errors.registerText}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary' disabled={loadingUpdate}>
                            {!loadingUpdate && 'Kaydet'}
                            {loadingUpdate && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Lütfen Bekleyiniz...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default RegisterText