import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageTitle } from "../_metronic/layout/core";
import { AccountHeader } from '../components/Account/AccountHeader'
import { Overview } from "../components/Account/Overview";
import { Automation } from "../components/Account/automation/Automation";
import { Settings } from "../components/Account/settings/Settings";
import { useDispatch, useSelector } from 'react-redux';
import { userDetailsAction } from '../services/redux/profile/user-details';
import { isFailure, isLoading, isSuccess } from '../utilities/reduxStateHelper';
import Configuration from "../components/Account/configuration/Configuration";
import { creditInformationAction } from '../services/redux/profile/credit-info';
import AddCredit from '../components/Account/add-credit/AddCredit';
import NotificationSettings from '../components/Account/notificationSettings/NotificationSettings';

const AccountPage = () => {
    const profileSelector = useSelector((state) => state.profile.profileDetails);
    const creditInfoSelector = useSelector((state) => state.profile.creditInfo)
    const updateProfile = useSelector((state) => state.profile.updateProfile);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState({});
    const [credits, setCredits] = useState({})
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(userDetailsAction())
        return () => {

        }
    }, [dispatch])

    useEffect(() => {
        if (isSuccess(profileSelector)) {
            setLoading(false);
            setUser(profileSelector.data.data)
            if (profileSelector.data.data)
                dispatch(creditInformationAction({ user: profileSelector.data.data.id }))
        }
        if (isFailure(profileSelector)) {
            setLoading(false);
        }
        if (isLoading(profileSelector)) {
            setLoading(true);
        }

        return () => {

        }
    }, [profileSelector, updateProfile, user, dispatch])
    useEffect(() => {
        if (isSuccess(creditInfoSelector)) {
            setLoading(false);
            setCredits(creditInfoSelector.data.data)
        }
        if (isFailure(creditInfoSelector)) {
            setLoading(false);
        }
        if (isLoading(creditInfoSelector)) {
            setLoading(true);
        }

        return () => {
        }
    }, [profileSelector, updateProfile, dispatch, creditInfoSelector])

    return (
        <Routes>
            <Route
                element={
                    <>
                        {user && (

                            <AccountHeader
                                avatar={user.avatar}
                                firstName={user.firstName}
                                lastName={user.lastName}
                                role={user.role}
                                current_credit={credits.currentCredit ? credits.currentCredit : 0}
                                total_spent={credits.totalSpent ? credits.totalSpent : 0}
                                average_monthly_spend={credits.percentageAverage ? credits.percentageAverage : 0}
                            />)}

                        <Outlet />
                    </>
                }
            >
                <Route
                    path='overview'
                    index
                    element={
                        <>
                            <PageTitle breadcrumbs={[]}>Genel Bakış</PageTitle>
                            <Overview
                                props={
                                    {
                                        firstName: user.firstName,
                                        lastName: user.lastName,
                                        phone: user.phone,
                                        email: user.email,
                                        credit: user.credits,
                                        extraPhoneData: user.extraPhoneData,
                                        registerDate: user.registerDate
                                    }
                                }
                            />
                        </>
                    }
                />
                <Route
                    path='settings'
                    element={
                        <>
                            <PageTitle breadcrumbs={[]}>Ayarlar</PageTitle>
                            <Settings />
                        </>
                    }
                />
                <Route
                    path='automation'
                    element={
                        <>
                            <PageTitle breadcrumbs={[]}>Otomasyon</PageTitle>
                            <Automation user={user} />
                        </>
                    }
                />
                <Route
                    path='add-credit'
                    element={
                        <>
                            <PageTitle breadcrumbs={[]}>Kredi Ekleme</PageTitle>
                            <AddCredit />
                        </>
                    }
                />
                {
                    user && user.role === "Admin" && (
                        <Route
                            path='configuration'
                            element={
                                <>
                                    <PageTitle breadcrumbs={[]}>Konfigürasyon</PageTitle>
                                    <Configuration />
                                </>
                            }
                        />
                    )
                }
                {
                    user && user.role === "Admin" && (
                 <Route
                    path='notifications'
                    element={
                        <>
                            <PageTitle breadcrumbs={[]}>Bildirim Ayarları</PageTitle>
                            <NotificationSettings />
                        </>
                    }
                />
                    )
                }
                <Route path='*' element={<Navigate to='/settings/overview' />} />

            </Route>
        </Routes>
    )
}
export { AccountPage }