import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const addToBlackListAction = createAsyncThunk("ADD_TO_BLACKLIST_ACTION", async(
    {
        userId,
        customerId,
    }, {rejectWithValue}) =>
{
    try {
        let endpoint = `${userId}/blacklist/customers/add/${customerId}`;
        return await HttpApi.PostAsync(endpoint, true, {});
    }
    catch(err)
    {
        return rejectWithValue(err);
    }
});

const addToBlackListSlice = createSlice({
    name: "add-to-blacklist",
    initialState,
    reducers: {
        resetAddBlackListCustomer: (state) => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder.addCase(addToBlackListAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        }).addCase(addToBlackListAction.fulfilled, (state, action) => {
            state.isFailure = false;
            state.isLoading = false;
            state.isSuccess = true;
            state.data = action.payload;
        }).addCase(addToBlackListAction.rejected, (state, action) => {
            state.isFailure = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.data = action.payload;
        })
    }
})
export {addToBlackListAction};
export const {resetAddBlackListCustomer} = addToBlackListSlice.actions;
export default addToBlackListSlice.reducer;