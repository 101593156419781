import React, { useState } from 'react';
import CustomButton from "./CustomButton";
import DraftEmojiModal from "../Apps/draft-management/modal/DraftEmojiModal";

const RichTextEditor = ({ message, setMessage, customTheme, guideControle, isDisabled = false }) => {
    const [showEmojiModel, setShowEmojiModel] = useState(false);

    const handleFormat = (format) => {
        const textarea = document.getElementById("message-box");
        const { selectionStart, selectionEnd } = textarea;

        if (selectionStart !== selectionEnd) {
            const beforeSelection = message.slice(0, selectionStart);
            const selectedText = message.slice(selectionStart, selectionEnd);
            const afterSelection = message.slice(selectionEnd);

            let formattedText = selectedText;

            switch (format) {
                case 'bold':
                    formattedText = `*${selectedText}*`;
                    break;
                case 'italic':
                    formattedText = `_${selectedText}_`;
                    break;
                case 'strikethrough':
                    formattedText = `~${selectedText}~`;
                    break;
                // Diğer biçimlendirme türleri için benzer şekilde durumlar ekleyebilirsiniz
                default:
                    break;
            }

            const updatedText = `${beforeSelection}${formattedText}${afterSelection}`;
            setMessage(updatedText);
            textarea.focus();
        } else {
            // Eğer seçili metin yoksa, imlecin bulunduğu yere format sembollerini ekle
            const insertSymbol = (() => {
                switch (format) {
                    case 'bold':
                        return '*';
                    case 'italic':
                        return '_';
                    case 'strikethrough':
                        return '~';
                    default:
                        return '';
                }
            })();

            const textBeforeCursor = message.slice(0, selectionStart);
            const textAfterCursor = message.slice(selectionStart);

            const updatedText = `${textBeforeCursor}${insertSymbol}${insertSymbol}${textAfterCursor}`;
            setMessage(updatedText);
            textarea.focus();
        }
    };

    const handleList = (listType) => {
        const textarea = document.getElementById("message-box");
        const { selectionStart, selectionEnd } = textarea;

        if (selectionStart !== selectionEnd) {
            const beforeSelection = message.slice(0, selectionStart);
            const selectedText = message.slice(selectionStart, selectionEnd);
            const afterSelection = message.slice(selectionEnd);

            let formattedText = selectedText.split('\n').map((line, index) => {
                if (listType === 'unordered') {
                    return `- ${line}`;
                } else if (listType === 'ordered') {
                    return `${index + 1}. ${line}`;
                }
                return line;
            }).join('\n');

            const updatedText = `${beforeSelection}${formattedText}\n${afterSelection}`;
            setMessage(updatedText);
            textarea.focus();
        }
    };

    const handleEmojiSelect = (emoji) => {
        const emojiText = emoji.native; // Seçilen emoji metni
        const textarea = document.getElementById("message-box");
        const { selectionStart, selectionEnd } = textarea;

        const beforeSelection = message.slice(0, selectionStart);
        const afterSelection = message.slice(selectionEnd);

        const updatedText = `${beforeSelection}${emojiText}${afterSelection}`;
        setMessage(updatedText);
        textarea.focus();

        setShowEmojiModel(false); // Emoji seçildikten sonra emoji modalini kapat
    };

    const handleGuideButton = (guideText) => {
        const textarea = document.getElementById("message-box");
        const { selectionStart, selectionEnd } = textarea;

        const beforeSelection = message.slice(0, selectionStart);
        const afterSelection = message.slice(selectionEnd);

        const updatedText = `${beforeSelection}[${guideText}]${afterSelection}`;
        setMessage(updatedText);
        textarea.focus();
    };

    return (
        <div className={"card"}>
            <div className={"d-flex flex-column mt-7"}>
                <h4>Mesaj</h4>
                <div className={"d-flex justify-content-center pt-2 pb-2 border rounded-top-3 light"}>
                    <CustomButton
                        onPress={() => handleFormat('bold')}
                        isDisabled={isDisabled}
                        iconName="text-bold"
                    />
                    <CustomButton
                        onPress={() => handleFormat('italic')}
                        isDisabled={isDisabled}
                        iconName="text-italic"
                    />
                    <CustomButton
                        onPress={() => handleFormat('strikethrough')}
                        isDisabled={isDisabled}
                        iconName="text-strikethrough"
                    />
                    <CustomButton
                        onPress={() => handleList('ordered')}
                        isDisabled={isDisabled}
                        iconName="text-number"
                    />
                    <CustomButton
                        onPress={() => handleList('unordered')}
                        isDisabled={isDisabled}
                        iconName="text-circle"
                    />
                    <CustomButton
                        onPress={() => setShowEmojiModel(true)}
                        isDisabled={isDisabled}
                        iconName="emoji-happy"
                    />
                </div>
                <textarea
                    id="message-box"
                    rows={6}
                    className={`form-control form-control-solid w-100 mb-5 ${isDisabled === true && "user-select-none"}`}
                    placeholder="Lütfen göndermek istediğiniz mesajınızı yazınız.."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    disabled={isDisabled}
                />

                {
                    guideControle && guideControle === "quick" && (
                        <div className='d-flex align-items-center mb-5'>
                            <h6 className='mb-0'>Kulavuz:</h6>
                            <div className='d-flex align-items-center mb-0'>
                                <button className='btn btn-link mx-3' type='button' onClick={() => handleGuideButton('isim')}>
                                    İsim
                                </button>
                                -
                                <button className='btn btn-link mx-3' type='button' onClick={() => handleGuideButton('bilgi1')}>
                                    Bilgi 1
                                </button>
                                -
                                <button className='btn btn-link mx-3' type='button' onClick={() => handleGuideButton('bilgi2')}>
                                    Bilgi 2
                                </button>
                                -
                                <button className='btn btn-link mx-3' type='button' onClick={() => handleGuideButton('bilgi3')}>
                                    Bilgi 3
                                </button>
                            </div>
                        </div>
                    )
                }

            </div>
            {
                showEmojiModel && (
                    <DraftEmojiModal
                        setShowEmojiModal={setShowEmojiModel}
                        setSelectedEmoji={handleEmojiSelect}
                        customTheme={customTheme}
                    />
                )
            }
            {showEmojiModel && <div className='modal-backdrop fade show'></div>}
        </div>
    )
}

export default RichTextEditor;