import { useCallback, useEffect, useState } from 'react'
import { KTIcon } from '../../_metronic/helpers';
import { useTheme } from '../../services/themeService';
import { useDispatch, useSelector } from 'react-redux';
import { isFailure, isLoading, isSuccess } from '../../utilities/reduxStateHelper';
import { fireToast } from '../../utilities/toastHelper';
import { getGroupsAction, resetGetAllGroups } from '../../services/redux/customers/groups/get-groups';
import { ConfigProvider, Pagination, Spin } from 'antd';
import GroupInfoModal from '../Modals/UserDetailsModal/GroupInfoModal';
import { getGroupCustomersAction, resetGroupCustomers } from '../../services/redux/customers/groups/get-group-customer';

const GroupList = ({ userId }) => {
    const { theme } = useTheme();
    const dispatch = useDispatch();
    const groupsState = useSelector((state) => state.group.getAllGroups);
    const getGroupState = useSelector((state) => state.group.getGroupCustomers);

    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [selectedGroupPaginateData, setSelecteddGroupPaginateData] = useState({});
    const [selectedGroupLoading, setSelectedGroupLoading] = useState(false);
    const [paginateData, setPaginateData] = useState({});
    const [loading, setLoading] = useState(false);
    const [requested, setRequested] = useState(false);
    const [defaultPerPage, setDefaultPerPage] = useState(10);
    const [isShowGroupInfoModal, setIsShowGroupInfoModal] = useState(false);

    const fetchNextData = useCallback((page, perPage) => {
        dispatch(getGroupsAction({ userId: userId, page: page, perPage: perPage ? perPage : defaultPerPage }));
    })

    const groupDetailFetchNextData = useCallback((page, perPage) => {
        dispatch(getGroupCustomersAction({ userId: userId, page: page, perPage: perPage ? perPage : defaultPerPage }));
    })

    const getGroup = (id) => {
        dispatch(getGroupCustomersAction({
            userId: userId,
            groupId: id
        }));
    }

    useEffect(() => {
        if (!requested) {
            dispatch(getGroupsAction({
                userId: userId,
                page: 1,
                perPage: defaultPerPage
            }))
        }
        setRequested(true);
    }, [defaultPerPage, dispatch, paginateData, requested])

    useEffect(() => {
        if (isSuccess(groupsState)) {
            setGroups(groupsState.data.data);
            setPaginateData(groupsState.data.meta);
            setLoading(false)
        }
        if (isLoading(groupsState)) {
            setLoading(true)
        }
        if (isFailure(groupsState)) {
            fireToast("Islemde bir problem oldu.", "FAIL", theme.theme)
            setLoading(false)
        }
        return () => {
            dispatch(resetGetAllGroups());
        }
    }, [groupsState, dispatch]);

    useEffect(() => {
        if (isSuccess(getGroupState)) {
            setSelectedGroup(getGroupState.data.data);
            setSelecteddGroupPaginateData(getGroupState.data.meta)
            setIsShowGroupInfoModal(true);
            setSelectedGroupLoading(false)
        }
        if (isLoading(getGroupState)) {
            setSelectedGroupLoading(true)
        }
        if (isFailure(getGroupState)) {
            fireToast(getGroupState.error.message, "FAIL", theme.theme);
            setSelectedGroupLoading(false)
        }
        return () => {
            dispatch(resetGroupCustomers());
        }
    }, [getGroupState, dispatch]);

    return (
        <div className='card mb-xl-8 border mb-5'>

            {/* begin::Header */}
            <div className='card-header align-items-center border-0 mt-4'>
                <div className={"card-title"}>
                    <h2>Grup Listesi</h2>
                </div>
            </div>
            {/* end::Header */}

            <div className="card-body py-4">
                {
                    loading ? (
                        <div className="d-flex align-items-center justify-content-center w-100 ">
                            <Spin size="large" />
                        </div>
                    ) : (
                        <div className={"dataTables_wrapper dt-bootstrap4 no-footer"}>
                            <div className={"table-responsive"} style={{ maxHeight: 400, overflowY: "auto" }}>
                                <table className={"table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"}>
                                    <thead>
                                        <tr className={"text-start text-muted fw-bold fs-7 gs-0"}>
                                            <th className={"min-w-125px sorting"} tabIndex="0" rowSpan="1" colSpan="1"
                                                style={{ width: "150.138px" }}>
                                                Grup Ismi
                                            </th>
                                            <th
                                                className={"text-end min-w-100px sorting_disabled"}
                                                tabIndex="0"
                                                rowSpan="1"
                                                colSpan="1"
                                                style={{ width: "122.575px" }}
                                            >
                                                Aksiyon
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className={"text-gray-600 fw-semibold"}>
                                        {
                                            groups.map((group) => (
                                                <tr key={group._id}>
                                                    <td className='p-2'>{group.groupName}</td>
                                                    <td className={"text-end p-2"}>
                                                        <button
                                                            className={"btn btn-icon btn-active-light-primary w-30px h-30px ms-auto"}
                                                            type="button"
                                                            onClick={() => {
                                                                getGroup(group._id)
                                                            }}
                                                        >
                                                            <KTIcon iconName="information-2" className={"fs-3"} />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: 30,
                                    marginBottom: 15
                                }}
                            >
                                <ConfigProvider
                                    theme={{
                                        token: {
                                            colorBgContainer: theme.backgroundColor,
                                            colorText: theme.color
                                        }
                                    }}
                                >
                                    <Pagination
                                        locale={{ items_per_page: '/ sayfa' }}
                                        showSizeChanger
                                        onShowSizeChange={(current, pageSize) => {
                                            fetchNextData(1, pageSize)
                                        }}
                                        pageSizeOptions={[10, 50, 100, 250, 500]}     // Per page degerleri
                                        defaultCurrent={1}
                                        total={paginateData.totalItems}
                                        pageSize={paginateData.perpage}
                                        className={theme.theme === "light" ? "light-theme" : "dark-theme"}
                                        onChange={(page, pageSize) => {
                                            fetchNextData(page, pageSize);
                                        }}
                                    />
                                </ConfigProvider>
                            </div>
                        </div>
                    )
                }

            </div>

            {
                isShowGroupInfoModal && (
                    <GroupInfoModal
                        setIsShowGroupInfoModal={setIsShowGroupInfoModal}
                        selectedGroup={selectedGroup}
                        groupDetailFetchNextData={groupDetailFetchNextData}
                        loading={selectedGroupLoading}
                        paginateData={selectedGroupPaginateData}
                        theme={theme}
                    />
                )
            }
            {isShowGroupInfoModal && <div className='modal-backdrop fade show'></div>}
        </div>
    )
}

export default GroupList