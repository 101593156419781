import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const updateProfileAction = createAsyncThunk('UPDATE_PROFILE_ACTION', async (
    {
        userId,
        avatar,
        firstName,
        lastName,
    }, { rejectWithValue }) => {
    try {
        const endpoint = `${userId}/profile/details/update/`;
        const body  = {avatar, firstName, lastName};
        return await HttpApi.PatchAsync(endpoint, true, body);
    }
    catch (err) {
        return rejectWithValue(err);
    }
})


const updateProfileSlice = createSlice({
    name: 'update-profile',
    initialState,
    reducers: {
        resetUpdateProfile:(state) => {return initialState}
    },
    extraReducers: (builder) => {
        builder.addCase(updateProfileAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(updateProfileAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(updateProfileAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
})

export { updateProfileAction };
export const { resetUpdateProfile } = updateProfileSlice.actions;
export default updateProfileSlice.reducer;
