import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const createCustomerAction = createAsyncThunk('CREATE_CUSTOMER_ACTION', async (
    {
        userId,
        name = null,
        lastName,
        phone,
        addGrayList, addToGroup
    }, { rejectWithValue }) => {
    try {
        const endpoint = `${userId}/customers/create`;
        const body = { name, lastName, phone, addGrayList, addToGroup };
        return await HttpApi.PostAsync(endpoint, true, body);
    } catch (err) {
        return rejectWithValue(err);
    }


})

const createCustomerSlice = createSlice({
    name: 'create-customer',
    initialState,
    reducers: {
        resetCreateCustomer: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createCustomerAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(createCustomerAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(createCustomerAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export { createCustomerAction };
export const { resetCreateCustomer } = createCustomerSlice.actions;
export default createCustomerSlice.reducer;
