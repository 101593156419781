import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";

const initialState = {
    isLoading: false,
    isFailure: false,
    isSuccess: false,
    data: {},
    error: {}
}

const toggleServiceActivityAction = createAsyncThunk("TOGGLE_SERVICE_ACITIVITY_ACTION", async (
    {
        activity
    }, { rejectWithValue }) => {
    try {
        console.log(activity);
        let endpoint = `admin/settings/toggle-service-activity`;
        const response = await HttpApi.PostAsync(endpoint, true, {activity: activity});
        return response;
    } catch (error) {
        return rejectWithValue(error)
    }
})

const toggleServiceActivitySlice = createSlice({
    name: "admin-block-user",
    initialState,
    reducers: {
        resetAdmintoggleServiceActivity: (state) => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder.addCase(toggleServiceActivityAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        }).addCase(toggleServiceActivityAction.fulfilled, (state, action) => {
            state.isFailure = false;
            state.isLoading = false;
            state.isSuccess = true;
            state.data = action.payload;
        }).addCase(toggleServiceActivityAction.rejected, (state, action) => {
            state.isFailure = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.data = action.payload;
        })
    }
})

export {toggleServiceActivityAction};
export const {resetAdmintoggleServiceActivity} = toggleServiceActivitySlice.actions;
export default toggleServiceActivitySlice.reducer;