import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";
import axios from "axios";
import { HEADER_TYPES, RequestBuilder, get_cookie } from "../../../utilities";

const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const addQueueAction = createAsyncThunk('ADD_QUEUE_ACTION', async (
    {
        userId,
        files,
        title,
        message,
        sessionId,
        customers,
        groupId,
        quickData,
        startDate,
        useCustomAreaCode
    }, {rejectWithValue}) => {
    try {
        const endpoint = RequestBuilder.BuildEndpoint(`${userId}/queue/create`);
        console.log(title);
        
        const formData = new FormData();
        files.forEach((file, index) => {
          formData.append('files', file, file.name);
        });        
        formData.append('title', title);
        formData.append('message', message);
        formData.append('sessionId', sessionId);
        formData.append('groupId', groupId);
        formData.append('startDate', startDate);
        formData.append('useCustomAreaCode', useCustomAreaCode);
        customers.map((item, index) => {
            formData.append(`customers[${index}]`, item);
        })
        quickData.map((quickItem, index) => {
            for (const key in quickItem) {
                if (quickItem.hasOwnProperty(key)) {
                    formData.append(`quickData[${index}][${key}]`, quickItem[key]);
                }
            }
        });
        const config = {
          headers: {
            ...RequestBuilder.BuildHeader(HEADER_TYPES.FORM_AUTH, get_cookie("session")),
            'Content-Type': 'multipart/form-data',
          },
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
        };      
        const response = await axios.post(endpoint, formData, config);
        return response.data;        
        } 
        catch (err) {
            if(err.response && err.response.data){
                return rejectWithValue(err.response.data)
            }
        return rejectWithValue({
            data: {},
            message: "Dosya boyutu cok buyuk!",
            requirement: "",
            status_code: 413
        });
    }


})

const addQueueSlice = createSlice({
    name: 'add-queue',
    initialState,
    reducers: {
        resetAddQueue: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(addQueueAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(addQueueAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(addQueueAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {addQueueAction};
export const { resetAddQueue } = addQueueSlice.actions;

export default addQueueSlice.reducer;
