import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomersAction, resetGetCustomers } from "../../../../services/redux/customers/get-customers";
import { get_local_storage_item } from "../../../../utilities";
import { isFailure, isLoading, isSuccess } from "../../../../utilities/reduxStateHelper";
import "../../customer-management/index.css";
import CustomerTable from "../Table/CustomerTable";
import UserGroup from "../../user-group/UserGroup";
import QuickList from "../forms/QuickList";

export const Step3 = ({ nextStep, handleCustomers, formData, handleEditCustomers, customTheme, setGuideControle, saveButtonShow = false, tabVisible = false, handleGroup, handleQuickData, selectedCustomers, setSelectedCustomers, checkAreaCode, setCheckAreaCode }) => {
    const [customers, setCustomers] = useState([])
    const dispathcer = useDispatch()
    const getCustomersState = useSelector((state) => state.customer.getCustomers);
    const [selectedGroupControle, setSelectedGroupControle] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState(1);
    const [mainSelectedTab, setMainSelectedTab] = useState(1)
    const [paginateData, setPaginateData] = useState({});
    const [defaultPerPage, setDefaultPerPage] = useState(10);
    const [requested, setRequested] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState(formData && formData.customers ? selectedCustomers : []);
    const [quickData, setQuickData] = useState(formData && formData.quickData ? formData.quickData : [])

    const handleSelectAllCheckbox = () => {
        const allUserIds = customers.map(user => user.id);
        if (!selectAll) {
            setSelectedUsers(allUserIds);
            formData && setSelectedCustomers(allUserIds);
        } else {
            setSelectedUsers([]);
            formData && setSelectedCustomers([]);
        }
        setSelectAll(!selectAll);
    };

    // Tek tek checkboxlarin secildigi fonksiyon.
    const handleSingleCheck = (userId) => {
        let updatedSelectedUsers = [...selectedUsers];

        if (updatedSelectedUsers.includes(userId)) {
            updatedSelectedUsers = updatedSelectedUsers.filter(id => id !== userId);
        } else {
            updatedSelectedUsers.push(userId);
        }

        setSelectedUsers(updatedSelectedUsers);
        formData && setSelectedCustomers(updatedSelectedUsers); 
    };

    const fetchNextData = (page, perPage) => {
        dispathcer(getCustomersAction({ userId: get_local_storage_item("user").id, page: page, perPage: perPage ? perPage : defaultPerPage }))
    }

    useEffect(() => {
        if (!requested) {
            dispathcer(getCustomersAction({
                userId: get_local_storage_item("user").id,
                page: 1,
                perPage: defaultPerPage
            }))
            setRequested(true)
        }
    }, [dispathcer, defaultPerPage, paginateData, requested])

    useEffect(() => {
        if (isSuccess(getCustomersState) && getCustomersState.data) {
            setCustomers(getCustomersState.data.data);
            setPaginateData(getCustomersState.data.meta);
            setLoading(false);
        }

        if (isFailure(getCustomersState)) {
            setLoading(false);
        }

        if (isLoading(getCustomersState)) {
            setLoading(true);
        }

        return () => {
            dispathcer(resetGetCustomers());
        }
    }, [customers, getCustomersState, paginateData, fetchNextData, defaultPerPage]);

    return (
        <div className="d-flex flex-column align-items-center w-100">

            {
                !tabVisible && (
                    <div className="d-flex justif-content-center mb-10">
                        <div
                            className={mainSelectedTab === 1
                                ? "queue-info-tab-button queue-info-tab-active-button"
                                : "queue-info-tab-button"
                            }
                            onClick={() => setMainSelectedTab(1)}
                        >
                            Hızlı İşlemler
                        </div>
                        <div
                            className={mainSelectedTab === 2
                                ? "queue-info-tab-button queue-info-tab-active-button"
                                : "queue-info-tab-button"
                            }
                            onClick={() => setMainSelectedTab(2)}
                        >
                            Kayıtlı Müşteriler
                        </div>
                    </div>
                )
            }

            {
                !tabVisible && (
                    mainSelectedTab === 2 && (
                        <div className="d-flex justif-content-center mb-10">
                            <div
                                className={selectedTab === 1
                                    ? "queue-info-tab-button queue-info-tab-active-button"
                                    : "queue-info-tab-button"
                                }
                                onClick={() => setSelectedTab(1)}
                            >
                                Gruplar
                            </div>
                            <div
                                className={selectedTab === 2
                                    ? "queue-info-tab-button queue-info-tab-active-button"
                                    : "queue-info-tab-button"
                                }
                                onClick={() => setSelectedTab(2)}
                            >
                                Müşteriler
                            </div>
                        </div>
                    )
                )
            }

            <div className={"d-flex flex-column border rounded p-5 w-auto card w-700 h-auto"}>

                {
                    !tabVisible ? (
                        mainSelectedTab === 1 ? (
                            <QuickList
                                handleQuickData={handleQuickData}
                                setQuickData={setQuickData}
                                nextStep={nextStep}
                                setGuideControle={setGuideControle}
                                formData={formData}
                                mainSelectedTab={mainSelectedTab}
                                checkAreaCode={checkAreaCode}
                                setCheckAreaCode={setCheckAreaCode}
                            />
                        ) : (
                            selectedTab === 1 ? (
                                <UserGroup
                                    visibleItem={true}
                                    setSelectedGroupId={handleGroup}
                                    setSelectedGroupControle={setSelectedGroupControle}
                                />
                            ) : (
                                <CustomerTable
                                    customers={customers}
                                    paginateData={paginateData}
                                    fetchNextData={fetchNextData}
                                    customTheme={customTheme}
                                    handleSelectAllCheckbox={handleSelectAllCheckbox}
                                    handleSingleCheck={handleSingleCheck}
                                    selectedUsers={selectedUsers}
                                    selectAll={selectAll}
                                />
                            )
                        )
                    ) : (
                        <CustomerTable
                            customers={customers}
                            paginateData={paginateData}
                            fetchNextData={fetchNextData}
                            customTheme={customTheme}
                            handleSelectAllCheckbox={handleSelectAllCheckbox}
                            handleSingleCheck={handleSingleCheck}
                            selectedUsers={selectedUsers}
                            selectAll={selectAll}
                        />
                    )
                }

                <div className={`d-flex justify-content-end`}>
                    {
                        !saveButtonShow ? (
                            <button
                                className={"btn btn-primary"}
                                onClick={() => {
                                    handleCustomers(selectedUsers);
                                    setGuideControle("guide")
                                    nextStep();
                                }}
                                style={{
                                    display: mainSelectedTab === 1 ? "none" : "block"
                                }}
                                disabled={
                                    selectedUsers.length === 0 && selectedGroupControle === ""
                                        ? true
                                        : false
                                }
                            >
                                Sonraki
                            </button>
                        ) : (
                            <button
                                style={{
                                    border: 'none',
                                    padding: "8px 15px",
                                    borderRadius: 10,
                                    backgroundColor: '#0095E8',
                                    color: 'white'
                                }}
                                onClick={() => {
                                    handleEditCustomers(selectedUsers);
                                }}
                            >
                                Kaydet
                            </button>
                        )
                    }

                </div>
            </div>
        </div>
    );
}
