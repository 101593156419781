import React, { useEffect, useState } from 'react';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { get_local_storage_item } from '../../../utilities';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordAction, resetPasswordState } from '../../../services/redux/auth/reset-password';
import { isFailure, isLoading, isSuccess } from '../../../utilities/reduxStateHelper';
import { fireToast } from '../../../utilities/toastHelper';
import { useTheme } from '../../../services/themeService';
import { KTIcon } from '../../../_metronic/helpers';
import { logoutAction } from '../../../services/redux/auth/logout';

const passwordChangeSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Güncel Şifre gereklidir'),
    newPassword: Yup.string().required('Yeni Şifre gereklidir'),
    rePassword: Yup.string().required('Yeniden Şifre gereklidir'),
})

const PasswordChanges = () => {
    const [passwordVisibleOne, setPasswordVisibleOne] = useState(true);
    const [passwordVisibleTwo, setPasswordVisibleTwo] = useState(true);
    const [passwordVisibleThree, setPasswordVisibleThree] = useState(true);

    const { theme } = useTheme();
    const [user, setUser] = useState(get_local_storage_item("user"));
    const dispatch = useDispatch();
    const selector = useSelector((state) => state.auth.resetPassword);
    const [loadingUpdate, setLoadingUpdate] = useState(false);

    const initialValues = {
        currentPassword: '',
        newPassword: '',
        rePassword: '',
    }

    const [data, setData] = useState(initialValues);

    const updatePassword = (passwordToUpdate) => {
        if(passwordToUpdate.newPassword !== passwordToUpdate.rePassword){
            alert("Yeni parolanız ve tekrarlanan parola aynı değil")
            return;
        }
        const updatedPassword = Object.assign(data, passwordToUpdate);
        dispatch(resetPasswordAction({
            id: user.id,
            currentPassword: updatedPassword.currentPassword,
            newPassword: updatedPassword.newPassword
        }))
    }

    const formik = useFormik({
        initialValues,
        validationSchema: passwordChangeSchema,
        onSubmit: (values) => {
            updatePassword(values)
        }
    })

    useEffect(() => {
        if (isSuccess(selector)) {
            setLoadingUpdate(false);
            fireToast(selector.data.message, "SUCCESS", theme.theme)
            setTimeout(() => {
                dispatch(logoutAction())
            }, 2000);
        }
        if (isFailure(selector)) {
            setLoadingUpdate(false)
            fireToast(selector.data.message, "FAIL", theme.theme)
        }
        if (isLoading(selector)) {
            setLoadingUpdate(true)
        }

        return () => {
            dispatch(resetPasswordState())
        }
    }, [selector, setLoadingUpdate])

    return (
        <div className='card mb-5'>
            <div
                className='card-header border-0'
                aria-controls='kt_password_changes'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Şifre Değişiklikleri</h3>
                </div>
            </div>

            <div className='collapse show'>
                <form onSubmit={formik.handleSubmit} className='form'>

                    <div className='card-body border-top p-9'>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Geçerli Şifre</label>

                            <div className='col-lg-8'>
                                <div className='row'>
                                    <div className='d-flex align-items-center col-lg-6 fv-row'>
                                        <input
                                            type={!passwordVisibleOne ? 'text' : 'password'}
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            placeholder='Geçerli Şifre'
                                            {...formik.getFieldProps('currentPassword')}
                                        />
                                        <span
                                            className='btn btn-icon btn-light mb-3 mb-lg-0'
                                            onClick={() => {
                                                setPasswordVisibleOne(!passwordVisibleOne)
                                            }}
                                        >
                                            <KTIcon iconName={passwordVisibleOne ? 'eye' : 'eye-slash'} />
                                        </span>
                                    </div>
                                    {formik.touched.currentPassword && formik.errors.currentPassword && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.currentPassword}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Yeni Şifre</label>

                            <div className='d-flex align-items-center col-lg-6 fv-row'>
                                <input
                                    type={!passwordVisibleTwo ? 'text' : 'password'}
                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                    placeholder='Yeni Şifre'
                                    {...formik.getFieldProps('newPassword')}
                                />
                                <span
                                    className='btn btn-icon btn-light mb-3 mb-lg-0'
                                    onClick={() => {
                                        setPasswordVisibleTwo(!passwordVisibleTwo)
                                    }}
                                >
                                    <KTIcon iconName={passwordVisibleTwo ? 'eye' : 'eye-slash'} />
                                </span>
                            </div>
                            {formik.touched.newPassword && formik.errors.newPassword && (
                                <div className='fv-plugins-message-container' placeholder='Yeni Şifre'>
                                    <div className='fv-help-block'>{formik.errors.newPassword}</div>
                                </div>
                            )}
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Yeniden Parola</label>

                            <div className='d-flex align-items-center col-lg-6 fv-row'>
                                <input
                                    type={!passwordVisibleThree ? 'text' : 'password'}
                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                    placeholder='Yeniden Parola'
                                    {...formik.getFieldProps('rePassword')}
                                />
                                <span
                                    className='btn btn-icon btn-light mb-3 mb-lg-0'
                                    onClick={() => {
                                        setPasswordVisibleThree(!passwordVisibleThree)
                                    }}
                                >
                                    <KTIcon iconName={passwordVisibleThree ? 'eye' : 'eye-slash'} />
                                </span>
                            </div>
                            {formik.touched.rePassword && formik.errors.rePassword && (
                                <div className='fv-plugins-message-container' placeholder='Yeniden Parola'>
                                    <div className='fv-help-block'>{formik.errors.rePassword}</div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary' disabled={loadingUpdate}>
                            {!loadingUpdate && 'Kaydet'}
                            {loadingUpdate && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Lütfen Bekleyin...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>

                </form>
            </div>
        </div>
    )
}

export default PasswordChanges