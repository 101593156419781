import { useState } from 'react'
import ModalHeader from './ModalHeader';
import { ConfigProvider, Pagination } from 'antd';

const BulkUserAdditionModal = ({ setIsShowBulkUserAddModal, customers, fetchNextData, grayListAdded, blackListAdded, paginateData, customTheme }) => {

    const [selectAll, setSelectAll] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const handleSelectAllCheckbox = () => {
        const allUserIds = customers.map(user => user.id);
        if (!selectAll) {
            setSelectedUsers(allUserIds);
        } else {
            setSelectedUsers([]);
        }
        setSelectAll(!selectAll);
    };

    // Tek tek checkboxlarin secildigi fonksiyon.
    const handleSingleCheck = (userId) => {
        let updatedSelectedUsers = [...selectedUsers];

        if (updatedSelectedUsers.includes(userId)) {
            updatedSelectedUsers = updatedSelectedUsers.filter(id => id !== userId);
        } else {
            updatedSelectedUsers.push(userId);
        }

        setSelectedUsers(updatedSelectedUsers);
    };

    return (
        <div
            className='modal fade show d-block'
            id='kt_modal_add_draft'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
        >
            <div className='modal-dialog modal-dialog-centered mw-800px'>
                <div className='modal-content d-flex justify-content-center'>
                    <ModalHeader
                        title={"Kullanıcı Ekle"}
                        handleCloseModal={() => {
                            setIsShowBulkUserAddModal(false)
                        }}
                    />

                    <div className={"d-flex justify-content-center mb-xl-8 mt-5"}>
                        <div className={"d-flex flex-column pb-5 w-auto card w-700px h-auto"}>
                            {/* Customers Start */}
                            <div className={"card body px-3 py-2 border"}>
                                <div className={"dataTables_wrapper dt-bootstrap4 no-footer"}>
                                    <div className={"table-responsive"}>
                                        <table className={"table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"}>
                                            <thead>
                                                <tr className={"text-start text-muted fw-bold fs-7 text-uppercase gs-0"}>
                                                    <th className={"w-10px pe-2 sorting_disabled"} rowSpan="1" colSpan="1" style={{ width: "27.6px" }}>
                                                        <div className={"form-check form-check-sm form-check-custom form-check-solid me-3"}>
                                                            <input
                                                                className={"form-check-input"}
                                                                type="checkbox"
                                                                value="1"
                                                                onChange={handleSelectAllCheckbox}
                                                                checked={selectAll}
                                                            />
                                                        </div>
                                                    </th>
                                                    <th
                                                        className={"min-w-125px sorting"}
                                                        tabIndex="0"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        style={{ width: "261.138px" }}
                                                    >
                                                        İsim
                                                    </th>
                                                    <th
                                                        className={"min-w-125px sorting"}
                                                        tabIndex="0"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        style={{ width: "261.138px" }}
                                                    >
                                                        Soyisim
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className={"text-gray-600 fw-semibold"}>
                                                {
                                                    customers && customers.length > 0
                                                        ? (
                                                            customers.map((user) => (
                                                                <tr key={user.id}>
                                                                    <td>
                                                                        <div
                                                                            className={"form-check form-check-sm form-check-custom form-check-solid"}
                                                                        >
                                                                            <input
                                                                                className={"form-check-input"}
                                                                                type="checkbox"
                                                                                value={user.id}
                                                                                onChange={() => handleSingleCheck(user.id)}
                                                                                checked={selectedUsers.includes(user.id)}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>{user.name}</td>
                                                                    <td>{user.lastName}</td>
                                                                </tr>
                                                            ))
                                                        )
                                                        : (
                                                            <p>No customers available.</p>
                                                        )

                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginTop: 30,
                                        marginBottom: 15
                                    }}
                                >
                                    <ConfigProvider
                                        theme={{
                                            token: {
                                                colorBgContainer: customTheme.backgroundColor,
                                                colorText: customTheme.color
                                            }
                                        }}
                                    >
                                        <Pagination
                                            locale={{ items_per_page: '/ sayfa' }}
                                            showSizeChanger
                                            onShowSizeChange={(current, pageSize) => {
                                                fetchNextData(1, pageSize)
                                            }}
                                            pageSizeOptions={[10, 50, 100, 250, 500]}
                                            defaultCurrent={1}
                                            total={paginateData.totalItems}
                                            pageSize={paginateData.perpage}
                                            className={customTheme.theme === "light" ? "light-theme" : "dark-theme"}
                                            onChange={(page, pageSize) => {
                                                fetchNextData(page, pageSize);
                                            }}
                                        />
                                    </ConfigProvider>
                                </div>
                            </div>

                            <div className={"d-flex justify-content-end mt-5"}>
                                <button className={"btn btn-primary me-3"} onClick={() => {
                                    grayListAdded(selectedUsers)
                                    setIsShowBulkUserAddModal(false)
                                }}>
                                    Gri Listeye Ekle
                                </button>
                                <button className={"btn btn-primary"} onClick={() => {
                                    blackListAdded(selectedUsers)
                                    setIsShowBulkUserAddModal(false)
                                }}>
                                    Kara Listeye Ekle
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BulkUserAdditionModal