import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";

const initialState = {
    isLoading: false,
    isFailure: false,
    isSuccess: false,
    data: {},
    error: {}
}

const changeMailAction = createAsyncThunk("CHANGE_MAIL_ACTION", async (
    {
        userId,
        mail
    }, { rejectWithValue }) => {
    try {
        let endpoint = `admin/user/${userId}/change-mail`;
        const response = await HttpApi.PostAsync(endpoint, true, { mail });
        return response;
    } catch (error) {
        return rejectWithValue(error)
    }
})

const changeMailSlice = createSlice({
    name: 'admin-change-mail',
    initialState,
    reducers: {
        resetChangeMail: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(changeMailAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(changeMailAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(changeMailAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});

export { changeMailAction };
export const { resetChangeMail } = changeMailSlice.actions;
export default changeMailSlice.reducer;