import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const pauseQueueAction = createAsyncThunk('PAUSE_QUEUE_ACTION', async (
    {
        userId,
        queueId,
    }, {rejectWithValue}) => {
    try {
        const endpoint = `${userId}/queue/pause/${queueId}`;
        return await HttpApi.PostAsync(endpoint, true, {});
    } catch (err) {
        return rejectWithValue(err);
    }


})

const pauseQueueSlice = createSlice({
    name: 'pause-queue',
    initialState,
    reducers: {
        resetPauseQueue: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(pauseQueueAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(pauseQueueAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(pauseQueueAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {pauseQueueAction};
export const { resetPauseQueue } = pauseQueueSlice.actions;

export default pauseQueueSlice.reducer;
