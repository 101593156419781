

const isLoading = (state) => {
    if (state.isLoading && !state.isSuccess && !state.isFailure) return true;
    return false;
}
const isSuccess = (state) => {
    if (!state.isLoading && state.isSuccess && !state.isFailure) return true;
    return false;
}

const isFailure = (state) => {
    if (!state.isLoading && !state.isSuccess && state.isFailure) return true;
    return false;
}

export {isFailure, isLoading, isSuccess};