import { remove_cookie, remove_local_storage_item } from ".";
import { REQUEST_TYPES } from "./constants";
import axios from "axios";
export class HttpProccess {
  static MakeRequest = async (requestTypes, endpoint, header, body) => {
    // eslint-disable-next-line default-case
    switch (requestTypes) {
      case REQUEST_TYPES.GET:
        {
          return await axios.get(endpoint, { headers: header });
        }
      case REQUEST_TYPES.POST:
        {
          return await axios.post(endpoint, body ? body : {}, { headers: header });
        }
      case REQUEST_TYPES.PUT:
        {
          return await axios.put(endpoint, body ? body : {}, { headers: header });
        }
      case REQUEST_TYPES.PATCH:
        {
          return await axios.patch(endpoint, body ? body : {}, { headers: header });
        }
      case REQUEST_TYPES.DELETE:
        {
          return await axios.delete(endpoint, { headers: header });
        }
    }
  }
  static HandleRejection = (is_auth_necessary, response) => {

    if (is_auth_necessary && response.status_code === 401) {
      // todo: clean cookie and localStorage
      console.log('unauthorized')
      remove_cookie('session');
      remove_local_storage_item("session");
      window.location.href = "/auth/login";
    }
  }
}
