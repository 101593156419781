import React, { useEffect, useState } from 'react'
import { KTIcon } from '../../_metronic/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { getQueueHistoryAction } from '../../services/redux/queues/get-queue-history';
import { isFailure, isLoading, isSuccess } from '../../utilities/reduxStateHelper';
import { Spin } from 'antd';
import { formatDate, formatPhoneNumber } from '../../utilities/stringHelper';


const HistoryTable = ({ queueId, userId }) => {
    const dispatch = useDispatch()
    const selector = useSelector((state) => state.queue.getHistory)
    const [firstRequested, setFirstRequested] = useState(false)
    const [searchTerm, setSearchTerm] = useState("");
    const [messageColumns, setMessageColumns] = useState([]);

    const [queueHistory, setQueueHistory] = useState([]);
    const [paginateData, setPaginateData] = useState({})
    const [defaultPerPage,] = useState(10)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (!firstRequested) {
            dispatch(getQueueHistoryAction({ userId, queue: queueId, page: 1, perPage: paginateData ? paginateData.page : defaultPerPage }))
            setFirstRequested(true)
        }
    }, [defaultPerPage, dispatch, firstRequested, paginateData, queueId, userId])

    useEffect(() => {
        if (isSuccess(selector)) {
            setLoading(false)
            setPaginateData(selector.data.meta);
            setQueueHistory(selector.data.data);
        }
        if (isFailure(selector)) {
            setLoading(false);
        }
        if (isLoading(selector)) {
            setLoading(true);
        }
    }, [selector])

    useEffect(() => {
        extractMessageKeys();
    }, [ queueHistory])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function extractMessageKeys() {
        if (!Array.isArray(queueHistory) || queueHistory.length === 0) {
            return [];
        }
        let messageKeys = []
        // eslint-disable-next-line array-callback-return
        queueHistory.map((data) => {
            data.message_status.map((item) => {
                if (!messageKeys.includes(item.message) && item.message !== "")
                    messageKeys.push(item.message)
            })
        })
        if (messageKeys.length === 0)
            return []
        setMessageColumns(messageKeys)
    }

    /*const searchByName = () => {
        const term = searchTerm.toLowerCase().trim();
        const searchResults = [];
        queueHistory.forEach((item) => {
            const userName = (item.customerInfo.name + item.customerInfo.lastName).toLowerCase();
            if (userName.includes(term)) {
                searchResults.push(item);
            }
        });
        queueHistory(searchResults)
    };*/

    return (
        loading ? (

            <Spin />


        ) : (
            <>
                <div className='card card-xl-stretch mb-xl-8 border rounded'>

                    {/*<div className='card-header align-items-center border-0 mt-4'>
                        <div className={"card-title"}>
                            <div className={"d-flex align-items-center position-relative my-1"}>
                                <KTIcon className={"ki-duotone ki-magnifier fs-3 position-absolute ms-5"} iconName="magnifier" />
                                <input
                                    type="text"
                                    className={"form-control form-control-solid w-250px ps-13"}
                                    placeholder={"Seacrh kullanıcısı"}
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>*/}
                    <div className={"card-body py-4"}>
                        <div className={"dataTables_wrapper dt-bootstrap4 no-footer"}>
                            <div className={"table-responsive"}>
                                <table className={"table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"}>
                                    {
                                        !loading && (
                                            <>
                                                <thead>
                                                    <tr className={"text-start text-muted fw-bold fs-7 gs-0"}>
                                                        <th className={"min-w-150px sorting"} tabIndex="0" rowSpan="1" colSpan="1"
                                                            style={{ width: "150px" }}>
                                                            İsim
                                                        </th>
                                                        <th className={"min-w-200px sorting"} tabIndex="0" rowSpan="1" colSpan="1"
                                                            style={{ width: "200px" }}>
                                                            Numara
                                                        </th>
                                                        {
                                                            messageColumns && messageColumns.map((column, index) => (
                                                                <th
                                                                    key={index}
                                                                    className={"min-w-125px sorting"}
                                                                    tabIndex="0"
                                                                    rowSpan="1"
                                                                    colSpan="1"
                                                                    style={{ width: "150px" }}
                                                                >
                                                                    {
                                                                        column === "extendedTextMessage"
                                                                            ? "Kısa Mesaj"
                                                                                : column === "documentMessage"
                                                                                    ? "Belge Mesajı"
                                                                                    : column === "imageMessage"
                                                                                        ? "Resim Mesajı"
                                                                                        : "Sesli Mesaj"
                                                                    }
                                                                </th>
                                                            ))
                                                        }

                                                        <th className={"min-w-200px sorting"} tabIndex="0" rowSpan="1" colSpan="1"
                                                            style={{ width: "200px" }}>
                                                            Ne Zaman Geldi
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className={"text-gray-600 fw-semibold"}>
                                                    {
                                                        queueHistory && queueHistory.map((data, index) => (
                                                            <tr key={data._id}>
                                                                <td className={"d-flex align-items-center"}>
                                                                    <div className={"d-flex flex-column"}>
                                                                        <div className='fw-bold fs-24'>{data.name}</div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className='fw-bold fs-24'>{formatPhoneNumber(data.phone)}</div>
                                                                </td>

                                                                {
                                                                    data.message_status.map((item, innerIndex) =>
                                                                        <React.Fragment key={innerIndex}>
                                                                            <td>
                                                                                <div
                                                                                    className={
                                                                                        `fw-bold 
                                                                                        ${item.status === "Read"
                                                                                            ? "text-success"
                                                                                            : item.status === "In_Progress"
                                                                                                ? "text-primary"
                                                                                                : item.status === "Error"
                                                                                                    ? "text-danger"
                                                                                                    : "text-default"
                                                                                        }
                                                                                    `}
                                                                                >{item.status}</div>
                                                                            </td>
                                                                            <td>
                                                                                <div className={"fw-bold text-primary"}>{formatDate(item.sent_at)}</div>
                                                                            </td>

                                                                        </React.Fragment>
                                                                    )
                                                                }

                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </>
                                        )
                                    }

                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )

    )
}

export default HistoryTable