import { useCallback, useEffect, useState } from "react";
import { KTIcon } from "../../_metronic/helpers";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "../../services/themeService";
import { getDraftsAction, resetGetAllDraft } from "../../services/redux/drafts/get-all-draft";
import { isFailure, isLoading, isSuccess } from "../../utilities/reduxStateHelper";
import { fireToast } from "../../utilities/toastHelper";
import { ConfigProvider, Pagination, Spin } from "antd";
import { getDraftByIdAction, resetDraftGetByID } from "../../services/redux/drafts/get-draft-by-id";
import DraftInfoModal from "../Modals/UserDetailsModal/DraftInfoModal";

const DraftTable = ({ userId }) => {
    const { theme } = useTheme();
    const dispatch = useDispatch();
    const draftsState = useSelector((state) => state.draft.getAllDraft);
    const getDraftState = useSelector((state) => state.draft.getByIdDraft);

    const [drafts, setDrafts] = useState([]);
    const [selectedDraft, setSelectedDraft] = useState({});
    const [paginateData, setPaginateData] = useState({});
    const [loading, setLoading] = useState(false);
    const [requested, setRequested] = useState(false);
    const [defaultPerPage, setDefaultPerPage] = useState(10);
    const [isShowDraftInfoModal, setIsShowDraftInfoModal] = useState(false);

    const fetchNextData = useCallback((page, perPage) => {
        dispatch(getDraftsAction({ userId: userId, page: page, perPage: perPage ? perPage : defaultPerPage }));
    })

    const getDraft = (id) => {
        dispatch(getDraftByIdAction({
            userId: userId,
            draftId: id
        }));
    }

    useEffect(() => {
        if (!requested) {
            dispatch(getDraftsAction({
                userId: userId,
                page: 1,
                perPage: defaultPerPage
            }))
        }
        setRequested(true);
    }, [defaultPerPage, dispatch, paginateData, requested])

    useEffect(() => {
        if (isSuccess(draftsState)) {
            setDrafts(draftsState.data.data);
            setPaginateData(draftsState.data.meta);
            setLoading(false)
        }
        if (isLoading(draftsState)) {
            setLoading(true)
        }
        if (isFailure(draftsState)) {
            fireToast("Islemde bir problem oldu.", "FAIL", theme.theme)
            setLoading(false)
        }
        return () => {
            dispatch(resetGetAllDraft());
        }
    }, [draftsState, dispatch]);

    useEffect(() => {
        if (isSuccess(getDraftState)) {
            setSelectedDraft(getDraftState.data.data);
            setIsShowDraftInfoModal(true);
        }
        if (isLoading(getDraftState)) { }
        if (isFailure(getDraftState)) {
            fireToast(getDraftState.error.message, "FAIL", theme.theme);
        }
        return () => {
            dispatch(resetDraftGetByID());
        }
    }, [getDraftState, dispatch]);

    return (
        <div className='card mb-xl-8 border mb-5'>

            {/* begin::Header */}
            <div className='card-header align-items-center border-0 mt-4'>
                <div className={"card-title"}>
                    <h2>Taslak Listesi</h2>
                </div>
            </div>
            {/* end::Header */}

            <div className="card-body py-4">
                {
                    loading ? (
                        <div className="d-flex align-items-center justify-content-center w-100 ">
                            <Spin size="large" />
                        </div>
                    ) : (
                        <div className={"dataTables_wrapper dt-bootstrap4 no-footer"}>
                            <div className={"table-responsive"} style={{ maxHeight: 400, overflowY: "auto" }}>
                                <table className={"table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"}>
                                    <thead>
                                        <tr className={"text-start text-muted fw-bold fs-7 gs-0"}>
                                            <th className={"min-w-125px sorting"} tabIndex="0" rowSpan="1" colSpan="1"
                                                style={{ width: "150.138px" }}>
                                                Taslak Basligi
                                            </th>
                                            <th
                                                className={"text-end min-w-100px sorting_disabled"}
                                                tabIndex="0"
                                                rowSpan="1"
                                                colSpan="1"
                                                style={{ width: "122.575px" }}
                                            >
                                                Aksiyon
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className={"text-gray-600 fw-semibold"}>
                                        {
                                            drafts.map((draft) => (
                                                <tr key={draft.id}>
                                                    <td className='p-2'>{draft.title}</td>
                                                    <td className={"text-end p-2"}>
                                                        <button
                                                            className={"btn btn-icon btn-active-light-primary w-30px h-30px ms-auto"}
                                                            type="button"
                                                            onClick={() => {
                                                                getDraft(draft.id)
                                                            }}
                                                        >
                                                            <KTIcon iconName="information-2" className={"fs-3"} />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    marginTop: 30,
                                    marginBottom: 15
                                }}
                            >
                                <ConfigProvider
                                    theme={{
                                        token: {
                                            colorBgContainer: theme.backgroundColor,
                                            colorText: theme.color
                                        }
                                    }}
                                >
                                    <Pagination
                                        locale={{ items_per_page: '/ sayfa' }}
                                        showSizeChanger
                                        onShowSizeChange={(current, pageSize) => {
                                            fetchNextData(1, pageSize)
                                        }}
                                        pageSizeOptions={[10, 50, 100, 250, 500]}     // Per page degerleri
                                        defaultCurrent={1}
                                        total={paginateData.totalItems}
                                        pageSize={paginateData.perpage}
                                        className={theme.theme === "light" ? "light-theme" : "dark-theme"}
                                        onChange={(page, pageSize) => {
                                            fetchNextData(page, pageSize);
                                        }}
                                    />
                                </ConfigProvider>
                            </div>
                        </div>
                    )
                }
            </div>

            {
                isShowDraftInfoModal && (
                    <DraftInfoModal
                        setIsShowDraftInfoModal={setIsShowDraftInfoModal}
                        selectedDraft={selectedDraft}
                    />
                )
            }
            {isShowDraftInfoModal && <div className='modal-backdrop fade show'></div>}
        </div>
    )
}

export default DraftTable