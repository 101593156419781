import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";

const initialState = {
    isLoading: false,
    isFailure: false,
    isSuccess: false,
    data: {},
    error: {}
}

const updateRegisterTextAction = createAsyncThunk("UPDATE_REGISTER_TEXT_ACTION", async (
    {
        text
    }, { rejectWithValue }) => {    
    try {
        let endpoint = `admin/settings/defaults/update/register-text`;
        const response = await HttpApi.PatchAsync(endpoint, true, {text: text.registerText});
        return response;
    } catch (error) {
        return rejectWithValue(error)
    }
})

const updateRegisterTextSlice = createSlice({
    name: 'update-register-text',
    initialState,
    reducers: {
        resetUpdateRegisterText: (state) => { return initialState }
    },
    extraReducers: (builder) => {
        builder.addCase(updateRegisterTextAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
        .addCase(updateRegisterTextAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
                state.error = {};
        })
        .addCase(updateRegisterTextAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
                state.data = {};
        })
    }
});

export {updateRegisterTextAction};
export const {resetUpdateRegisterText} = updateRegisterTextSlice.actions;
export default updateRegisterTextSlice.reducer;