import {Suspense, useEffect, useState} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator'
import { AccountPage } from '../pages/AccountPage';

import {DashboardWrapper} from "../pages";
import {getCSSVariableValue} from "../_metronic/assets/ts/_utils";
import QueueWizardsPage from "../pages/QueuePage";
import Error404 from "../components/Errors/Error404";
import AdminWrapper from "../pages/AdminWrapper";
import { useDispatch, useSelector } from 'react-redux';
import { get_cookie, get_local_storage_item } from '../utilities';
import { resetProfileDetails, userDetailsAction } from '../services/redux/profile/user-details';
import { isSuccess } from '../utilities/reduxStateHelper';
import { io } from 'socket.io-client';
import { onlineUsersUserPath } from '../utilities/requestBuilder';



const userOnlineUsersSocket = io(onlineUsersUserPath, {
    path: "/socket.io",
    extraHeaders: {
        "x-api-gateway": "test1"
    },
    secure: true,
    autoConnect: false,
    requestTimeout: 3000
});
const events = {
    here: "Im here",
    quit: "quit"
}
const PrivateRoutes = () => 
{
    
    const dispatcher = useDispatch();
    const selector = useSelector((state) => state.profile.profileDetails);
    const [user, setUser] = useState(get_local_storage_item("user"));
    const [session,] = useState(get_cookie("session"))

    const quitEventListener = () => {
        userOnlineUsersSocket.emit(events.quit, {id: user && user.id})
    }

    window.addEventListener('beforeunload', quitEventListener)
    useEffect(() => {
        if(session)
        {
            if(!user || (user && !user.firstName))
            {
                dispatcher(userDetailsAction());
            }
        }
    }, [dispatcher, session, user])

    useEffect(() => {
        if(session)
        {
            if (isSuccess(selector))
            {
                setUser(get_local_storage_item("user"));
            }
            return () => {
                dispatcher(resetProfileDetails());
            }
        }
    }, [dispatcher, selector, session, user])

    useEffect(() => {
        if (user && user.role === "User") {
            if (!userOnlineUsersSocket.active) userOnlineUsersSocket.connect();
    
            // Belirli süre aralıklarında veri gönderimi için setInterval kullanılıyor
            const intervalId = setInterval(() => {
                userOnlineUsersSocket.emit(events.here, {
                    id: user.id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    avatar: user.avatar
                });
                
            }, 10000); // Her 10 saniyede bir çalıştır
    
            // Temizlik fonksiyonu
            return () => {
                clearInterval(intervalId); // Interval temizleniyor
                //userOnlineUsersSocket.disconnect(); // Gerekirse bağlantıyı kesin
            };
        }
    }, [user, userOnlineUsersSocket]);
    return (
        <>
            {user && (
                <>
                    <Routes>
                        <Route element={<MasterLayout />}>
                            {/* <Route path='auth/*' element={<Navigate to='/auth/login'/>}/> */}
            
                            {
                                user.role === "Admin" && (
                                    <Route path='admin/*' element={<AdminWrapper />}/>
                                )
                            }
                            <Route path='dashboard' element={<DashboardWrapper/>}/>
            
                            <Route
                                path='settings/*'
                                element={
                                    <SuspensedView>
                                        <AccountPage />
                                    </SuspensedView>
                                }
                            />
                            <Route
                                path='queue/*'
                                element={
                                    <SuspensedView>
                                        <QueueWizardsPage />
                                    </SuspensedView>
                                }
                            />
                            {/*
                        <Route
                            path='admin/settings/*'
                            element={
                                <SuspensedView>
                                    <AdminAccountPage/>
                                </SuspensedView>
                            }
                        />
            
                        */}
                            {/*<Route
                            path='queue/create'
                            element={
                                <SuspensedView>
                                    <QueueAdd/>
                                </SuspensedView>
                            }
                        />
                        */}
                        </Route>
                        <Route path='*' element={<Error404 />}/>
                    </Routes>
                </>
            )}
        </>
        
       
    )
}
const SuspensedView = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}
export {PrivateRoutes};