import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const getByIdQueueAction = createAsyncThunk('GET_BY_ID_QUEUE_ACTION', async (
    {
        userId,
        queueId
    }, {rejectWithValue}) => {
    try {
        const endpoint =`${userId}/queue/${queueId}`;
        return await HttpApi.GetAsync(endpoint, true);
    } catch (err) {
        return rejectWithValue(err);
    }


})

const getByIdQueueSlice = createSlice({
    name: 'get-by-id-queue',
    initialState,
    reducers: {
        resetByIdQueue: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getByIdQueueAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(getByIdQueueAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(getByIdQueueAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {getByIdQueueAction};
export const { resetByIdQueue } = getByIdQueueSlice.actions;

export default getByIdQueueSlice.reducer;
