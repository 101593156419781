import axios, { AxiosResponse } from 'axios';
import { RequestBuilder, get_cookie } from '../../../utilities';

export const requestUserExport = async (userId, queue) => {
    ///api/v1/admin/users/export-data
    const endpoint = RequestBuilder.BuildEndpoint(`admin/users/export-data`);
    try {
        const response = await axios.get(endpoint, {
            responseType: 'blob',
            headers: {
                "X-API-GATEWAY": process.env.REACT_APP_API_KEY,
                "X-TOKEN": `Bearer ${get_cookie("session")}`
            }
        });
        
        const blob = new Blob([response.data], { type: 'application/octet-stream' })
        const fileName = "users-export.xlsx";
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('İstek hatası:', error);
        throw error;
    }
};