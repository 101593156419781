import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";


const initialState = {
  isLoading: false,
  isSuccess: false,
  isFailure: false,
  data: {},
  error: {}
};

const forgotPasswordAction = createAsyncThunk('FORGOT_PASSWORD_ACTION', async ({
  email

}, { rejectWithValue }) => {
  try {
    const endpoint = `auth/forgot-password`;
    const body = { email };
    return await HttpApi.PostAsync(endpoint, false, body);
  }
  catch (err) {
    return rejectWithValue(err);
  }


})

const forgotPasswordSlice = createSlice({
  name: 'forgot-password',
  initialState,
  reducers: {
    resetForgotPassword: (state) => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(forgotPasswordAction.pending, (state) => {
      state.isFailure = false;
      state.isLoading = true;
      state.isSuccess = false;
      state.data = {};
    })
      .addCase(forgotPasswordAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isFailure = false;
        state.data = action.payload;
      })
      .addCase(forgotPasswordAction.rejected, (state, action) => {
        state.isFailure = true;
        state.isSuccess = false;
        state.isLoading = false;
        state.error = action.payload;
      })
  }
});
export { forgotPasswordAction };
export const { resetForgotPassword } = forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;
