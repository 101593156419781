import React, { useState } from 'react'
import {
    ListsWidget5,
} from "../../_metronic/partials/widgets";
import { QueueWidget } from "../Widgets/QueueWidget";
import GrayandBlackListTable from '../Tables/GrayandBlackListTable';
import { formatDate, formatPhoneNumber } from '../../utilities/stringHelper';
import { get_local_storage_item } from '../../utilities';
export function Overview({ props }) {
    const user = get_local_storage_item("user");

    return (
        <>
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Profil Detayları</h3>
                    </div>
                </div>

                <div className='card-body p-9'>
                    <div className='row mb-10'>
                        <label className='col-lg-4 fw-bold text-muted'>İsim - Soyisim</label>

                        <div className='col-lg-8'>
                            <span className='fw-bolder fs-6 text-dark'>
                                {props.firstName} {props.lastName}
                            </span>
                        </div>
                    </div>

                    <div className='row mb-10'>
                        <label className='col-lg-4 fw-bold text-muted'>
                            Telefon Numarası
                            <i
                                className='fas fa-exclamation-circle ms-1 fs-7'
                                data-bs-toggle='tooltip'
                                title='Phone number must be active'
                            ></i>
                        </label>

                        <div className='col-lg-8 d-flex align-items-center'>
                            <span className='fw-bolder fs-6 me-2'>{formatPhoneNumber(props && props.phone)}</span>
                        </div>
                    </div>
                    <div className='row mb-10'>
                        <label className='col-lg-4 fw-bold text-muted'>
                            Fazladan Telefon Numarası
                            <i
                                className='fas fa-exclamation-circle ms-1 fs-7'
                                data-bs-toggle='tooltip'
                                title='Phone number must be active'
                            ></i>
                        </label>

                        <div className='col-lg-8 d-flex align-items-center'>
                            <span className='fw-bolder fs-6 me-2'>
                                {props.extraPhoneData && props.extraPhoneData.totalPhoneCount > 0 ? (
                                    <span className='fw-bolder fs-6 me-2'>
                                        {props.extraPhoneData && props.extraPhoneData.usagePhoneCount} / {props.extraPhoneData && props.extraPhoneData.totalPhoneCount}
                                    </span>
                                ) : (
                                    <span className='fw-bolder fs-6 me-2'>Fazladan Telefon Numarası Yok</span>
                                )}
                            </span>
                        </div>
                    </div>

                    <div className='row mb-10'>
                        <label className='col-lg-4 fw-bold text-muted'>
                            Email
                            <i
                                className='fas fa-exclamation-circle ms-1 fs-7'
                                data-bs-toggle='tooltip'
                                title='Phone number must be active'
                            ></i>
                        </label>

                        <div className='col-lg-8 d-flex align-items-center'>
                            <span className='fw-bolder fs-6 me-2'>{props.email}</span>
                        </div>
                    </div>

                    {
                        user && user.role === "User" && (
                            <div className='row mb-10'>
                                <label className='col-lg-4 fw-bold text-muted'>
                                    Kayıt Olma Tarihi
                                    <i
                                        className='fas fa-exclamation-circle ms-1 fs-7'
                                        data-bs-toggle='tooltip'
                                        title='Phone number must be active'
                                    ></i>
                                </label>

                                <div className='col-lg-8 d-flex align-items-center'>
                                    <span className='fw-bolder fs-6 me-2'>{formatDate(props.registerDate)}</span>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>

            <div className='row gy-10 gx-xl-10 mb-8'>
                <div className='col-xl-stretch'>
                    <QueueWidget className='card-xl-stretch mb-8' state="completed" />
                    {/*<TablesWidget5 className='card-xxl-stretch mb-5 mb-xl-10'/>*/}
                </div>
            </div>

            <div className='row g-5 g-xl-8'>
                <div className='col-xl-stretch'>
                    <GrayandBlackListTable />
                </div>
            </div>

            {/* <div className='col-xl-6'>
                    <ListsWidget5 className='card-xxl-stretch mb-5 mb-xl-10' />
                </div>

            <div className='row gy-10 gx-xl-10'>
                <div className='col-xl-6'>
                    <ChartsWidget1 className='card-xxl-stretch mb-5 mb-xl-10'/>
                </div>

                <div className='col-xl-6'>
                    <TablesWidget1 className='card-xxl-stretch mb-5 mb-xl-10'/>
                </div>
            </div> */}
        </>
    )
}
