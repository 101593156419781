import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";
import axios from "axios";
import { HEADER_TYPES, RequestBuilder, get_cookie } from "../../../utilities";

const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const uploadFileToQueueAction = createAsyncThunk('UPLOAD_FILE_TO_QUEUE_ACTION', async (
    {
        userId,
        queue,
        file,
    }, {rejectWithValue}) => {
    try {
        const endpoint = RequestBuilder.BuildEndpoint(`${userId}/queue/upload/${queue}/file`);
        const formData = new FormData();
        formData.append('file', file[0], file[0].name)
        const config = {
          headers: {
            ...RequestBuilder.BuildHeader(HEADER_TYPES.FORM_AUTH, get_cookie("session")),
            'Content-Type': 'multipart/form-data',
          },
        };
        const response = await axios.post(endpoint, formData, config);
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }


})

const uploadFileToQueueSlice = createSlice({
    name: 'upload-file-to-queue',
    initialState,
    reducers: {
        resetUploadFileToQueue: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(uploadFileToQueueAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(uploadFileToQueueAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(uploadFileToQueueAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {uploadFileToQueueAction};
export const { resetUploadFileToQueue } = uploadFileToQueueSlice.actions;

export default uploadFileToQueueSlice.reducer;
