import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";


const initialState = {
  isLoading: false,
  isSuccess: false,
  isFailure: false,
  data: {},
  error: {}
};

const resetPasswordAction = createAsyncThunk('RESET_PASSWORD_ACTION', async ({
  id,
  currentPassword,
  newPassword
}, { rejectWithValue }) => {
  try {
    const endpoint = `auth/reset-password`;
    const body = { id, currentPassword, newPassword };
    return await HttpApi.PatchAsync(endpoint, true, body);
  }
  catch (err) {
    return rejectWithValue(err);
  }


})

const resetPasswordSlice = createSlice({
  name: 'reset-password',
  initialState,
  reducers: {
    resetPasswordState: () => {
      return initialState;
    } 
  },
  extraReducers: (builder) => {
    builder.addCase(resetPasswordAction.pending, (state) => {
      state.isFailure = false;
      state.isLoading = true;
      state.isSuccess = false;
      state.data = {};
    })
      .addCase(resetPasswordAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isFailure = false;
        state.data = action.payload;
      })
      .addCase(resetPasswordAction.rejected, (state, action) => {
        state.isFailure = true;
        state.isSuccess = false;
        state.isLoading = false;
        state.error = action.payload;
      })
  }
});
export const {resetPasswordState} = resetPasswordSlice.actions; 
export { resetPasswordAction };
export default resetPasswordSlice.reducer;
