import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";

const initialState = {
    isLoading: false,
    isFailure: false,
    isSuccess: false,
    data: {},
    error: {}
}

const getSettingsDefaultAction = createAsyncThunk("GET_SETTINGS_DEFAULT_ACTION", async (
    _, { rejectWithValue }) => {
    try {
        let endpoint = `admin/settings/defaults`;
        const response = await HttpApi.GetAsync(endpoint, true);
        return response;
    } catch (error) {
        return rejectWithValue(error)
    }
})

const getSettingsDefaultSlice = createSlice({
    name: 'admin-settings-default',
    initialState,
    reducers: {
        resetGetSettingsDefault: (state) => { return initialState }
    },
    extraReducers: (builder) => {
        builder.addCase(getSettingsDefaultAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
        .addCase(getSettingsDefaultAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
                state.error = {};
        })
        .addCase(getSettingsDefaultAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
                state.data = {};
        })
    }
});

export {getSettingsDefaultAction};
export const {resetGetSettingsDefault} = getSettingsDefaultSlice.actions;
export default getSettingsDefaultSlice.reducer;