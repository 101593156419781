import React, {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from './_metronic/i18n/i18nProvider';
import {LayoutProvider, LayoutSplashScreen} from "./_metronic/layout/core";
import {MasterInit} from "./_metronic/layout/MasterInit";
import {ThemeModeProvider} from "./_metronic/partials";
import {ThemeProvider} from "./services/themeService";
import {ToastContainer} from "react-toastify";

function App() {
  return (
      <Suspense fallback={<LayoutSplashScreen />}>
          <I18nProvider>
              <LayoutProvider>
                  <ThemeModeProvider>
                      <ThemeProvider>
                          <Outlet />
                          <MasterInit />
                          <ToastContainer />
                      </ThemeProvider>
                  </ThemeModeProvider>
              </LayoutProvider>
          </I18nProvider>
      </Suspense>
  );
}

export default App;
