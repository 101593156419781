import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";

const initialState = {
    isLoading: false,
    isFailure: false,
    isSuccess: false,
    data: {},
    error: {}
}

const uploadCreditAction = createAsyncThunk("UPLOAD_CREDIT_ACTION", async (
    {
        userId,
        credit
    }, { rejectWithValue }) => {
    try {
        let endpoint = `admin/users/${userId}/upload-credit`;
        const response = await HttpApi.PostAsync(endpoint, true, {creditAmount: credit});
        return response;
    } catch (error) {
        return rejectWithValue(error)
    }
})

const uploadCreditSlice = createSlice({
    name: 'upload-credit',
    initialState,
    reducers: {
        resetUploadCredit: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(uploadCreditAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
        .addCase(uploadCreditAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
        })
        .addCase(uploadCreditAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
        })
    }
});

export {uploadCreditAction};
export const {resetUploadCredit} = uploadCreditSlice.actions;
export default uploadCreditSlice.reducer;