import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const deleteDraftAction = createAsyncThunk('DELETE_DRAFT_ACTION', async (
    {
        userId,
        draftId
    }, {rejectWithValue}) => {
    try {
        const endpoint = `${userId}/drafts/delete/${draftId}/`;
        return await HttpApi.DeleteAsync(endpoint, true);
    } catch (err) {
        return rejectWithValue(err);
    }


})

const deleteDraftSlice = createSlice({
    name: 'delete-draft',
    initialState,
    reducers: {
        resetDeleteDrafts: (state) => {return initialState}
    },
    extraReducers: (builder) => {
        builder.addCase(deleteDraftAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(deleteDraftAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(deleteDraftAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {deleteDraftAction};
export const {resetDeleteDrafts} = deleteDraftSlice.actions;

export default deleteDraftSlice.reducer;
