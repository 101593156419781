import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";

const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const addExtraPhoneNumberAction = createAsyncThunk('ADD_EXTRA_PHONE_NUMBER_ACTION', async (
    {
        user,
        phoneNumber
    }, {rejectWithValue}) => {
    try {
        const endpoint = `${user}/profile/add-extra-phone`;
        return await HttpApi.PostAsync(endpoint, true, {phoneNumber: phoneNumber});
    } catch (err) {
        return rejectWithValue(err);
    }
})

const addExtraPhoneNumberSlice = createSlice({
    name: 'add-extra-phone-number',
    initialState,
    reducers: {
        resetAddExtraPhoneNumber: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(addExtraPhoneNumberAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
        .addCase(addExtraPhoneNumberAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
        })
        .addCase(addExtraPhoneNumberAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
        })
    }
});

export {addExtraPhoneNumberAction};
export const {resetAddExtraPhoneNumber} = addExtraPhoneNumberSlice.actions;
export default addExtraPhoneNumberSlice.reducer;