import React, { useState } from "react";
import "../../Apps/customer-management/index.css";
import ModalHeader from "./../../Apps/draft-management/modal/ModalHeader";
import { KTIcon } from "../../../_metronic/helpers";
import { right } from "@popperjs/core";

const InfoDraftModal = ({
  setIsAddModalShow,
  modalType,
  updateDraft,
  initialObject,
  currentKey,
}) => {
  const [title, setTitle] = useState(initialObject.title);
  const [message, setMessage] = useState(initialObject.message);
  const [info, setInfo] = useState(initialObject.info);
  const [selectedOption, setSelectedOption] = useState(initialObject.sent_type);
  const [tags] = useState(
    initialObject.tags.split(",").map((item) => {
      return item.replace("[", "").replace("]", "");
    })
  );

  const handleSubmit = () => {
    if (title === "") {
      alert("Lütfen mesajın başlığını giriniz..");
      return;
    }
    if (message === "") {
      alert("Lütfen bir mesaj gönderiniz!");
      return;
    }
    if (info === "") {
      alert("Lütfen bir bilgi ekleyiniz!");
      return;
    }
    updateDraft(currentKey, { title: title, message: message, info: info, tags: initialObject.tags, sent_type: selectedOption });
  };

  const handleGuideMessage = (guideMessage) => {
    const massageArea = document.getElementById("message-box");
    const { selectionStart, selectionEnd } = massageArea;

    const beforeSelection = message.slice(0, selectionStart);
    const afterSelection = message.slice(selectionEnd);

    const updatedMessage = `${beforeSelection}[${guideMessage}]${afterSelection}`;
    setMessage(updatedMessage);
    massageArea.focus();
  };

  const handleGuideInfo = (guideInfo) => {
    const infoArea = document.getElementById("info-box");

    const { selectionStart, selectionEnd } = infoArea;
    const beforeInfoSelection = info.slice(0, selectionStart);
    const afterInfoSelection = info.slice(selectionEnd);

    const updatedInfo = `${beforeInfoSelection}[${guideInfo}]${afterInfoSelection}`;
    setInfo(updatedInfo);
    infoArea.focus();
  };

  return (
    <div className="modal fade show d-block" id="kt_modal_add_draft" role="dialog" tabIndex={-1} aria-modal="true">
      <div className="modal-dialog modal-dialog-centered mw-650px">
        <div className="modal-content" style={{ display: "flex", flexDirection: "column" }}>
          <ModalHeader title="Taslak Düzenle" handleCloseModal={() => setIsAddModalShow(false)} />
          <div className="card" style={{ padding: 20 }}>
            <div className="d-flex flex-column">
              <h4>Başlık</h4>
              <input
                className="form-control form-control-solid w-100 mb-5"
                placeholder="Mesaj başlığı.."
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div>
              <div className="d-flex flex-column mt-3">
                <h4>Mesaj</h4>
                <div className="d-flex flex-column pt-2 pb-2 border rounded-top-3 light">
                  <textarea
                    id="message-box"
                    rows={6}
                    className="form-control form-control-solid w-100 mb-5"
                    placeholder="Lütfen göndermek istediğiniz mesajınızı yazınız.."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <div className="d-flex align-items-center mb-5">
                    <h6 className="mb-0">Kılavuz:</h6>
                    <div className="d-flex align-items-center mb-0">
                      <button
                        className="btn btn-outline-primary border border-primary mx-1"
                        type="button"
                        onClick={() => handleGuideMessage("isim")}
                        style={{ padding: "0.2rem 0.5rem", color: "#0095e8" }}
                        onMouseOver={(e) => (e.target.style.color = "white")}
                        onMouseOut={(e) => (e.target.style.color = "#0095e8")}
                      >
                        İsim
                      </button>
                      <button
                        className="btn btn-outline-primary border border-primary mx-1"
                        type="button"
                        onClick={() => handleGuideMessage("bilgi1")}
                        style={{ padding: "0.2rem 0.5rem", color: "#0095e8" }}
                        onMouseOver={(e) => (e.target.style.color = "white")}
                        onMouseOut={(e) => (e.target.style.color = "#0095e8")}
                      >
                        Bilgi 1
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column mt-3">
                <h4>Bilgi</h4>
                <div className="d-flex flex-column pt-2 pb-2 border rounded-top-3 light">
                  <textarea
                    id="info-box"
                    rows={6}
                    className="form-control form-control-solid w-100 mb-5"
                    placeholder="Lütfen eklemek istedğiniz bilgiyi yazınız.."
                    value={info}
                    onChange={(e) => setInfo(e.target.value)}
                  />
                  <div className="d-flex flex-wrap mb-5">
                    <h6 className="mb-0">Kılavuz:</h6>
                    <div className="d-flex flex-wrap mb-0">
                      {tags.length > 0 && (
                        <>
                          {tags.map((tag, index) => (
                            <button
                              key={`${tag}-${index}`}
                              id={`${tag}-${index}`}
                              type="button"
                              className="btn btn-outline-primary border border-primary mx-1"
                              onClick={() => handleGuideInfo(tag)}
                              style={{
                                padding: "0.2rem 0.5rem",
                                color: "#0095e8",
                              }}
                              onMouseOver={(e) =>
                                (e.target.style.color = "white")
                              }
                              onMouseOut={(e) =>
                                (e.target.style.color = "#0095e8")
                              }
                            >
                              {tag}
                            </button>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column mt-3">
                <h4>Gönderim Tipi</h4>
                <select
                  className="form-select form-select-solid w-100 mb-5"
                  value={selectedOption}
                  onChange={(e) => setSelectedOption(e.target.value)}
                >
                  <option value="">Seçiniz</option>
                  <option value="WP">WP</option>
                  <option value="MAIL">MAIL</option>
                  <option value="ALL">ALL</option>
                  <option value="NONE">NONE</option>
                </select>
              </div>

              <div style={{ alignSelf: "flex-end", marginTop: 20, float: "right" }}>
                <button className="btn btn-primary" onClick={handleSubmit}>
                  Kaydet
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoDraftModal;
