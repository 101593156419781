import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const deleteCustomerAction = createAsyncThunk('DELETE_CUSTOMER_ACTION', async (
    {
        userId,
        customerId
    }, {rejectWithValue}) => {
    try {
        const endpoint = `${userId}/customers/delete/${customerId}`;
        return await HttpApi.DeleteAsync(endpoint, true);
    } catch (err) {
        return rejectWithValue(err);
    }
})

const deleteCustomerSlice = createSlice({
    name: 'delete-customer',
    initialState,
    reducers: {
        resetDeleteState: (state) => {return initialState;}
    },
    extraReducers: (builder) => {
        builder.addCase(deleteCustomerAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(deleteCustomerAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(deleteCustomerAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {deleteCustomerAction};
export const { resetDeleteState } = deleteCustomerSlice.actions;
export default deleteCustomerSlice.reducer;
