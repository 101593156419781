import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const deleteQueueAction = createAsyncThunk('DELETE_QUEUE_ACTION', async (
    {
        userId,
        queueId,
    }, {rejectWithValue}) => {
    try {
        const endpoint = `${userId}/queue/delete/${queueId}`;
        return await HttpApi.DeleteAsync(endpoint, true);
    } catch (err) {
        return rejectWithValue(err);
    }


})

const deleteQueueSlice = createSlice({
    name: 'delete-queue',
    initialState,
    reducers: {
        resetDeleteQueue: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(deleteQueueAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(deleteQueueAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(deleteQueueAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {deleteQueueAction};
export const { resetDeleteQueue } = deleteQueueSlice.actions;

export default deleteQueueSlice.reducer;
