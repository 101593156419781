import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";
import { set_cookie, set_local_storage_item } from '../../../utilities';
const initialState = {
  isLoading: false,
  isSuccess: false,
  isFailure: false,
  data: {},
  error: {}
};

const loginAction = createAsyncThunk('LOGIN_ACTION', async ({
  email,
  password
}, { rejectWithValue }) => {
  try {
    const endpoint = 'auth/login/';
    const body = { email, password };
    const response = await HttpApi.PostAsync(endpoint, false, body);
    set_cookie("session", response.data.token, response.data.expireDate);
    set_local_storage_item("user", {id: response.data.id});
    window.location.href = "/dashboard";
    return response;
  }
  catch (err) {
    return rejectWithValue(err);
  }
})

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetLoginState: (state) => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginAction.pending, (state) => {
      state.isFailure = false;
      state.isLoading = true;
      state.isSuccess = false;
      state.data = {};
    })
      .addCase(loginAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isFailure = false;
        state.data = action.payload;
      })
      .addCase(loginAction.rejected, (state, action) => {
        state.isFailure = true;
        state.isSuccess = false;
        state.isLoading = false;
        state.error = action.payload;
      })
  }
});
export { loginAction };
export const {resetLoginState} = loginSlice.actions;
export default loginSlice.reducer;
