import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const notificationSettingsAction = createAsyncThunk('NOTIFICATION_SETTINGS_ACTION', async (
    {
        userId
    }, { rejectWithValue }) => {
    try {
        const endpoint = `${userId}/profile/settings/get-notification-settings`;
        let response = await HttpApi.GetAsync(endpoint, true);
        return response;
    }
    catch (err) {

        return rejectWithValue(err);
    }
})


const notificationSettingsSlice = createSlice({
    name: 'notification-settings',
    initialState,
    reducers: {
        resetNotificationSettings: (state) => {return initialState;}
    },
    extraReducers: (builder) => {
        builder.addCase(notificationSettingsAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(notificationSettingsAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                let resultObj = {};
                Object.keys(action.payload.data).map((key)=>{
                    if(key !== "_id" && key !== "userId" && key !== "__v") {
                        
                        resultObj[key] = JSON.parse(action.payload.data[key])
                    }
                })
                state.data = resultObj;
            })
            .addCase(notificationSettingsAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
})

export { notificationSettingsAction };
export const { resetNotificationSettings } = notificationSettingsSlice.actions;
export default notificationSettingsSlice.reducer;
