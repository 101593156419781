import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'


import {PasswordMeterComponent} from "../../_metronic/assets/ts/components";
import { useDispatch, useSelector } from 'react-redux'
import { forgotPasswordAction, resetForgotPassword  } from '../../services/redux/auth/forgot-password';
import { isLoading, isFailure, isSuccess } from '../../utilities/reduxStateHelper'
const initialValues = {
    email: '',
}
const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
        .email('Yanlış e-posta biçimi')
        .min(3, 'En az 3 sembol')
        .max(50, 'Maksimum 50 sembol')
        .required('E-posta gereklidir'),
})

const ForgotPassword = () => {
    const dispatcher = useDispatch();
    const selector = useSelector((state) => state.auth.forgotPassword);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState(undefined)
    const formik = useFormik({
        initialValues,
        validationSchema: forgotPasswordSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            dispatcher(forgotPasswordAction({email: values.email}));
            setSubmitting(true);
            setLoading(true);
        },
    })
    formik.status = formik.status || {};
    useEffect(() => {
        PasswordMeterComponent.bootstrap()
    }, [])

    useEffect(() => {
        let timeout;
        if(isLoading(selector))
        {
            setLoading(true);
        }
        if(isFailure(selector))
        {
            setLoading(false);
            formik.setStatus({sent: false, msg: selector.error.message});
        }
        if(isSuccess(selector))
        {
            setLoading(false);
            formik.setStatus({sent: true, msg: selector.data.message});
            timeout = setTimeout(() => {
                navigate('/auth/login') 
                
            }, 3000);
        }
        return () => {
            dispatcher(resetForgotPassword());
            clearTimeout(timeout);
        }
    },[dispatcher, formik, navigate, selector])
    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_password_reset_form'
            onSubmit={formik.handleSubmit}
        >
            <div className='text-center mb-10'>
                {/* begin::Title */}
                <h1 className='text-dark fw-bolder mb-3'>Şifrenizimi unuttunuz ?</h1>
                {/* end::Title */}

                {/* begin::Link */}
                <div className='text-gray-500 fw-semibold fs-6'>
                    Şifrenizi Yenilemek için E-Posta adresinizi giriniz.
                </div>
                {/* end::Link */}
            </div>

            {/* begin::Title */}
            {
                Object.keys(formik.status).length !== 0 && (
                    <div className={`mb-lg-15 alert ${formik.status.sent ? 'alert-success' : 'alert-danger'}`}>
                        <div className='alert-text font-weight-bold'>{formik.status.msg}</div>
                    </div>
                )
            }

            {hasErrors === false && (
                <div className='mb-10 bg-light-info p-8 rounded'>
                    <div className='text-info'>Şifre yenileme gönderildi. Lüften E-Posta kutunuzu kontrol ediniz.</div>
                </div>
            )}
            {/* end::Title */}

            {/* begin::Form group */}
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
                <input
                    type='email'
                    placeholder='Email adresiniz..'
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        {
                            'is-valid': formik.touched.email && !formik.errors.email,
                        }
                    )}
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.email}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
               <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-warning'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>Gönder</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                    Lütfen Bekleyin...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                    )}
                </button>
                <Link to='/auth/login'>
                    <button
                        type='button'
                        id='kt_login_password_reset_form_cancel_button'
                        className='btn btn-light-danger'
                        disabled={formik.isSubmitting || !formik.isValid}
                    >
                        İptal
                    </button>
                </Link>{' '}
            </div>
            {/* end::Form group */}
        </form>
    )
}
export {ForgotPassword};