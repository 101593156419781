import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const deleteSessionsAction = createAsyncThunk('DELETE_SESSIONS_ACTION', async ({
userId, password, session}, { rejectWithValue }) => {
    try {
        let endpoint = `${userId}/profile/active-sessions/delete`
        return await HttpApi.PostAsync(endpoint, true, {password: password, session: session});
    }
    catch (err) {
        return rejectWithValue(err);
    }
})


const deleteSessionsSlice = createSlice({
    name: 'delete-sessions',
    initialState,
    reducers: {
        resetDeleteSessions: (state) => {return initialState;}
    },
    extraReducers: (builder) => {
        builder.addCase(deleteSessionsAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(deleteSessionsAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(deleteSessionsAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
})

export { deleteSessionsAction };
export const { resetDeleteSessions } = deleteSessionsSlice.actions;
export default deleteSessionsSlice.reducer;
