import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";


const initialState = {
  isLoading: false,
  isSuccess: false,
  isFailure: false,
  data: {},
  error: {}
};

const reactivateAccountAction = createAsyncThunk('REACTIVATE_ACCOUNT_ACTION', async ({ userId }, { rejectWithValue }) => {
  try {
    const endpoint = `auth/${userId}/account/reactivate-account`;
    return await HttpApi.PostAsync(endpoint, true, {});
  }
  catch (err) {
    return rejectWithValue(err);
  }
})


const reactivateAccountSlice = createSlice({
  name: 'reactivate-account',
  initialState,
  reducers: {
    resetReactivateAccount: (state) => {return initialState}
  },
  extraReducers: (builder) => {
    builder.addCase(reactivateAccountAction.pending, (state) => {
      state.isFailure = false;
      state.isLoading = true;
      state.isSuccess = false;
      state.data = {};
    })
      .addCase(reactivateAccountAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isFailure = false;
        state.data = action.payload;
      })
      .addCase(reactivateAccountAction.rejected, (state, action) => {
        state.isFailure = true;
        state.isSuccess = false;
        state.isLoading = false;
        state.error = action.payload;
      })
  }
})

export { reactivateAccountAction };
export const { resetReactivateAccount } = reactivateAccountSlice.actions;
export default reactivateAccountSlice.reducer;
