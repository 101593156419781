import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";
import { set_local_storage_item } from "../../../utilities";


const initialState = {
  isLoading: false,
  isSuccess: false,
  isFailure: false,
  data: {},
  error: {}
};

const creditInformationAction = createAsyncThunk('CREDIT_INFORMATION_ACTION', async ({user}, { rejectWithValue }) => {
  try {
    const endpoint = `${user}/profile/credit-information`;
    const response = await HttpApi.GetAsync(endpoint, true);
    return response;
  }
  catch (err) {
    return rejectWithValue(err);
  }
})


const creditInformationSlice = createSlice({
  name: 'credit-information',
  initialState,
  reducers: {
    resetCreditInformation: (state) => {return initialState;}
  },
  extraReducers: (builder) => {
    builder.addCase(creditInformationAction.pending, (state) => {
      state.isFailure = false;
      state.isLoading = true;
      state.isSuccess = false;
      state.data = {};
    })
      .addCase(creditInformationAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isFailure = false;
        state.data = action.payload;
      })
      .addCase(creditInformationAction.rejected, (state, action) => {
        state.isFailure = true;
        state.isSuccess = false;
        state.isLoading = false;
        state.error = action.payload;
      })
  }
})

export { creditInformationAction };
export const {resetCreditInformation} = creditInformationSlice.actions;
export default creditInformationSlice.reducer;
