import parsePhoneNumberFromString from "libphonenumber-js";

export const formatPhoneNumber = (phoneNumber) => {
  const phoneNumberWithPlus = "+" + phoneNumber; // Başına + ekleyerek olası bir ülke kodu oluştur
  const parsedNumber = parsePhoneNumberFromString(phoneNumberWithPlus);

  if (!parsedNumber) {
    // Numara geçersizse veya hala analiz edilememişse, orijinal numarayı döndür
    return phoneNumber;
  }

  const formattedNumber = parsedNumber.formatInternational();
  return formattedNumber;
};
export function formatDate(startDate) {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  const formattedDate = new Date(startDate).toLocaleDateString(
    "en-US",
    options
  );
  return formattedDate;
}
export function getStatusText(status) {
  switch (status) {
    case "PENDING":
      return "Beklemede";
    case "IN_PROGRESS":
      return "Devam Ediyor";
    case "PAUSED":
      return "Duraklatıldı";
    case "COMPLETED":
      return "Tamamlandı";
    default:
      return "Hatalı";
  }
}
export function getStatusClassBadge(status) {
  switch (status) {
    case "PENDING":
      return "badge-light-primary";
    case "IN_PROGRESS":
      return "badge-light-success";
    case "PAUSED":
      return "badge-light-warning";
    case "COMPLETED":
      return "badge-light-success";
    default:
      return "badge-light-danger";
  }
}
export function getStatusColorFormat(status) {
  switch (status) {
    case "PENDING":
      return "normal";
    case "IN_PROGRESS":
      return "success";
    case "PAUSED":
      return "normal";
    case "COMPLETED":
      return "success";
    default:
      if (status.includes("ERROR"))
        return "exception";
  }
}
export function getStatusClassFontColor(status) {
  switch (status) {
    case "PENDING":
      return "text-primary";
    case "IN_PROGRESS":
      return "text-success";
    case "PAUSED":
      return "text-warning";
    case "COMPLETED":
      return "text-success";
    default:
      return "text-danger";
      return;
  }
}
