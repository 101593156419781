import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const fireToast = (message, type, customTheme) => {

    const toastOptions = {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: customTheme === "light" ? "light" : "dark",
    }

    // eslint-disable-next-line default-case
    switch(type)
    {
        case 'SUCCESS':
        {
            toast.success(message, toastOptions);
            break;
        }
        case 'FAIL':
        {
            toast.error(message, toastOptions);
            break;
        }
        case 'INFO':
        {
            toast.info(message, toastOptions);
            break;
        }
        case 'WARNING':
        {
            toast.warning(message, toastOptions);
            break;
        }
        // eslint-disable-next-line no-fallthrough
        default:
        {
            toast.info(message, toastOptions);
            break;
        }
    }
}