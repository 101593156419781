import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import {AuthPage} from "../pages";
import {PrivateRoutes} from "./PrivateRoutes";
import App from "../App";
import {useEffect, useState} from "react";
import {get_cookie} from "../utilities";


const {PUBLIC_URL} = process.env;
const AppRoutes = () => {
    const [session, setSession] = useState({});
    useEffect(() => {
        const currentSession = get_cookie('session');
        setSession(currentSession);
    
        if (!currentSession) {
            if (!window.location.pathname.startsWith('/auth')) {
                window.location.href = PUBLIC_URL + "/auth/login";
            }
        } else {
            if (window.location.pathname.startsWith('/auth')) {
                window.location.href = PUBLIC_URL + "/dashboard";
            }
        }
    }, [setSession]);
    
    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes>
                <Route element={<App />}>
                    {session ? (
                        <>
                            <Route index element={<Navigate to='/dashboard' />} />
                            <Route path='/*' element={<PrivateRoutes />} />
                        </>
                    ):(
                        <>
                            <Route index element={<Navigate to='/auth/login' />} />
                            <Route path='auth/*' element={<AuthPage />} />
                        </>
                    )}

                </Route>
            </Routes>
        </BrowserRouter>
    )
}
export {AppRoutes};