import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";

const initialState = {
    isLoading: false,
    isFailure: false,
    isSuccess: false,
    data: {},
    error: {}
}

const adminUploadCreditAmountAction = createAsyncThunk("ADMIN_CREDIT_AMOUNTS_ACTION", async (
    {
        admin,
        creditAmount
    }, { rejectWithValue }) => {
    try {
        let endpoint = `admin/${admin}/self/upload-credit`;
        const response = await HttpApi.PostAsync(endpoint, true, { creditAmount });
        return response;
    } catch (error) {
        return rejectWithValue(error)
    }
})

const adminUploadCreditAmountSlice = createSlice({
    name: 'admin-upload-credit-amount',
    initialState,
    reducers: {
        resetAdminUploadCreditAmount: (state) => { return initialState }
    },
    extraReducers: (builder) => {
        builder.addCase(adminUploadCreditAmountAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
        .addCase(adminUploadCreditAmountAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
                state.error = {};
        })
        .addCase(adminUploadCreditAmountAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
                state.data = {};
        })
    }
});

export {adminUploadCreditAmountAction};
export const {resetAdminUploadCreditAmount} = adminUploadCreditAmountSlice.actions;
export default adminUploadCreditAmountSlice.reducer;