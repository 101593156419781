import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const updateDraftAction = createAsyncThunk('UPDATE_DRAFT_ACTION', async (
    {
        userId,
        draftId,
        title,
        text
    }, {rejectWithValue}) => {
    try {
        const endpoint = `${userId}/drafts/update/${draftId}/`;
        const body = {title, text};
        return await HttpApi.PatchAsync(endpoint, true, body);
    } catch (err) {
        return rejectWithValue(err);
    }


})

const updateDraftSlice = createSlice({
    name: 'update-draft',
    initialState,
    reducers: {
        resetUpdateDraft: (state) => {return initialState;}
    },
    extraReducers: (builder) => {
        builder.addCase(updateDraftAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(updateDraftAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(updateDraftAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {updateDraftAction};
export const { resetUpdateDraft } = updateDraftSlice.actions;

export default updateDraftSlice.reducer;
