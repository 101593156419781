import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { PasswordMeterComponent } from "../../_metronic/assets/ts/components";
import { isFailure, isSuccess, isLoading } from '../../utilities/reduxStateHelper'
import { loginAction, resetLoginState } from '../../services/redux/auth/login'
import { useDispatch, useSelector } from "react-redux";
import { resetSessionAction, resetReSessionState } from '../../services/redux/auth/reset-session-login';
import { reactivateAccountAction, resetReactivateAccount } from '../../services/redux/account/reactivate-account'
import { KTIcon } from '../../_metronic/helpers'

const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email('Yanlış e-posta biçimi')
        .min(8, 'En az 8 sembol')
        .max(50, 'Maksimum 50 sembol')
        .required('E-posta gereklidir'),
    password: Yup.string()
        .min(8, 'En az 8 sembol')
        .max(50, 'Maksimum 50 sembol')
        .required('Şifre gereklidir'),
})
const initialValues = {
    email: '',
    password: '',
}
const Login = () => {
    const dispathcer = useDispatch();
    const selector = useSelector((state) => state.auth.login);
    const resetSessionSelector = useSelector((state) => state.auth.resetSession);
    const reactivateAccountSelector = useSelector((state) => state.account.reactivateAccount);

    const navigator = useNavigate();
    const [loading, setLoading] = useState(false)
    const [showAnotherAction, setShowAnotherAction] = useState(false);
    const [showResetSession, setShowResetSession] = useState(false);
    const [killSessionMessage, setKillSessionMessage] = useState("");
    const [showFrozenAccount, setShowFrozenAccount] = useState(false);
    const [frozenAccountMessage, setFrozenAccountMessage] = useState("");
    const [tempUserId, setTempUserId] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(true)

    const killSession = async (id) => {
        setLoading(true);
        dispathcer(resetSessionAction({ id }))
    }
    const frozenAccountLink = (id) => {
        setLoading(true);
        dispathcer(reactivateAccountAction({ userId: id }))
    }
    const cancelRequest = () => {
        setLoading(false);
        setShowAnotherAction(false);
        setShowFrozenAccount(false);
        setShowResetSession(false);
        setKillSessionMessage("");
        setFrozenAccountMessage("");
        dispathcer(resetReSessionState());
        dispathcer(resetReactivateAccount())
        setTempUserId("");
    }
    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            const body = { email: values.email, password: values.password }
            dispathcer(loginAction(body));
            setSubmitting(true);
        },
    })
    formik.status = formik.status || {};
    useEffect(() => {
        PasswordMeterComponent.bootstrap()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isLoading(selector)) {
            setLoading(true);
        }
        if (isSuccess(selector)) {
            setLoading(false);
            formik.setStatus({ sent: true, msg: selector.data.message });
            dispathcer(resetLoginState());
        }
        if (isFailure(selector)) {
            setLoading(false);
            if (selector.error.status_code === 409) {
                setShowAnotherAction(true);
                setKillSessionMessage(selector.error.message);
                setTempUserId(selector.error.data.id);
                setShowResetSession(true);
            }
            else if (selector.error.requirement === 'FROZEN_ACCOUNT') {
                setShowAnotherAction(true);
                setShowFrozenAccount(true);
                setTempUserId(selector.error.data.id);
                setFrozenAccountMessage(selector.error.message);
            }
            else {
                formik.setStatus({ sent: false, msg: selector.error.message });
                dispathcer(resetLoginState());
            }
        }
        return () => {
            dispathcer(resetLoginState());
        }
    }, [dispathcer, formik, navigator, selector, selector.data, selector.error]);

    useEffect(() => {
        if (isLoading(resetSessionSelector)) {
            setLoading(true);
        }
        if (isSuccess(resetSessionSelector)) {
            setLoading(false);
            formik.setStatus({ sent: true, msg: resetSessionSelector.data.message });
            dispathcer(resetLoginState());
            dispathcer(resetReSessionState());
            cancelRequest();
        }
        if (isFailure(resetSessionSelector)) {
            setLoading(false);
            dispathcer(resetReSessionState());
            formik.setStatus({ sent: false, msg: resetSessionSelector.error.message });
            cancelRequest();
        }
    }, [cancelRequest, dispathcer, formik, resetSessionSelector])
    useEffect(() => {
        if (isLoading(reactivateAccountSelector)) {
            setLoading(true);
        }
        if (isSuccess(reactivateAccountSelector)) {
            setLoading(false);
            formik.setStatus({ sent: true, msg: reactivateAccountSelector.data.message });
            dispathcer(resetLoginState());
            cancelRequest();
        }
        if (isFailure(reactivateAccountSelector)) {
            setLoading(false);
            formik.setStatus({ sent: false, msg: reactivateAccountSelector.error.message });
            dispathcer(resetReactivateAccount());
            cancelRequest();
        }
    }, [cancelRequest, dispathcer, formik, reactivateAccountSelector])
    return (
        <>
            {showAnotherAction ?
                (
                    <>
                        {showResetSession ? (
                            <>
                                < div className='text-center mb-11'>
                                    <h1 className='text-dark fw-bolder mb-3'>{killSessionMessage}</h1>
                                </div>
                                <div>
                                    <div className='d-grid mb-10'>
                                        <button
                                            type='submit'
                                            id='kt_kill_session_submit'
                                            className='btn btn-warning'
                                            onClick={() => {
                                                killSession(tempUserId)
                                            }}
                                        >
                                            {!loading && <span className='indicator-label'>Buradan Devam Edin</span>}
                                            {loading && (
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    Lütfen Bekleyin...
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                    </div>
                                    <div className='d-grid mb-10'>
                                        <button
                                            type='button'
                                            id='kt_login_signup_form_cancel_button'
                                            className='btn btn-lg btn-light-danger w-100 mb-5'
                                            onClick={cancelRequest}
                                        >
                                            İptal
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                        {showFrozenAccount ? (
                            <>
                                < div className='text-center mb-11'>
                                    <h1 className='text-dark fw-bolder mb-3'>{frozenAccountMessage}</h1>
                                </div>
                                <div>
                                    <div className='d-grid mb-10'>
                                        <button
                                            type='submit'
                                            id='kt_kill_session_submit'
                                            className='btn btn-warning'
                                            onClick={() => {
                                                frozenAccountLink(tempUserId)
                                            }}
                                        >
                                            {!loading && <span className='indicator-label'>Buradan Devam Edin</span>}
                                            {loading && (
                                                <span className='indicator-progress' style={{ display: 'block' }}>
                                                    Lütfen Bekleyin...
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </button>
                                    </div>
                                    <div className='d-grid mb-10'>
                                        <button
                                            type='button'
                                            id='kt_login_signup_form_cancel_button'
                                            className='btn btn-lg btn-light-danger w-100 mb-5'
                                            onClick={cancelRequest}
                                        >
                                            İptal
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </>
                )
                :
                (<>
                    < form
                        className='form w-100'
                        onSubmit={formik.handleSubmit}
                        noValidate
                        id='kt_login_signin_form'
                    >
                        {/* begin::Heading */}
                        < div className='text-center mb-11'>
                            <h1 className='text-dark fw-bolder mb-3' style={{ fontSize: "32px" }}>Giriş Yap</h1>
                        </div>
                        {
                            Object.keys(formik.status).length !== 0 && (
                                <div className={`mb-lg-15 alert ${formik.status.sent ? 'alert-success' : 'alert-danger'}`}>
                                    <div className='alert-text font-weight-bold'>{formik.status.msg}</div>
                                </div>
                            )
                        }
                        {/* begin::Heading */}


                        {/* begin::Form group */}

                        <div className='fv-row mb-8'>
                            <label className='form-label fs-5 fw-bolder text-dark'>Email</label>
                            <input
                                placeholder='Email'
                                {...formik.getFieldProps('email')}
                                className={clsx(
                                    'form-control bg-transparent',
                                    { 'is-invalid': formik.touched.email && formik.errors.email },
                                    {
                                        'is-valid': formik.touched.email && !formik.errors.email,
                                    }
                                )}
                                type='email'
                                name='email'
                                autoComplete='off'
                            />
                            {formik.touched.email && formik.errors.email && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.email}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* end::Form group */}

                        {/* begin::Form group */}
                        <div className='fv-row mb-3'>
                            <label className='form-label fw-bolder text-dark fs-5 mb-0'>Şifre</label>
                            <div className='d-flex align-items-center col-lg-6 fv-row w-100'>
                                <input
                                    type={!passwordVisible ? 'text' : 'password'}
                                    placeholder='Şifre'
                                    autoComplete='off'
                                    {...formik.getFieldProps('password')}
                                    className={clsx(
                                        'form-control bg-transparent',
                                        {
                                            'is-invalid': formik.touched.password && formik.errors.password,
                                        },
                                        {
                                            'is-valid': formik.touched.password && !formik.errors.password,
                                        }
                                    )}
                                />
                                <span
                                    className='btn btn-icon btn-light mb-3 mb-lg-0'
                                    onClick={() => {
                                        setPasswordVisible(!passwordVisible)
                                    }}
                                >
                                    <KTIcon iconName={passwordVisible ? 'eye' : 'eye-slash'} />
                                </span>
                            </div>
                            {formik.touched.password && formik.errors.password && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>
                                        <span role='alert'>{formik.errors.password}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* end::Form group */}

                        {/* begin::Wrapper */}
                        <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                            <div />

                            {/* begin::Link */}
                            <Link to='/auth/forgot-password' className='link-warning'>
                                Şifreni mi unuttun ?
                            </Link>
                            {/* end::Link */}
                        </div>
                        {/* end::Wrapper */}

                        {/* begin::Action */}
                        <div className='d-grid mb-10'>
                            <button
                                type='submit'
                                id='kt_sign_in_submit'
                                className='btn btn-warning'
                                disabled={formik.isSubmitting || !formik.isValid}
                            >
                                {!loading && <span className='indicator-label'>Devam Et</span>}
                                {loading && (
                                    <span className='indicator-progress' style={{ display: 'block' }}>
                                        Lütfen Bekleyin...
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                        {/* end::Action */}

                        <div className='text-gray-500 text-center fw-semibold fs-6'>
                            Halen Kayıtlı değilmisin?{' '}
                            <Link to='/auth/register' className='link-warning'>
                                Kayıt ol
                            </Link>
                        </div>
                    </form>
                </>)}

        </>

    )
}
export { Login }