import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";

const initialState = {
  isLoading: false,
  isFailure: false,
  isSuccess: false,
  data: {},
  error: {},
};

const updateNotificationSettingsAction = createAsyncThunk(
  "UPDATE_ADMIN_NOTIFICATION_ACTION",
  async (
    {
      registerObj,
      uploadCreditObj,
      newQueueObj,
      queueBeginObj,
      endMinute,
      queueErrorObj,
      queuePausedObj,
      queueFinishedObj,
      queueStartedAgainObj,
      serviceStatus,
    },
    { rejectWithValue }
  ) => {
    try {
      let endpoint = `admin/settings/defaults/update-notification-settings`;
      const response = await HttpApi.PatchAsync(endpoint, true, {
        registerObj: registerObj,
        uploadCreditObj: uploadCreditObj,
        newQueueObj: newQueueObj,
        queueBeginObj: queueBeginObj,
        endMinute: endMinute,
        queueErrorObj: queueErrorObj,
        queuePausedObj: queuePausedObj,
        queueFinishedObj: queueFinishedObj,
        queueStartedAgainObj: queueStartedAgainObj,
        serviceStatus: serviceStatus,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const updateNotificationSettingsSlice = createSlice({
  name: "update-admin-notification-settings",
  initialState,
  reducers: {
    resetUpdateNotificationSettings: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateNotificationSettingsAction.pending, (state) => {
        state.isFailure = false;
        state.isLoading = true;
        state.isSuccess = false;
        state.data = {};
      })
      .addCase(updateNotificationSettingsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isFailure = false;
        state.data = action.payload;
      })
      .addCase(updateNotificationSettingsAction.rejected, (state, action) => {
        state.isFailure = true;
        state.isSuccess = false;
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export { updateNotificationSettingsAction };
export const { resetUpdateNotificationSettings } =
  updateNotificationSettingsSlice.actions;
export default updateNotificationSettingsSlice.reducer;
