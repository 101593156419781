import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { automationSettingsAction, resetAutomationSettings } from '../../../services/redux/profile/automation-settings'
import { updateAutomationSettingsAction } from '../../../services/redux/profile/update-automation-settings';
import { activeSessionsAction } from '../../../services/redux/profile/active-sessions';
import { get_local_storage_item } from '../../../utilities'
import { isFailure, isLoading, isSuccess } from "../../../utilities/reduxStateHelper";
import { fireToast } from "../../../utilities/toastHelper";
import { PasswordMeterComponent } from '../../../_metronic/assets/ts/components'
import { Spin, Switch } from 'antd'
import { Session } from './Session'
import { useTheme } from "../../../services/themeService";
import "../index.css"

const profileDetailsSchema = Yup.object().shape({
    min_message_delay: Yup.number().required('Minimum Message delay range is required'),
    max_message_delay: Yup.number().required('Maximum Message delay range is required'),
})

const AutomationDetails = () => {
    const { theme } = useTheme();
    const dispatch = useDispatch();
    const automationSelector = useSelector((state) => state.profile.automationSettings);
    const updateAutomationSelector = useSelector((state) => state.profile.updateAutomationSettings);
    const sessionsSelector = useSelector((state) => state.profile.activeSessions);
    const [sessions, setSessions] = useState([]);
    const [user, setUser] = useState(get_local_storage_item("user"));
    const [startHour, setStartHour] = useState();
    const [startMinute, setStartMinute] = useState();
    const [endHour, setEndHour] = useState();
    const [endMinute, setEndMinute] = useState();
    const [checked, setChecked] = useState(false);
    const [defaultAreaCode, setDefaultAreaCode] = useState(90)
    //const [startTime, setStartTime] = useState()

    const [data, setData] = useState({
        min_message_delay: 0,
        max_message_delay: 0,
        beginTime: new Date(),
        updatedAt: new Date()
    })
    function formatTime(number) {
        // Sayıyı iki basamaklı string olarak döndür
        return number < 10 ? '0' + number : '' + number;
    }
    const [loadingFetch, setLoadingFetch] = useState(false)
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [loadingSession, setLoadingSession] = useState(false);

    const formik = useFormik({
        initialValues: data,
        validationSchema: profileDetailsSchema,
        onSubmit: (values) => {
            const data = {
                userId: user.id,
                startHour: parseInt(startHour),
                startMinute: parseInt(startMinute),
                endHour: parseInt(endHour),
                endMinute: parseInt(endMinute),
                min_message_delay: values.min_message_delay,
                max_message_delay: values.max_message_delay,
                useSpamCode: checked,
                defaultAreaCode: defaultAreaCode
            };
            dispatch(updateAutomationSettingsAction(data))
        },
    })

    useEffect(() => {
        dispatch(automationSettingsAction({ userId: user.id }));
    }, [dispatch, user.id, updateAutomationSelector])

    useEffect(() => {
        if (isSuccess(automationSelector)) {
            setLoadingFetch(false);
            setChecked(automationSelector.data.data.useSpamCode ? automationSelector.data.data.useSpamCode : false)
            setData(automationSelector.data.data);
            formik.setValues(automationSelector.data.data);
            setStartHour(formatTime(automationSelector.data.data.start_Hour));
            setStartMinute(formatTime(automationSelector.data.data.start_Minute));
            setEndHour(formatTime(automationSelector.data.data.end_Hour));
            setEndMinute(formatTime(automationSelector.data.data.end_Minute));
            setDefaultAreaCode(automationSelector.data.data.defaultAreaCode ? automationSelector.data.data.defaultAreaCode : 90)
        }
        if (isFailure(automationSelector)) {
            setLoadingFetch(false);
        }
        if (isLoading(automationSelector)) {
            setLoadingFetch(true);
        }
        return () => {
            dispatch(resetAutomationSettings());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [automationSelector, setLoadingFetch])

    useEffect(() => {
        if (isSuccess(updateAutomationSelector)) {
            setLoadingUpdate(false);
            fireToast(updateAutomationSelector.data.message, "SUCCESS", theme.theme);
            dispatch(automationSettingsAction({ userId: user.id }));
        }
        if (isFailure(updateAutomationSelector)) {
            setLoadingUpdate(false);
            fireToast(updateAutomationSelector.error.message, "FAIL", theme.theme);
        }
        if (isLoading(updateAutomationSelector)) {
            setLoadingUpdate(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setLoadingUpdate, updateAutomationSelector])

    useEffect(() => {
        PasswordMeterComponent.bootstrap()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    formik.status = formik.status || {};

    const onChangeSwitch = (checked) => {
        setChecked(checked)
    }

    const getSessions = () => {
        dispatch(activeSessionsAction({
            userId: user.id
        }))
    }

    useEffect(() => {
        getSessions()
    }, [dispatch, user.id])

    useEffect(() => {
        if (isSuccess(sessionsSelector)) {
            setLoadingSession(false)
            setSessions(sessionsSelector.data.data);
        }
        if (isFailure(sessionsSelector)) {
            setLoadingSession(false);
        }
        if (isLoading(sessionsSelector)) {
            setLoadingSession(true);
        }
    }, [sessionsSelector])
    
    return (
        <>
            {!isLoading ? (
                <Spin />
            ) : (
                <>
                    <div className='card mb-5 mb-xl-10'>
                        <div
                            className='card-header border-0'
                            data-bs-target='#kt_account_profile_details'
                            aria-expanded='true'
                            aria-controls='kt_account_profile_details'
                        >
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>Otomasyon Ayarları</h3>
                            </div>
                        </div>

                        <div id='kt_account_profile_details' className='collapse show'>
                            <form onSubmit={formik.handleSubmit} noValidate className='form'>
                                <div className='card-body border-top p-9'>
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Mesaj Gecikme Aralığı</label>

                                        <div className='col-lg-8'>
                                            <div className='row'>
                                                <div className='col-lg-6 fv-row'>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                        placeholder='Minimum message delay'
                                                        {...formik.getFieldProps('min_message_delay')}
                                                    />
                                                    {formik.touched.min_message_delay && formik.errors.min_message_delay && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>{formik.errors.min_message_delay}</div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className='col-lg-6 fv-row'>
                                                    <input
                                                        type='text'
                                                        className='form-control form-control-lg form-control-solid'
                                                        placeholder='Minimum message delay'
                                                        {...formik.getFieldProps('max_message_delay')}
                                                    />
                                                    {formik.touched.max_message_delay && formik.errors.max_message_delay && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>{formik.errors.max_message_delay}</div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Mesaj Gönderme Zamanı</label>
                                        <div className={"d-flex align-items-center col-lg-8"}>

                                            {/* start::StartTimePicker */}
                                            <div className={"d-flex align-items-center"}>
                                                <select
                                                    value={startHour}
                                                    onChange={(event) => setStartHour(event.target.value)}
                                                    className={"p-3 border rounded me-3"}
                                                >
                                                    {[...Array(24).keys()].map((h) => (
                                                        <option key={h} value={h < 10 ? `0${h}` : `${h}`}>
                                                            {h < 10 ? `0${h}` : `${h}`}
                                                        </option>
                                                    ))}
                                                </select>
                                                <h6>
                                                    :
                                                </h6>
                                                <select
                                                    value={startMinute}
                                                    onChange={(event) => setStartMinute(event.target.value)}
                                                    className={"p-3 border rounded ms-3 me-5"}
                                                >
                                                    {[...Array(60).keys()].map((m) => (
                                                        <option key={m} value={m < 10 ? `0${m}` : `${m}`}>
                                                            {m < 10 ? `0${m}` : `${m}`}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {/* end::StartTimePicker */}

                                            <div>{" "} - {" "}</div>

                                            {/* start::EndTimePicker */}
                                            <div className='d-flex align-items-center ms-3'>
                                                <select
                                                    value={endHour}
                                                    onChange={(event) => setEndHour(event.target.value)}
                                                    className={"p-3 border rounded me-3"}
                                                >
                                                    {[...Array(24).keys()].map((h) => (
                                                        <option key={h} value={h < 10 ? `0${h}` : `${h}`}>
                                                            {h < 10 ? `0${h}` : `${h}`}
                                                        </option>
                                                    ))}
                                                </select>
                                                <h6>
                                                    :
                                                </h6>
                                                <select
                                                    value={endMinute}
                                                    onChange={(event) => setEndMinute(event.target.value)}
                                                    className={"p-3 border rounded ms-3 me-5"}
                                                >
                                                    {[...Array(60).keys()].map((m) => (
                                                        <option key={m} value={m < 10 ? `0${m}` : `${m}`}>
                                                            {m < 10 ? `0${m}` : `${m}`}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            {/* end::EndTimePicker */}

                                        </div>
                                        {formik.touched.beginTime && formik.errors.beginTime && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.beginTime}</div>
                                            </div>
                                        )}
                                    </div>
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Ülke kodu</label>
                                        <div className='col-lg-8'>
                                            <div className='row'>
                                                <div className='col-lg-6 fv-row'>
                                                    <input
                                                        type='number'
                                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                        placeholder='Ülke kodu'
                                                        value={defaultAreaCode}
                                                        onChange={(e)=> {
                                                            setDefaultAreaCode(parseInt(e.target.value))
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Spam Kodunu Kullan: </label>

                                        <div className='col-lg-8'>
                                            <Switch checked={checked} onChange={onChangeSwitch} />
                                        </div>
                                    </div>
                                </div>
                                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                    <button type='submit' className='btn btn-primary' disabled={loadingUpdate}>
                                        {!loadingUpdate && 'Kaydet'}
                                        {loadingUpdate && (
                                            <span className='indicator-progress' style={{ display: 'block' }}>
                                                Lütfen bekleyiniz...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='card mb-5 mb-xl-10'>
                        <div
                            className='card-header border-0'
                            data-bs-target='#kt_account_active_sessions'
                            aria-expanded='true'
                            aria-controls='kt_account_active_sessions'
                        >
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>Aktif WhatsApp Oturumları</h3>
                            </div>
                        </div>
                        <div id='kt_account_active_sessions' className='collapse show'>
                            <div className='row g-5 g-xl-8'>
                                {/** Kuyruk Oluşturma */}
                                <div className='col-xl-stretch mb-8'>
                                    {/*<SessionListWrapper />*/}
                                    <Session user={user} sessions={sessions.length > 0 ? sessions : []} customTheme={theme} getSessions={getSessions} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

        </>
    )
}
export { AutomationDetails }