import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api"

const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const getPrivacyAction = createAsyncThunk('GET_PRIVACY_ACTION', async (_, { rejectWithValue }) => {
    try {
        const endpoint = `auth/privacy`;
        const response = await HttpApi.GetAsync(endpoint, true);
        return response;
    }
    catch (err) {
        return rejectWithValue(err);
    }
})

const getPrivacySlice = createSlice({
    name: 'auth-privacy',
    initialState,
    reducers: {
        resetGetPrivacy: (state) => { return initialState; }
    },
    extraReducers: (builder) => {
        builder.addCase(getPrivacyAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(getPrivacyAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(getPrivacyAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
})

export {getPrivacyAction};
export const {resetGetPrivacy} = getPrivacySlice.actions;
export default getPrivacySlice.reducer;