import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const createGroupAction = createAsyncThunk("CREATE_GROUP_ACTION", async(
    {
        userId,
        groupName,
    }, {rejectWithValue}) =>
{
    try {
        let endpoint = `${userId}/groups/create`;
        const body = {groupName}
        return await HttpApi.PostAsync(endpoint, true, body);
    }
    catch(err)
    {
        return rejectWithValue(err);
    }
});

const createGroupSlice = createSlice({
    name: "create-group",
    initialState,
    reducers: {
        resetCreateGroup: (state) => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(createGroupAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
        .addCase(createGroupAction.fulfilled, (state, action) => {
            state.isFailure = false;
            state.isLoading = false;
            state.isSuccess = true;
            state.data = action.payload;
        })
        .addCase(createGroupAction.rejected, (state, action) => {
            state.isFailure = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.error = action.payload;
        })
    }
})
export {createGroupAction};
export const { resetCreateGroup } = createGroupSlice.actions;
export default createGroupSlice.reducer;