import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";

const initialState = {
    isLoading: false,
    isFailure: false,
    isSuccess: false,
    data: {},
    error: {}
}

const deletePhoneNumberAction = createAsyncThunk("DELETE_PHONE_NUMBER_ACTION", async (
    {
        user,
        phone
    }, { rejectWithValue }) => {
    try {
        let endpoint = `admin/user/${user}/extra-phone-permission/delete`;
        const response = await HttpApi.PostAsync(endpoint, true, {phone});
        return response;
    } catch (error) {
        return rejectWithValue(error)
    }
})

const deletePhoneNumberSlice = createSlice({
    name: "admin-delete-phone-number",
    initialState,
    reducers: {
        resetDeletePhoneNumber: (state) => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder.addCase(deletePhoneNumberAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        }).addCase(deletePhoneNumberAction.fulfilled, (state, action) => {
            state.isFailure = false;
            state.isLoading = false;
            state.isSuccess = true;
            state.data = action.payload;
        }).addCase(deletePhoneNumberAction.rejected, (state, action) => {
            state.isFailure = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.data = action.payload;
        })
    }
})

export {deletePhoneNumberAction};
export const {resetDeletePhoneNumber} = deletePhoneNumberSlice.actions;
export default deletePhoneNumberSlice.reducer;