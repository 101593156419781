import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const updateQueueAction = createAsyncThunk('UPDATE_QUEUE_ACTION', async (
    {
        userId,
        queqeuId,
        title,
        message
    }, {rejectWithValue}) => {
    try {
        const endpoint = `${userId}/queue/update/${queqeuId}/content`;
        const body = {
            title: title,
            message: message,
        };
        return await HttpApi.PutAsync(endpoint, true, body);
    } catch (err) {
        return rejectWithValue(err);
    }
})

const updateQueueSlice = createSlice({
    name: 'update-queue',
    initialState,
    reducers: {
        resetUpdateQueue: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(updateQueueAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(updateQueueAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(updateQueueAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {updateQueueAction};
export const { resetUpdateQueue } = updateQueueSlice.actions;
export default updateQueueSlice.reducer;
