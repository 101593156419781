import React, { useEffect, useState } from 'react'
import { Step1 } from './Steps/Step1'
import { Step2 } from './Steps/Step2'
import { Step3 } from './Steps/Step3'
import { Form, Formik } from 'formik'
import { Step4 } from "./Steps/Step4";
import Stepper from "./Stepper/Stepper";
import { useTheme } from "../../../services/themeService";
import { useDispatch, useSelector } from "react-redux";
import { addQueueAction, resetAddQueue } from "../../../services/redux/queues/add-queue";
import { get_local_storage_item } from "../../../utilities";
import { isFailure, isLoading, isSuccess } from "../../../utilities/reduxStateHelper";
import { fireToast } from '../../../utilities/toastHelper'
import { useNavigate } from 'react-router-dom'
import { Step5 } from "./Steps/Step5";
import { activeSessionsAction, resetActiveSessions } from "../../../services/redux/profile/active-sessions";
import { handleDateForSubmit } from '../../../utilities/dateHelper'

const steps = [
    {
        title: 'Müşteri Seçme',
    },
    {
        title: 'Mesaj',
    },
    {
        title: 'Dosya Yükleme',
    },
    {
        title: 'Zaman Seçme',
    },
    {
        title: 'QR Code',
    },
];

const QueueAdd = () => {
    const dispathcer = useDispatch();
    const getAddQueueState = useSelector((state) => state.queue.createQueue);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [sessions, setSessions] = useState([]);
    const [checkAreaCode, setCheckAreaCode] = useState(false)
    const getSessionsState = useSelector((state) => state.profile.activeSessions);
    const { theme } = useTheme();
    const [formData, setFormData] = useState({
        step: 0,
        files: [],
        customers: [],
        title: '',
        message: '',
        groupId: '',
        startHour: 0,
        startMinute: 0,
        guideControle: "",
        quickData: []
    });
    const [step, setStep] = useState(0);
    const [guideControle, setGuideControle] = useState("");
    const [selectedCustomers, setSelectedCustomers] = useState([]);

    useEffect(() => {
        // eslint-disable-next-line no-const-assign, react-hooks/exhaustive-deps
        dispathcer(activeSessionsAction({ userId: get_local_storage_item("user").id }))
    }, [dispathcer]);

    useEffect(() => {
        if (isSuccess(getSessionsState)) {
            setSessions(getSessionsState.data.data);
            setLoading(false);
        }
        if (isFailure(getSessionsState)) {
            setLoading(false)
        }
        if (isLoading(getSessionsState)) {
            setLoading(true)
        }
        return () => {
            dispathcer(resetActiveSessions());
        }
    }, [sessions, dispathcer, getSessionsState])


    const addNewQueue = ({
        files,
        title,
        message,
        sessionId,
        customers,
        groupId,
        quickData,
        startDate,
        useCustomAreaCode
    }) => {
        let data = {
            userId: get_local_storage_item("user").id,
            files: files,
            title: title,
            message: message,
            sessionId: sessionId,
            customers: customers,
            groupId: groupId,
            quickData: quickData,
            startDate: startDate,
            useCustomAreaCode: useCustomAreaCode
        }
        dispathcer(addQueueAction(data));
    }

    const currentDate = new Date();
    const titleDate = currentDate.toLocaleDateString('tr-TR', { year: '2-digit', month: '2-digit', day: '2-digit' });
    const titleTime = currentDate.toLocaleTimeString('tr-TR', { hour12: false });

    const handleSubmit = (sessionId) => {

        const selectedDate = handleDateForSubmit(formData.startHour, formData.startMinute)

        const data = {
            files: formData.files,
            title: `${sessionId.phone} - ${formData.title.trim() ? formData.title : `${titleDate} ${titleTime}`}`,
            message: formData.message,
            sessionId: sessionId.sessionName,
            customers: formData.customers,
            groupId: formData.groupId,
            quickData: formData.quickData,
            startDate: selectedDate.toISOString(),
            useCustomAreaCode: checkAreaCode
        }

       addNewQueue(data)
    }

    const updateFormData = (data) => {
        setFormData({
          ...formData,
          ...data
        });
      };

    const handleQuickData = (data) => {
        updateFormData({
            quickData: data
        })
    }

    const handleFiles = (uploadedFiles) => {
        updateFormData({
            files: uploadedFiles
        })
    }

    const handleGroup = (group) => {
        updateFormData({
            groupId: group
        })
    }
    const handleCustomers = (customers) => {
        updateFormData({
            customers: customers
        })
    }

    const handleContent = (title, message) => {
        updateFormData({
            title: title,
            message: message
        })
    }

    const handleTimeSelector = (hour, minute) => {
        updateFormData({
            startHour: hour,
            startMinute: minute
        })
    }

    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    useEffect(() => {
        if (isSuccess(getAddQueueState)) {
            fireToast(getAddQueueState.data.message, "SUCCESS", theme.theme)
            setTimeout(() => {
                navigate('/dashboard');
            }, (2000));
        }
        if (isFailure(getAddQueueState)) {
            fireToast(getAddQueueState.error.message, "FAIL", theme.theme);
        }
        // Handle loading state if needed
        return () => {
            dispathcer(resetAddQueue());
        }
    }, [dispathcer, getAddQueueState, navigate, theme]);

    return (
        <div className={"px-2 px-sm-10 px-md-20 mb-20"}>
            <Stepper step={step} steps={steps} customTheme={theme} />
            {
                step === 1 && (
                    <div className='d-flex justify-content-center'>
                        <h1 className='mb-10'>Mesajın Gönderileceği Kişi Sayısı: {guideControle === "quick" ? formData.quickData.length : formData.customers.length}</h1>
                    </div>
                )
            }
            <Formik>
                {() => (
                    <Form className={"d-flex justify-content-center align-items-center h-100"}>
                        {step === 0 && <Step3 nextStep={nextStep} handleCustomers={handleCustomers} formData={formData} handleGroup={handleGroup} setGuideControle={setGuideControle} handleQuickData={handleQuickData} selectedCustomers={selectedCustomers} setSelectedCustomers={setSelectedCustomers} group={formData.groupId} customTheme={theme} checkAreaCode={checkAreaCode} setCheckAreaCode={setCheckAreaCode}/>}
                        {step === 1 && <Step1 nextStep={nextStep} prevStep={prevStep} formData={formData} guideControle={guideControle} handleContent={handleContent} customTheme={theme} />}
                        {step === 2 && <Step2 nextStep={nextStep} prevStep={prevStep} formData={formData} handleFiles={handleFiles} customTheme={theme} />}
                        {step === 3 && <Step4 nextStep={nextStep} prevStep={prevStep} formData={formData} updateFormData={updateFormData} handleTimeSelector={handleTimeSelector} customTheme={theme} />}
                        {step === 4 && <Step5 prevStep={prevStep} handleSubmit={handleSubmit} customTheme={theme} />}
                    </Form>
                )}
            </Formik>
        </div>

    )
}

export { QueueAdd }
