import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const multiAddToGrayListAction = createAsyncThunk("MULTI_ADD_TO_GRAYLIST_ACTION", async (
    {
        userId,
        customers,
    }, { rejectWithValue }) => {
    try {
        let endpoint = `${userId}/graylist/customers/add/multiple`;
        return await HttpApi.PostAsync(endpoint, true, {customers});
    }
    catch (err) {
        return rejectWithValue(err);
    }
});

const multiAddToGrayListSlice = createSlice({
    name: "multi-add-to-graylist",
    initialState,
    reducers: {
        resetMultiAddGrayListCustomer: (state) => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder.addCase(multiAddToGrayListAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(multiAddToGrayListAction.fulfilled, (state, action) => {
                state.isFailure = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.data = action.payload;
            })
            .addCase(multiAddToGrayListAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.data = action.payload;
            })
    }
})
export { multiAddToGrayListAction };
export const { resetMultiAddGrayListCustomer } = multiAddToGrayListSlice.actions;
export default multiAddToGrayListSlice.reducer;