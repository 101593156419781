import {Outlet, Route, Routes} from 'react-router-dom'
import {PageTitle} from '../_metronic/layout/core'
import {QueueAdd} from "../components/Apps/queue-management/QueueAdd";
import React from "react";

const queueBreadCrumbs = [
    {
        title: 'Mesaj Listesi Ekleme',
        path: '/queue/add',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const QueueWizardsPage = () => (
    <Routes>
        <Route element={<Outlet/>}>
            <Route
                path='add'
                element={
                    <>
                        <PageTitle breadcrumbs={queueBreadCrumbs}>Mesaj Listesi</PageTitle>
                        <QueueAdd />
                    </>
                }
            />
        </Route>
    </Routes>
)

export default QueueWizardsPage
