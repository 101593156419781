import React from 'react'
import ModalHeader from '../ModalHeader'
import { ConfigProvider, Pagination, Spin } from 'antd'
import { formatPhoneNumber } from '../../../utilities/stringHelper'

const GroupInfoModal = ({ setIsShowGroupInfoModal, selectedGroup, groupDetailFetchNextData, paginateData, loading, theme }) => {
    return (
        <div
            className='modal fade show d-block'
            id='kt_modal_user_info'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
        >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-700px'>
                {/* begin::Modal content */}
                <div className='modal-content' style={{ display: 'flex', justifyContent: 'center' }}>
                    <ModalHeader
                        title="Grup Detayi"
                        handleCloseModal={() => setIsShowGroupInfoModal(false)}
                    />

                    {/* begin::Modal body */}
                    <div className={"modal-body scroll-y mx-5 mx-xl-15 my-7"}>
                        <div className='card mb-xl-8 border mb-5'>

                            {/* begin::Header */}
                            <div className='card-header align-items-center border-0 mt-4'>
                                <div className={"card-title"}>
                                    <h2>Müşteri Listesi</h2>
                                </div>
                            </div>
                            {/* end::Header */}

                            <div className="card-body py-4">
                                {
                                    loading ? (
                                        <div className="d-flex align-items-center justify-content-center w-100 ">
                                            <Spin size="large" />
                                        </div>
                                    ) : (
                                        <div className={"dataTables_wrapper dt-bootstrap4 no-footer"}>
                                            <div className={"table-responsive"}
                                                style={{ maxHeight: 400, overflowY: "auto" }}
                                            >
                                                <table className={"table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"}>
                                                    <thead>
                                                        <tr className={"text-start text-muted fw-bold fs-7 gs-0"}>
                                                            <th className={"min-w-125px sorting"} tabIndex="0" rowSpan="1" colSpan="1"
                                                                style={{ width: "150.138px" }}>
                                                                İsim
                                                            </th>
                                                            <th className={"min-w-125px sorting"} tabIndex="0" rowSpan="1" colSpan="1"
                                                                style={{ width: "150.138px" }}>
                                                                Soyisim
                                                            </th>
                                                            <th className={"min-w-125px sorting"} tabIndex="0" rowSpan="1" colSpan="1"
                                                                style={{ width: "152.663px" }}>
                                                                Telefon Numarası
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className={"text-gray-600 fw-semibold"}>
                                                        {
                                                            selectedGroup.map((customer) => (
                                                                <tr key={customer.id}>
                                                                    <td className='p-2'>{customer.name}</td>
                                                                    <td className='p-2'>{customer.lastName}</td>
                                                                    <td className='p-2'>
                                                                        <div>{formatPhoneNumber(customer.phone)}</div>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    marginTop: 30,
                                                    marginBottom: 15
                                                }}
                                            >
                                                <ConfigProvider
                                                    theme={{
                                                        token: {
                                                            colorBgContainer: theme.backgroundColor,
                                                            colorText: theme.color
                                                        }
                                                    }}
                                                >
                                                    <Pagination
                                                        locale={{ items_per_page: '/ sayfa' }}
                                                        showSizeChanger
                                                        onShowSizeChange={(current, pageSize) => {
                                                            groupDetailFetchNextData(1, pageSize)
                                                        }}
                                                        pageSizeOptions={[10, 50, 100, 250, 500]}     // Per page degerleri
                                                        defaultCurrent={1}
                                                        total={paginateData.totalItems}
                                                        pageSize={paginateData.perpage}
                                                        className={theme.theme === "light" ? "light-theme" : "dark-theme"}
                                                        onChange={(page, pageSize) => {
                                                            groupDetailFetchNextData(page, pageSize)
                                                        }}
                                                    />
                                                </ConfigProvider>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    {/* end::Modal body */}

                </div>
                {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}

        </div>
    )
}

export default GroupInfoModal