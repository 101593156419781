import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};
const getCustomersAction = createAsyncThunk('GET_ALL_CUSTOMERS_ACTION', async (
    {
        userId,
        page,
        perPage,
        search
    }, {rejectWithValue}) => {
    try {
        let endpoint = !(page && perPage) ? `${userId}/customers` : `${userId}/customers?page=${page}&perpage=${perPage}`;
        endpoint = (search && search != "") ? (endpoint+ `&search=${search}`) : endpoint;
        const response = await HttpApi.GetAsync(endpoint, true);
        return response;
    } catch (err) {
        return rejectWithValue(err);
    }
})

const getCustomersSlice = createSlice({
    name: 'get-customers',
    initialState,
    reducers: {
        resetGetCustomers: (state) => {return initialState}
    },
    extraReducers: (builder) => {
        builder.addCase(getCustomersAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
        .addCase(getCustomersAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
                state.error = {};
        })
        .addCase(getCustomersAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
                state.data = {};
        })
    }
});
export {getCustomersAction};
export const {resetGetCustomers} = getCustomersSlice.actions; 
export default getCustomersSlice.reducer;
