import React, { useEffect, useState } from 'react'
import { KTIcon } from '../../../_metronic/helpers';
import { get_local_storage_item } from '../../../utilities';
import { fireToast } from '../../../utilities/toastHelper';
import { useTheme } from '../../../services/themeService';
import { useDispatch, useSelector } from 'react-redux';
import { adminUploadCreditAmountAction, resetAdminUploadCreditAmount } from '../../../services/redux/admin/admin-credit-amount';
import { isFailure, isSuccess } from '../../../utilities/reduxStateHelper';
import { creditInformationAction } from '../../../services/redux/profile/credit-info';

const AddCredit = () => {
    const {theme} = useTheme();
    const user = get_local_storage_item("user");
    const dispatch = useDispatch();
    const adminUploadCreditState = useSelector((state) => state.admin.adminUploadCredit);

    const [creditAmount, setCreditAmount] = useState("");

    const handleLoadCredit = () => {
        if(creditAmount === ""){
            fireToast("Kredi miktarı alanı boş gönderilemez!", "FAIL", theme.theme);
            return;
        }

        const credit = parseInt(creditAmount);
        dispatch(adminUploadCreditAmountAction({
            admin: user.id,
            creditAmount: credit
        }));
    }

    useEffect(() => {
        if(isSuccess(adminUploadCreditState)){
            fireToast(adminUploadCreditState.data.message, "SUCCESS", theme.theme);
            dispatch(creditInformationAction({
                user: user.id
            }))
            setCreditAmount("");
        }
        if(isFailure(adminUploadCreditState)){
            fireToast(adminUploadCreditState.error.message, "FAIL", theme.theme)
        }
        return () => {
            dispatch(resetAdminUploadCreditAmount());
        }
    }, [dispatch, adminUploadCreditState])

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Kredi Ekleme</h3>
                </div>
            </div>

            <div id='kt_account_notifications' className='collapse show'>
                <div className='card-body border-top px-9 pt-3 pb-4'>

                    {/* begin::notice */}
                    <div className={`notice d-flex bg-light-primary border-primary border border-dashed rounded mt-3 mb-5 p-6`}>
                        <KTIcon iconName="information" className={`fs-2tx me-4 text-primary`} />
                        <div className={"d-flex flex-stack flex-grow-1"}>
                            <div className={"fw-semibold"}>
                                <div className={"fs-6 text-gray-700 "}>
                                    {
                                        user && user.role === "Admin"
                                            ? "Bu kısımı sadece Yönetici olan kişi görebilmekte. Kendinize yüklemek istediğiniz kredi miktarını aşağıya giriniz."
                                            : "Kredi yükleme işlemi için yönetici ile iletişime geçmeniz gerekmektedir. Aşağıdaki WhatsApp iletişim bağlantısını kullanabilirsiniz."
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end::notice */}

                    {
                        user && user.role === "User" ? (
                            <div className='d-flex justify-content-end'>
                                <a
                                    href='https://api.whatsapp.com/send?phone=905468468462&text=Merhaba%20Pro%20Whatsapp%20Web%20i%C3%A7in%20'
                                    className='btn btn-primary'
                                >
                                    Yönetici ile İletişime Geç
                                </a>
                            </div>
                        ) : (
                            <div className='d-flex align-items-center row my-5 mt-10'>
                                <label className='col-lg-2 fw-bold fs-4'>Kredi Miktarı</label>

                                <div className='col-lg-6'>
                                    <input
                                        type='number'
                                        name='creditAmount'
                                        value={creditAmount}
                                        onChange={(e) => setCreditAmount(e.target.value)}
                                        required
                                        placeholder='Kendinize yüklemek istediğiniz kredi miktarını giriniz..'
                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                    />
                                </div>

                                <div className='col-lg-4'>
                                    <button
                                        className='btn btn-primary'
                                        type='button'
                                        onClick={handleLoadCredit}
                                    >
                                        Kaydet
                                    </button>
                                </div>
                            </div>
                        )
                    }

                </div>
            </div>
        </div>
    )
}

export default AddCredit;