import React, { useEffect, useState } from 'react'
import { useTheme } from '../../services/themeService';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { automationSettingsAction, resetAutomationSettings } from '../../services/redux/profile/automation-settings';
import { isFailure, isLoading, isSuccess } from '../../utilities/reduxStateHelper';
import * as Yup from 'yup'
import { Switch } from 'antd';
import { resetUpdateAdminAutomationSettings, updateAdminAutomationSettingsAction } from '../../services/redux/admin/automation-settings';
import { fireToast } from '../../utilities/toastHelper';

const automationSettingsSchema = Yup.object().shape({
    min_message_delay: Yup.number().required('Minimum Message delay range is required'),
    max_message_delay: Yup.number().required('Maximum Message delay range is required'),
})

const AutomationSettings = ({ userId }) => {
    const { theme } = useTheme();
    const dispatch = useDispatch();
    const automationSelector = useSelector((state) => state.profile.automationSettings);
    const updateAdminAutomationSettingsState = useSelector((state) => state.admin.adminAutomationSettings);

    const [startHour, setStartHour] = useState();
    const [startMinute, setStartMinute] = useState();
    const [endHour, setEndHour] = useState();
    const [endMinute, setEndMinute] = useState();
    const [defaultAreaCode, setDefaultAreaCode] = useState(90)

    const [data, setData] = useState({
        min_message_delay: 0,
        max_message_delay: 0,
        beginTime: new Date(),
        updatedAt: new Date()
    })
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [checked, setChecked] = useState(false);

    function formatTime(number) {
        // Sayıyı iki basamaklı string olarak döndür
        return number < 10 ? '0' + number : '' + number;
    }

    const formik = useFormik({
        initialValues: data,
        validationSchema: automationSettingsSchema,
        onSubmit: (values) => {
            const data = {
                userId: userId,
                startHour: parseInt(startHour),
                startMinute: parseInt(startMinute),
                endHour: parseInt(endHour),
                endMinute: parseInt(endMinute),
                min_message_delay: values.min_message_delay,
                max_message_delay: values.max_message_delay,
                useSpamCode: checked,
                defaultAreaCode: defaultAreaCode

            };
            //dispatch(updateAutomationSettingsAction(data))
            dispatch(updateAdminAutomationSettingsAction(data));
        },
    })

    formik.status = formik.status || {};

    const onChangeSwitch = (checked) => {
        setChecked(checked)
    }

    useEffect(() => {
        dispatch(automationSettingsAction({ userId: userId }));
    }, [dispatch, userId])

    useEffect(() => {
        if (isSuccess(automationSelector)) {
            setData(automationSelector.data.data);
            formik.setValues(automationSelector.data.data);
            setStartHour(formatTime(automationSelector.data.data.start_Hour));
            setStartMinute(formatTime(automationSelector.data.data.start_Minute));
            setEndHour(formatTime(automationSelector.data.data.end_Hour));
            setEndMinute(formatTime(automationSelector.data.data.end_Minute));
            setChecked(automationSelector.data.data.useSpamCode)
            setDefaultAreaCode(automationSelector.data.data.defaultAreaCode ? automationSelector.data.data.defaultAreaCode : 90)
        }
        if (isFailure(automationSelector)) {
        }
        if (isLoading(automationSelector)) {
        }
        return () => {
            dispatch(resetAutomationSettings());
        }
    }, [automationSelector, dispatch, checked, setChecked])

    useEffect(() => {
        if (isSuccess(updateAdminAutomationSettingsState)) {
            fireToast(updateAdminAutomationSettingsState.data.message, "SUCCESS", theme.theme);
            dispatch(automationSettingsAction({ userId: userId }));
        }
        if (isFailure(updateAdminAutomationSettingsState)) {
            fireToast(updateAdminAutomationSettingsState.error.message, "FAIL", theme.theme);
        }
        return () => {
            dispatch(resetUpdateAdminAutomationSettings());
        }
    }, [dispatch, updateAdminAutomationSettingsState])

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Otomasyon Ayarları</h3>
                </div>
            </div>

            <div id='kt_account_profile_details' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Mesaj Gecikme Aralığı</label>

                            <div className='col-lg-8'>
                                <div className='row'>
                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            placeholder='Minimum message delay'
                                            {...formik.getFieldProps('min_message_delay')}
                                        />
                                        {formik.touched.min_message_delay && formik.errors.min_message_delay && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.min_message_delay}</div>
                                            </div>
                                        )}
                                    </div>

                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Minimum message delay'
                                            {...formik.getFieldProps('max_message_delay')}
                                        />
                                        {formik.touched.max_message_delay && formik.errors.max_message_delay && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.max_message_delay}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Mesaj Gönderme Zamanı</label>
                            <div className={"d-flex align-items-center col-lg-8"}>

                                {/* start::StartTimePicker */}
                                <div className={"d-flex align-items-center"}>
                                    <select
                                        value={startHour}
                                        onChange={(event) => setStartHour(event.target.value)}
                                        className={"p-3 border rounded me-3"}
                                    >
                                        {[...Array(24).keys()].map((h) => (
                                            <option key={h} value={h < 10 ? `0${h}` : `${h}`}>
                                                {h < 10 ? `0${h}` : `${h}`}
                                            </option>
                                        ))}
                                    </select>
                                    <h6>
                                        :
                                    </h6>
                                    <select
                                        value={startMinute}
                                        onChange={(event) => setStartMinute(event.target.value)}
                                        className={"p-3 border rounded ms-3 me-5"}
                                    >
                                        {[...Array(60).keys()].map((m) => (
                                            <option key={m} value={m < 10 ? `0${m}` : `${m}`}>
                                                {m < 10 ? `0${m}` : `${m}`}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {/* end::StartTimePicker */}

                                <div>{" "} - {" "}</div>

                                {/* start::EndTimePicker */}
                                <div className='d-flex align-items-center ms-3'>
                                    <select
                                        value={endHour}
                                        onChange={(event) => setEndHour(event.target.value)}
                                        className={"p-3 border rounded me-3"}
                                    >
                                        {[...Array(24).keys()].map((h) => (
                                            <option key={h} value={h < 10 ? `0${h}` : `${h}`}>
                                                {h < 10 ? `0${h}` : `${h}`}
                                            </option>
                                        ))}
                                    </select>
                                    <h6>
                                        :
                                    </h6>
                                    <select
                                        value={endMinute}
                                        onChange={(event) => setEndMinute(event.target.value)}
                                        className={"p-3 border rounded ms-3 me-5"}
                                    >
                                        {[...Array(60).keys()].map((m) => (
                                            <option key={m} value={m < 10 ? `0${m}` : `${m}`}>
                                                {m < 10 ? `0${m}` : `${m}`}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                {/* end::EndTimePicker */}

                            </div>
                            {formik.touched.beginTime && formik.errors.beginTime && (
                                <div className='fv-plugins-message-container'>
                                    <div className='fv-help-block'>{formik.errors.beginTime}</div>
                                </div>
                            )}
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Ülke kodu</label>
                            <div className='col-lg-8'>
                                <div className='row'>
                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            type='number'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            placeholder='Ülke kodu'
                                            value={defaultAreaCode}
                                            onChange={(e)=> {
                                                setDefaultAreaCode(parseInt(e.target.value))
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex align-items-center row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Spam Kodunu Kullan: </label>

                            <div className='col-lg-8'>
                                <Switch checked={checked} onChange={onChangeSwitch} />
                            </div>
                        </div>
                    </div>
                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary' disabled={loadingUpdate}>
                            {!loadingUpdate && 'Kaydet'}
                            {loadingUpdate && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Lütfen bekleyiniz...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AutomationSettings