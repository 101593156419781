import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";

const initialState = {
    isLoading: false,
    isFailure: false,
    isSuccess: false,
    data: {},
    error: {}
}

const updatePhoneAmountsAction = createAsyncThunk("UPDATE_PHONE_AMOUNTS_ACTION", async (
    {
        phoneAmount
    }, { rejectWithValue }) => {    
    try {
        let endpoint = `admin/settings/defaults/update/phone-amount`;
        const response = await HttpApi.PatchAsync(endpoint, true, {phoneAmount});
        return response;
    } catch (error) {
        return rejectWithValue(error)
    }
})

const updatePhoneAmountsSlice = createSlice({
    name: 'update-phone-amount',
    initialState,
    reducers: {
        resetUpdatePhoneAmounts: (state) => { return initialState }
    },
    extraReducers: (builder) => {
        builder.addCase(updatePhoneAmountsAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
        .addCase(updatePhoneAmountsAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
                state.error = {};
        })
        .addCase(updatePhoneAmountsAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
                state.data = {};
        })
    }
});

export {updatePhoneAmountsAction};
export const {resetUpdatePhoneAmounts} = updatePhoneAmountsSlice.actions;
export default updatePhoneAmountsSlice.reducer;