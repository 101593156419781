import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { PasswordMeterComponent } from "../../_metronic/assets/ts/components";
import { useDispatch, useSelector } from 'react-redux'
import { isFailure, isLoading, isSuccess } from '../../utilities/reduxStateHelper'
import { forgotPasswordConfirmAction, resetForgotPasswordConfirm } from '../../services/redux/auth/forgot-password-confirm'
import { resetForgotPassword } from '../../services/redux/auth/forgot-password'
const loginSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'En az 3 sembol')
        .max(50, 'Maksimum 50 sembol')
        .required('Şifre gereklidir'),
    confirmPassword: Yup.string()
        .min(8, 'En az 3 sembol')
        .max(50, 'Maksimum 50 sembol')
        .required('Şifre gereklidir')
        .oneOf([Yup.ref('password')], 'Şifreler eşleşmiyor'),
})

const initialValues = {
    password: '',
    confirmPassword: '',
}
const ForgotPasswordConfirm = () => {
    const [loading, setLoading] = useState(false)
    const { token } = useParams();
    const dispatcher = useDispatch();
    const navigate = useNavigate();
    const selector = useSelector((state) => state.auth.forgotPassConfirm);

    const formik = useFormik({
        initialValues,
        validationSchema: loginSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            dispatcher(forgotPasswordConfirmAction({ password: values.password, hash_token: token }))
        },
    })
    formik.status = formik.status || {};
    useEffect(() => {
        PasswordMeterComponent.bootstrap()
    }, [])
    useEffect(() => {
        let timeout;

        if (isLoading(selector)) {
            setLoading(true);
        }

        if (isFailure(selector)) {
            setLoading(false);
            formik.setStatus({ sent: false, msg: selector.error.message });
        }

        if (isSuccess(selector)) {
            setLoading(false);
            formik.setStatus({ sent: true, msg: selector.data.message });

            timeout = setTimeout(() => {
                navigate('/auth/login');
            }, 3000);
        }

        return () => {
            clearTimeout(timeout);
            dispatcher(resetForgotPasswordConfirm());
        };
    }, [dispatcher, formik, navigate, selector]);

    return (

        < form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_forgot_password_confirm_form'
        >
            {/* begin::Heading */}
            < div className='text-center mb-11' >
                <h1 className='text-dark fw-bolder mb-3'>Yeni Şifreni Belirle</h1>
            </div >
            {
                Object.keys(formik.status).length !== 0 && (
                    <div className={`mb-lg-15 alert ${formik.status.sent ? 'alert-success' : 'alert-danger'}`}>
                        <div className='alert-text font-weight-bold'>{formik.status.msg}</div>
                    </div>
                )
            }
            {/* begin::Heading */}


            {/* begin::Form group */}

            <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                <input
                    placeholder='Yeni Şifre'
                    {...formik.getFieldProps('newPassword')}
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.password && formik.errors.password },
                        {
                            'is-valid': formik.touched.password && !formik.errors.password,
                        }
                    )}
                    type='password'
                    name='password'
                    autoComplete='off'
                />
                {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                        <span role='alert'>{formik.errors.password}</span>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className='fv-row mb-3'>
                <label className='form-label fw-bolder text-dark fs-6 mb-0'>Şifreni Onayla</label>
                <input
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('confirmPassword')}
                    className={clsx(
                        'form-control bg-transparent',
                        {
                            'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
                        },
                        {
                            'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                        }
                    )}
                />
                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.confirmPassword}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Wrapper */}
            <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                <div />

                {/* begin::Link */}
                <Link to='/auth/login' className='link-warning'>
                    Geri Dön
                </Link>
                {/* end::Link */}
            </div>
            {/* end::Wrapper */}

            {/* begin::Action */}
            <div className='d-grid mb-10'>
                <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-warning'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>Devam Et</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Lütfen Bekleyin...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
            </div>
            {/* end::Action */}

            <div className='text-gray-500 text-center fw-semibold fs-6'>
                Halen Kayıtlı değilmisin?{' '}
                <Link to='/auth/register' className='link-warning'>
                    Kayıt ol
                </Link>
            </div>
        </form >
    )
}
export { ForgotPasswordConfirm };