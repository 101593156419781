import { combineReducers, configureStore } from "@reduxjs/toolkit";

// auth reducers
import RegisterReducer from './auth/register';
import LoginReducer from './auth/login';
import VerifyEmailReducer from './auth/verify-email';
import ForgotPasswordReducer from './auth/forgot-password';
import ForgotPasswordConfirmReducer from './auth/forgot-password-confirm';
import LogoutReducer from './auth/logout';
import ResetPasswordReducer from './auth/reset-password';
import ResetSessionReducer from './auth/reset-session-login';
import PrivacyReducer from "./auth/privacy";

// customers
import GetCustomersReducer from './customers/get-customers'
import GetCustomerReducer from './customers/get-customer'
import AddCustomerReducer from './customers/add-customer'
import UpdateCustomerReducer from './customers/update-customer'
import DeleteCustomerReducer from './customers/delete-customer'
import GetGrayListReducer from './customers/graylist/get-graylist'
import AddGrayListReducer from './customers/graylist/add-to-graylist'
import RemoveGrayListReducer from './customers/graylist/remove-from-graylist'
import GetBlackListReducer from './customers/blacklist/get-blacklist'
import AddBlackListReducer from './customers/blacklist/add-to-blacklist'
import RemoveBlackListReducer from './customers/blacklist/remove-from-blacklist'
import AddMultipleCustomerReducer from './customers/add-multiple-customer'
import MultiAddGrayListReducer from './customers/graylist/multi-add-grayList'
import MultiAddBlackListReducer from './customers/blacklist/multi-add-blackList'

// groups 
import AddToGroupReducer from './customers/groups/add-to-group';
import GetAllGroupsReducer from './customers/groups/get-groups';
import CreateGroupReducer from './customers/groups/create-group';
import DeleteGroupReducer from './customers/groups/delete-group';
import GetGroupByIdReducer from './customers/groups/get-group-by-id';
import GetGroupCustomersReducer from './customers/groups/get-group-customer';
import RemoveFromGroupReducer from './customers/groups/remove-from-group';
import UpdateGroupReducer from './customers/groups/update-group';
// account reducers
import DeleteAccountReducer from './account/delete-account';
import FreezeAccountReducer from './account/freeze-account';
import ReactivateAccountReducer from './account/reactivate-account';
import ReactivateAccountConfirmReducer from './account/reactivate-account-confirm';
// profile reducers
import ActiveSessionsReducer from './profile/active-sessions';
import UpdateProfileReducer from './profile/update-profile';
import AutomationSettingsReducer from './profile/automation-settings';
import UpdateAutomationSettingsReducer from './profile/update-automation-settings';
import UserDetailsReducer from './profile/user-details';
import DeleteSessionReducer from './profile/delete-session'
import CreditInformationReducer from './profile/credit-info'
import AddExtraPhoneNumberReducer from './profile/addExtraPhoneNumber';
import SendHelpMailReducer from './profile/help-mail'
// drafts reducers
import CreateDraftReducer from './drafts/create-draft';
import GetDraftByIdReducer from './drafts/get-draft-by-id';
import DeleteDraftReducer from './drafts/delete-draft';
import GetDraftsReducer from './drafts/get-all-draft';
import UpdateDraftReducer from './drafts/update-draft.js';
// queue reducers
import CreateQueueReducer from './queues/add-queue'
import DeleteQueueReducer from './queues/delete-queue'
import UpdateQueueReducer from './queues/update-queue';
import GetAllQueueReducer from './queues/get-all-queue';
import GetByIdQueueReducer from './queues/get-by-id';
import PauseQueueReducer from './queues/pause-queue';
import StartQueueAgainReducer from './queues/start-queue-again';
import GetQueueItemsReducer from './queues/get-queue-items';
import AddCustomersToQueueReducer from './queues/add-customers';
import RemoveCustomerFromQueueReducer from './queues/remove-customer';
import DeleteFileQueueReducer from './queues/delete-file';
import UploadFileToQueueReducer from './queues/upload-file';
import GetQueueHistoryReducer from './queues/get-queue-history';

//admin reducers
import GetUsersReducer from './admin/get-users';
import GetUserByIdReducer from './admin/get-user-by-id';
import BlockUserReducer from './admin/block-user';
import UnblockUserReducer from './admin/unblock-user';
import UploadCreditReducer from './admin/upload-credit';
import GetUserSessionsReducer from './admin/user-sessions';
import ExtraPhonePermissionReducer from './admin/extra-phone-permission';
import GetSpentCreditDataReducer from './admin/spent-credit-data';
import GetLogsReducer from './admin/get-logs';
import ChangeMailReducer from './admin/change-mail';
import ChangePhoneReducer from './admin/change-phone';
import GetSettingsDefaultReducer from './admin/settings-default';
import UpdatePrivacyTextReducer from './admin/privacy-text';
import UpdateRegisterTextReducer from './admin/register-text';
import UpdateCreditAmountReducer from './admin/update-credit-amount';
import UpdatePhoneAmountReducer from './admin/update-phone-amount';
import DeletePhoneNumberReducer from './admin/delete-phone-number';
import AdminUploadCreditAmountReducer from './admin/admin-credit-amount';
import UpdateAdminAutomationSettingsReducer from './admin/automation-settings';
import DeleteUserReducer from './admin/delete-user'
import updateNotificationSettingsReducer from "./admin/update-notification-settings";
import getNotificationSettings from "./admin/get-notification-settings";
import getUserNotificationSettings from "./profile/get-user-notification-settings";
import updateUserNotificationSettings from "./profile/update-user-notification-settings";
import toggleServiceActivity from "./admin/toggle-service-activity";
const authReducer = combineReducers({
  register: RegisterReducer,
  login: LoginReducer,
  verifyEmail: VerifyEmailReducer,
  forgotPassword: ForgotPasswordReducer,
  forgotPassConfirm: ForgotPasswordConfirmReducer,
  logout: LogoutReducer,
  resetPassword: ResetPasswordReducer,
  resetSession: ResetSessionReducer,
  privacy: PrivacyReducer
})
const accountReducer = combineReducers({
  deleteAccount: DeleteAccountReducer,
  freezeAccount: FreezeAccountReducer,
  reactivateAccount: ReactivateAccountReducer,
  reactivateAccountConfirm: ReactivateAccountConfirmReducer,
})

const customerReducer = combineReducers({
  getCustomers: GetCustomersReducer,
  getCustomer: GetCustomerReducer,
  addCustomer: AddCustomerReducer,
  updateCustomer: UpdateCustomerReducer,
  deleteCustomer: DeleteCustomerReducer,
  addMultiple: AddMultipleCustomerReducer,
})

const grayListReducer = combineReducers({
  getGrayListCustomers: GetGrayListReducer,
  addGrayListCustomer: AddGrayListReducer,
  removeGrayListCustomer: RemoveGrayListReducer,
  multiAddGrayList: MultiAddGrayListReducer
})

const blackListReducer = combineReducers({
  getBlackListCustomers: GetBlackListReducer,
  addBlackListCustomer: AddBlackListReducer,
  removeBlackListCustomer: RemoveBlackListReducer,
  multiAddBlackList: MultiAddBlackListReducer
})

const groupReducer = combineReducers({
  createGroup: CreateGroupReducer,
  deleteGroup: DeleteGroupReducer,
  updateGroup: UpdateGroupReducer,
  getAllGroups: GetAllGroupsReducer,
  getByIdGroup: GetGroupByIdReducer,
  getGroupCustomers: GetGroupCustomersReducer,
  addToGroup: AddToGroupReducer,
  removeFromGroup: RemoveFromGroupReducer,
})
const profileReducer = combineReducers({
  activeSessions: ActiveSessionsReducer,
  updateProfile: UpdateProfileReducer,
  automationSettings: AutomationSettingsReducer,
  updateAutomationSettings: UpdateAutomationSettingsReducer,
  profileDetails: UserDetailsReducer,
  deleteSession: DeleteSessionReducer,
  creditInfo: CreditInformationReducer,
  addExtraPhoneNumber: AddExtraPhoneNumberReducer,
  sendHelpMail: SendHelpMailReducer,
  getUserNotification: getUserNotificationSettings,
  updateUserNotification: updateUserNotificationSettings
});
const draftsReducer = combineReducers({
  createDraft: CreateDraftReducer,
  updateDraft: UpdateDraftReducer,
  deleteDraft: DeleteDraftReducer,
  getByIdDraft: GetDraftByIdReducer,
  getAllDraft: GetDraftsReducer
});

const queueReducer = combineReducers({
  createQueue: CreateQueueReducer,
  updateQueue: UpdateQueueReducer,
  deleteQueue: DeleteQueueReducer,
  getQueueById: GetByIdQueueReducer,
  getAllQueue: GetAllQueueReducer,
  pauseQueue: PauseQueueReducer,
  startQueueAgain: StartQueueAgainReducer,
  getQueueItems: GetQueueItemsReducer,
  addCustomersToQueue: AddCustomersToQueueReducer,
  removeCustomersFromQueue: RemoveCustomerFromQueueReducer,
  deleteFile: DeleteFileQueueReducer,
  uploadFile: UploadFileToQueueReducer,
  getHistory: GetQueueHistoryReducer,
})

const adminReducer = combineReducers({
  getAllUsers: GetUsersReducer,
  getUserById: GetUserByIdReducer,
  blockUser: BlockUserReducer,
  unblockUser: UnblockUserReducer,
  uploadCredit: UploadCreditReducer,
  getUserSessions: GetUserSessionsReducer,
  extraPhonePermission: ExtraPhonePermissionReducer,
  getSpentCreditData: GetSpentCreditDataReducer,
  getLogs: GetLogsReducer,
  changeMail: ChangeMailReducer,
  changePhone: ChangePhoneReducer,
  getSettingsDefault: GetSettingsDefaultReducer,
  updatePrivacyText: UpdatePrivacyTextReducer,
  updateRegisterText: UpdateRegisterTextReducer,
  updateCreditAmount: UpdateCreditAmountReducer,
  updatePhoneAmount: UpdatePhoneAmountReducer,
  deletePhoneNumber: DeletePhoneNumberReducer,
  adminUploadCredit: AdminUploadCreditAmountReducer,
  adminAutomationSettings: UpdateAdminAutomationSettingsReducer,
  deleteUser: DeleteUserReducer,
  updateNotification: updateNotificationSettingsReducer,
  getNotificationSettings: getNotificationSettings,
  toggleServiceActivity: toggleServiceActivity
})

const rootReducer = combineReducers({
  auth: authReducer,
  account: accountReducer,
  profile: profileReducer,
  customer: customerReducer,
  grayList: grayListReducer,
  blackList: blackListReducer,
  group: groupReducer,
  queue: queueReducer,
  draft: draftsReducer,
  admin: adminReducer,
});
export const store = configureStore({
  reducer: rootReducer
})
