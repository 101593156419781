import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPrivacyAction, resetGetPrivacy } from '../../services/redux/auth/privacy';
import { isFailure, isLoading, isSuccess } from '../../utilities/reduxStateHelper';
import { useTheme } from '../../services/themeService';
import ModalHeader from './ModalHeader';
import { Spin } from 'antd';

const PrivacyModal = ({ setIsShowPrivacyModal, isChecked, setIsChecked }) => {
    const dispatch = useDispatch();
    const { theme } = useTheme();
    const privacyTextState = useSelector((state) => state.auth.privacy);
    const [privacyText, setPrivacyText] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(getPrivacyAction())
    }, [dispatch])

    useEffect(() => {
        if (isSuccess(privacyTextState)) {
            setPrivacyText(privacyTextState.data.data.privacy)
            setLoading(false)
        }
        if (isLoading(privacyTextState)) {
            setLoading(true)
        }
        if (isFailure(privacyTextState)) {
            setLoading(false)
        }
        return () => {
            dispatch(resetGetPrivacy())
        }
    }, [dispatch, privacyTextState])

    return (
        <div
            className='modal fade show d-block'
            id='kt_kvkk_text'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
        >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-700px'>
                {/* begin::Modal content */}
                <div className='modal-content' style={{ display: 'flex', justifyContent: 'center' }}>
                    <ModalHeader
                        title="KVKK Metni"
                        handleCloseModal={() => setIsShowPrivacyModal(false)}
                    />

                    {/* begin::Modal body */}
                    <div className={"modal-body scroll-y mx-5 mx-xl-15 my-2 mb-5"}>

                        {
                            privacyText && (
                                <div className='w-100' style={{
                                    maxHeight: 500,
                                    overflowY: "auto"
                                }}>
                                    <h1 className='mb-3 text-center'>KVKK AYDINLATMA METNİ</h1>
                                    <span className='fs-5'>
                                        {privacyText}
                                    </span>
                                    <div className='fv-row mb-5 mt-3 d-flex align-items-center'>
                                        <input
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={() => setIsChecked(!isChecked)}
                                            className='w-20px h-20px'
                                        />
                                        <span
                                            className='ms-2 fw-bold'
                                        >
                                            KVKK metnini okudum, onaylıyorum.
                                        </span>
                                    </div>
                                </div>
                            )
                        }
                        {
                            loading && (
                                <div>
                                    <Spin size='large' />
                                </div>
                            )
                        }

                    </div>
                    {/* end::Modal body */}

                </div>
                {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}

        </div>
    )
}

export default PrivacyModal