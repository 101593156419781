import { ConfigProvider, Pagination } from 'antd'
import { KTIcon } from '../../_metronic/helpers'
import { formatPhoneNumber } from '../../utilities/stringHelper'

const SmallCustomerTable = ({ dataSource, deleteCustomer, paginateData, fetchNextData, customTheme, tableType }) => {
    return (
        <div className='card mb-xl-8 border mb-5'>

            {/* begin::Header */}
            <div className='card-header align-items-center border-0 mt-4'>
                <div className={"card-title"}>
                    <h2>Müşteri Listesi</h2>
                </div>
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className={"card-body py-4"}>
                <div className={"dataTables_wrapper dt-bootstrap4 no-footer"}>
                    <div className={"table-responsive"}>
                        <table className={"table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"}>
                            <thead>
                                <tr className={"text-start text-muted fw-bold fs-7 text-uppercase gs-0"}>
                                    <th className={"min-w-125px sorting"} tabIndex="0" rowSpan="1" colSpan="1"
                                        style={{ width: "150.138px" }}>
                                        İsim
                                    </th>
                                    <th className={"min-w-125px sorting"} tabIndex="0" rowSpan="1" colSpan="1"
                                        style={{ width: "152.663px" }}>
                                        Telefon Numarası
                                    </th>
                                    {
                                        tableType === "Edit" && (
                                            <th
                                                className={"text-end min-w-100px sorting_disabled"}
                                                tabIndex="0"
                                                rowSpan="1"
                                                colSpan="1"
                                                style={{ width: "122.575px" }}
                                            >
                                                Aksiyonlar
                                            </th>
                                        )
                                    }
                                </tr>
                            </thead>
                            <tbody className={"text-gray-600 fw-semibold"}>
                                {
                                    dataSource.map((customer) => (
                                        <tr key={customer.id}>
                                            <td className='p-2'>{customer.name}</td>
                                            <td className='p-2'>
                                                <div>{formatPhoneNumber(customer.phone)}</div>
                                            </td>
                                            {
                                                tableType === "Edit" && (
                                                    <td className={"text-end p-2"}>
                                                        <button
                                                            className={"btn btn-icon btn-active-light-primary w-30px h-30px ms-auto"}
                                                            type="button"
                                                            onClick={() => {
                                                                deleteCustomer(customer.id)
                                                            }}
                                                        >
                                                            <KTIcon iconName="trash-square" className={"fs-3"} />
                                                        </button>
                                                    </td>
                                                )
                                            }
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 10,
                        marginBottom: 10
                    }}
                >
                    <ConfigProvider
                        theme={{
                            token: {
                                colorBgContainer: customTheme.backgroundColor,
                                colorText: customTheme.color
                            }
                        }}
                    >
                        <Pagination
                            locale={{ items_per_page: '/ sayfa' }}
                            showSizeChanger
                            onShowSizeChange={(current, pageSize) => {
                                fetchNextData(1, pageSize)
                            }}
                            pageSizeOptions={[10, 50, 100, 250, 500]}     // Per page degerleri
                            defaultCurrent={1}
                            total={paginateData ? paginateData.totalItems : dataSource.length}
                            pageSize={paginateData ? paginateData.perpage : 5}
                            className={customTheme.theme === "light" ? "light-theme" : "dark-theme"}
                            onChange={(page, pageSize) => {
                                fetchNextData(page, pageSize);
                            }}
                        />
                    </ConfigProvider>
                </div>
            </div>
            {/* end::Body */}

        </div>
    )
}

export default SmallCustomerTable