import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";
import { HEADER_TYPES, RequestBuilder, get_cookie } from "../../../utilities";

const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const addCustomersQueueAction = createAsyncThunk('ADD_CUSTOMERS_QUEUE_ACTION', async (
    {
        user,
        queue,
        customers,
        
    }, {rejectWithValue}) => {
        try {
            const endpoint = `${user}/queue/${queue}/add-customer`;
            const body = {customers}
            return await HttpApi.PostAsync(endpoint, true, body);
        } catch (err) {
            return rejectWithValue(err);
        }


})

const addCustomersQueueSlice = createSlice({
    name: 'add-customers-queue',
    initialState,
    reducers: {
        resetAddCustomersQueue: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(addCustomersQueueAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(addCustomersQueueAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(addCustomersQueueAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {addCustomersQueueAction};
export const { resetAddCustomersQueue } = addCustomersQueueSlice.actions;

export default addCustomersQueueSlice.reducer;
