import React from "react";
import {KTIcon} from "../../_metronic/helpers";

const CustomButton = ({onPress, iconName, isDisabled}) => {
    return (
        <button
            onClick={onPress}
            style={{marginRight: 5, backgroundColor: "transparent", border: "none"}}
            type={"button"}
            disabled={isDisabled}
        >
            <KTIcon
                iconName={iconName}
                iconType="duotone"
                className={"bigger-icon"}
            />
        </button>
    )
}

export default CustomButton;