import React, { useEffect, useState } from 'react'
import { fireToast } from '../../../../utilities/toastHelper';
import { useTheme } from '../../../../services/themeService';
import { useDispatch, useSelector } from 'react-redux';
import { automationSettingsAction, resetAutomationSettings } from '../../../../services/redux/profile/automation-settings';
import { isSuccess } from '../../../../utilities/reduxStateHelper';
import { get_local_storage_item } from '../../../../utilities';
import { checkIsActive } from '../../../../_metronic/helpers';

const QuickList = ({ handleQuickData, setGuideControle, nextStep, formData, checkAreaCode, setCheckAreaCode }) => {
  const { theme } = useTheme();
  const dispathcer = useDispatch();
  const automationSelector = useSelector((state) => state.profile.automationSettings);
  const [phoneTextArea, setPhoneArea] = useState("")
  const [nameTextArea, setNameArea] = useState("")
  const [info1TextArea, setInfo1TextArea] = useState("");
  const [info2TextArea, setInfo2TextArea] = useState("");
  const [info3TextArea, setInfo3TextArea] = useState("");
  const [phones, setPhones] = useState([]);
  const [names, setNames] = useState([]);
  const [info1, setInfo1] = useState([]);
  const [info2, setInfo2] = useState([]);
  const [info3, setInfo3] = useState([]);
  const [defaultAreaCode, setDefaultAreaCode] = useState("90");
  const [requested, setRequested] = useState(false);

  useEffect(() => {
    if (!requested) {
      dispathcer(automationSettingsAction({
        userId: get_local_storage_item("user").id,
      }))
      setRequested(true)
    }
  }, [])

  useEffect(() => {
    if (formData && formData.quickData && formData.quickData.length > 0) {
      const phones = formData.quickData.map(data => data.phone || "");
      setPhones(phones)
      const names = formData.quickData.map(data => data.name || "");
      setNames(names)
      const info1 = formData.quickData.map(data => data.info1 || "");
      setInfo1(info1)
      const info2 = formData.quickData.map(data => data.info2 || "");
      setInfo2(info2)
      const info3 = formData.quickData.map(data => data.info3 || "");
      setInfo3(info3)

      const phonesArray = formData.quickData.map((data) => data.phone).join("\n");
      setPhoneArea(phonesArray)

      const namesArray = formData.quickData.map((data) => data.name).join("\n");
      setNameArea(namesArray)

      const info1Array = formData.quickData.map((data) => data.info1).join('\n');
      setInfo1TextArea(info1Array);

      const info2Array = formData.quickData.map((data) => data.info2).join('\n');
      setInfo2TextArea(info2Array);

      const info3Array = formData.quickData.map((data) => data.info3).join('\n');
      setInfo3TextArea(info3Array);
    }
  }, [formData])

  useEffect(() => {
    if (isSuccess(automationSelector)) {
      setDefaultAreaCode(automationSelector.data.data.defaultAreaCode ? String(automationSelector.data.data.defaultAreaCode) : "90")
    }
    return () => {
      dispathcer(resetAutomationSettings())
    }
  }, [automationSelector])

  const handlePaste = (event, fieldName) => {
    event.preventDefault();
    let pastedData = "";
    if (event.type === "paste") {
      pastedData = event.clipboardData.getData('text');
    } else if (event.type === "change") {
      pastedData = event.target.value;
    }

    const dataArray = pastedData.split('\n');

    switch (fieldName) {
      case 'name':
        setNameArea(pastedData)
        setNames(dataArray);
        break;
      case 'phone':
        setPhoneArea(pastedData)
        setPhones(dataArray);
        break;
      case 'info1':
        setInfo1TextArea(pastedData)
        setInfo1(dataArray);
        break;
      case 'info2':
        setInfo2TextArea(pastedData)
        setInfo2(dataArray);
        break;
      case 'info3':
        setInfo3TextArea(pastedData)
        setInfo3(dataArray);
        break;
      default:
        break;
    }
  };

  function formatPhoneNumber(phoneNumber) {
    // Boşlukları ve parantezleri temizle
    const cleanedNumber = phoneNumber.replace(/[()\s+-]/g, '');

    const normalizedNumber = cleanedNumber.startsWith('/') ? cleanedNumber.slice(1) : cleanedNumber;

    if (!checkAreaCode) {

      // Başında "90" varsa veya başında kullanıcının belirlediği alan kodu varsa sadece numarayı döndür
      if (normalizedNumber.startsWith("90")) {
        return normalizedNumber;
      }

      // Başında "00" varsa, "0" ile değiştirerek döndür
      if (normalizedNumber.startsWith('00')) {
        return `90${normalizedNumber.slice(2)}`;
      }

      // 0 ile başlıyorsa Türkiye kodu olan '90' ekleyerek döndür
      if (normalizedNumber.startsWith('0')) {
        return `90${normalizedNumber.substring(1)}`;
      }

      // Diğer durumlarda sadece '90' ekleyerek döndür
      return `90${normalizedNumber}`;
    }

    // Diğer durumlarda direkt numarayı dışarı çıkart.
    return `${normalizedNumber}`;
  }

  // phone number validation
  function isValidPhoneNumber(phoneNumber) {
    // Telefon numarasının minimum ve maksimum uzunluğunu kontrol et
    if (phoneNumber.length >= 19) {
      return false;
    }

    if (phoneNumber.length <= 8) {
      return false;
    }

    // Geçerli bir telefon numarası
    return true;
  }

  const onFinish = () => {
    let array = []
    if (phones.length === 0) {
      fireToast("Telefon numarası alanı boş geçilemez!", "FAIL", theme.theme)
      return;
    } else {
      const length = phones[phones.length - 1] === "" ? phones.length - 1 : phones.length

      for (let i = 0; i < length; i++) {
        if (isValidPhoneNumber(phones[i])) {
          //phones[i] = formatPhoneNumber(phones[i]);
          let data = {
            phone: phones[i],
            name: names[i] === undefined ? "" : names[i],
            info1: info1[i] === undefined ? "" : info1[i],
            info2: info2[i] === undefined ? "" : info2[i],
            info3: info3[i] === undefined ? "" : info3[i]
          }
          array.push(data)

        } else {
          fireToast("Geçersiz bir telefon numarası girişi mevcut, kontrol ediniz!", "FAIL", theme.theme)
          return;
        }
      }
      fireToast("Bilgiler başarılı bir şekilde kayıt edildi.", "SUCCESS", theme.theme)

      handleQuickData(array);

      setGuideControle("quick")

      setPhones([])
      setNames([])
      setInfo1([])
      setInfo2([])
      setInfo3([])
      setPhoneArea("")
      setNameArea("")
      setInfo1TextArea("")
      setInfo2TextArea("")
      setInfo3TextArea("")

      nextStep()
    }
  }

  return (
    <div className='d-flex flex-column w-300px w-sm-500px w-md-auto'
      style={{
        fontFamily: "Inter",
        marginTop: 20
      }}
    >
      <div className='d-flex align-items-center mx-3 my-3 form-check form-check-sm form-check-custom form-check-solid'>
        <input
          className='form-check-input'
          type='checkbox'
          value={checkAreaCode}
          onChange={() => setCheckAreaCode(!checkAreaCode)}
          checked={checkAreaCode}
        />
        <h5 className='mb-0 ms-2 mt-1'>Yabancı numara kullanmak istiyorum.</h5>
      </div>
      <div className='d-flex flex-column flex-md-row g-3'>
        <div className={"mt-3 mx-2"}>
          <h4 className=''>Telefon Numaraları</h4>
          <textarea
            type="number"
            name="phone"
            value={phoneTextArea}
            onChange={(e) => handlePaste(e, 'phone')}
            rows={15}
            required
            className={"form-control form-control-solid w-100 mb-5 mt-2"}
            placeholder={"Telefon Numaraları.."}
          />
        </div>
        <div className={"mt-3 mx-2"}>
          <h4>İsim Soyisim</h4>
          <textarea
            type="text"
            name="name"
            value={nameTextArea}
            onChange={(e) => handlePaste(e, 'name')}
            rows={15}
            className={"form-control form-control-solid w-100 mb-5 mt-2"}
            placeholder={"Müşteri isimleri.."}
          />
        </div>
        <div className={"mt-3 mx-2"}>
          <h4>Bilgi 1</h4>
          <textarea
            type="text"
            name="info1"
            value={info1TextArea}
            onChange={(e) => handlePaste(e, 'info1')}
            rows={15}
            className={"form-control form-control-solid w-100 mb-5 mt-2"}
            placeholder={"Kullanıcı için eklenecek bilgi 1 alani.."}
          />
        </div>
        <div className={"mt-3 mx-2"}>
          <h4>Bilgi 2</h4>
          <textarea
            type="text"
            name="info2"
            value={info2TextArea}
            onChange={(e) => handlePaste(e, 'info2')}
            rows={15}
            className={"form-control form-control-solid w-100 mb-5 mt-2"}
            placeholder={"Kullanıcı için eklenecek bilgi 2 alani.."}
          />
        </div>
        <div className={"mt-3 mx-2"}>
          <h4>Bilgi 3</h4>
          <textarea
            type="text"
            name="info3"
            value={info3TextArea}
            onChange={(e) => handlePaste(e, 'info3')}
            rows={15}
            className={"form-control form-control-solid w-100 mb-5 mt-2"}
            placeholder={"Kullanıcı için eklenecek bilgi 3 alani.."}
          />
        </div>
      </div>
      <div>
        <div className='d-flex justify-content-end'>
          <button
            onClick={onFinish}
            className='btn btn-success fw-bold'
            type="button"
          >
            Bilgileri Kayıt Et
          </button>
        </div>
      </div>
    </div>
  )
}

export default QuickList