import React, { useEffect, useState } from "react";
import { automationSettingsAction, resetAutomationSettings } from "../../../../services/redux/profile/automation-settings";
import { get_local_storage_item } from "../../../../utilities";
import { useDispatch, useSelector } from "react-redux";
import { isFailure, isLoading, isSuccess } from "../../../../utilities/reduxStateHelper";
import { fireToast } from "../../../../utilities/toastHelper";

export const Step4 = ({ prevStep, nextStep, handleTimeSelector, customTheme, formData, updateFormData }) => {
    const [user, setUser] = useState(get_local_storage_item("user"));
    const dispatch = useDispatch();
    const automationSelector = useSelector((state) => state.profile.automationSettings);

    const [defaultStartHour, setDefaultStartHour] = useState();
    const [defaultStartMinute, setDefaultStartMinute] = useState();
    const [defaultEndHour, setDefaultEndHour] = useState();
    const [defaultEndMinute, setDefaultEndMinute] = useState();
    const [startHour, setStartHour] = useState(formatTime(formData.startHour));
    const [startMinute, setStartMinute] = useState(formatTime(formData.startMinute));

    function formatTime(number) {
        return number < 10 ? '0' + number : '' + number;
    }

    const handleTimeContoller = (hour, minute) => {
        const selectedTime = new Date(0, 0, 0, hour, minute);

        const defaultStartTime = new Date(0, 0, 0, parseInt(defaultStartHour), parseInt(defaultStartMinute));
        const defaultEndTime = new Date(0, 0, 0, parseInt(defaultEndHour), parseInt(defaultEndMinute));

        if (selectedTime >= defaultStartTime && selectedTime <= defaultEndTime) {
            fireToast("İşleminiz başarılı.", "SUCCESS", customTheme.theme);
            handleTimeSelector(formData.startHour, formData.startMinute);
            nextStep();
        } else {
            fireToast("Seçtiğiniz başlama zamanı belirlenen aralıkta değil.", "FAIL", customTheme.theme);
        }
    }

    const handleOneMinuteLater = () => {
        const now = new Date();
        let newMinute = now.getMinutes() + 5;
        let newHour = now.getHours();
        
        if (newMinute >= 60) {
            newMinute -= 60;
            newHour += 1;
        }
        
        if (newHour >= 24) {
            newHour = 0;
        }
        
        const formattedHour = `0${newHour}`.slice(-2);
        const formattedMinute = `0${newMinute}`.slice(-2);
        
        setStartHour(formattedHour);
        setStartMinute(formattedMinute);
    }

    useEffect(() => {
        dispatch(automationSettingsAction({ userId: user.id }));
    }, [dispatch, user.id])

    useEffect(() => {
        if (isSuccess(automationSelector)) {
            setDefaultStartHour(formatTime(automationSelector.data.data.start_Hour));
            setDefaultStartMinute(formatTime(automationSelector.data.data.start_Minute));
            setDefaultEndHour(formatTime(automationSelector.data.data.end_Hour));
            setDefaultEndMinute(formatTime(automationSelector.data.data.end_Minute));
        }
        if (isFailure(automationSelector)) {
        }
        if (isLoading(automationSelector)) {
        }
        return () => {
            dispatch(resetAutomationSettings());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [automationSelector])

    return (
        <div className="d-flex flex-column border rounded p-5 w-auto card w-500px h-auto rounded-2">
            <h2 className={"mb-1 mt-3"}>Mesaj Gönderimine Başlama Zamanı: </h2>
            <span className="fs-7">Sizin Belirlediğiniz Mesaj Gönderim Aralığı: {`${defaultStartHour}:${defaultStartMinute} - ${defaultEndHour}:${defaultEndMinute}`}</span>
            <div>
                <div className={"d-flex align-items-center justify-content-evenly px-3 py-2 w-100 col-lg-8 mt-5"}>

                    {/* start::StartTimePicker */}
                    <div className={"d-flex align-items-center"}>
                        <select
                            value={startHour}
                            onChange={(event) => {
                                setStartHour(event.target.value)
                            }}
                            className={"p-3 border rounded me-3"}
                        >
                            {[...Array(24).keys()].map((h) => (
                                <option key={h} value={h < 10 ? `0${h}` : `${h}`}>
                                    {h < 10 ? `0${h}` : `${h}`}
                                </option>
                            ))}
                        </select>
                        <h6>
                            :
                        </h6>
                        <select
                            value={startMinute}
                            onChange={(event) => {
                                setStartMinute(event.target.value)
                            }}
                            className={"p-3 border rounded ms-3"}
                        >
                            {[...Array(60).keys()].map((m) => (
                                <option key={m} value={m < 10 ? `0${m}` : `${m}`}>
                                    {m < 10 ? `0${m}` : `${m}`}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/* end::StartTimePicker */}
                    
                    <div>
                        <button
                            type="button"
                            onClick={handleOneMinuteLater}
                            className="btn btn-primary"
                        >
                            Anlik +5
                        </button>
                    </div>
                </div>
            </div>
            <div className={"d-flex justify-content-between mt-5"}>
                <button
                    style={{
                        border: '1px solid',
                        padding: "8px 15px",
                        borderRadius: 10,
                        borderColor: '#0095E8',
                        backgroundColor: 'white',
                        color: '#0095E8'
                    }}
                    onClick={() => {
                        prevStep()
                    }}
                >
                    Önceki
                </button>
                <button
                    className={"btn btn-primary"}
                    type="button"
                    onClick={() => {
                        handleTimeContoller(startHour, startMinute)
                        updateFormData({
                            startHour: parseInt(startHour),
                            startMinute: parseInt(startMinute)
                        })                        
                    }}
                >
                    Sonraki
                </button>
            </div>

        </div>
    );
}