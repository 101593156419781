import { ResponsiveBar } from "@nivo/bar";
import { useEffect, useState } from "react";

const myDarkTheme = {
    background: "#1E1E2D",
    textColor: "red",
    axis: {
        domain: {
            line: {
                stroke: "#777",
                strokeWidth: 1,
            },
        },
        ticks: {
            line: {
                stroke: "#777",
                strokeWidth: 1,
            },
            text: {
                fill: "#ffffff",
                fontSize: 12,
            },
        },
        legend: {
            text: {
                fontSize: 16,
                fill: "#ffffff",

            },
        },
    },
    grid: {
        line: {
            stroke: "gray",
            strokeWidth: 0.3,
        },
    },
};

const CreditCharts = ({ spentData, customTheme }) => {
    const [spentCreditEditData, setSpentCreditEditData] = useState([]);

    useEffect(() => {
        const transformedData = transformData(spentData);
        setSpentCreditEditData(transformedData)
    }, [spentData])

   function transformData(spentData) {
    const months = [
        "Ocak",
        "Şubat",
        "Mart",
        "Nisan",
        "Mayıs",
        "Haziran",
        "Temmuz",
        "Ağustos",
        "Eylül",
        "Ekim",
        "Kasım",
        "Aralık"
    ];

    // Veriyi ay bazında kolay erişilebilir hale getirmek için bir sözlük oluşturun
    const monthDataMap = spentData.reduce((map, item) => {
        map[item.month] = item;
        return map;
    }, {});

    // Her ay için ilgili veriyi al veya varsayılan bir değer döndür
    const transformedData = months.map((month, index) => {
        const monthIndex = index + 1; // Ay indeksini 1'den başlatmak için
        const data = monthDataMap[monthIndex];
        console.log(data)
        const percentage = data ? data.monthlyTotalAmount.toFixed(1) : "0.0"; // Belirtilen hassasiyet
        return {
            id: month,
            totalAmount: percentage.toString(),
        };
    });

    return transformedData;
}
    
    return (
        <div className='tab-content'>
            <div className='card card-flush mb-6 mb-xl-9'>
                <div className='card-header mt-6'>
                    <div className='card-title'>
                        <h2 className='mb-1'>Kredi Tabloları</h2>
                    </div>
                </div>
                <div className='card p-9 pt-4' style={{ width: "100%", height: "400px", color: "black" }}>
                <ResponsiveBar
                    data={spentCreditEditData}
                    keys={["totalAmount"]}
                    indexBy="id"
                    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                    padding={0.3}
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    colors={{ scheme: 'category10' }}
                    borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Katagoriler',
                        legendPosition: 'middle',
                        legendOffset: 32
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Kullanım Adedi',
                        legendPosition: 'middle',
                        legendOffset: -40
                    }}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    theme={customTheme.theme === "dark" ? myDarkTheme : null}

                />
                </div>
            </div>

        </div>
    )
}

export default CreditCharts