import { useDispatch, useSelector } from 'react-redux';
import { isFailure, isLoading, isSuccess } from '../../../utilities/reduxStateHelper'
import {AutomationDetails} from './AutomationDetails'
import { useEffect, useState } from 'react';
import { userDetailsAction } from '../../../services/redux/profile/user-details';
import ExtraPhoneNumbers from '../settings/ExtraPhoneNumbers';
import { useTheme } from '../../../services/themeService';

export function Automation() {
    const { theme } = useTheme();
    const dispatch = useDispatch();
    const profileDetailsState = useSelector((state) => state.profile.profileDetails);
    const [profileDetailsData, setProfileDetailsData] = useState({});

    useEffect(() => {
        dispatch(userDetailsAction())
    }, [dispatch])

    useEffect(() => {
        if (isSuccess(profileDetailsState)) {
            setProfileDetailsData(profileDetailsState.data.data)
        }
        if (isLoading(profileDetailsState)) {

        }
        if (isFailure(profileDetailsState)) {

        }
    }, [profileDetailsState, profileDetailsData, dispatch])

    return (
        <>
            <ExtraPhoneNumbers phonesData={profileDetailsData && profileDetailsData.extraPhoneData} theme={theme} />
            <AutomationDetails />
        </>
    )
}