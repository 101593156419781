import React, {useState} from "react";
import ModalHeader from "./ModalHeader";

const ConfirmationModal = ({type, handleCloseModal, onSubmit}) => {
    const [password, setPassword] = useState("");

    return (
        <div
            className='modal fade show d-block'
            id='kt_modal_add_draft'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
        >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-650px'>
                {/* begin::Modal content */}
                <div className='modal-content' style={{display: 'flex', justifyContent: 'center'}}>
                    <ModalHeader
                        title={type === "delete" ? "Hesabı Sil ve Şifreyi Onayla" : "Hesabı Devre Dışı Bırak ve Şifreyi Onayla"}
                        handleCloseModal={handleCloseModal}
                    />

                    {/* begin::Modal body */}
                    <div
                        className={"card"}
                        style={{padding: 30}}
                    >
                        <div style={{marginBottom: 30}}>
                            <h4>Şifreniz</h4>
                            <span>Hesabınızın tamamen devre dışı bırakılmasını tamamlamak için şifrenizi doğru girmelisiniz!</span>
                        </div>
                        <input
                            className={"form-control form-control-solid w-100 mb-5"}
                            placeholder="Şifrenizi girin.."
                            value={password}
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className='card-footer d-flex justify-content-end'>
                            <button
                                id='kt_account_delete_account_submit'
                                type='submit'
                                className='btn btn-danger fw-bold'
                                disabled={password === "" ? true : false}
                                onClick={() => {
                                    handleCloseModal();
                                    onSubmit(password)
                                }}
                            >
                                {
                                    type === "delete" ? "Hesabı Sil" : "Hesabı dondur"
                                }
                            </button>
                        </div>
                    </div>

                    {/* end::Modal body */}

                </div>
                {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}
        </div>
    )
}

export default ConfirmationModal;