import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const getAllQueueAction = createAsyncThunk('GET_ALL_QUEUE_ACTION', async (
    {
        userId,
        page,
        perPage,
        state // active | completed
    }, {rejectWithValue}) => {
    try {
        const endpoint = !(page && perPage && state) ? `${userId}/queue/list` : `${userId}/queue/list?page=${page}&perpage=${perPage}&state=${state}`;
        return await HttpApi.GetAsync(endpoint, true);
    } catch (err) {
        return rejectWithValue(err);
    }


})

const getAllQueueSlice = createSlice({
    name: 'all-queue-queue',
    initialState,
    reducers: {
        resetAllQueue: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllQueueAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(getAllQueueAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(getAllQueueAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {getAllQueueAction};
export const { resetAllQueue } = getAllQueueSlice.actions;

export default getAllQueueSlice.reducer;
