import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const sendHelpMailAction = createAsyncThunk('SEND_HELP_MAIL_ACTION', async (
    {
        userId,
        email,
        text,
        subject,
    }, { rejectWithValue }) => {
    try {
        const endpoint = `${userId}/help/request/`;
        const body  = {email: email, text: text, subject: subject};
        return await HttpApi.PostAsync(endpoint, true, body);
    }
    catch (err) {
        return rejectWithValue(err);
    }
})


const sendHelpMailSlice = createSlice({
    name: 'send-help-mail',
    initialState,
    reducers: {
        resetHelpMail:(state) => {return initialState}
    },
    extraReducers: (builder) => {
        builder.addCase(sendHelpMailAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(sendHelpMailAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(sendHelpMailAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
})

export { sendHelpMailAction };
export const { resetHelpMail } = sendHelpMailSlice.actions;
export default sendHelpMailSlice.reducer;
