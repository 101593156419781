import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";

const initialState = {
    isLoading: false,
    isFailure: false,
    isSuccess: false,
    data: {},
    error: {}
}

const updateAdminAutomationSettingsAction = createAsyncThunk("UPDATE_ADMIN_AUTOMATION_SETTINGS_ACTION", async (
    {
        userId,
        startHour,
        startMinute,
        endHour,
        endMinute,
        min_message_delay,
        max_message_delay,
        useSpamCode,
        defaultAreaCode
    }, { rejectWithValue }) => {
    try {
        let endpoint = `admin/user/update-auto-settings`;
        const response = await HttpApi.PostAsync(endpoint, true, {
            user: userId,
            startHour,
            startMinute,
            endHour,
            endMinute,
            min_message_delay,
            max_message_delay,
            useSpamCode,
            defaultAreaCode
        });
        return response;
    } catch (error) {
        return rejectWithValue(error)
    }
})

const updateAdminAutomationSettingsSlice = createSlice({
    name: 'update-admin-automation-settings',
    initialState,
    reducers: {
        resetUpdateAdminAutomationSettings: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(updateAdminAutomationSettingsAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
        .addCase(updateAdminAutomationSettingsAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
        })
        .addCase(updateAdminAutomationSettingsAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
        })
    }
});

export {updateAdminAutomationSettingsAction};
export const {resetUpdateAdminAutomationSettings} = updateAdminAutomationSettingsSlice.actions;
export default updateAdminAutomationSettingsSlice.reducer;