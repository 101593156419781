import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const removeFromGroupAction = createAsyncThunk("REMOVE_FROM_GROUP_ACTION", async(
    {
        userId,
        groupId,
        customerId,
    }, {rejectWithValue}) =>
{
    try {
        let endpoint = `${userId}/customers/groups/remove/${groupId}`;
        const body = {customerId}
        return await HttpApi.PostAsync(endpoint, true, body);
    }
    catch(err)
    {
        return rejectWithValue(err);
    }
});

const removeFromGroupSlice = createSlice({
    name: "remove-from-group",
    initialState,
    reducers: {
        resetRemoveFromGroup: (state) => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(removeFromGroupAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        }).addCase(removeFromGroupAction.fulfilled, (state, action) => {
            state.isFailure = false;
            state.isLoading = false;
            state.isSuccess = true;
            state.data = action.payload;
        }).addCase(removeFromGroupAction.rejected, (state, action) => {
            state.isFailure = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.error = action.payload;
        })
    }
})
export {removeFromGroupAction};
export const { resetRemoveFromGroup } = removeFromGroupSlice.actions;
export default removeFromGroupSlice.reducer;