import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";

const initialState = {
    isLoading: false,
    isFailure: false,
    isSuccess: false,
    data: {},
    error: {}
}

const blockUserAction = createAsyncThunk("BLOCK_USER_ACTION", async (
    {
        userId
    }, { rejectWithValue }) => {
    try {
        let endpoint = `admin/users/${userId}/block-user`;
        const response = await HttpApi.PostAsync(endpoint, true, {});
        return response;
    } catch (error) {
        return rejectWithValue(error)
    }
})

const blockUserSlice = createSlice({
    name: "admin-block-user",
    initialState,
    reducers: {
        resetAdminBlockUser: (state) => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder.addCase(blockUserAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        }).addCase(blockUserAction.fulfilled, (state, action) => {
            state.isFailure = false;
            state.isLoading = false;
            state.isSuccess = true;
            state.data = action.payload;
        }).addCase(blockUserAction.rejected, (state, action) => {
            state.isFailure = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.data = action.payload;
        })
    }
})

export {blockUserAction};
export const {resetAdminBlockUser} = blockUserSlice.actions;
export default blockUserSlice.reducer;