/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { KTIcon, toAbsoluteUrl } from "../../_metronic/helpers";
import { useDispatch, useSelector } from "react-redux";
import { ConfigProvider, Pagination, Progress, Spin } from "antd";
import {
  getAllQueueAction,
  resetAllQueue,
} from "../../services/redux/queues/get-all-queue";
import { get_local_storage_item } from "../../utilities";
import {
  isSuccess,
  isFailure,
  isLoading,
} from "../../utilities/reduxStateHelper";
import {
  deleteQueueAction,
  resetDeleteQueue,
} from "../../services/redux/queues/delete-queue";
import { useTheme } from "../../services/themeService";
import "../Apps/queue-management/index.css";
import QueueInfoModal from "../Modals/QueueInfoModal";
import "../Apps/draft-management/index.css";
import QueueEditModal from "../Modals/QueueEditModal";
import { fireToast } from "../../utilities/toastHelper";
import {
  getStatusClassBadge,
  getStatusColorFormat,
  getStatusText,
} from "../../utilities/stringHelper";

const QueueWidget = ({ className, state, id }) => {
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const [queues, setQueues] = useState([]);
  const [paginateData, setPaginateData] = useState({ totalItems: 0, perpage: 10, page: 1 });
  const getAllQueueState = useSelector((state) => state.queue.getAllQueue);
  const deleteQueueState = useSelector((state) => state.queue.deleteQueue);
  const [requested, setRequested] = useState(false);
  const [defaultPerPage, setDefaultPerPage] = useState(10);
  const [selectedQueue, setSelectedQueue] = useState();
  const [isShowQueueInfoModal, setIsShowQueueInfoModal] = useState(false);
  const [isShowQueueEditModal, setIsShowQueueEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isQueueStartedAgain, setIsQueueStartedAgain] = useState(false);
  const [hoveredActionMap, setHoveredActionMap] = useState({});

  const handleHover = (queueId, action) => {
    setHoveredActionMap((prevMap) => ({ ...prevMap, [queueId]: action }));
  };

  const fetchNextData = (page, perPage) => {
    dispatch(
      getAllQueueAction({
        userId: id ? id : get_local_storage_item("user").id,
        page: page,
        perPage: perPage ? perPage : defaultPerPage,
        state: state,
      })
    );
  };

  function formatDate(startDate) {
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    const formattedDate = new Date(startDate).toLocaleDateString(
      "en-US",
      options
    );
    return formattedDate;
  }

  const deleteQueue = (item) => {
    dispatch(
      deleteQueueAction({
        userId: id ? id : get_local_storage_item("user").id,
        queueId: item._id,
      })
    );
    fetchNextData(paginateData.page, paginateData.perpage);
    dispatch(resetDeleteQueue());
  };

  useEffect(() => {
    const fetchQueues = () => {
      const userId = id ? id : get_local_storage_item("user").id;
      dispatch(
        getAllQueueAction({
          userId: userId,
          page: paginateData.page,
          perPage: paginateData.perpage,
          state: state,
        })
      );
    };
    fetchQueues();
    const intervalId = setInterval(() => {
      if (state === "active" || state === "all") fetchQueues();
    }, 5000);

    return () => {
      clearInterval(intervalId);
      dispatch(resetAllQueue());
    };
  }, [dispatch, id, state, paginateData.page, paginateData.perpage]);

  useEffect(() => {
    if (isSuccess(getAllQueueState)) {
      setQueues(getAllQueueState.data.data);
      setPaginateData((prevData) => ({
        ...prevData,
        totalItems: getAllQueueState.data.meta.totalItems,
      }));
      setLoading(false);
    }
    if (isFailure(getAllQueueState)) {
      setLoading(false);
    }
    if (isLoading(getAllQueueState)) {
      setLoading(true);
    }
    return () => {
      dispatch(resetAllQueue());
    };
  }, [getAllQueueState, dispatch]);

  useEffect(() => {
    if (isSuccess(deleteQueueState)) {
      fireToast(deleteQueueState.data.message, "SUCCESS", theme.theme);
      fetchNextData(paginateData.page, paginateData.perpage);
    }
    if (isFailure(deleteQueueState)) {
      fireToast(deleteQueueState.error.message, "FAIL", theme.theme);
    }
    if (isLoading(deleteQueueState)) {
    }
    return () => {
      dispatch(resetDeleteQueue());
    };
  }, [deleteQueueState, dispatch, paginateData.page, paginateData.perpage, theme.theme]);

  return (
    <div className="card card-xl-stretch mb-xl-8">
      {/* begin::Header */}
      <div className="card-header d-flex flex-column flex-sm-row align-items-center border-0 mt-4">
        <h3 className="card-title align-items-start flex-column">
          <span className="fw-bold text-dark">Mesaj Listesi</span>
          <span className="text-muted mt-1 fw-semibold fs-7">
            Kuyruk durumu sağlayan Kuyruk Listesi
          </span>
        </h3>
        <div
          className={`${"d-flex my-2 my-sm-5 ps-0 px-sm-5"}`}
          style={state === "active" ? {} : { visibility: "hidden" }}
        >
          <a href="/queue/add" className="btn btn-primary p-2 p-md-3">
            <KTIcon iconName="plus" className="fs-2" />
            Mesaj Sırası ekle
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* Burasi active olanlarin kismi */}
      {state === "active" && (
        <div className="card-body pt-3">
          {loading && (
            <div className={"d-flex justify-content-center align-items-center"}>
              <Spin />
            </div>
          )}
          <div
            style={{
              maxHeight: 400,
              overflowY: "auto",
            }}
          >
            {queues &&
              queues.map((item) => (
                <div
                  className="d-flex align-items-sm-center mb-7"
                  key={item._id}
                >
                  <div className="d-none d-sm-block symbol symbol-60px symbol-2 me-4">
                    {item.percentage !== undefined && item.percentage >= 0 ? (
                      <div style={{ color: getStatusClassBadge(item.status) }}>
                        <Progress
                          type="circle"
                          percent={parseInt(item.percentage)}
                          width={50}
                          strokeWidth={10}
                          strokeColor={getStatusClassBadge("COMPLETED")}
                          trailColor={"white"}
                          status={getStatusColorFormat("COMPLETED")}
                          format={() => (
                            <span
                              style={{
                                color: getStatusColorFormat("COMPLETED"),
                              }}
                            >
                              %{parseInt(item.percentage)}
                            </span>
                          )}
                        />
                      </div>
                    ) : (
                      <>
                        <div
                          className="symbol-label"
                          style={{
                            backgroundImage: `url(${toAbsoluteUrl(
                              "/media/stock/600x400/img-20.jpg"
                            )})`,
                          }}
                        ></div>
                      </>
                    )}
                  </div>

                  <div className="d-flex flex-column flex-sm-row flex-row-fluid align-items-sm-center">
                    <div className="flex-grow-1 me-2">
                      <span className="text-gray-800 fw-bold text-hover-primary fs-6">
                        {item.queueTitle}
                      </span>
                    </div>

                    {item.startDate ? (
                      <>
                        <span className="text-gray-800 fw-bold fs-6">
                          Başlangıç Zamanı: {formatDate(item.startDate)}
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    <span
                      className={`h-25 mx-3 badge ${getStatusClassBadge(
                        item.status
                      )} fs-5 fw-bold`}
                    >
                      {getStatusText(item.status)}
                    </span>
                  </div>
                  <div style={{ position: "relative" }}>
                    <button
                      onClick={() => {
                        setSelectedQueue(item);
                        setIsShowQueueInfoModal(true);
                      }}
                      onMouseOver={() => handleHover(item._id, "Detay")}
                      onMouseOut={() => handleHover(item._id, null)}
                      style={{
                        marginRight: 5,
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <KTIcon
                        iconName={"information-2"}
                        iconType="duotone"
                        className={"bigger-icon"}
                      />
                    </button>
                    <button
                      onClick={() => {
                        setSelectedQueue(item);
                        setIsShowQueueEditModal(true);
                      }}
                      onMouseOver={() => handleHover(item._id, "Düzenle")}
                      onMouseOut={() => handleHover(item._id, null)}
                      disabled={item.status === "IN_PROGRESS"}
                      style={{
                        marginRight: 5,
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <KTIcon
                        iconName={"message-edit"}
                        iconType="duotone"
                        className={"bigger-icon"}
                      />
                    </button>
                    <button
                      onClick={() => {
                        deleteQueue(item);
                      }}
                      onMouseOver={() => handleHover(item._id, "Sil")}
                      onMouseOut={() => handleHover(item._id, null)}
                      disabled={item.status === "IN_PROGRESS"}
                      style={{
                        marginRight: 5,
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <KTIcon
                        iconName={"delete-files"}
                        iconType="duotone"
                        className={"bigger-icon"}
                      />
                    </button>

                    {hoveredActionMap[item._id] && (
                      <div
                        className={`${
                          theme.theme === "light"
                            ? "info-text"
                            : "info-text-dark"
                          }`}
                      >
                        {`${hoveredActionMap[item._id]}`}
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>

          {queues.length === 0 && (
            <div
              className={
                "d-flex flex-column p-10 w-100 justify-content-center align-items-center border rounded mb-5"
              }
            >
              <KTIcon iconName="cross-circle" className={"bigger-icon mb-4"} />
              <h3 className={"mb-0 "}>
                Oluşturulan Kuyruk Mevcut Değil. Yeni bir tane oluşturun!
              </h3>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 10,
            }}
          >
            <ConfigProvider
              theme={{
                token: {
                  colorBgContainer: theme.backgroundColor,
                  colorText: theme.color,
                },
              }}
            >
              <Pagination
                locale={{ items_per_page: "/ sayfa" }}
                showSizeChanger
                onShowSizeChange={(current, pageSize) => {
                  setPaginateData((prevData) => ({ ...prevData, page: 1, perpage: pageSize }));
                  fetchNextData(1, pageSize);
                }}
                pageSizeOptions={[10, 50, 100, 250, 500]}
                current={paginateData.page}
                total={paginateData.totalItems}
                pageSize={paginateData.perpage}
                className={
                  theme.theme === "light" ? "light-theme" : "dark-theme"
                }
                onChange={(page, pageSize) => {
                  setPaginateData((prevData) => ({ ...prevData, page: page, perpage: pageSize }));
                  fetchNextData(page, pageSize);
                }}
              />
            </ConfigProvider>
          </div>
        </div>
      )}

      {/* Burasi compoleted olanlarin kismi */}
      {state === "completed" && (
        <div className="card-body pt-3">
          {loading && (
            <div className={"d-flex justify-content-center align-items-center"}>
              <Spin />
            </div>
          )}
          <div
            style={{
              maxHeight: 400,
              overflowY: "auto",
            }}
          >
            {queues &&
              queues.map((item) => (
                <div
                  className="d-flex align-items-sm-center mb-7"
                  key={item._id}
                >
                  <div className="d-none d-sm-block symbol symbol-60px symbol-2 me-4">
                    {item.percentage !== undefined && item.percentage >= 0 ? (
                      <div style={{ color: getStatusClassBadge(item.status) }}>
                        <Progress
                          type="circle"
                          percent={parseInt(item.percentage)}
                          width={50}
                          strokeWidth={10}
                          strokeColor={getStatusClassBadge("COMPLETED")}
                          trailColor={"white"}
                          status={getStatusColorFormat("COMPLETED")}
                          format={() => (
                            <span
                              style={{
                                color: getStatusColorFormat("COMPLETED"),
                              }}
                            >
                              %{parseInt(item.percentage)}
                            </span>
                          )}
                        />
                      </div>
                    ) : (
                      <>
                        <div
                          className="symbol-label"
                          style={{
                            backgroundImage: `url(${toAbsoluteUrl(
                              "/media/stock/600x400/img-20.jpg"
                            )})`,
                          }}
                        ></div>
                      </>
                    )}
                  </div>
                  <div className="d-flex flex-column flex-sm-row flex-row-fluid align-items-sm-center">
                    <div className="flex-grow-1 me-2">
                      <a
                        href="#"
                        className="text-gray-800 fw-bold text-hover-primary fs-6"
                      >
                        {item.queueTitle}
                      </a>
                    </div>
                    {item.startDate ? (
                      <>
                        <a
                          href="#"
                          className="text-gray-800 fw-bold text-hover-primary fs-6"
                        >
                          Başlangıç Zamanı: {formatDate(item.startDate)}
                        </a>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    <span
                      className={` mx-5 badge ${getStatusClassBadge(
                        item.status
                      )} fs-5 fw-bold`}
                    >
                      {getStatusText(item.status)}
                    </span>
                  </div>
                  <div>
                    <button
                      onClick={() => {
                        setSelectedQueue(item);
                        setIsShowQueueInfoModal(true);
                      }}
                      onMouseOver={() => handleHover(item._id, "Detay")}
                      onMouseOut={() => handleHover(item._id, null)}
                      style={{
                        marginRight: 5,
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <KTIcon
                        iconName={"information-2"}
                        iconType="duotone"
                        className={"bigger-icon"}
                      />
                    </button>
                    <button
                      onClick={() => {
                        deleteQueue(item);
                      }}
                      onMouseOver={() => handleHover(item._id, "Sil")}
                      onMouseOut={() => handleHover(item._id, null)}
                      style={{
                        marginRight: 5,
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <KTIcon
                        iconName={"delete-files"}
                        iconType="duotone"
                        className={"bigger-icon"}
                      />
                    </button>

                    {hoveredActionMap[item._id] && (
                      <div
                        className={`${
                          theme.theme === "light"
                            ? "info-text"
                            : "info-text-dark"
                        }`}
                      >
                        {`${hoveredActionMap[item._id]}`}
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>
          {queues.length === 0 && (
            <div
              className={
                "d-flex flex-column p-10 w-100 justify-content-center align-items-center border rounded mb-5"
              }
            >
              <KTIcon iconName="cross-circle" className={"bigger-icon mb-4"} />
              <h3 className={"mb-0 "}>
                Oluşturulan Kuyruk Mevcut Değil. Yeni bir tane oluşturun!
              </h3>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 10,
              }}
            >
              <ConfigProvider
                theme={{
                  token: {
                    colorBgContainer: theme.backgroundColor,
                    colorText: theme.color,
                  },
                }}
              >
                <Pagination
                  locale={{ items_per_page: "/ sayfa" }}
                  showSizeChanger
                  onShowSizeChange={(current, pageSize) => {
                    fetchNextData(1, pageSize);
                  }}
                  pageSizeOptions={[10, 50, 100, 250,500 ]}
                  defaultCurrent={1}
                  total={paginateData.totalItems}
                  pageSize={paginateData.perpage}
                  className={
                    theme.theme === "light" ? "light-theme" : "dark-theme"
                  }
                  onChange={(page, pageSize) => {
                    fetchNextData(page, pageSize);
                  }}
                />
              </ConfigProvider>
            </div>
          </div>
        </div>
      )}

      {/* Burasi admin panelinden grunen kisim  */}
      {state === "all" && (
        <div className={"card-body pt-3"}>
          {loading && (
            <div className={"d-flex justify-content-center align-items-center"}>
              <Spin />
            </div>
          )}
          <div
            style={{
              maxHeight: 400,
              overflowY: "auto",
            }}
          >
            {queues &&
              queues.map((item) => (
                <div
                  className="d-flex align-items-sm-center mb-7"
                  key={item._id}
                >
                  <div className="d-none d-sm-block symbol symbol-60px symbol-2 me-4">
                    {item.percentage !== undefined && item.percentage >= 0 ? (
                      <div style={{ color: getStatusClassBadge(item.status) }}>
                        <Progress
                          type="circle"
                          percent={parseInt(item.percentage)}
                          width={50}
                          strokeWidth={10}
                          strokeColor={getStatusClassBadge("COMPLETED")}
                          trailColor={"white"}
                          status={getStatusColorFormat("COMPLETED")}
                          format={() => (
                            <span
                              style={{
                                color: getStatusColorFormat("COMPLETED"),
                              }}
                            >
                              %{parseInt(item.percentage)}
                            </span>
                          )}
                        />
                      </div>
                    ) : (
                      <>
                        <div
                          className="symbol-label"
                          style={{
                            backgroundImage: `url(${toAbsoluteUrl(
                              "/media/stock/600x400/img-20.jpg"
                            )})`,
                          }}
                        ></div>
                      </>
                    )}
                  </div>
                  <div className="d-flex flex-row-fluid flex-wrap align-items-center">
                    <div className="flex-grow-1 me-2">
                      <a
                        href="#"
                        className="text-gray-800 fw-bold text-hover-primary fs-6"
                      >
                        {item.queueTitle}
                      </a>
                    </div>
                    {item.startDate ? (
                      <>
                        <a
                          href="#"
                          className="text-gray-800 fw-bold text-hover-primary fs-6"
                        >
                          Başlangıç Zamanı: {formatDate(item.startDate)}
                        </a>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <span
                    className={`mx-5 badge ${getStatusClassBadge(
                      item.status
                    )} fs-5 fw-bold`}
                  >
                    {getStatusText(item.status)}
                  </span>
                  <div>
                    <button
                      onClick={() => {
                        setSelectedQueue(item);
                        setIsShowQueueInfoModal(true);
                      }}
                      onMouseOver={() => handleHover(item._id, "Detay")}
                      onMouseOut={() => handleHover(item._id, null)}
                      style={{
                        marginRight: 5,
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    >
                      <KTIcon
                        iconName={"information-2"}
                        iconType="duotone"
                        className={"bigger-icon"}
                      />
                    </button>

                    {hoveredActionMap[item._id] && (
                      <div
                        className={`${
                          theme.theme === "light"
                            ? "info-text"
                            : "info-text-dark"
                        }`}
                      >
                        {`${hoveredActionMap[item._id]}`}
                      </div>
                    )}
                  </div>
                </div>
              ))}
          </div>

          {queues.length === 0 && (
            <div
              className={
                "d-flex flex-column p-10 w-100 justify-content-center align-items-center border rounded mb-5"
              }
            >
              <KTIcon iconName="cross-circle" className={"bigger-icon mb-4"} />
              <h3 className={"mb-0 "}>
                Oluşturulan Kuyruk Mevcut Değil. Yeni bir tane oluşturun!
              </h3>
            </div>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 10,
            }}
          >
            <ConfigProvider
              theme={{
                token: {
                  colorBgContainer: theme.backgroundColor,
                  colorText: theme.color,
                },
              }}
            >
              <Pagination
                locale={{ items_per_page: "/ sayfa" }}
                showSizeChanger
                onShowSizeChange={(current, pageSize) => {
                  fetchNextData(1, pageSize);
                }}
                pageSizeOptions={[10, 50, 100, 250, 500]}
                defaultCurrent={1}
                total={paginateData.totalItems}
                pageSize={paginateData.perpage}
                className={
                  theme.theme === "light" ? "light-theme" : "dark-theme"
                }
                onChange={(page, pageSize) => {
                  fetchNextData(page, pageSize);
                }}
              />
            </ConfigProvider>
          </div>
        </div>
      )}
      {isShowQueueInfoModal && (
        <QueueInfoModal
          id={id}
          setIsShowQueueInfoModal={setIsShowQueueInfoModal}
          selectedQueue={selectedQueue}
          setIsQueueStartedAgain={setIsQueueStartedAgain}
          customTheme={theme}
          state={state}
        />
      )}
      {isShowQueueInfoModal && <div className="modal-backdrop fade show"></div>}

      {isShowQueueEditModal && (
        <QueueEditModal
          setIsShowQueueEditModal={setIsShowQueueEditModal}
          selectedQueue={selectedQueue}
          customTheme={theme}
        />
      )}
      {isShowQueueEditModal && <div className="modal-backdrop fade show"></div>}
    </div>
  );
};

export { QueueWidget };
