import { REQUEST_TYPES, HEADER_TYPES } from "./requestTypes";


const baseBanner = `
██╗░░░██╗███╗░░██╗██╗████████╗███████╗██████╗░  ███╗░░░███╗███╗░░░███╗  ██████╗░███████╗██╗░░░██╗░██████╗
██║░░░██║████╗░██║██║╚══██╔══╝██╔════╝██╔══██╗  ████╗░████║████╗░████║  ██╔══██╗██╔════╝██║░░░██║██╔════╝
██║░░░██║██╔██╗██║██║░░░██║░░░█████╗░░██║░░██║  ██╔████╔██║██╔████╔██║  ██║░░██║█████╗░░╚██╗░██╔╝╚█████╗░
██║░░░██║██║╚████║██║░░░██║░░░██╔══╝░░██║░░██║  ██║╚██╔╝██║██║╚██╔╝██║  ██║░░██║██╔══╝░░░╚████╔╝░░╚═══██╗
╚██████╔╝██║░╚███║██║░░░██║░░░███████╗██████╔╝  ██║░╚═╝░██║██║░╚═╝░██║  ██████╔╝███████╗░░╚██╔╝░░██████╔╝
░╚═════╝░╚═╝░░╚══╝╚═╝░░░╚═╝░░░╚══════╝╚═════╝░  ╚═╝░░░░░╚═╝╚═╝░░░░░╚═╝  ╚═════╝░╚══════╝░░░╚═╝░░░╚═════╝░`;
export { REQUEST_TYPES, HEADER_TYPES, baseBanner };
