import React, { useState } from 'react';
import { KTIcon } from "../../_metronic/helpers";
import UserDetailEditModal from "../Modals/UserDetailEditModal";
import { formatPhoneNumber } from '../../utilities/stringHelper';
import { fireToast } from '../../utilities/toastHelper';
import { useTheme } from '../../services/themeService';

const Profile = ({ selectedData, updateExtraPhonePermission, extraPhoneData, adminChangeMail, adminChangePhone, deletePhoneNumber }) => {
    const { theme } = useTheme();
    const [isShowUserDetailEditModal, setIsShowDetailEditModal] = useState(false);
    const [selectedInputType, setSelectedInputType] = useState("");
    const [extraPhoneNumber, setExtraPhoneNumber] = useState(0)

    const handleSubmitEmail = (email) => {
        if (email === "") {
            return alert("Email degisikliligi icin inputu doldurunuz!");
        }

        if (isEmailValid(email)) {
            adminChangeMail(email);
        } else {
            fireToast("Geçerli bir email giriniz!", "FAIL", theme.theme)
        }
    };

    const isEmailValid = (inputEmail) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(inputEmail);
    };

    const handleSubmitPhoneNumber = (phoneNumber) => {
        if (phoneNumber === "") {
            return alert("Telefon degisikliligi icin inputu doldurunuz!");
        }

        if (isValidPhoneNumber(phoneNumber)) {
            phoneNumber = customFormatPhoneNumber(phoneNumber);
        } else {
            alert("Geçersiz bir telefon numarası girdiniz! Tekrar deneyiniz!")
            return;
        }

        adminChangePhone(phoneNumber)
    };

    const handleChangeExtraPhoneNumbers = (phoneAmount) => {
        setExtraPhoneNumber(phoneAmount)
        updateExtraPhonePermission(phoneAmount)
    }

    function customFormatPhoneNumber(phoneNumber) {
        // Boşlukları ve parantezleri temizle
        //90 durum kontrolü kaldırıldı
        const cleanedNumber = phoneNumber.replace(/[()\s+-]/g, '');

        return `${cleanedNumber}`;
    }

    // phone number validation
    function isValidPhoneNumber(phoneNumber) {
        // Telefon numarasının minimum ve maksimum uzunluğunu kontrol et
        if (phoneNumber.length >= 19) {
            return false;
        }

        if (phoneNumber.length <= 8) {
            return false;
        }

        // Geçerli bir telefon numarası
        return true;
    }

    return (
        <div className={"tab-content"}>
            <div className={"card pt-4 mb-6 mb-xl-9"}>
                <div className={"card-header border-0"}>
                    <div className={"card-title"}>
                        <h2>Profil</h2>
                    </div>
                </div>
                <div className={"card-body pb-5 pt-0"}>
                    <div>
                        <div className='d-flex align-items-center justify-content-between w-100 my-3 px-3 py-2'>
                            <div>
                                <h4 className='me-5 text-muted fw-bold mb-0'>Email</h4>
                            </div>

                            <div className={"d-flex align-items-center"}>
                                <span className='mb-0 text-muted fw-bold me-3'>{selectedData.email}</span>
                                <button
                                    className={"btn btn-icon btn-active-light-primary w-30px h-30px ms-auto"}
                                    type="button"
                                    onClick={() => {
                                        setSelectedInputType("email")
                                        setIsShowDetailEditModal(true)
                                    }}
                                >
                                    <KTIcon iconName="pencil" className={"fs-3"} />
                                </button>
                            </div>
                        </div>
                        <div className='separator'></div>
                        <div className='d-flex align-items-center justify-content-between w-100 my-3 px-3 py-2'>
                            <div>
                                <h4 className='me-5 text-muted fw-bold mb-0'>Telefon Numarası</h4>
                            </div>

                            <div className={"d-flex align-items-center"}>
                                <span className='mb-0 text-muted fw-bold me-3'>{formatPhoneNumber(selectedData.phone)}</span>
                                <button
                                    className={"btn btn-icon btn-active-light-primary w-30px h-30px ms-auto"}
                                    type="button"
                                    onClick={() => {
                                        setSelectedInputType("phone")
                                        setIsShowDetailEditModal(true)
                                    }}
                                >
                                    <KTIcon iconName="pencil" className={"fs-3"} />
                                </button>
                            </div>
                        </div>
                        <div className='separator'></div>
                        <div className='d-flex align-items-center justify-content-between w-100 my-3 px-3 py-2'>
                            <div className=''>
                                <h4 className='me-5 text-muted fw-bold mb-0'>Ekstra Telefon Numaraları</h4>
                            </div>

                            <div className={"d-flex align-items-center"}>
                                <span className='mb-0 me-3 text-muted fw-bold'>{extraPhoneData.usagePhoneCount}/{extraPhoneData.totalPhoneCount}</span>
                                <button
                                    className={"btn btn-icon btn-active-light-primary w-30px h-30px ms-auto"}
                                    type="button"
                                    onClick={() => {
                                        setSelectedInputType("extraPhoneNumbers")
                                        setIsShowDetailEditModal(true)
                                    }}
                                >
                                    <KTIcon iconName="pencil" className={"fs-3"} />
                                </button>
                            </div>
                        </div>
                        {
                            extraPhoneData.phones && extraPhoneData.phones.map((phoneData, index) => (
                                <div key={phoneData._id} className='px-2'>
                                    <div className=''>
                                        <h4 className='me-5 text-muted fw-bold mb-0'>Fazladan Numara {index + 1}</h4>
                                    </div>
                                    <div className={"d-flex align-items-center"}>
                                        <span className='mb-0 me-3 text-muted fw-bold'>{formatPhoneNumber(phoneData.phone)}</span>
                                        <button
                                            onClick={() => {
                                                deletePhoneNumber(phoneData)
                                            }}
                                            className='btn btn-icon btn-active-light-primary w-30px h-30px ms-auto'
                                        >
                                            <KTIcon iconName='trash-square' className={"fs-3"} />
                                        </button>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

            {/* begin::userDetailEditModal */}
            {
                isShowUserDetailEditModal && (
                    <UserDetailEditModal
                        setIsShowDetailEditModal={setIsShowDetailEditModal}
                        selectedData={selectedData}
                        selectedInputType={selectedInputType}
                        handleSubmitEmail={handleSubmitEmail}
                        handleSubmitPhoneNumber={handleSubmitPhoneNumber}
                        handleChangeExtraPhoneNumbers={handleChangeExtraPhoneNumbers}
                    />
                )
            }
            {isShowUserDetailEditModal && <div className='modal-backdrop fade show'></div>}
            {/* end::userDetailEditModal */}

        </div>
    )
}

export default Profile;