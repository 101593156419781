import React, { useCallback, useEffect, useState } from "react";
import "./index.css";
import { ConfigProvider, Divider, Pagination, Spin, Modal } from "antd";
import { KTIcon } from "../../../_metronic/helpers";
import GroupInfoModal from "../../Modals/GroupInfoModal";
import { useTheme } from "../../../services/themeService";
import { useDispatch, useSelector } from "react-redux";
import { get_local_storage_item } from "../../../utilities";
import {
  createGroupAction,
  resetCreateGroup,
} from "../../../services/redux/customers/groups/create-group";
import {
  isFailure,
  isLoading,
  isSuccess,
} from "../../../utilities/reduxStateHelper";
import { fireToast } from "../../../utilities/toastHelper";

import {
  deleteGroupAction,
  resetDeleteGroup,
} from "../../../services/redux/customers/groups/delete-group";
import { getGroupsAction } from "../../../services/redux/customers/groups/get-groups";

const UserGroup = ({ visibleItem = false, setSelectedGroupId, setSelectedGroupControle }) => {
  const dispatch = useDispatch();
  const getAllState = useSelector((state) => state.group.getAllGroups);
  const createGroupState = useSelector((state) => state.group.createGroup);
  const deleteState = useSelector((state) => state.group.deleteGroup);

  const { theme } = useTheme();
  const [selectedGroup, setSelectedGroup] = useState("");
  const [userId] = useState(get_local_storage_item("user").id);
  const [groupName, setGroupName] = useState("");
  const [groups, setGroups] = useState([]);
  const [isShowGroupInfoModal, setIsShowGroupInfoModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [requested, setRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [defaultPerPage, setDefaultPerPage] = useState(10);
  const [paginateData, setPaginateData] = useState({});

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);
  const [step, setStep] = useState(1);

  const addNewGroupName = (e) => {
    e.preventDefault();
    if (groupName === "") {
      alert("Grup adı alanını boş bıraktınız, tekrar deneyin");
      return;
    }
    let body = {
      userId: userId,
      groupName: groupName,
    };
    dispatch(createGroupAction(body));
    setGroupName("");
  };

  const confirmDeleteGroup = (group) => {
    setGroupToDelete(group);
    setIsDeleteModalVisible(true);
    setStep(1);
  };

  const deleteGroup = (deleteMembers) => {
    let body = {
      userId: userId,
      groupId: groupToDelete._id,
      deleteUsers: deleteMembers,
    };
    dispatch(deleteGroupAction(body));
    setIsDeleteModalVisible(false);
  };


  // const deleteGroup = (item) => {
  //   let body = {
  //     userId: userId,
  //     groupId: item._id,
  //   };
  //   dispatch(deleteGroupAction(body));
  // };


  const fetchNextData = useCallback((page, perPage) => {
    dispatch(getGroupsAction({ userId: userId, page: page, perPage: perPage ? perPage : defaultPerPage }))
  })

  useEffect(() => {
    if (!requested) {
      dispatch(getGroupsAction({ userId: userId, page: 1, perPage: defaultPerPage }));
      setRequested(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, dispatch]);

  useEffect(() => {
    if (isSuccess(getAllState)) {
      setGroups(getAllState.data.data);
      setPaginateData(getAllState.data.meta)
      setLoading(false);
    }
    if (isFailure(getAllState)) {
    }
    if (isLoading(getAllState)) {
      setLoading(true);
    }
  }, [getAllState, dispatch]);

  useEffect(() => {
    if (isSuccess(createGroupState)) {
      fireToast(createGroupState.data.message, "SUCCESS");
      dispatch(getGroupsAction({ userId: userId, page: 1, perPage: defaultPerPage }));
    }
    if (isFailure(createGroupState)) {
      fireToast(createGroupState.error.message, "FAIL");
    }
    if (isLoading(createGroupState)) {
    }
    return () => {
      dispatch(resetCreateGroup());
    };
  }, [createGroupState, dispatch]);

  useEffect(() => {
    if (isSuccess(deleteState)) {
      fireToast(deleteState.data.message, "SUCCESS");
      dispatch(getGroupsAction({ userId: userId, page: 1, perPage: defaultPerPage }));
    }
    if (isFailure(deleteState)) {
      fireToast(deleteState.error.message, "FAIL");
    }
    if (isLoading(deleteState)) {
    }
    return () => {
      dispatch(resetDeleteGroup());
    };
  }, [deleteState, dispatch]);

  const openGroupInfoModal = (item) => {
    setModalData(item);
    setIsShowGroupInfoModal(true);
  };

  const closeGroupInfoModal = () => {
    setModalData(null);
    setIsShowGroupInfoModal(false);
  };

  return (
    <>
      <div className={`card rounded ${visibleItem && "w-300px w-sm-400px w-md-600px"} `} style={{ padding: "50px 20px" }}>
        <h2 className={"mb-3"}>Gruplar</h2>
        <div>
          {
            loading && (
              <div className={"d-flex justify-content-center align-items-center"}>
                <Spin />
              </div>
            )
          }
          <div
            style={{
              maxHeight: 300,
              overflowY: "auto"
            }}
          >
            {
              groups && groups.map((item) => (
                <div key={item._id} className={"groups-container"}>
                  <span
                    onClick={() => {
                      if (!visibleItem) {
                        openGroupInfoModal(item);
                      } else {
                        setSelectedGroup(item);
                        setSelectedGroupId(item._id);
                        setSelectedGroupControle(item._id);
                      }
                    }}
                    className={
                      !visibleItem
                        ? ("group-link-item")
                        : (
                          selectedGroup._id === item._id
                            ? "group-link-active-item"
                            : "group-link-item"
                        )
                    }
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {item.groupName}
                  </span>
                  <div className={"d-flex align-items-center"}>
                    {
                      !visibleItem && (
                        <div
                          className={"d-flex align-items-center ms-2"}
                          onClick={() => confirmDeleteGroup(item)}
                        >
                          <KTIcon
                            iconName="trash-square"
                            className={"bigger-icon"}
                          />
                        </div>
                      )
                    }
                  </div>
                </div>
              ))
            }
          </div>

          {
            groups.length === 0 && (
              <div className={"d-flex flex-column p-10 w-100 justify-content-center align-items-center border rounded"}>
                <KTIcon iconName="cross-circle" className={"bigger-icon mb-4"} />
                <h3 className={"mb-0 user-select-none fs-5"}>Organize Grup Mevcut Değildir!</h3>
              </div>
            )
          }
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 20,
          }}
        >
          <ConfigProvider
            theme={{
              token: {
                colorBgContainer: theme.backgroundColor,
                colorText: theme.color
              }
            }}
          >
            <Pagination
              locale={{ items_per_page: '/ sayfa' }}
              showSizeChanger
              onShowSizeChange={(current, pageSize) => {
                fetchNextData(1, pageSize)
              }}
              pageSizeOptions={[10, 50, 100, 250, 500]}
              defaultCurrent={1}
              total={paginateData ? paginateData.totalItems : 1}
              pageSize={paginateData ? paginateData.perpage : defaultPerPage}
              className={theme.theme === "light" ? "light-theme" : "dark-theme"}
              onChange={(page, pageSize) => {
                fetchNextData(page, pageSize);
            }}
            />
          </ConfigProvider>
        </div>
        {(isShowGroupInfoModal && modalData) && (
          <>
            <GroupInfoModal
              setIsShowGroupInfoModal={closeGroupInfoModal}
              selectedGroup={modalData}
              customTheme={theme}
            />
            <div className="modal-backdrop fade show"></div>
          </>
        )}

        {
          !visibleItem && (
            <Divider />
          )
        }

        {
          !visibleItem && (
            <div>
              <h4 className={"mb-2"}>Yeni grup ekle</h4>
              <div className={"d-flex align-items-center mt-3"}>
                <input
                  placeholder="Grup adı"
                  className={"form-control form-control-solid"}
                  value={groupName}
                  onChange={(e) => {
                    e.preventDefault();
                    setGroupName(e.target.value);
                  }}
                />
                <span
                  className={"btn btn-icon btn-primary"}
                  onClick={addNewGroupName}
                >
                  <KTIcon iconName="plus" iconType="duotone" />
                </span>
              </div>
            </div>
          )
        }
      </div>
      <ConfigProvider
      theme={{
        token: {
            colorBgContainer: theme.backgroundColor,
            colorText: theme.color
        }
    }}
      >

      <Modal
        title="Grup Silme Onayı"
        visible={isDeleteModalVisible}
        onCancel={() => setIsDeleteModalVisible(false)}
        className={theme.theme === "light" ? "light-theme" : "dark-theme"}
        footer={null}
        width={600}
      >
        {step === 1 && (
          <div style={{ textAlign: 'center' }}>
            <p>Grubun içerisindeki kişileri de silmek ister misiniz?</p>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
              <button type="button" class="btn btn-danger" onClick={() => setStep(2)}>
                Grubu ve Kişileri Sil
              </button>
              <button type="button" class="btn btn-primary" onClick={() => deleteGroup(false)}>
                Yalnızca Grubu Sil
              </button>
            </div>
          </div>
        )}
        {step === 2 && (
          <div style={{ textAlign: 'center' }}>
            <p>Grup ve içerisindeki kişiler kalıcı olarak silinecek. Onaylıyor musunuz?</p>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
              <button type="button" class="btn btn-secondary" onClick={() => setIsDeleteModalVisible(false)}>
                İptal
              </button>
              <button type="button" class="btn btn-danger" danger onClick={() => deleteGroup(true)}>
                Onaylıyorum
              </button>
            </div>
          </div>
        )}
      </Modal>
      </ConfigProvider>
    

    </>
  );
};

export default UserGroup;
