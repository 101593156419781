import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const getCustomerAction = createAsyncThunk('GET_CUSTOMER_ACTION', async (
    {
        userId,
        customerId,
    }, {rejectWithValue}) => {
    try {
        let endpoint = `${userId}/customers/${customerId}`;
        return await HttpApi.GetAsync(endpoint, true);
    } catch (err) {
        return rejectWithValue(err);
    }
})

const getCustomerSlice = createSlice({
    name: 'get-customer',
    initialState,
    reducers: {
        resetGetCustomer: (state) => {return initialState}
    },
    extraReducers: (builder) => {
        builder.addCase(getCustomerAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(getCustomerAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(getCustomerAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {getCustomerAction};
export const {resetGetCustomer} = getCustomerSlice.actions;
export default getCustomerSlice.reducer;
