import React from "react";
import {KTIcon} from "../../../_metronic/helpers";

const ModalHeader = ({title, handleCloseModal}) => {
    return (
        <div className='modal-header'>
            {/* begin::Modal title */}
            <h2 className='fw-bolder'>{title}</h2>
            {/* end::Modal title */}

            {/* begin::Close */}
            <div
                className='btn btn-icon btn-sm btn-active-icon-primary cursor-pointer'
                data-kt-users-modal-action='close'
                onClick={handleCloseModal}
            >
                <KTIcon iconName='cross' className='fs-1'/>
            </div>
            {/* end::Close */}
        </div>
    )
}

export default ModalHeader;