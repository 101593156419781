/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { KTIcon, toAbsoluteUrl } from "../../_metronic/helpers";

const translateRole = (role) => {
    switch (role) {
        case 'User':
            return 'Kullanıcı';
        case 'Admin':
            return 'Yönetici';
        default:
            return role;
    }
};

const AccountHeader = ({ avatar, firstName, lastName, role, current_credit, total_spent, average_monthly_spend }) => {
    const location = useLocation()

    return (
        <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
                <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                    <div className='me-7 mb-4'>
                        <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                            <img src={avatar === 'blank' ? toAbsoluteUrl('/media/avatars/blank.png') : avatar} alt='' />
                            <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                        </div>
                    </div>

                    <div className='flex-grow-1'>
    <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
        <div className='d-flex flex-column'>
            <div className='d-flex align-items-center mb-2'>
                <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {firstName && lastName ? (
                        <>
                            {firstName} {lastName}
                        </>
                    ) : (
                        <></>
                    )}
                </a>
            </div>

            <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                <span className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                    <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                    {translateRole(role)}
                </span>
            </div>
        </div>
    </div>

                        <div className='d-flex flex-wrap flex-stack'>
                            <div className='d-flex flex-column flex-grow-1 pe-8'>
                                <div className='d-flex flex-wrap'>
                                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                                            <div className='fs-2 fw-bolder'>{total_spent}</div>
                                        </div>

                                        <div className='fw-bold fs-6 text-gray-400'>Harcanan Krediler</div>
                                    </div>

                                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <KTIcon iconName='arrow-down' className='fs-3 text-danger me-2' />
                                            <div className='fs-2 fw-bolder'>{current_credit}</div>
                                        </div>

                                        <div className='fw-bold fs-6 text-gray-400'>Kalan Krediler</div>
                                    </div>

                                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                                        <div className='d-flex align-items-center'>
                                            <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                                            <div className='fs-2 fw-bolder'>{average_monthly_spend}%</div>
                                        </div>

                                        <div className='fw-bold fs-6 text-gray-400'>Ortalama Aylık Harcama</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='d-flex overflow-auto h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/settings/overview' && 'active')
                                }
                                to='/settings/overview'
                            >
                                Genel Bakış
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/settings/settings' && 'active')
                                }
                                to='/settings/settings'
                            >
                                Ayarlar
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/settings/automation' && 'active')
                                }
                                to='/settings/automation'
                            >
                                Otomasyon
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/settings/add-credit' && 'active')
                                }
                                to='/settings/add-credit'
                            >
                                Kredi Ekleme
                            </Link>
                        </li>
                        {role === "Admin" && (
                            <li className='nav-item'>
                                <Link
                                    className={
                                        `nav-link text-active-primary me-6 ` +
                                        (location.pathname === '/settings/configuration' && 'active')
                                    }
                                    to='/settings/configuration'
                                >
                                    Konfigürasyon
                                </Link>
                            </li>
                            
                        )}
                        {role === "Admin" && (
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/settings/notifications' && 'active')
                                }
                                to='/settings/notifications'
                            >
                                Bildirim Ayarları
                            </Link>
                        </li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export { AccountHeader }
