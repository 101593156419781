import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const multiAddToBlackListAction = createAsyncThunk("MULTI_ADD_TO_BLACKLIST_ACTION", async (
    {
        userId,
        customers,
    }, { rejectWithValue }) => {
    try {
        let endpoint = `${userId}/blacklist/customers/add/multiple`;
        return await HttpApi.PostAsync(endpoint, true, {customers});
    }
    catch (err) {
        return rejectWithValue(err);
    }
});

const multiAddToBlackListSlice = createSlice({
    name: "multi-add-to-blacklist",
    initialState,
    reducers: {
        resetMultiAddBlackListCustomer: (state) => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder.addCase(multiAddToBlackListAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(multiAddToBlackListAction.fulfilled, (state, action) => {
                state.isFailure = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.data = action.payload;
            })
            .addCase(multiAddToBlackListAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.data = action.payload;
            })
    }
})
export { multiAddToBlackListAction };
export const { resetMultiAddBlackListCustomer } = multiAddToBlackListSlice.actions;
export default multiAddToBlackListSlice.reducer;