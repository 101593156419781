import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetGetCustomers } from "../../../../services/redux/customers/get-customers";
import { get_local_storage_item } from "../../../../utilities";
import { isFailure, isLoading, isSuccess } from "../../../../utilities/reduxStateHelper";
import { getDraftsAction } from "../../../../services/redux/drafts/get-all-draft";
import { getDraftByIdAction, resetDraftGetByID } from "../../../../services/redux/drafts/get-draft-by-id";
import { ConfigProvider, Pagination, Spin } from "antd";
import "../index.css";
import RichTextEditor from "../../../Editor/RichTextEditor";
import { KTIcon } from "../../../../_metronic/helpers";

const Step1 = ({ handleContent, customTheme, nextStep, prevStep, guideControle, saveButtonShow = false, prevButtonShow = false, formData, defaultTitle, defaultMessage }) => {
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState(defaultTitle ? defaultTitle : formData.title);
    const [message, setMessage] = useState(defaultMessage ? defaultMessage : formData.message);
    const [drafts, setDrafts] = useState([]);
    const [selectedDraftId, setSelectedDraftId] = useState(null);
    const [requested, setRequested] = useState(false);
    const [paginateData, setPaginateData] = useState({});
    const [defaultPerPage, setDefaultPerPage] = useState(10);
    const dispathcer = useDispatch()
    const getDraftsState = useSelector((state) => state.draft.getAllDraft);
    const getDraftByIDState = useSelector((state) => state.draft.getByIdDraft);

    const fetchNextData = (page, perPage) => {
        dispathcer(getDraftsAction({ userId: get_local_storage_item("user").id, page: page, perPage: perPage ? perPage : defaultPerPage }))
    }

    useEffect(() => {
        if (!requested) {
            dispathcer(getDraftsAction({
                userId: get_local_storage_item("user").id,
                page: 1,
                perPage: defaultPerPage
            }))
            setRequested(true)
        }
    }, [dispathcer, defaultPerPage, paginateData, requested])

    useEffect(() => {
        if (isSuccess(getDraftsState)) {
            setDrafts(getDraftsState.data.data);
            setPaginateData(getDraftsState.data.meta)
            setLoading(false);
        }
        if (isFailure(getDraftsState)) {
            setLoading(false);
        }
        if (isLoading(getDraftsState)) {
            setLoading(true);
        }
        return () => {
            dispathcer(resetGetCustomers());
        }
    }, [drafts, dispathcer, getDraftsState, fetchNextData, defaultPerPage])

    const getDraftByID = (id) => {
        dispathcer(getDraftByIdAction({ userId: get_local_storage_item("user").id, draftId: id.id }))
    };

    useEffect(() => {
        if (isSuccess(getDraftByIDState)) {
            setMessage(getDraftByIDState.data.data.text);
            dispathcer(resetDraftGetByID());
        }
        if (isFailure(getDraftByIDState)) {

        }
        if (isLoading(getDraftByIDState)) {
        }
        return () => {
            dispathcer(resetDraftGetByID());
        }
    }, [drafts, dispathcer, getDraftByIDState]);

    const handleNextStep = () => {
        // Eğer title boşsa, içinde bulunduğumuz tarihi ve saati ata

        // İçeriği işle ve bir sonraki adıma git
        handleContent(title, message);
        nextStep();
    };

    return (
        <div
            className="d-flex flex-column border rounded p-5 w-auto card w-700px h-auto rounded-2"
        >

            {/* Title Start */}
            <>
                <h4 className={"mb-3 mt-3"}>Mesaj Listesi Başlığı</h4>
                <p className="text-dark-emphasis">Bu alanı boş bırakırsanız sistem kendisi dolduracaktır.</p>
                <textarea
                    id="title-box"
                    className={"draft-message-box mb-5 p-3 rounded"}
                    placeholder="Sıra Başlığı"
                    value={title}
                    rows={2}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </>
            {/* Title End */}

            {/* Drafts Start */}
            <div className={"border p-5 rounded"}>
                <h4 className={"mb-3 mt-3"}>Mesaj Taslakları</h4>
                <div>
                    <ul className={"list-group-item"}>
                        {
                            loading && (
                                <div className={"d-flex justify-content-center align-items-center"}>
                                    <Spin />
                                </div>
                            )
                        }
                        {
                            drafts && drafts.map((draft) => (
                                <li key={draft.id} className={"d-flex bg-light mb-3 ps-5 pt-3 pb-3 rounded"}>
                                    <input
                                        type="radio"
                                        name="selectedDraft"
                                        checked={selectedDraftId === draft.id}
                                        onChange={() => {
                                            setSelectedDraftId(draft.id);
                                            getDraftByID({ id: draft.id });
                                        }}
                                    />
                                    <span className={"ms-3 fs-5"}>{draft.title}</span>
                                </li>
                            ))
                        }
                        {
                            drafts.length === 0 && (
                                <div
                                    className={"d-flex flex-column p-10 w-100 justify-content-center align-items-center border rounded"}
                                >
                                    <KTIcon iconName="cross-circle" className={"bigger-icon mb-4"} />
                                    <h3 className={"mb-0 user-select-none"}>Oluşturulan Mesaj Taslağı Bulunmamaktadır!</h3>
                                </div>
                            )
                        }
                    </ul>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 10,
                    }}
                >
                    <ConfigProvider
                        theme={{
                            token: {
                                colorBgContainer: customTheme.backgroundColor,
                                colorText: customTheme.color
                            }
                        }}
                    >
                        <Pagination
                            locale={{ items_per_page: '/ sayfa' }}
                            showSizeChanger
                            onShowSizeChange={(current, pageSize) => {
                                fetchNextData(1, pageSize)
                            }}
                            pageSizeOptions={[10, 50, 100, 250, 500]}
                            defaultCurrent={1}
                            total={paginateData.totalItems}
                            pageSize={paginateData.perpage}
                            className={customTheme.theme === "light" ? "light-theme" : "dark-theme"}
                            onChange={(page, pageSize) => {
                                fetchNextData(page, pageSize);
                            }}
                        />
                    </ConfigProvider>
                </div>
            </div>
            {/* Drafts End */}

            {/* Draft Content Start */}
            <RichTextEditor message={message} setMessage={setMessage} customTheme={customTheme} guideControle={guideControle} />
            {/* Draft Content End */}


            <div className={`d-flex ${!prevButtonShow ? "justify-content-between" : "justify-content-end"}`}>
                {
                    !prevButtonShow && (
                        <button
                            style={{
                                border: '1px solid',
                                padding: "8px 15px",
                                borderRadius: 10,
                                borderColor: '#0095E8',
                                backgroundColor: 'white',
                                color: '#0095E8'
                            }}
                            onClick={() => {
                                prevStep();
                            }}                        >
                            Önceki
                        </button>
                    )
                }
                {
                    !saveButtonShow ? (
                        <button
                            className={"btn btn-primary"}
                            onClick={handleNextStep}
                        // onClick={() => {
                        //     handleContent(title, message);  
                        //     nextStep();
                        // }}
                        >
                            Sonraki
                        </button>
                    ) : (
                        <button
                            className={"btn btn-primary"}
                            onClick={() => {
                                handleContent(title, message);
                            }}
                        >
                            Kaydet
                        </button>
                    )
                }
            </div>

        </div>
    );
};

export { Step1 };
