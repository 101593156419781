import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";


const initialState = {
  isLoading: false,
  isSuccess: false,
  isFailure: false,
  data: {},
  error: {}
};

const verifyEmailAction = createAsyncThunk('VERIFY_EMAIL_ACTION', async ({
  userId,
  hash_token,

}, { rejectWithValue }) => {
  try {
    const encoded = encodeURIComponent(hash_token);
    const endpoint = `auth/${userId}/verify-email?hash=${encoded}`;
    return await HttpApi.GetAsync(endpoint, false);
  }
  catch (err) {
    return rejectWithValue(err);
  }


})

const verifyEmailSlice = createSlice({
  name: 'verify-email',
  initialState,
  reducers: {
    resetVerifyEmailState: (state) => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(verifyEmailAction.pending, (state) => {
      state.isFailure = false;
      state.isLoading = true;
      state.isSuccess = false;
      state.data = {};
    })
      .addCase(verifyEmailAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isFailure = false;
        state.data = action.payload;
      })
      .addCase(verifyEmailAction.rejected, (state, action) => {
        state.isFailure = true;
        state.isSuccess = false;
        state.isLoading = false;
        state.error = action.payload;
      })
  }
});

export const {resetVerifyEmailState} = verifyEmailSlice.actions;
export { verifyEmailAction };
export default verifyEmailSlice.reducer;
