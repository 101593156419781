import React from "react";
import ModalHeader from "./ModalHeader";
import { KTIcon } from "../../../../_metronic/helpers";

const CustomerAddedGroupModal = ({setIsAddGroupModal, addToGrayListCustomer, addToBlackListCustomer, customerId}) => {
    
    return (
        <div
            className='modal fade show d-block'
            id='kt_modal_add_draft'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
        >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-650px'>
                {/* begin::Modal content */}
                <div className='modal-content' style={{display: 'flex', justifyContent: 'center'}}>
                    <ModalHeader
                        title={"Müşteri Eklenen Grup"}
                        handleCloseModal={() => setIsAddGroupModal(false)}
                    />

                    {/* begin::notice */}
                    <div className={`notice d-flex bg-light-primary border-primary border border-dashed rounded m-5 mx-10 p-6`}>
                            <KTIcon iconName="information" className={`fs-2tx me-4 text-primary`}  />
                            <div className={"d-flex flex-stack flex-grow-1"}>
                                <div className={"fw-semibold"}>
                                    <div className={"fs-6 text-gray-700 "}>
                                    Bu işlemlere devam ederseniz, kullanıcıyı gri listeye veya kara listeye eklersiniz!
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end::notice */}


                    {/* begin::Modal body */}
                    <div className={" d-flex justify-content-between p-10 "}>
                        <div className=' d-flex justify-content-end'>
                            <button
                                className='btn btn-primary fw-bold'
                                type="button"
                                onClick={() => {
                                    addToGrayListCustomer(customerId);
                                    setIsAddGroupModal(false)
                                }}
                            >
                                Gri Listeye Ekle
                            </button>
                        </div>
                        <div className=' d-flex justify-content-end'>
                            <button
                                className='btn btn-primary fw-bold'
                                type="button"
                                onClick={() => {
                                    addToBlackListCustomer(customerId);
                                    setIsAddGroupModal(false);
                                }}
                            >
                                Kara Listeye Ekle
                            </button>
                        </div>
                    </div>
                    {/* end::Modal body */}

                </div>
                {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}
        </div>
    )
}

export default CustomerAddedGroupModal;