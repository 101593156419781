import { HEADER_TYPES } from "./constants";
import { get_env_data, is_production } from "./envHelper";
export class RequestBuilder {
  static BuildHeader = (headerType, token) => {
    let header = {}
    // eslint-disable-next-line default-case
    switch (headerType) {
      case HEADER_TYPES.BASIC:
        {
          header = {
            "Content-Type": "application/json",
            "X-API-GATEWAY": process.env.REACT_APP_API_KEY
          }
          break;
        }
      case HEADER_TYPES.WITH_AUTH:
        {
          header = {
            "Content-Type": "application/json",
            "X-API-GATEWAY": process.env.REACT_APP_API_KEY,
            "X-TOKEN": `Bearer ${token}`
          }
          break;
        }
        case HEADER_TYPES.FORM_AUTH: 
        {
          header = {
            "Content-Type": "multipart/form-data",
            "X-API-GATEWAY": process.env.REACT_APP_API_KEY,
            "X-TOKEN": `Bearer ${token}`
          }
        }

    }
    return header;
  }
  
  static BuildEndpoint = (endpoint) => {
    //let base_endpoint = is_production() ? process.env.REACT_APP_BASE_URL : 'http://localhost:5000/api/v1';
    
    let base_endpoint = process.env.REACT_APP_ENVIRONMENT === "PRODUCTION" ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_DEV
    let result_endpoint = `${base_endpoint}/${endpoint}`;
    return result_endpoint;
  }
}
export const qrCodeSocketPath = process.env.REACT_APP_ENVIRONMENT === "PRODUCTION" ? `https://online.prowhatsapp.com/api/v1/socket/qr-code` : "http://localhost:5001/api/v1/socket/qr-code";
export const checkSessionPath = process.env.REACT_APP_ENVIRONMENT === "PRODUCTION" ? `https://online.prowhatsapp.com/api/v1/socket/check-wp-session` : "http://localhost:5001/api/v1/socket/check-wp-session";
export const onlineUsersAdminPath = process.env.REACT_APP_ENVIRONMENT === "PRODUCTION" ? `https://online.prowhatsapp.com/api/v1/socket/online-users` : "http://localhost:5001/api/v1/socket/online-users";
export const onlineUsersUserPath = process.env.REACT_APP_ENVIRONMENT === "PRODUCTION" ? `https://online.prowhatsapp.com/v1/socket/user-activity` : "http://localhost:5001/api/v1/socket/user-activity";
