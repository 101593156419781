import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {toAbsoluteUrl} from "../../_metronic/helpers";
import { verifyEmailAction, resetVerifyEmailState } from '../../services/redux/auth/verify-email'
import { isFailure, isSuccess, isLoading } from '../../utilities/reduxStateHelper'
import {useDispatch, useSelector} from "react-redux";
const VerifyEmail = () => {
    const { id, token } = useParams();
    const [requestMessage, setRequestMessage] = useState("");
    const [requested, setRequested] = useState(false);
    const dispatcher = useDispatch();
    const selector = useSelector((state) => state.auth.verifyEmail);
    
    useEffect(() => {
        if (!requested) {
            dispatcher(verifyEmailAction({userId: id, hash_token: token}));
            setRequested(true);
        }
    }, [dispatcher, id, requested, token]);
    useEffect(() => {
        if(isLoading(selector))
        {

        }
        if (isSuccess(selector))
        {
            setRequestMessage(selector.data.message);
        }
        if (isFailure(selector))
        {
            setRequestMessage(selector.error.message);
        }
    }, [dispatcher, selector]);
    return (
        <>
            {/* begin::Title */}
            <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Email Doğrulama</h1>
            {/* end::Title */}

            {/* begin::Text */}
            <div className='fw-semibold fs-6 text-gray-500 mb-7'>{requestMessage}</div>
            {/* end::Text */}

            {/* begin::Illustration */}
            <div className='mb-3'>
                <img
                    src={toAbsoluteUrl('/media/auth/please-verify-your-email-dark.webp')}
                    className='mw-100 mh-300px theme-dark-show'
                    alt=''
                />
                <img
                    src={toAbsoluteUrl('/media/auth/please-verify-your-email.webp')}
                    className='mw-100 mh-300px theme-light-show'
                    alt=''
                />
            </div>
            {/* end::Illustration */}

            {/* begin::Link */}
            <div className='mb-0'>
                <Link
                    to="/auth/login"
                    className='btn btn-warning'
                >
                    Devam Et
                </Link>
            </div>
            {/* end::Link */}
        </>
    )
}
export {VerifyEmail}