import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../../api";



const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const getGroupCustomersAction = createAsyncThunk('GET_GROUP_CUSTOMERS_ACTION', async (
    {
        userId,
        groupId,
        page,
        perPage
    }, {rejectWithValue}) => {
    try {
        let endpoint = !(page && perPage) ? `${userId}/customers/groups/${groupId}` : `${userId}/customers/groups/${groupId}?page=${page}&perpage=${perPage}`;
        return await HttpApi.GetAsync(endpoint, true);
    } catch (err) {
        return rejectWithValue(err);
    }
})

const getGroupCustomersSlice = createSlice({
    name: 'get-group-customers',
    initialState,
    reducers: {
        resetGroupCustomers: (state) => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(getGroupCustomersAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(getGroupCustomersAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(getGroupCustomersAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {getGroupCustomersAction};
export const { resetGroupCustomers } = getGroupCustomersSlice.actions;
export default getGroupCustomersSlice.reducer;
