import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { KTIcon } from "../../../_metronic/helpers";
import ConfirmationModal from "../modals/ConfirmationModal";
import { useDispatch, useSelector } from 'react-redux';
import { freezeAccountAction } from '../../../services/redux/account/freeze-account';
import { get_local_storage_item } from '../../../utilities';
import { isFailure, isLoading, isSuccess } from '../../../utilities/reduxStateHelper';
import { logoutAction } from '../../../services/redux/auth/logout';
import { fireToast } from '../../../utilities/toastHelper';

const deactivateAccount = {
    confirm: false,
}

const deactivateAccountSchema = Yup.object().shape({
    confirm: Yup.boolean().oneOf([true], 'Please check the box to deactivate your account'),
})

const DeactivateAccount = () => {
    const dispatch = useDispatch();
    const freezeAccountState = useSelector((state) => state.account.freezeAccount)
    const [isConfirmationModal, setIsConfirmationModal] = useState(false);
    const [loading, setLoading] = useState(false)
    const formik = useFormik({
        initialValues: {
            ...deactivateAccount,
        },
        validationSchema: deactivateAccountSchema,
        onSubmit: () => {
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
            }, 1000)
            setIsConfirmationModal(true);
        },
    })
    const handleFreezeSubmit = (password) => {
        const id = get_local_storage_item('user').id;
        dispatch(freezeAccountAction({ userId: id, password: password }));
    }
    const handleCloseModal = () => {
        setIsConfirmationModal(false);
    }

    useEffect(() => {
        if (isSuccess(freezeAccountState)) {
            setLoading(true);
            fireToast("Hesabınız devre dışı bırakıldı. Giriş sayfasına yönlendirme", "SUCCESS");
            setTimeout(() => {
                dispatch(logoutAction())
            }, (3000));
        }
        else if (isFailure(freezeAccountState)) {
            fireToast(freezeAccountState.error.message, "FAIL");
            setLoading(false);
        }
        else if (isLoading(freezeAccountState)) {
            setLoading(true);
        }
    }, [freezeAccountState, dispatch])

    return (
        <div className='card'>
            <div
                className='card-header border-0'
                data-bs-target='#kt_account_deactivate'
                aria-expanded='true'
                aria-controls='kt_account_deactivate'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Hesabı Devre Dışı Bırak</h3>
                </div>
            </div>

            <div id='kt_account_deactivate' className='collapse show'>
                <form onSubmit={formik.handleSubmit} id='kt_account_deactivate_form' className='form'>
                    <div className='card-body border-top p-9'>
                        <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6'>
                            <KTIcon iconName='information-5' className='fs-2tx text-warning me-4' />

                            <div className='d-flex flex-stack flex-grow-1'>
                                <div className='fw-bold'>
                                    <h4 className='text-gray-800 fw-bolder'>Hesabınızı Devre Dışı Bırakıyorsunuz</h4>
                                    <div className='fs-6 text-gray-600'>
                                        Ekstra güvenlik için oturum açma şifrenizi sıfırlarken, e-posta veya telefon numaranızı onaylamanızı gerektirir.
                                        <br />
                                        <a className='fw-bolder' href='#'>
                                            Daha Fazla
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='form-check form-check-solid fv-row'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                {...formik.getFieldProps('confirm')}
                            />
                            <label className='form-check-label fw-bold ps-2 fs-6' htmlFor='deactivate'>
                            Hesabımın devre dışı bırakılmasını onaylıyorum
                            </label>
                        </div>
                        {formik.touched.confirm && formik.errors.confirm && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formik.errors.confirm}</div>
                            </div>
                        )}
                    </div>

                    {/* Deactivate Button Start */}
                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button
                            id='kt_account_deactivate_account_submit'
                            type='submit'
                            className='btn btn-danger fw-bold'

                        >
                            {!loading && 'Hesabı Devre Dışı Bırak'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Lütfen bekleyiniz...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                    {/* Deactivate Button End */}

                </form>
            </div>
            {
                isConfirmationModal && (
                    <ConfirmationModal type="deactivate" handleCloseModal={handleCloseModal} onSubmit={handleFreezeSubmit} />
                )
            }
            {isConfirmationModal && <div className='modal-backdrop fade show'></div>}
        </div>
    )
}

export { DeactivateAccount }
