import { useEffect } from 'react';
import {KTIcon} from '../../../_metronic/helpers'
const SessionEditModalHeader = ({
    handleCloseModal
    }) => {
    
    useEffect(() => {

        return () => {
        }
    }, [])
    return (
        <div className='modal-header'>
            {/* begin::Modal title */}
            <h2 className='fw-bolder'>Oturum Ekle</h2>
            {/* end::Modal title */}

            {/* begin::Close */}
            <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => {
                    handleCloseModal();
                }}
                style={{cursor: 'pointer'}}
            >
                <KTIcon iconName='cross' className='fs-1'/>
            </div>
            {/* end::Close */}
        </div>
    )
}

export {SessionEditModalHeader}