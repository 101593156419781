import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";
import { set_local_storage_item } from "../../../utilities";


const initialState = {
  isLoading: false,
  isSuccess: false,
  isFailure: false,
  data: {},
  error: {}
};

const userDetailsAction = createAsyncThunk('USER_DETAILS_ACTION', async (_, { rejectWithValue }) => {
  try {
    const endpoint = `profile/details`;
    const response = await HttpApi.GetAsync(endpoint, true);
    set_local_storage_item("user", response.data);
    return response;
  }
  catch (err) {
    return rejectWithValue(err);
  }
})


const userDetailsSlice = createSlice({
  name: 'user-details',
  initialState,
  reducers: {
    resetProfileDetails: (state) => {return initialState;}
  },
  extraReducers: (builder) => {
    builder.addCase(userDetailsAction.pending, (state) => {
      state.isFailure = false;
      state.isLoading = true;
      state.isSuccess = false;
      state.data = {};
    })
      .addCase(userDetailsAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isFailure = false;
        state.data = action.payload;
      })
      .addCase(userDetailsAction.rejected, (state, action) => {
        state.isFailure = true;
        state.isSuccess = false;
        state.isLoading = false;
        state.error = action.payload;
      })
  }
})

export { userDetailsAction };
export const {resetProfileDetails} = userDetailsSlice.actions;
export default userDetailsSlice.reducer;
