import React from 'react';
import {Link} from "react-router-dom";

const Error404 = () => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
        }}>
            {/* begin::Title */}
            <h1 style={{
                fontWeight: 'bolder',
                fontSize: 48,
                color: "indigo",
                marginBottom: 12
            }}>Oops!</h1>
            {/* end::Title */}

            {/* begin::Text */}
            <div style={{
                fontWeight: "bold",
                fontSize: 15,
                marginBottom: 21
            }} className='text-gray-500'>O sayfayı bulamıyoruz.</div>
            {/* end::Text */}

            {/* begin::Illustration */}
            <div className='mb-3'>
                <img
                    src={'/media/auth/404-error.webp'}
                    className='mw-250px theme-light-show'
                    alt=''
                />
                <img
                    src={'/media/auth/404-error-dark.webp'}
                    className='mw-250px theme-dark-show'
                    alt=''
                />
            </div>
            {/* end::Illustration */}

            {/* begin::Link */}
            <div className='mb-0'>
                <Link to='/dashboard' className='btn btn-sm btn-primary'>
                    Ana Sayfaya Dön
                </Link>
            </div>
            {/* end::Link */}
        </div>
    )
}

export default Error404;