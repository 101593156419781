/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import { KTIcon } from '../../../helpers'
import { fireToast } from '../../../../utilities/toastHelper';
import { useTheme } from '../../../../services/themeService';
import { useDispatch, useSelector } from 'react-redux';
import { isFailure, isLoading, isSuccess } from '../../../../utilities/reduxStateHelper';
import { resetHelpMail, sendHelpMailAction } from '../../../../services/redux/profile/help-mail';
import { get_local_storage_item } from '../../../../utilities';



const HelpDrawer = () => {
  const {theme} = useTheme();
  const dispatch = useDispatch();
  const user = get_local_storage_item("user");
  const sendHelpMail = useSelector((state) => state.profile.sendHelpMail)
  const [text, setText] = useState("");
  const [subject, setSubject] = useState("");
  const [mail, setMail] = useState("")

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleRequestMail = () => {
    if(subject === "" || mail === "" || text === ""){
      fireToast("Eksik Bilgi Girişi Yaptınız, Kontrol Ediniz.", "FAIL", theme.theme)
      return;
    }

    if (isEmailValid(mail)) {
      const data = {
        userId: user.id,
        email: mail,
        text: text,
        subject: subject
      }
      dispatch(sendHelpMailAction(data))

    } else {
      fireToast("Girilen mail adresi doğru formatta olduğundan emin olun!", "FAIL", theme.theme)
    }
  }

  useEffect(() => {
    if(isSuccess(sendHelpMail)){
      fireToast(sendHelpMail.data.message, "SUCCESS", theme.theme);
    }
    if(isFailure(sendHelpMail)){
      fireToast(sendHelpMail.error.message, "FAIL", theme.theme);
    }
    return () => {
      dispatch(resetHelpMail())
    }
  }, [dispatch, sendHelpMail])

  return (
    <div
      id='kt_help'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='help'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'350px', 'md': '525px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_help_toggle'
      data-kt-drawer-close='#kt_help_close'
    >
      {/* begin::Card */}
      <div className='card shadow-none rounded-0 w-100'>
        {/* begin::Header */}
        <div className='card-header' id='kt_help_header'>
          <h5 className='card-title fw-bold text-gray-600'>Yardım</h5>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
              id='kt_help_close'
            >
              <KTIcon iconName='cross' className='fs-2' />
            </button>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body' id='kt_help_body'>
          {/* begin::Content */}
          <div
            id='kt_help_scroll'
            className='hover-scroll-overlay-y'
            data-kt-scroll='true'
            data-kt-scroll-height='auto'
            data-kt-scroll-wrappers='#kt_help_body'
            data-kt-scroll-dependencies='#kt_help_header'
            data-kt-scroll-offset='5px'
          >
            {/* begin::Support */}
            <div className='rounded border border-dashed border-gray-300 p-6 p-lg-8 mb-10'>
              {/* begin::Heading */}
              <h2 className='fw-bolder mb-5'>
                Yardım Almak İstediğiniz Konu Nedir?
              </h2>
              {/* end::Heading */}

              <div className='fs-5 fw-bold mb-5'>
                <h4>Konu</h4>
                <input
                  name='subject'
                  type='text'
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  required
                  className={"form-control form-control-solid w-100 mb-5 mt-2"}
                  placeholder='Konu'
                />
              </div>
              <div className='fs-5 fw-bold mb-5'>
                <h4>Mail Adresiniz</h4>
                <input
                  name='mail'
                  type='text'
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                  required
                  className={"form-control form-control-solid w-100 mb-5 mt-2"}
                  placeholder='Mail adresiniz'
                />
              </div>
              <div className='fs-5 fw-bold mb-5'>
                <h4>Yardım Detay</h4>
                <textarea
                  name='text'
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  required
                  className={"form-control form-control-solid w-100 mb-5 mt-2"}
                  rows={7}
                  placeholder='Yardım metniniz..'
                />
              </div>

              {/* begin::Link */}
              <button
                onClick={handleRequestMail}
                className='btn btn-lg explore-btn-primary w-100'
              >
                Gönder
              </button>
              {/* end::Link */}
            </div>
            {/* end::Support */}

          </div>
          {/* end::Content */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Card */}
    </div>
  )
}

export { HelpDrawer }
