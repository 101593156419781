import { Suspense, lazy, useState } from 'react';
import {useIntl} from 'react-intl'
import {PageTitle} from "../_metronic/layout/core";
import UserGroup from "../components/Apps/user-group/UserGroup";
import {QueueWidget} from "../components/Widgets/QueueWidget";

const DashboardPage = () => {
    const CustomerList = lazy(() => import("../components/Apps/customer-management/CustomerList"));
    const DraftList = lazy(() => import("../components/Apps/draft-management/DraftList"));

    const [selectedGroupId, setSelectedGroupId] = useState()

    return (
        <>
            <div className='row g-5 g-xl-9'>
                <div className='col-xl-4 mb-8'>
                    <UserGroup setSelectedGroupId={setSelectedGroupId} />
                </div>
                <div className='col-xl-8'>
                    <QueueWidget className='card-xl-stretch mb-8' state={"active"} />
                </div>
            </div>

            <div className='row g-5 g-xl-8'>
                <div className='col-xl-stretch mb-8'>
                    <Suspense>
                        <CustomerList/>
                    </Suspense>
                </div>
            </div>

            <div className='row g-5 g-xl-8'>
                <div className='col-xl-stretch mb-8'>
                    <Suspense>
                        <DraftList/>
                    </Suspense>
                </div>
            </div>

            <div className='row g-5 g-xl-8'>
                <div className='col-xl-stretch mb-8'>
                    <Suspense>
                        
                    </Suspense>
                </div>
            </div>
        </>
    )
}
const DashboardWrapper = () => {
    const intl = useIntl()

    return (
        <>
            <PageTitle breadcrumbs={[]}>Planlama</PageTitle>
            <DashboardPage/>
        </>
    )
}
export {DashboardWrapper};