import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const removeFromGrayListAction = createAsyncThunk("REMOVE_FROM_GRAYLIST_ACTION", async(
    {
        userId,
        customerId,
    }, {rejectWithValue}) =>
{
    try {
        let endpoint = `${userId}/graylist/customers/remove/${customerId}`;
        return await HttpApi.PostAsync(endpoint, true, {});
    }
    catch(err)
    {
        return rejectWithValue(err);
    }
});

const removeFromGrayListSlice = createSlice({
    name: "remove-from-graylist",
    initialState,
    reducers: {
        resetRemoveGrayListCustomer: (state) => {return initialState}
    },
    extraReducers: (builder) => {
        builder.addCase(removeFromGrayListAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        }).addCase(removeFromGrayListAction.fulfilled, (state, action) => {
            state.isFailure = false;
            state.isLoading = false;
            state.isSuccess = true;
            state.data = action.payload;
        }).addCase(removeFromGrayListAction.rejected, (state, action) => {
            state.isFailure = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.data = action.payload;
        })
    }
})
export {removeFromGrayListAction};
export const {resetRemoveGrayListCustomer} = removeFromGrayListSlice.actions;
export default removeFromGrayListSlice.reducer;