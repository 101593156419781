import React, { useEffect, useState } from "react";
import {useTheme} from "../../../services/themeService";
import {KTIcon, toAbsoluteUrl} from "../../../_metronic/helpers";
import {Link} from "react-router-dom";

import "./index.css"
import { io } from "socket.io-client";
import { onlineUsersAdminPath } from "../../../utilities/requestBuilder";

const adminOnlineUsersSocket = io(onlineUsersAdminPath, {
    path: "/socket.io",
    extraHeaders: {
        "x-api-gateway": "test1"
    },
    secure: true,
    autoConnect: true
});
const ActiveCustomerList = () => {
    const {theme} = useTheme();
    const [onlineUsers, setOnlineUsers] = useState([])

    useEffect(() => {
        if (!adminOnlineUsersSocket.active) adminOnlineUsersSocket.connect();
    
        // Olay dinleyicisini bir kez kaydedin
        adminOnlineUsersSocket.on("get-online-users", (data) => {
            setOnlineUsers(data);
        });
    
        // Her 3 saniyede sunucuya istek gönder
        const intervalId = setInterval(() => {
            adminOnlineUsersSocket.emit("request-online-users"); // Sunucuya veri isteği gönderin
        }, 3000);
    
        // Temizlik fonksiyonu
        return () => {
            clearInterval(intervalId);
            adminOnlineUsersSocket.off("get-online-users"); // Olay dinleyicisini kaldırın
    //        adminOnlineUsersSocket.disconnect(); // Gerekirse bağlantıyı kesin
        };
    }, [adminOnlineUsersSocket]);

    return(
        <div
            style={{
                marginTop: '20px',
                display: 'flex',
                flexDirection: 'column',
                padding: 10,
                backgroundColor: theme.backgroundColor,
                borderRadius: 10
            }}
        >
            <div style={{
                display: 'flex',
                marginTop: 20,
                marginBottom: 20,
                paddingLeft: 20,
                paddingRight: 20
            }}>
                <span style={{
                    color: theme.color,
                    fontSize: 21,
                    fontWeight: 'bold',
                    fontFamily: 'Inter'
                }}>Aktif Kullanıcılar</span>
            </div>
            <div className={"ps-8 pe-8 active-customer-list-container"}>
                {
                    onlineUsers && onlineUsers.map((user) => (
                        <div
                            key={user.id}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: "10px"
                            }}
                        >
                            <div style={{marginRight: "20px"}}>
                                <img
                                    style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "25px"
                                    }}
                                    src={user.avatar === 'blank' ? toAbsoluteUrl('/media/avatars/blank.png') : user.avatar} 
                                />
                            </div>
                            <div className={"d-flex align-items-center justify-content-between flex-grow-1"}>
                                <Link to={`user/${user.id}`} className={"fw-bold"}>{user.firstName} {user.lastName}</Link>
                                <div className={"d-flex align-items-center"}>
                                    <KTIcon iconName="user-tick" className={"bigger-icon text-success"} />
                                    <h6 className={"mx-2 text-success mb-0"}>Aktif</h6>
                                </div>
                            </div>
                        </div>
                    ))
                }
                {
                    onlineUsers.length === 0 && (
                        <div className="w-100 d-flex flex-column align-items-center justify-content-center px-5 mb-3">
                            <img
                                className="w-250px h-200px object-fit-contain"
                                src={"/media/illustrations/sigma-1/20.png"}
                                alt=""
                            />
                            <div className="d-flex align-items-center">
                                <KTIcon iconName="cross-circle" className="fs-2" />
                                <h3 className="fs-2 fw-bold mb-0 ms-3">Suanda aktif kullanıcı bulunmamaktadır..</h3>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default ActiveCustomerList;