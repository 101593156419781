import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const addMultipleCustomerAction = createAsyncThunk('ADD_MULTIPLE_CUSTOMER_ACTION', async (
    {
        userId,
        createGroup,
        groupName,
        customers,
    }, {rejectWithValue}) => {
    try {
        const endpoint = `${userId}/customers/bulk/create`;
        const body = {customers: customers, createGroup: createGroup, groupName: groupName};
        return await HttpApi.PostAsync(endpoint, true, body);
    } catch (err) {
        return rejectWithValue(err);
    }


})

const addMultipleCustomerSlice = createSlice({
    name: 'create-customer-multiple',
    initialState,
    reducers: {
        resetAddMultipleCustomer: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(addMultipleCustomerAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
        .addCase(addMultipleCustomerAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
        })
        .addCase(addMultipleCustomerAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
        })
    }
});
export {addMultipleCustomerAction};
export const { resetAddMultipleCustomer } = addMultipleCustomerSlice.actions;
export default addMultipleCustomerSlice.reducer;
