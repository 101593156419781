import React, {useEffect, useState} from "react";
import ModalHeader from "./SessionDeleteModalHeader";
import {useDispatch, useSelector} from "react-redux";
import {isSuccess, isLoading, isFailure} from "../../../utilities/reduxStateHelper";
import {fireToast} from "../../../utilities/toastHelper";
import {deleteSessionsAction, resetDeleteSessions} from "../../../services/redux/profile/delete-session";
import {get_local_storage_item} from "../../../utilities";

const SessionDeleteConfirmationModal = ({type, handleCloseModal, sessionID, theme, getSessions}) => {
    const dispatcher = useDispatch()
    const [password, setPassword] = useState("");
    const sessionDeleteState = useSelector((state) => state.profile.deleteSession);
    const [isDeleteLoading, setIsLoading] = useState(false);
    
    const onSubmit = () => {
        dispatcher(deleteSessionsAction({userId: get_local_storage_item("user").id, session: sessionID, password: password}));
    }
    
    useEffect(() => {
        if(isSuccess(sessionDeleteState))
        {
            setIsLoading(false);
            fireToast(sessionDeleteState.data.message, 'SUCCESS', theme.theme);
            getSessions();
            handleCloseModal();
        }
        if(isLoading(sessionDeleteState))
        {
            setIsLoading(true);
        }
        if(isFailure(sessionDeleteState))
        {
            setIsLoading(false);
            fireToast(sessionDeleteState.error.message, 'FAIL', theme.theme);
        }
        return () => {
            dispatcher(resetDeleteSessions());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionDeleteState])
    
    return (
        <div
            className='modal fade show d-block'
            id='kt_modal_add_draft'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
            >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-650px'>
                {/* begin::Modal content */}
                <div className='modal-content' style={{display: 'flex', justifyContent: 'center'}}>
                    <ModalHeader
                        title={type === "delete" ? "Oturumu Sil ve Şifreyi Onayla" : "Oturumu Devre Dışı Bırak ve Şifreyi Onayla"}
                        handleCloseModal={handleCloseModal}
                    />

                    {/* begin::Modal body */}
                    <div
                        className={"card"}
                        style={{padding: 30}}
                        >
                        <div style={{marginBottom: 30}}>
                            <h4>Parolanız</h4>
                            <span>Whatsapp Oturumunuzun tamamen silinmesini tamamlamak için şifrenizi doğru girmelisiniz!</span>
                        </div>
                        <input
                            className={"form-control form-control-solid w-100 mb-5"}
                            placeholder="Place enter your password.."
                            value={password}
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className='card-footer d-flex justify-content-end'>
                            <button
                                id='kt_account_delete_account_submit'
                                type='submit'
                                className='btn btn-danger fw-bold'
                                disabled={password === "" ? true : false}
                                onClick={() => {
                                onSubmit()
                            }}
                                >
                                {
                                    type === "delete" ? "Oturumu Sil" : "Oturumu Dondur"
                                }
                            </button>
                        </div>
                    </div>

                    {/* end::Modal body */}

                </div>
                {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}
        </div>
        )
}

export default SessionDeleteConfirmationModal;