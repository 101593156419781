import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { activeSessionsAction, resetActiveSessions } from "../../../../services/redux/profile/active-sessions";
import { isFailure, isLoading, isSuccess } from "../../../../utilities/reduxStateHelper";
import { get_local_storage_item } from "../../../../utilities";
import { KTIcon, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { Link } from "react-router-dom";
import { fireToast } from "../../../../utilities/toastHelper";
import { useTheme } from "../../../../services/themeService";

export const Step5 = ({ prevStep, handleSubmit, customTheme}) => {
    const { theme } = useTheme();
    const [sessions, setSessions] = useState([]);
    const dispathcer = useDispatch()
    const getSessionsState = useSelector((state) => state.profile.activeSessions);
    const [selectedSession, setSelectedSession] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // eslint-disable-next-line no-const-assign, react-hooks/exhaustive-deps
        dispathcer(activeSessionsAction({ userId: get_local_storage_item("user").id }))
    }, [dispathcer]);

    useEffect(() => {
        if (isSuccess(getSessionsState)) {
            setSessions(getSessionsState.data.data);
            setLoading(false);
        }
        if (isFailure(getSessionsState)) {
            setLoading(false)
        }
        if (isLoading(getSessionsState)) {
            setLoading(true)
        }
        return () => {
            dispathcer(resetActiveSessions());
        }
    }, [sessions, dispathcer, getSessionsState])


    const hangleSelectedSession = (session) => { 
        if (selectedSession.phone !== session.phone) { //seçili ise
            setSelectedSession(session)
        } else {
            setSelectedSession(""); 
            
        }
    }
    

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="d-flex flex-column border rounded p-5 card">
                        <h4 className={"mb-3 mt-3"}>Oturumlar: </h4>
                        <div className="d-flex align-items-center justify-content-center flex-wrap">
                            {
                                sessions && sessions.map((session) => (
                                    <div
                                        className={`d-flex flex-column align-items-center p-5 mx-3 rounded my-5 cursor-pointer ${selectedSession && selectedSession.sessionName === session.sessionName && "border border-primary"}`}
                                        key={session.sessionName}
                                        onClick={() => hangleSelectedSession(session)}
                                        style={
                                            { backgroundColor: customTheme.itemBg }
                                        }
                                    >
                                        <img
                                            src={`${toAbsoluteUrl('/media/icons/duotune/social/whatsapp.png')}`}
                                            alt="wp svg icons"
                                            style={{
                                                maxWidth: '75px',
                                                maxHeight: '75px',
                                                marginBottom: '10px',
                                                objectFit: 'contain',
                                            }}
                                        />
                                        {session.phone}
                                    </div>
                                ))
                            }
                            {
                                sessions.length === 0 && (
                                    <div
                                        className={"d-flex flex-column p-10 w-100 justify-content-center align-items-center border rounded"}
                                    >
                                        <KTIcon iconName="cross-circle" className={"bigger-icon mb-4"} />
                                        <h3 className={"mb-0 user-select-none"}>Şu anda aktif bir oturum yok!</h3>
                                        <Link to={"/settings/automation"} className="btn btn-primary mt-3">Oturum Oluşturma</Link>
                                    </div>
                                )
                            }
                        </div>
                        <div className={"d-flex justify-content-between mt-5"}>
                            <button
                                style={{
                                    border: '1px solid',
                                    padding: "8px 15px",
                                    borderRadius: 10,
                                    borderColor: '#0095E8',
                                    backgroundColor: 'white',
                                    color: '#0095E8'
                                }}
                                onClick={() => {
                                    prevStep()
                                }}
                            >
                                Önceki
                            </button>
                            <button
                                className={"btn btn-primary"}
                                onClick={() => {
                                    if (selectedSession === "") {
                                        fireToast("Lütfen aktif bir Whatsapp hesabı seçiniz.", "FAIL", theme.theme);
                                    } else {
                                        setLoading(true); // Yükleme durumunu başlat
                                        handleSubmit(selectedSession);
                                    }
                                }}
                                type="button"
                                disabled={loading} // Butonun yükleme sırasında devre dışı kalmasını sağlayalım
                            >
                                {loading ? (
                                     <div className="d-flex justify-content-center align-items-center" style={{ position: "fixed", top: "0", left: "0", width: "100%", height: "100%", backgroundColor: "rgba(255, 255, 255, 0.5)" }}>
                                     <span className="spinner-border text-primary" style={{ width: "4rem", height: "4rem" }} role="status"></span>
                                 </div>
                                ) : (
                                    "Gönder"
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}