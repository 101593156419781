import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const deleteGroupAction = createAsyncThunk('DELETE_GROUP_ACTION', async (
    {
        userId,
        groupId,
        deleteUsers
    }, {rejectWithValue}) => {
    try {
        const endpoint = `${userId}/groups/delete/${groupId}`;
        const body = {deleteUsers};
        return await HttpApi.PostAsync(endpoint, true, body);
    } catch (err) {
        return rejectWithValue(err);
    }


})

const deleteGroupSlice = createSlice({
    name: 'delete-group',
    initialState,
    reducers: {
        resetDeleteGroup: (state) => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(deleteGroupAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
        .addCase(deleteGroupAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(deleteGroupAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {deleteGroupAction};
export const { resetDeleteGroup } = deleteGroupSlice.actions;
export default deleteGroupSlice.reducer;
