export const handleDateForSubmit = (hour, minute) => {
    let tempDate = new Date();
    let parsedHour = parseInt(hour);
    let parsedMinute = parseInt(minute);
    let desiredDate;
    if (tempDate.getHours() >= parsedHour && tempDate.getMinutes() > parsedMinute)
    {
        let nextDay = new Date(tempDate)
        nextDay.setDate(tempDate.getDate() + 1);
        nextDay.setHours(parsedHour)
        nextDay.setMinutes(parsedMinute)
        desiredDate = nextDay;            
    }
    else {
        desiredDate = tempDate;
        desiredDate.setHours(parsedHour);
        desiredDate.setMinutes(parsedMinute);
    }
    
    return desiredDate;
}