import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";


const initialState = {
  isLoading: false,
  isSuccess: false,
  isFailure: false,
  data: {},
  error: {}
};

const registerAction = createAsyncThunk('REGISTER_ACTION', async ({
  avatar,
  firstName,
  lastName,
  email,
  phone,
  password
}, { rejectWithValue }) => {
  try {
    const endpoint = 'auth/sign-up/';
    const body = { avatar, firstName, lastName, email, password, phone };
    const response = await HttpApi.PostAsync(endpoint, false, body);
    return response;
  }
  catch (err) {
    return rejectWithValue(err);
  }


})

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    resetRegisterState: (state) => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(registerAction.pending, (state) => {
      state.isFailure = false;
      state.isLoading = true;
      state.isSuccess = false;
      state.data = {};
    })
      .addCase(registerAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isFailure = false;
        state.data = action.payload;
      })
      .addCase(registerAction.rejected, (state, action) => {
        state.isFailure = true;
        state.isSuccess = false;
        state.isLoading = false;
        state.error = action.payload;
      })
  }
});
export const {resetRegisterState} = registerSlice.actions;
export { registerAction };
export default registerSlice.reducer;
