import React from 'react';
import {createRoot} from 'react-dom/client'
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

import {QueryClient, QueryClientProvider} from 'react-query'
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
import {Chart, registerables} from 'chart.js'

import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'

import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import {AppRoutes} from "./router";
import { store } from './services'

import {Provider} from "react-redux";
import { baseBanner } from './utilities/constants';
Chart.register(...registerables)
const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'));

console.log(baseBanner)
root.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <MetronicI18nProvider>
                <AppRoutes/>
            </MetronicI18nProvider>
        </QueryClientProvider>
    </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
