import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Profile from "../components/UserDetails/Profile";
import UserBannedModal from "../components/Modals/UserBannedModal";
import ActiveSessions from "../components/UserDetails/ActiveSessions";
import { useTheme } from "../services/themeService";
import { QueueWidget } from "../components/Widgets/QueueWidget";
import CreditInformation from "../components/UserDetails/CreditInformation";
import CreditCharts from "../components/UserDetails/CreditCharts";
import CreditLoadingModal from "../components/Modals/CreditLoadingModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserByIdAction,
  resetGetUserById,
} from "../services/redux/admin/get-user-by-id";
import { isFailure, isLoading, isSuccess } from "../utilities/reduxStateHelper";
import { toAbsoluteUrl } from "../_metronic/helpers";
import { formatDate, formatPhoneNumber } from "../utilities/stringHelper";
import { fireToast } from "../utilities/toastHelper";
import {
  resetAdminUnblockUser,
  unblockUserAction,
} from "../services/redux/admin/unblock-user";
import {
  blockUserAction,
  resetAdminBlockUser,
} from "../services/redux/admin/block-user";
import {
  resetUploadCredit,
  uploadCreditAction,
} from "../services/redux/admin/upload-credit";
import {
  getUserSessionsAction,
  resetGetUserSessions,
} from "../services/redux/admin/user-sessions";
import {
  extraPhonePermissionAction,
  resetExtraPhonePermission,
} from "../services/redux/admin/extra-phone-permission";
import {
  getSpentCreditDataAction,
  resetGetSpentCreditData,
} from "../services/redux/admin/spent-credit-data";
import {
  changeMailAction,
  resetChangeMail,
} from "../services/redux/admin/change-mail";
import {
  changePhoneAction,
  resetChangePhone,
} from "../services/redux/admin/change-phone";
import {
  deletePhoneNumberAction,
  resetDeletePhoneNumber,
} from "../services/redux/admin/delete-phone-number";
import { Spin } from "antd";
import CustomersTable from "../components/UserDetails/CustomersTable";
import DraftTable from "../components/UserDetails/DraftTable";
import GroupList from "../components/UserDetails/GroupList";
import GrayAndBlackList from "../components/UserDetails/GrayAndBlackList";
import { getCustomersAction } from "../services/redux/customers/get-customers";
import AutomationSettings from "../components/UserDetails/AutomationSettings";
import { DeleteAccountAdmin } from "../components/Account/settings/DeleteAccountAdmin";
import { Notifications } from "../components/Account/settings/Notifications";

const tabs = [
  {
    id: 1,
    title: "Genel Bakış",
  },
  {
    id: 2,
    title: "Güvenlik",
  },
  {
    id: 3,
    title: "Kuyruk ve Oturumlar",
  },
];

const UserDetailPage = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const getUserByIdState = useSelector((state) => state.admin.getUserById);
  const blockUserState = useSelector((state) => state.admin.blockUser);
  const unblockUserState = useSelector((state) => state.admin.unblockUser);
  const uploadCreditState = useSelector((state) => state.admin.uploadCredit);
  const getUserSessionsState = useSelector(
    (state) => state.admin.getUserSessions
  );
  const extraPhonePermissionState = useSelector(
    (state) => state.admin.extraPhonePermission
  );
  const spentCreditDataState = useSelector(
    (state) => state.admin.getSpentCreditData
  );
  const changeMailState = useSelector((state) => state.admin.changeMail);
  const changePhoneState = useSelector((state) => state.admin.changePhone);
  const deletePhoneNumberState = useSelector(
    (state) => state.admin.deletePhoneNumber
  );

  const [selectedUser, setSelectedUser] = useState({});
  const [userSessions, setUserSessions] = useState([]);
  const [spentCreditData, setSpentCreditData] = useState([]);
  const [extraPhoneData, setExtraPhoneData] = useState({});
  const [currentCreditInfo, setCurrentCreditInfo] = useState({});
  const [selectedTab, setSelectedTab] = useState(1);
  const [loading, setLoading] = useState(false);

  const [isShowUserBannedModal, setIsShowUserBannedModal] = useState(false);
  const [isShowCreditLoadingModal, setIsShowCreditLoadingModal] =
    useState(false);

  const CreditLoad = (credit) => {
    credit = parseInt(credit);

    dispatch(
      uploadCreditAction({
        userId: userId,
        credit: credit,
      })
    );
  };

  const fetchUserDetails = (userId) => {
    dispatch(
      getUserByIdAction({
        userId: userId,
      })
    );
  };

  const blockTheUser = (id) => {
    dispatch(
      blockUserAction({
        userId: id,
      })
    );
    fetchUserDetails(userId);
  };

  const unblockTheUser = (id) => {
    dispatch(
      unblockUserAction({
        userId: id,
      })
    );
    fetchUserDetails(userId);
  };

  const fetchUserSessions = (userId) => {
    dispatch(
      getUserSessionsAction({
        userId: userId,
      })
    );
  };

  const updateExtraPhonePermission = (phoneAmount) => {
    dispatch(
      extraPhonePermissionAction({
        userId: userId,
        phoneAmount: phoneAmount,
      })
    );
  };

  const fetchSpentCreditData = (id) => {
    dispatch(
      getSpentCreditDataAction({
        userId: id,
      })
    );
  };

  const adminChangeMail = (mail) => {
    dispatch(
      changeMailAction({
        userId: userId,
        mail: mail,
      })
    );
  };

  const adminChangePhone = (phone) => {
    dispatch(
      changePhoneAction({
        userId: userId,
        phone: phone,
      })
    );
  };

  const deletePhoneNumber = ({ phone }) => {
    dispatch(
      deletePhoneNumberAction({
        user: userId,
        phone: phone,
      })
    );
  };

  // Bu işlemler doğru veriyi göstermeyi test etmek için.
  useEffect(() => {
    fetchUserDetails(userId);
    fetchUserSessions(userId);
    fetchSpentCreditData(userId);
  }, [userId]);

  useEffect(() => {
    if (isSuccess(getUserByIdState)) {
      setSelectedUser(getUserByIdState.data.data);
      setExtraPhoneData(getUserByIdState.data.data.extraPhoneData);
      setLoading(false);
    }
    if (isLoading(getUserByIdState)) {
      setLoading(true);
    }
    if (isFailure(getUserByIdState)) {
      setLoading(false);
    }
    return () => {
      dispatch(resetGetUserById());
    };
  }, [
    dispatch,
    getUserByIdState,
    blockUserState,
    unblockUserState,
    selectedUser,
  ]);

  useEffect(() => {
    if (isSuccess(blockUserState)) {
      fireToast(blockUserState.data.message, "SUCCESS", theme.theme);
      dispatch(getUserByIdAction({ userId }));
    }
    if (isFailure(blockUserState)) {
      fireToast(blockUserState.data.message, "FAIL", theme.theme);
    }
    return () => {
      dispatch(resetAdminBlockUser());
    };
  }, [blockUserState, dispatch]);

  useEffect(() => {
    if (isSuccess(unblockUserState)) {
      fireToast(unblockUserState.data.message, "SUCCESS", theme.theme);
      dispatch(getUserByIdAction({ userId }));
    }
    if (isFailure(unblockUserState)) {
      fireToast(unblockUserState.data.message, "FAIL", theme.theme);
    }
    return () => {
      dispatch(resetAdminUnblockUser());
    };
  }, [unblockUserState, dispatch]);

  useEffect(() => {
    if (isSuccess(uploadCreditState)) {
      fireToast(uploadCreditState.data.message, "SUCCESS", theme.theme);
      dispatch(getUserByIdAction({ userId }));
    }
    if (isFailure(uploadCreditState)) {
      fireToast(uploadCreditState.data.message, "FAIL", theme.theme);
    }
    return () => {
      dispatch(resetUploadCredit());
    };
  }, [uploadCreditState, dispatch]);

  useEffect(() => {
    if (isSuccess(getUserSessionsState)) {
      setUserSessions(getUserSessionsState.data.data);
    }
    if (isLoading(getUserSessionsState)) {
    }
    if (isFailure(getUserSessionsState)) {
    }
    return () => {
      dispatch(resetGetUserSessions());
    };
  }, [dispatch, getUserSessionsState, selectedUser]);

  useEffect(() => {
    if (isSuccess(extraPhonePermissionState)) {
      fireToast(extraPhonePermissionState.data.message, "SUCCESS", theme.theme);
      dispatch(getUserByIdAction({ userId }));
    }
    if (isFailure(extraPhonePermissionState)) {
      fireToast(extraPhonePermissionState.data.message, "FAIL", theme.theme);
    }
    return () => {
      dispatch(resetExtraPhonePermission());
    };
  }, [extraPhonePermissionState, dispatch]);

  useEffect(() => {
    if (isSuccess(spentCreditDataState)) {
      setSpentCreditData(spentCreditDataState.data.data.chartInfo);
      setCurrentCreditInfo(spentCreditDataState.data.data.creditInfo);
    }
    if (isLoading(spentCreditDataState)) {
    }
    if (isFailure(spentCreditDataState)) {
    }
    return () => {
      dispatch(resetGetSpentCreditData());
    };
  }, [dispatch, spentCreditDataState, selectedUser]);

  useEffect(() => {
    if (isSuccess(changeMailState)) {
      fireToast(changeMailState.data.message, "SUCCESS", theme.theme);
      dispatch(getUserByIdAction({ userId }));
    }
    if (isFailure(changeMailState)) {
      fireToast(changeMailState.data.message, "FAIL", theme.theme);
    }
    return () => {
      dispatch(resetChangeMail());
    };
  }, [changeMailState, dispatch]);

  useEffect(() => {
    if (isSuccess(changePhoneState)) {
      fireToast(changePhoneState.data.message, "SUCCESS", theme.theme);
      dispatch(getUserByIdAction({ userId }));
    }
    if (isFailure(changePhoneState)) {
      fireToast(changePhoneState.data.message, "FAIL", theme.theme);
    }
    return () => {
      dispatch(resetChangePhone());
    };
  }, [changePhoneState, dispatch]);

  useEffect(() => {
    if (isSuccess(deletePhoneNumberState)) {
      fireToast(deletePhoneNumberState.data.message, "SUCCESS", theme.theme);
      dispatch(
        getUserByIdAction({
          userId: userId,
        })
      );
    }
    if (isFailure(deletePhoneNumberState)) {
      fireToast(deletePhoneNumberState.data.message, "FAIL", theme.theme);
    }
    return () => {
      dispatch(resetDeletePhoneNumber());
    };
  }, [deletePhoneNumberState, dispatch]);

  return (
    <div className={"app-container container-xxl w-100 h-100"}>
      {selectedUser && (
        <div className={"d-flex flex-column flex-lg-row"}>
          {/* begin::Sidebar */}
          <div
            className={
              "flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10"
            }
          >
            <div className={"card mb-5 mb-xl-8"}>
              {selectedUser && selectedUser ? (
                <div className={"card-body"}>
                  <div className={"d-flex flex-center flex-column py-5"}>
                    <div className={"symbol symbol-100px symbol-circle mb-7"}>
                      <img
                        src={
                          selectedUser.avatar === "blank"
                            ? toAbsoluteUrl("/media/avatars/blank.png")
                            : selectedUser.avatar
                        }
                        alt={selectedUser.firstName}
                      />
                    </div>
                    <span className={"fs-3 text-gray-800 fw-bold mb-3"}>
                      {selectedUser.firstName} {selectedUser.lastName}
                    </span>
                    <div className={"mb-9"}>
                      <div
                        className={
                          "badge badge-lg badge-light-primary d-inline"
                        }
                      >
                        {selectedUser.role}
                      </div>
                    </div>
                  </div>
                  <div className={"separator"}></div>
                  <div className={"collapse show"}>
                    <div className={"pb-5 fs-6"}>
                      <div className={"fw-bold mt-5"}>Hesap Kimliği</div>
                      <div className={"text-gray-600"}>{selectedUser.id}</div>
                      <div className={"fw-bold mt-5"}>Email</div>
                      <div className={"text-gray-600"}>
                        <span className={"text-gray-600 text-hover-primary"}>
                          {selectedUser.email}
                        </span>
                      </div>
                      <div className={"fw-bold mt-5"}>Telefon Numarası</div>
                      <div className={"text-gray-600"}>
                        {formatPhoneNumber(selectedUser.phone)}
                      </div>
                      <div className={"fw-bold mt-5"}>Kayıt Olduğu Tarih</div>
                      <div className={"text-gray-600"}>
                        {formatDate(selectedUser.registerDate)}
                      </div>
                      <div className={"fw-bold mt-5"}>
                        Kullanıcı Hesabı Durumu
                      </div>
                      <div className={"text-gray-600"}>
                        {selectedUser.isSuspend
                          ? "Kullanıcı Banlı"
                          : "Kullanıcı Hesabı Açık"}
                      </div>
                      <div className={"fw-bold mt-5"}>Kullanıcı Durumu</div>
                      <div className={"text-gray-600"}>
                        {selectedUser.isFrozen
                          ? "Kullanıcı Donuk"
                          : "Hesap Donuk Değil"}
                      </div>
                      <div className={"d-flex justify-content-evenly mt-5"}>
                        <button
                          onClick={() => setIsShowCreditLoadingModal(true)}
                          className="btn btn-primary"
                        >
                          Kredi Yükle
                        </button>
                        <button
                          onClick={() => setIsShowUserBannedModal(true)}
                          className={"btn btn-danger py-3"}
                        >
                          {selectedUser.isSuspend ? "Ban Kaldır" : "Banla"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <p>Kullanıcı Verileri Bulunamadı!</p>
              )}
            </div>
          </div>
          {/* end::Sidebar */}

          {/* begin::Content */}
          <div className={"flex-lg-row-fluid ms-lg-15"}>
            {/* begin::Tabs */}
            <ul
              className={
                "nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8"
              }
              role="tablist"
            >
              {tabs.map((tab) => (
                <li className={"nav-item"} role="presentation" key={tab.id}>
                  <span
                    className={`nav-link text-active-primary pb-4 cursor-pointer ${
                      tab.id === selectedTab && "active"
                    }`}
                    aria-selected={tab.id === selectedTab ? "tab" : "false"}
                    onClick={() => setSelectedTab(tab.id)}
                  >
                    {tab.title}
                  </span>
                </li>
              ))}
            </ul>
            {/* end::Tabs */}

            {/* begin::Tabs Content */}
            {selectedTab === 1 ? (
              <div>
                <CreditCharts spentData={spentCreditData} customTheme={theme} />
                <CreditInformation currentCreditData={currentCreditInfo} />
                <Notifications isRenderingInAdmin={userId} />
                <CustomersTable userId={userId} />
                <DraftTable userId={userId} />
                <GroupList userId={userId} />
              </div>
            ) : selectedTab === 2 ? (
              <div>
                <Profile
                  selectedData={selectedUser}
                  updateExtraPhonePermission={updateExtraPhonePermission}
                  extraPhoneData={extraPhoneData}
                  adminChangeMail={adminChangeMail}
                  adminChangePhone={adminChangePhone}
                  deletePhoneNumber={deletePhoneNumber}
                />
                <DeleteAccountAdmin userId={userId} />
                <AutomationSettings userId={userId} />
              </div>
            ) : (
              <div>
                <ActiveSessions sessions={userSessions} customTheme={theme} />
                <QueueWidget state={"all"} id={selectedUser.id} />
                <GrayAndBlackList userId={userId} />
              </div>
            )}
            {/* end::Tabs Content */}
          </div>
          {/* end::Content */}
        </div>
      )}
      {loading && (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <Spin size="large" />
        </div>
      )}

      {isShowUserBannedModal && (
        <UserBannedModal
          setIsShowUserBannedModal={setIsShowUserBannedModal}
          selectedUser={selectedUser}
          blockTheUser={blockTheUser}
          unblockTheUser={unblockTheUser}
        />
      )}

      {isShowUserBannedModal && (
        <div className="modal-backdrop fade show"></div>
      )}

      {isShowCreditLoadingModal && (
        <CreditLoadingModal
          setIsShowCreditLoadingModal={setIsShowCreditLoadingModal}
          CreditLoad={CreditLoad}
        />
      )}

      {isShowCreditLoadingModal && (
        <div className="modal-backdrop fade show"></div>
      )}
    </div>
  );
};

export default UserDetailPage;
