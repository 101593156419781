import { useEffect, useState } from "react";
import ModalHeader from "../Apps/draft-management/modal/ModalHeader";
import { useDispatch, useSelector } from "react-redux";
import { getDraftsAction } from "../../services/redux/drafts/get-all-draft";
import { get_local_storage_item } from "../../utilities";
import { isFailure, isLoading, isSuccess } from "../../utilities/reduxStateHelper";
import { resetGetCustomers } from "../../services/redux/customers/get-customers";
import { resetDraftGetByID } from "../../services/redux/drafts/get-draft-by-id";
import { getByIdQueueAction } from "../../services/redux/queues/get-by-id";
import "../Apps/customer-management/index.css";
import { Step1 } from "../Apps/queue-management/Steps/Step1";
import { Step2 } from "../Apps/queue-management/Steps/Step2";
import { Step3 } from "../Apps/queue-management/Steps/Step3";
import "../Apps/queue-management/index.css";
import { getAllQueueItemsAction } from "../../services/redux/queues/get-queue-items";
import { resetUpdateQueue, updateQueueAction } from "../../services/redux/queues/update-queue";
import { fireToast } from "../../utilities/toastHelper";
import { addCustomersQueueAction, resetAddCustomersQueue } from "../../services/redux/queues/add-customers";
import { KTIcon, toAbsoluteUrl } from "../../_metronic/helpers";
import { deleteFileQueueAction } from "../../services/redux/queues/delete-file";
import { resetUploadFileToQueue, uploadFileToQueueAction } from "../../services/redux/queues/upload-file";
import SmallCustomerTable from "../Tables/SmallCustomerTable";
import { removeCustomerQueueAction } from "../../services/redux/queues/remove-customer";

const tabs = [
    {
        id: 1,
        title: "Mesaj Düzenleme",
    },
    {
        id: 2,
        title: "Dosya Düzenleme",
    },
    {
        id: 3,
        title: "Müşteri Düzenleme",
    }
];

const QueueEditModal = ({ setIsShowQueueEditModal, selectedQueue, customTheme }) => {
    const [selectedTab, setSelectedTab] = useState(1);
    const [userId,] = useState(get_local_storage_item('user').id);
    const [title, setTitle] = useState(selectedQueue && selectedQueue.queueTitle);
    const [message, setMessage] = useState('');
    const [drafts, setDrafts] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [files, setFiles] = useState([]);
    const [queueItems, setQueueItems] = useState([]);
    const [defaultPerPage, setDefaultPerPage] = useState(10);
    const [queueItemsPaginateData, setQueueItemsPaginateData] = useState();
    const [queueInfo, setQueueInfo] = useState({});
    const [queueFiles, setQueueFiles] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const dispatch = useDispatch();

    const getDraftsState = useSelector((state) => state.draft.getAllDraft);
    const getDraftByIDState = useSelector((state) => state.draft.getByIdDraft);
    const queueItemsState = useSelector((state) => state.queue.getQueueItems);
    const updateQueueState = useSelector((state) => state.queue.updateQueue);
    const addCustomersToQueueState = useSelector((state) => state.queue.addCustomersToQueue);
    const removeCustomerFromQueueState = useSelector((state) => state.queue.removeCustomersFromQueue)
    const deleteQueueFileState = useSelector((state) => state.queue.deleteFile);
    const queueState = useSelector((state) => state.queue.getQueueById);
    const uploadFileToQueueState = useSelector((state) => state.queue.uploadFile);

    const fetchNextPageItems = (page, perPage) => {
        dispatch(getAllQueueItemsAction({
            userId: userId,
            queue: selectedQueue._id,
            page: page ? page : 1,
            perPage: perPage ? perPage : defaultPerPage
        }
        ))
    }

    const handleContent = (title, message) => {
        setTitle(title);
        setMessage(message);
        dispatch(updateQueueAction({
            userId: userId,
            queqeuId: selectedQueue._id,
            title: title,
            message: message
        }))
    }
    const handleFiles = (uploadedFiles) => {
        setFiles(uploadedFiles)
        dispatch(uploadFileToQueueAction({ userId: userId, queue: selectedQueue._id, file: uploadedFiles }))
    }

    const handleCustomers = (customers) => {
        setCustomers(customers)
        dispatch(addCustomersQueueAction({
            user: userId,
            queue: selectedQueue._id,
            customers: customers
        }))
    }
    const handleStopQueue = () => {
        const onay = window.confirm('Silmek istediğinize emin misiniz?');

        if (onay) {
            //console.log('Öğe silindi!')
            // Kuyruk burada durdurulacak.
        } else {
            // console.log('Silme işlemi iptal edildi.');
        }
    }

    useEffect(() => {
        // eslint-disable-next-line no-const-assign, react-hooks/exhaustive-deps
        dispatch(getDraftsAction({ userId: get_local_storage_item("user").id }))
        dispatch(getByIdQueueAction({ userId: userId, queueId: selectedQueue._id }))
        fetchNextPageItems()
    }, [dispatch])
    useEffect(() => {
        if (isSuccess(queueState)) {

            setQueueFiles(queueState.data.data.files);
            setQueueInfo(queueState.data.data.queueDetail);
            fetchNextPageItems()
        }
        if (isFailure(queueState)) {

        }
        if (isLoading(queueState)) {
        }
    }, [dispatch, queueState]);
    useEffect(() => {
        if (isSuccess(queueItemsState)) {
            setQueueItems(queueItemsState.data.data)
            setQueueItemsPaginateData(queueItemsState.data.meta)
            let tempDataSource = [];
            queueItemsState.data.data.map((item) => {

                const data = {
                    id: item._id,
                    name: item.name,
                    phone: item.phone
                };
                tempDataSource.push(data);
            })
            setDataSource(tempDataSource);
        }
        if (isFailure(queueItemsState)) {
            //console.log(queueItemsState.error);
        }
        if (isLoading(queueItemsState)) {
        }
    }, [dispatch, queueItemsState]);
    useEffect(() => {
        if (isSuccess(getDraftsState)) {
            setDrafts(getDraftsState.data.data);
        }
        if (isFailure(getDraftsState)) {
        }
        if (isLoading(getDraftsState)) {

        }
        return () => {
            dispatch(resetGetCustomers());
        }
    }, [drafts, dispatch, getDraftsState])

    useEffect(() => {
        if (isSuccess(getDraftByIDState)) {
            setMessage(getDraftByIDState.data.data.text);
            dispatch(resetDraftGetByID());
        }
        if (isFailure(getDraftByIDState)) {
        }
        if (isLoading(getDraftByIDState)) {

        }
        return () => {
            dispatch(resetDraftGetByID());
        }
    }, [drafts, dispatch, getDraftByIDState]);

    useEffect(() => {
        if (isSuccess(updateQueueState)) {
            dispatch(getByIdQueueAction({ userId: userId, queueId: selectedQueue._id }));
            fireToast(updateQueueState.data.message, "SUCCESS", customTheme.theme)
        }
        if (isFailure(updateQueueState)) {
            fireToast(updateQueueState.error.message, "FAIL", customTheme.theme)

        }
        if (isLoading(updateQueueState)) {

        }
        return () => {
            dispatch(resetUpdateQueue());
        }
    }, [dispatch, updateQueueState])
    useEffect(() => {
        if (isSuccess(deleteQueueFileState)) {
            dispatch(getByIdQueueAction({ userId: userId, queueId: selectedQueue._id }));
            fireToast(deleteQueueFileState.data.message, "SUCCESS", customTheme.theme)
        }
        if (isFailure(deleteQueueFileState)) {
            fireToast(deleteQueueFileState.error.message, "FAIL", customTheme.theme)

        }
        if (isLoading(deleteQueueFileState)) {

        }
        return () => {
            dispatch(resetUpdateQueue());
        }
    }, [dispatch, deleteQueueFileState])
    useEffect(() => {
        if (isSuccess(uploadFileToQueueState)) {
            dispatch(getByIdQueueAction({ userId: userId, queueId: selectedQueue._id }));
            fireToast(uploadFileToQueueState.data.message, "SUCCESS", customTheme.theme)
        }
        if (isFailure(uploadFileToQueueState)) {
            fireToast(uploadFileToQueueState.error.message, "FAIL", customTheme.theme)

        }
        if (isLoading(uploadFileToQueueState)) {

        }
        return () => {
            dispatch(resetUploadFileToQueue());
        }
    }, [dispatch, uploadFileToQueueState])
    useEffect(() => {
        if (isSuccess(addCustomersToQueueState)) {
            fetchNextPageItems(queueItemsPaginateData.page);
            fireToast(addCustomersToQueueState.data.message, "SUCCESS", customTheme.theme)
        }
        if (isFailure(addCustomersToQueueState)) {
            fireToast(addCustomersToQueueState.error.message, "FAIL", customTheme.theme)

        }
        if (isLoading(addCustomersToQueueState)) {

        }
        return () => {
            dispatch(resetAddCustomersQueue());
        }
    }, [dispatch, addCustomersToQueueState]);
    useEffect(() => {
        if (isSuccess(removeCustomerFromQueueState)) {
            fetchNextPageItems(queueItemsPaginateData.page);
            fireToast(removeCustomerFromQueueState.data.message, "SUCCESS", customTheme.theme)
        }
        if (isFailure(removeCustomerFromQueueState)) {
            fireToast(removeCustomerFromQueueState.error.message, "FAIL", customTheme.theme)

        }
        if (isLoading(removeCustomerFromQueueState)) {

        }
        return () => {
            dispatch(resetAddCustomersQueue());
        }
    }, [dispatch, removeCustomerFromQueueState]);

    const deleteCustomer = (customerId) => {
        dispatch(removeCustomerQueueAction({ user: userId, queue: selectedQueue._id.toString(), customer: customerId }))
    }

    const truncateFileName = (fileName, maxLength) => {
        const fileExtension = fileName.split('.').pop(); // Dosya uzantısını al
        const fileNameWithoutExtension = fileName.replace(`.${fileExtension}`, ''); // Dosya adını uzantısız al
        let truncatedName = fileNameWithoutExtension.substring(0, maxLength); // Belirli bir uzunluğa kadar kısalt

        // Eğer kısaltılan isim, asıl dosya adından daha kısa ise uzantıyı tekrar ekle
        if (truncatedName.length < fileNameWithoutExtension.length) {
            truncatedName += `...${fileExtension}`;
        } else {
            truncatedName += `.${fileExtension}`;
        }

        return truncatedName;
    }
    const getFileExtension = (fileName) => {
        const parts = fileName.split('.');
        const iconPart = parts[parts.length - 1].toLowerCase();
        const showToIcon = getIconByExtension(iconPart);
        return showToIcon;
    };

    const getIconByExtension = (fileExtension) => {
        switch (fileExtension) {
            case 'mp3':
                return '/media/svg/files/mp3.svg';
            case 'jpg':
                return '/media/svg/files/jpg.svg';
            case 'jpeg':
                return '/media/svg/files/jpg.svg';
            case 'png':
                return '/media/svg/files/png-file.svg';
            case 'mp4':
                return '/media/svg/files/mp4.svg';
            case 'pdf':
                return '/media/svg/files/pdf.svg';
            case 'ogg':
                return '/media/svg/files/mp3.svg';
            case 'waptt':
                return '/media/svg/files/mp3.svg';
            default:
                return '/media/svg/files/folder-document-dark.svg'; // Varsayılan ikon
        }
    };

    const deleteFile = (fileName) => {
        dispatch(deleteFileQueueAction({
            user: userId,
            queue: selectedQueue._id,
            file: fileName
        }))
    };

    return (
        <div
            className='modal fade show d-block'
            id='kt_modal_add_draft'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
        >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-800px'>
                {/* begin::Modal content */}
                <div className='modal-content' style={{ display: 'flex', justifyContent: 'center' }}>
                    <ModalHeader
                        title={"Sıra Düzenleme"}
                        handleCloseModal={() => setIsShowQueueEditModal(false)}
                    />

                    {/* begin::Modal body */}
                    <div className={"d-flex flex-column p-8"}> {/* PENDING  IN_PROGRESS  PAUSED */}
                        {
                            selectedQueue.status === "PENDING" ? (
                                <>
                                    <div className={"d-flex justify-content-center mb-5"}>
                                        {
                                            tabs.map((item) => (
                                                <button
                                                    className={selectedTab === item.id
                                                        ? "btn btn-primary fw-bold px-3 py-2 me-3 fs-7 fs-sm-4"
                                                        : "btn btn-light me-3 px-3 py-2 fs-7 fs-sm-4"
                                                    }
                                                    key={item.id}
                                                    onClick={() => setSelectedTab(item.id)}
                                                >
                                                    {item.title}
                                                </button>
                                            ))
                                        }
                                    </div>
                                    <div className={"d-flex justify-content-center"}>
                                        {
                                            selectedTab === 1
                                                ? (
                                                    <Step1
                                                        defaultTitle={title}
                                                        defaultMessage={selectedQueue.queueMessage}
                                                        handleContent={handleContent}
                                                        customTheme={customTheme}
                                                        saveButtonShow={true}
                                                        prevButtonShow={true}
                                                    />
                                                )
                                                : selectedTab === 2
                                                    ? (
                                                        <div className={"d-flex flex-column w-100"}>
                                                            <div className={"card mt-10 mb-10 px-5"}>
                                                                <h2 className={"my-3"}>Dosyalar</h2>
                                                                <div className={"d-flex flex-column flex-md-row gap-3"}>
                                                                    {
                                                                        queueFiles && queueFiles.length > 0 && queueFiles.map((file, index) => (
                                                                            <div
                                                                                className={`d-flex flex-column align-items-center w-100 border py-5 px-2 rounded`}
                                                                                style={
                                                                                    customTheme.theme === "light"
                                                                                        ? { backgroundColor: customTheme.itemBg }
                                                                                        : { backgroundColor: customTheme.itemBg }
                                                                                }
                                                                                key={`${file.file_type}-${file.file_name}-${index}`}
                                                                            >
                                                                                <img
                                                                                    src={`${toAbsoluteUrl(getFileExtension(file.file_type))}`}
                                                                                    alt={`svg icons`}
                                                                                    style={{
                                                                                        maxWidth: '50px',
                                                                                        maxHeight: '50px',
                                                                                        marginBottom: 10,
                                                                                        objectFit: 'contain'
                                                                                    }}
                                                                                />
                                                                                <h6 className={"mb-0"}>{truncateFileName(file.file_name, 10)}</h6>
                                                                                <button
                                                                                    className={"btn btn-danger py-2 mt-3"}
                                                                                    onClick={() => deleteFile(file.file_name)}
                                                                                >
                                                                                    Sil
                                                                                </button>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                    {
                                                                        ((!queueFiles) || (queueFiles && queueFiles.length === 0)) && (
                                                                            <div
                                                                                className={"d-flex p-20 w-100 justify-content-center align-items-center border rounded"}
                                                                            >
                                                                                <h1>Ekli dosya yok!</h1>
                                                                            </div>
                                                                        )
                                                                    }


                                                                </div>
                                                            </div>
                                                            <Step2
                                                                handleEditFiles={handleFiles}
                                                                prevButtonShow={true}
                                                                saveButtonShow={true}
                                                            />
                                                        </div>

                                                    )
                                                    : (
                                                        <div>
                                                            <SmallCustomerTable
                                                                dataSource={dataSource}
                                                                deleteCustomer={deleteCustomer}
                                                                paginateData={queueItemsPaginateData}
                                                                fetchNextData={fetchNextPageItems}
                                                                customTheme={customTheme}
                                                                tableType="Edit"
                                                            />
                                                            <Step3
                                                                handleEditCustomers={handleCustomers}
                                                                saveButtonShow={true}
                                                                customTheme={customTheme}
                                                                tabVisible={true}
                                                            />
                                                        </div>
                                                    )
                                        }
                                    </div>
                                </>

                            ) : selectedQueue.status === "IN_PROGRESS"
                                ? (
                                    <>
                                        <div className={"d-flex justify-content-center mb-5"}>
                                            {
                                                tabs.map((item) => (
                                                    <button
                                                        className={"btn btn-primary fw-bold px-3 py-2 me-3 fs-7 fs-sm-4"}
                                                        key={item.id}
                                                        disabled={true}
                                                    >
                                                        {item.title}
                                                    </button>
                                                ))
                                            }
                                        </div>
                                        <div className={"d-flex justify-content-end mt-10"}>
                                            <button className={"btn btn-danger"} onClick={handleStopQueue}>
                                                Kuyruğu Duraklat
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className={"d-flex justify-content-center mb-5"}>
                                            {
                                                tabs.map((item) => (
                                                    <button
                                                        className={selectedTab === item.id
                                                            ? "btn btn-primary fw-bold px-3 py-2 me-3 fs-7 fs-sm-4"
                                                            : "btn btn-light me-3 px-3 py-2 fs-7 fs-sm-4"
                                                        }
                                                        key={item.id}
                                                        onClick={() => setSelectedTab(item.id)}
                                                    >
                                                        {item.title}
                                                    </button>
                                                ))
                                            }
                                        </div>
                                        <div className={"d-flex justify-content-center"}>
                                            {
                                                selectedTab === 1 ? (
                                                    <Step1
                                                        defaultTitle={title}
                                                        defaultMessage={selectedQueue.queueMessage}
                                                        handleContent={handleContent}
                                                        customTheme={customTheme}
                                                        saveButtonShow={true}
                                                        prevButtonShow={true}
                                                    />
                                                ) : selectedTab === 2 ? (
                                                    <div className={"d-flex flex-column w-100"}>
                                                        <div className={"card mt-10 mb-10 px-5"}>
                                                            <h2 className={"my-3"}>Dosyalar</h2>
                                                            <div className={"d-flex flex-column flex-md-row gap-3"}>
                                                                {
                                                                    queueFiles && queueFiles.length > 0 && queueFiles.map((file, index) => (
                                                                        <div
                                                                            className={`d-flex flex-column align-items-center w-100 border py-5 px-2 rounded`}
                                                                            style={
                                                                                customTheme.theme === "light"
                                                                                    ? { backgroundColor: customTheme.itemBg }
                                                                                    : { backgroundColor: customTheme.itemBg }
                                                                            }
                                                                            key={`${file.file_type}-${file.file_name}-${index}`}
                                                                        >
                                                                            <img
                                                                                src={`${toAbsoluteUrl(getFileExtension(file.file_type))}`}
                                                                                alt={`svg icons`}
                                                                                style={{
                                                                                    maxWidth: '50px',
                                                                                    maxHeight: '50px',
                                                                                    marginBottom: 10,
                                                                                    objectFit: 'contain'
                                                                                }}
                                                                            />
                                                                            <h6 className={"mb-0"}>{truncateFileName(file.file_name, 10)}</h6>
                                                                            <button
                                                                                className={"btn btn-danger py-2 mt-3"}
                                                                                onClick={() => deleteFile(file.file_name)}
                                                                            >
                                                                                Sil
                                                                            </button>
                                                                        </div>
                                                                    ))
                                                                }
                                                                {
                                                                    ((!queueFiles) || (queueFiles && queueFiles.length === 0)) && (
                                                                        <div
                                                                            className={"d-flex p-20 w-100 justify-content-center align-items-center border rounded"}
                                                                        >
                                                                            <h1>Ekli dosya yok!</h1>
                                                                        </div>
                                                                    )
                                                                }


                                                            </div>
                                                        </div>
                                                        <Step2
                                                            handleFiles={handleFiles}
                                                            prevButtonShow={true}
                                                            saveButtonShow={true}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <SmallCustomerTable
                                                            dataSource={dataSource}
                                                            deleteCustomer={deleteCustomer}
                                                            paginateData={queueItemsPaginateData}
                                                            fetchNextData={fetchNextPageItems}
                                                            customTheme={customTheme}
                                                            tableType="Edit"
                                                        />
                                                        <Step3
                                                            handleCustomers={handleCustomers}
                                                            saveButtonShow={true}
                                                            customTheme={customTheme}
                                                            tabVisible={true}
                                                        />
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </>
                                )
                        }
                    </div>
                    {/* end::Modal body */}

                </div>
                {/* end::Modal content */}

            </div>
            {/* end::Modal dialog */}

        </div>
    )
}

export default QueueEditModal;