import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";
import {createCustomerAction} from "../customers/add-customer";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const createDraftAction = createAsyncThunk('CREATE_DRAFT_ACTION', async (
    {
        userId,
        title,
        text
    }, {rejectWithValue}) => {
    try {
        const endpoint = `${userId}/drafts/create`;
        const body = {title, text};
        return await HttpApi.PostAsync(endpoint, true, body);
    } catch (err) {
        return rejectWithValue(err);
    }


})

const createDraftSlice = createSlice({
    name: 'create-draft',
    initialState,
    reducers: {
        resetCreateDraft: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(createDraftAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(createDraftAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(createDraftAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {createDraftAction};
export const { resetCreateDraft } = createDraftSlice.actions;

export default createDraftSlice.reducer;
