import React from "react";
import { KTIcon, toAbsoluteUrl } from "../../_metronic/helpers";
import { formatPhoneNumber } from "../../utilities/stringHelper";

const ActiveSessions = ({ sessions, customTheme }) => {
    return (
        <div className={"tab-content"}>
            <div className={"card pt-4 mb-6 mb-xl-9"}>
                <div className={"card-header border-0"}>
                    <div className={"card-title flex-column"}>
                        <h2>Aktif Oturumlar</h2>
                        <div className={"fs-6 fw-semibold text-muted"}>{sessions.length} Oturumlar</div>
                    </div>
                </div>
                <div className={"card-body pb-5 pt-0"}>
                    <div className="col-lg-12">
                        <div className="d-flex flex-column border rounded p-5">
                            <div className="d-flex align-items-center justify-content-center flex-wrap">
                                {
                                    sessions && sessions.map((session) => (
                                        <div
                                            className={"d-flex flex-column align-items-center justify-content-center p-5 border rounded m-3"}
                                            key={session.sessionName}
                                        >
                                            <img
                                                src={`${toAbsoluteUrl('/media/icons/duotune/social/whatsapp.png')}`}
                                                alt={`svg icons`}
                                                style={{
                                                    maxWidth: '75px',
                                                    maxHeight: '75px',
                                                    marginBottom: '10px',
                                                    objectFit: 'contain',
                                                }}
                                            />
                                            <h3 style={{ margin: '0' }}>{formatPhoneNumber(session.phone)}</h3>
                                        </div>
                                    ))
                                }
                                {
                                    sessions.length === 0 && (
                                        <div className={"d-flex flex-column justify-content-center align-items-center p-10 border rounded"}>
                                            <KTIcon iconName="cross-circle" className={"bigger-icon mb-3"} />
                                            <h2 className={"mb-0"}>Bu kullanıcının şu anda etkin bir oturumu yok.</h2>
                                        </div>
                                    )
                                }
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActiveSessions;