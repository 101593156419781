import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";


const initialState = {
  isLoading: false,
  isSuccess: false,
  isFailure: false,
  data: {},
  error: {}
};

const deleteUserAction = createAsyncThunk('DELETE_USER_ACTION', async ({ userId }, { rejectWithValue }) => {
  try {
    const endpoint = `admin/user/${userId}/delete`;
    return await HttpApi.DeleteAsync(endpoint, true);
  }
  catch (err) {
    return rejectWithValue(err);
  }
})


const deleteUserSlice = createSlice({
  name: 'delete-user',
  initialState,
  reducers: {
    resetDeleteUser: ()=>{return initialState;}
  },
  extraReducers: (builder) => {
    builder.addCase(deleteUserAction.pending, (state) => {
      state.isFailure = false;
      state.isLoading = true;
      state.isSuccess = false;
      state.data = {};
    })
      .addCase(deleteUserAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isFailure = false;
        state.data = action.payload;
      })
      .addCase(deleteUserAction.rejected, (state, action) => {
        state.isFailure = true;
        state.isSuccess = false;
        state.isLoading = false;
        state.error = action.payload;
      })
  }
})

export { deleteUserAction };
export const {resetDeleteUser} = deleteUserSlice.actions;
export default deleteUserSlice.reducer;
