import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";

const initialState = {
    isLoading: false,
    isFailure: false,
    isSuccess: false,
    data: {},
    error: {}
}

const updateCreditAmountsAction = createAsyncThunk("UPDATE_CREDIT_AMOUNTS_ACTION", async (
    {
        creditAmount
    }, { rejectWithValue }) => {    
    try {
        let endpoint = `admin/settings/defaults/update/credit-amount`;
        const response = await HttpApi.PatchAsync(endpoint, true, {creditAmount});
        return response;
    } catch (error) {
        return rejectWithValue(error)
    }
})

const updateCreditAmountsSlice = createSlice({
    name: 'update-credit-amount',
    initialState,
    reducers: {
        resetUpdateCreditAmounts: (state) => { return initialState }
    },
    extraReducers: (builder) => {
        builder.addCase(updateCreditAmountsAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
        .addCase(updateCreditAmountsAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
                state.error = {};
        })
        .addCase(updateCreditAmountsAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
                state.data = {};
        })
    }
});

export {updateCreditAmountsAction};
export const {resetUpdateCreditAmounts} = updateCreditAmountsSlice.actions;
export default updateCreditAmountsSlice.reducer;