import React from 'react'
import ModalHeader from '../ModalHeader'
import { KTIcon } from '../../../_metronic/helpers'

const DraftInfoModal = ({ setIsShowDraftInfoModal, selectedDraft }) => {

    return (
        <div
            className='modal fade show d-block'
            id='kt_modal_user_info'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
        >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-700px'>
                {/* begin::Modal content */}
                <div className='modal-content' style={{ display: 'flex', justifyContent: 'center' }}>
                    <ModalHeader
                        title="Taslak Icerigi"
                        handleCloseModal={() => setIsShowDraftInfoModal(false)}
                    />

                    {/* begin::Modal body */}
                    <div className={"modal-body scroll-y mx-5 mx-xl-15 my-7"}>
                        <div className='card'>
                            <div
                                className={"d-flex flex-column "}
                            >
                                <h4>Başlık</h4>
                                <input
                                    className={"form-control form-control-solid w-100 mb-5"}
                                    placeholder="Mesaj başlığı.."
                                    value={selectedDraft.title}
                                    disabled={true}
                                />
                            </div>
                            <div className={"d-flex flex-column mt-3"}>
                                <h4>Mesaj</h4>
                                <div className={"d-flex justify-content-center pt-2 pb-2 border rounded-top-3 light"}>
                                    <CustomButton
                                        iconName="text-bold"
                                    />
                                    <CustomButton
                                        iconName="text-italic"
                                    />
                                    <CustomButton
                                        iconName="text-strikethrough"
                                    />
                                    <CustomButton
                                        iconName="text-number"
                                    />
                                    <CustomButton
                                        iconName="text-circle"
                                    />
                                    <CustomButton
                                        iconName="emoji-happy"
                                    />
                                </div>
                                <textarea
                                    rows={6}
                                    className={"form-control form-control-solid w-100 mb-5"}
                                    value={selectedDraft.text}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    {/* end::Modal body */}

                </div>
                {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}

        </div>
    )
}

const CustomButton = ({ iconName }) => {
    return (
        <button
            style={{ marginRight: 5, backgroundColor: "transparent", border: "none" }}
            disabled={true}>
            <KTIcon
                iconName={iconName}
                iconType="duotone"
                className={"bigger-icon"}
            />
        </button>
    )
}

export default DraftInfoModal