import React, { useEffect, useState } from 'react'
import ModalHeader from './ModalHeader'
import { formatPhoneNumber } from '../../utilities/stringHelper'
import { useDispatch, useSelector } from 'react-redux'
import { isFailure, isLoading, isSuccess } from '../../utilities/reduxStateHelper'
import { userDetailsAction } from '../../services/redux/profile/user-details'

const SessionSelectModal = ({ setIsSelectPhoneModal, user, sessions, selectedPhoneNumber, setSelectedPhoneNumber, handleSelectedPhone }) => {
    const [allPhones, setAllPhones] = useState([])
    const [profileDetailsData, setProfileDetailsData] = useState({});
    const profileDetailsState = useSelector((state) => state.profile.profileDetails);
    const dispatch = useDispatch();
    const [sessionsPhone, setSessionsPhone] = useState([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleAllPhones = (data) => {

        let phones = []
        let sessionsPhone = []
        phones.push(user.phone)
        if (data.extraPhoneData.phones && data.extraPhoneData.phones.length > 0) {
            data.extraPhoneData.phones.map((phone) => {
                phones.push(phone.phone)
            })
            sessions.map((item) => {
                sessionsPhone.push(item.phone)
            })
        }
        setSessionsPhone(sessionsPhone);
        setAllPhones(phones);
    }

    useEffect(() => {
        dispatch(userDetailsAction())
    }, [dispatch])

    useEffect(() => {
        if (isSuccess(profileDetailsState)) {
            setProfileDetailsData(profileDetailsState.data.data)
            handleAllPhones(profileDetailsState.data.data)
        }
        if (isLoading(profileDetailsState)) {

        }
        if (isFailure(profileDetailsState)) {

        }
    }, [profileDetailsState, profileDetailsData, dispatch, handleAllPhones])
    return (
        <div
            className='modal fade show d-block'
            id='kt_modal_session_select'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
        >
            <div className='modal-dialog modal-dialog-centered mw-650px'>
                <div
                    className='modal-content'
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <ModalHeader
                        title={"Telefon Numarası Seç"}
                        handleCloseModal={() => setIsSelectPhoneModal(false)}
                    />
                    <div
                        style={{ padding: 30 }}
                        className='card d-flex align-items-center fw-bold fs-3'
                    >
                        <div>
                            <span>Oturum açmak istediginiz telefon numarasını seçiniz</span>
                        </div>
                        <small className='mb-10 mt-2 fs-6 fw-light'>Devam edebilmek için bu alan zorunludur.</small>

                        <div className='d-flex flex-column w-100 align-items-center'>
                            {
                                allPhones && allPhones.length > 0 && allPhones.map((phone, index) => (
                                    <div key={index} className='mb-5'>
                                        <button
                                            className={`btn 
                                                ${selectedPhoneNumber === phone
                                                    ? "btn-primary"
                                                    : "btn-light"}
                                            `}
                                            onClick={() => {
                                                setSelectedPhoneNumber(phone)
                                            }}
                                            disabled={sessionsPhone.includes(phone) ? true : false}
                                            type={'button'}
                                        >

                                            {formatPhoneNumber(phone)}
                                        </button>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div className='d-flex justify-content-end me-5 mb-5'>
                        <button className='btn btn-primary' onClick={handleSelectedPhone} disabled={!selectedPhoneNumber}>
                            Oturum Olustur
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SessionSelectModal