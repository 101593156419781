import React, { useCallback, useEffect, useState } from 'react'
import { KTIcon } from '../../_metronic/helpers';
import UserInfoModal from '../Modals/UserInfoModal';
import RemoveUserFromListModal from '../Modals/RemoveUserFromListModal';
import { useDispatch, useSelector } from 'react-redux';
import { isFailure, isLoading, isSuccess } from '../../utilities/reduxStateHelper';
import { getGrayListCustomersAction, resetGetGrayListCustomers } from '../../services/redux/customers/graylist/get-graylist';
import { get_local_storage_item } from '../../utilities';
import { fireToast } from '../../utilities/toastHelper';
import { useTheme } from '../../services/themeService';
import { removeFromGrayListAction, resetRemoveGrayListCustomer } from '../../services/redux/customers/graylist/remove-from-graylist';
import { getBlacklistCustomersAction, resetGetBlackListCustomers } from '../../services/redux/customers/blacklist/get-blacklist';
import { removeFromBlackListAction, resetRemoveBlackListCustomer } from '../../services/redux/customers/blacklist/remove-from-blacklist';
import { ConfigProvider, Pagination } from 'antd';
import BulkUserAdditionModal from '../Modals/BulkUserAdditionModal';
import { getCustomersAction, resetGetCustomers } from '../../services/redux/customers/get-customers';
import { multiAddToGrayListAction, resetMultiAddGrayListCustomer } from '../../services/redux/customers/graylist/multi-add-grayList';
import { multiAddToBlackListAction, resetMultiAddBlackListCustomer } from '../../services/redux/customers/blacklist/multi-add-blackList';

const GrayandBlackListTable = () => {
    const { theme } = useTheme();
    const dispathcer = useDispatch();
    const getGrayListCustomersState = useSelector((state) => state.grayList.getGrayListCustomers)
    const deleteGrayListCustomerState = useSelector((state) => state.grayList.removeGrayListCustomer)
    const getBlackListCustomerState = useSelector((state) => state.blackList.getBlackListCustomers)
    const deleteBlackListCustomerState = useSelector((state) => state.blackList.removeBlackListCustomer)
    const getCustomersState = useSelector((state) => state.customer.getCustomers);
    const multiAddGrayListState = useSelector((state) => state.grayList.multiAddGrayList);
    const multiAddBlackListState = useSelector((state) => state.blackList.multiAddBlackList)

    const [selectedUser, setSelectedUser] = useState();

    const [grayList, setGrayList] = useState([]);
    const [blackList, setBlackList] = useState([]);
    const [customers, setCustomers] = useState();

    const [grayPaginateData, setGrayPaginateData] = useState({});
    const [blackPaginateData, setBlackPaginateData] = useState({});
    const [paginateData, setPaginateData] = useState({});

    const [defaultPerPage, setDefaultPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [requested, setRequested] = useState(false)
    const [selectedTab, setSelectedTab] = useState("grayList");
    const [searchTerm, setSearchTerm] = useState("");

    const [isShowUserInfoModal, setIsShowUserInfoModal] = useState(false);
    const [isShowRemoveUserFromListModal, setIsShowRemoveUserFromListModal] = useState(false);
    const [isShowBulkUserAddModal, setIsShowBulkUserAddModal] = useState(false);

    const grayFetchNextData = useCallback((page, perPage) => {
        dispathcer(getGrayListCustomersAction({ userId: get_local_storage_item("user").id, page: page, perPage: perPage ? perPage : defaultPerPage }))
    })

    const blackFetchNextData = useCallback((page, perPage) => {
        dispathcer(getBlacklistCustomersAction({ userId: get_local_storage_item("user").id, page: page, perPage: perPage ? perPage : defaultPerPage }))
    })

    const fetchNextData = useCallback((page, pageSize) => {
        dispathcer(getCustomersAction({ userId: get_local_storage_item("user").id, page: page, perPage: pageSize ? pageSize : defaultPerPage }));
    })

    // Remove User From Gray List 
    const deleteGrayListCustomer = (customerId) => {
        dispathcer(removeFromGrayListAction({ userId: get_local_storage_item("user").id, customerId: customerId }))
    }
    // Remove User From Black List 
    const deleteBlackListCustomer = (customerId) => {
        dispathcer(removeFromBlackListAction({ userId: get_local_storage_item("user").id, customerId: customerId }))
    }

    // gri listeye ekleme islemi icin.
    const grayListAdded = (customers) => {
        dispathcer(multiAddToGrayListAction({
            userId: get_local_storage_item("user").id,
            customers: customers
        }))
    }

    // kara listeye eklemek için.
    const blackListAdded = (customers) => {
        dispathcer(multiAddToBlackListAction({
            userId: get_local_storage_item("user").id,
            customers: customers
        }))
    }

    useEffect(() => {
        if (!requested) {
            dispathcer(getGrayListCustomersAction({
                userId: get_local_storage_item("user").id,
                page: 1,
                perPage: defaultPerPage
            }))
            dispathcer(getBlacklistCustomersAction({
                userId: get_local_storage_item("user").id,
                page: 1,
                perPage: defaultPerPage
            }))
            dispathcer(getCustomersAction({
                userId: get_local_storage_item("user").id,
                page: 1,
                perPage: defaultPerPage
            }))
            setRequested(true)
        }
    }, [defaultPerPage, dispathcer, requested])

    useEffect(() => {
        if (isSuccess(getGrayListCustomersState)) {
            setGrayList(getGrayListCustomersState.data.data);
            setGrayPaginateData(getGrayListCustomersState.data.meta);
            setLoading(false)
        }
        if (isFailure(getGrayListCustomersState)) {
        }
        if (isLoading(getGrayListCustomersState)) {
            setLoading(true)
        }
        return () => {
            dispathcer(resetGetGrayListCustomers())
        }
    }, [grayList, defaultPerPage, dispathcer, getGrayListCustomersState])

    useEffect(() => {
        if (isSuccess(deleteGrayListCustomerState)) {
            fireToast(deleteGrayListCustomerState.data.message, "SUCCESS", theme.theme)
            grayFetchNextData(grayPaginateData.page)
            dispathcer(getCustomersAction({
                userId: get_local_storage_item("user").id,
                page: 1,
                perPage: defaultPerPage
            }))
        }
        if (isFailure(deleteGrayListCustomerState)) {
            fireToast(deleteGrayListCustomerState.data.message, "FAIL", theme.theme)
        }
        return () => {
            dispathcer(resetRemoveGrayListCustomer())
        }
    }, [dispathcer, deleteGrayListCustomerState])

    useEffect(() => {
        if (isSuccess(getBlackListCustomerState)) {
            setBlackList(getBlackListCustomerState.data.data)
            setBlackPaginateData(getBlackListCustomerState.data.meta)
            setLoading(false)
        }
        if (isFailure(getBlackListCustomerState)) {

        }
        if (isLoading(getBlackListCustomerState)) {
            setLoading(true)
        }
        return () => {
            dispathcer(resetGetBlackListCustomers())
        }
    }, [blackList, defaultPerPage, dispathcer, getBlackListCustomerState])

    useEffect(() => {
        if (isSuccess(deleteBlackListCustomerState)) {
            fireToast(deleteBlackListCustomerState.data.message, "SUCCESS", theme.theme)
            blackFetchNextData(blackPaginateData.page)
            dispathcer(getCustomersAction({
                userId: get_local_storage_item("user").id,
                page: 1,
                perPage: defaultPerPage
            }))
        }
        if (isFailure(deleteBlackListCustomerState)) {
            fireToast(deleteBlackListCustomerState.data.message, "FAIL", theme.theme)
        }
        return () => {
            dispathcer(resetRemoveBlackListCustomer())
        }
    }, [blackFetchNextData, blackPaginateData.page, defaultPerPage, deleteBlackListCustomerState, dispathcer, theme.theme])

    useEffect(() => {
        if (isSuccess(getCustomersState)) {
            setCustomers(getCustomersState.data.data);
            setPaginateData(getCustomersState.data.meta);
        }
        if (isFailure(getCustomersState)) {
        }
        if (isLoading(getCustomersState)) {

        }
        return () => {
            dispathcer(resetGetCustomers());
        }
    }, [dispathcer, getCustomersState])

    useEffect(() => {
        if (isSuccess(multiAddGrayListState)) {
            fireToast(multiAddGrayListState.data.message, "SUCCESS", theme.theme);
            dispathcer(getGrayListCustomersAction({
                userId: get_local_storage_item("user").id,
                page: 1,
                perPage: defaultPerPage
            }))
        }
        if (isFailure(multiAddGrayListState)) {
            fireToast(multiAddGrayListState.error.message, "FAIL", theme.theme);
        }
        return () => {
            dispathcer(resetMultiAddGrayListCustomer())
        }
    }, [defaultPerPage, dispathcer, multiAddGrayListState, theme.theme])

    useEffect(() => {
        if (isSuccess(multiAddBlackListState)) {
            fireToast(multiAddBlackListState.data.message, "SUCCESS", theme.theme);
            dispathcer(getBlacklistCustomersAction({
                userId: get_local_storage_item("user").id,
                page: 1,
                perPage: defaultPerPage
            }))
        }
        if (isFailure(multiAddBlackListState)) {
            fireToast(multiAddBlackListState.error.message, "FAIL", theme.theme);
        }
        return () => {
            dispathcer(resetMultiAddBlackListCustomer())
        }
    }, [dispathcer, multiAddBlackListState])

    return (
        <div className='card card-xl-stretch mb-xl-8 border rounded'>

            {/* begin::Header */}
            <div className='card-header align-items-center border-0 mt-4'>
                <div className={"card-title"}>
                    <div className={"d-flex align-items-center my-1"}>
                        <h2>
                            {selectedTab === "grayList" ? "Gri Liste" : "Kara Liste"}
                        </h2>
                    </div>
                </div>
                <div>
                    <button
                        className={`btn me-3 ${selectedTab === "grayList" ? "btn-primary p-3" : "btn-light p-3"}`}
                        onClick={() => {
                            setSelectedTab("grayList")
                        }}
                    >
                        Gri Liste
                    </button>
                    <button
                        className={`btn ${selectedTab === "blackList" ? "btn-primary p-3" : "btn-light p-3"}`}
                        onClick={() => {
                            setSelectedTab("blackList")
                        }}
                    >
                        Kara Liste
                    </button>
                </div>
            </div>
            {/* end::Header */}

            {/* begin::Body */}
            <div className={"card-body py-4 mb-3"}>
                <div className={"dataTables_wrapper dt-bootstrap4 no-footer"}>
                    <div className={"table-responsive"}
                        style={{
                            maxHeight: 350,
                            overflowY: "auto"
                        }}
                    >
                        <table className={"table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"}>
                            <thead>
                                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                                    <th
                                        className='min-w-125px sorting'
                                        tabIndex="0"
                                        rowSpan="1"
                                        colSpan="1"
                                        style={{ width: "150px" }}
                                    >
                                        Ad
                                    </th>
                                    <th
                                        className='min-w-125px sorting'
                                        tabIndex="0"
                                        rowSpan="1"
                                        colSpan="1"
                                        style={{ width: "150px" }}
                                    >
                                        Soyad
                                    </th>
                                    <th
                                        className={"text-end min-w-100px sorting_disabled"}
                                        tabIndex="0"
                                        rowSpan="1"
                                        colSpan="1"
                                        style={{ width: "122.575px" }}
                                    >
                                        Aksiyonlar
                                    </th>
                                </tr>
                            </thead>
                            <tbody className={"text-gray-600 fw-semibold"}>
                                {
                                    selectedTab === "grayList" ? (
                                        grayList.length === 0
                                            ? (
                                                <tr className='mt-5 fw-bold'>
                                                    <td colSpan="3" className='mt-5 fw-bold'>
                                                        Gri listeye eklenmiş birisi bulunmamaktadır.
                                                    </td>
                                                </tr>
                                            ) : (
                                                grayList.map((data) => (
                                                    <tr key={data.id}>
                                                        <td>
                                                            <div>{data.name}</div>
                                                        </td>
                                                        <td>
                                                            <div>{data.lastName}</div>
                                                        </td>
                                                        <td className={"text-end"}>
                                                            <div>
                                                                <button
                                                                    className='btn btn-icon btn-active-light-primary w-30px h-30px ms-auto'
                                                                    type='button'
                                                                    onClick={() => {
                                                                        //setSelectedUser(grayAndBlackListData.filter(item => item.id === data.id))
                                                                        setSelectedUser(data)
                                                                        setIsShowUserInfoModal(true)
                                                                    }}
                                                                >
                                                                    <KTIcon iconName="information" className={"fs-2"} />
                                                                </button>
                                                                <button
                                                                    className='btn btn-icon btn-active-light-primary w-30px h-30px ms-auto'
                                                                    type='button'
                                                                    onClick={() => {
                                                                        setSelectedUser(data)
                                                                        setIsShowRemoveUserFromListModal(true)
                                                                    }}
                                                                >
                                                                    <KTIcon iconName="minus-circle" className={"fs-2 text-danger"} />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            )
                                    ) : (
                                        blackList.length === 0
                                            ? (
                                                <tr className='mt-5 fw-bold'>
                                                    <td colSpan="3" className='mt-5 fw-bold'>
                                                        Kara listeye eklenmiş birisi bulunmamaktadır.
                                                    </td>
                                                </tr>
                                            )
                                            : (
                                                blackList.map((data) => (
                                                    <tr key={data.id}>
                                                        <td>
                                                            <div>{data.name}</div>
                                                        </td>
                                                        <td>
                                                            <div>{data.lastName}</div>
                                                        </td>
                                                        <td className={"text-end"}>
                                                            <div>
                                                                <button
                                                                    className='btn btn-icon btn-active-light-primary w-30px h-30px ms-auto'
                                                                    type='button'
                                                                    onClick={() => {
                                                                        //setSelectedUser(grayAndBlackListData.filter(item => item.id === data.id))
                                                                        setSelectedUser(data)
                                                                        setIsShowUserInfoModal(true)
                                                                    }}
                                                                >
                                                                    <KTIcon iconName="information" className={"fs-2"} />
                                                                </button>
                                                                <button
                                                                    className='btn btn-icon btn-active-light-primary w-30px h-30px ms-auto'
                                                                    type='button'
                                                                    onClick={() => {
                                                                        setSelectedUser(data)
                                                                        setIsShowRemoveUserFromListModal(true)
                                                                    }}
                                                                >
                                                                    <KTIcon iconName="minus-circle" className={"fs-2 text-danger"} />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            )
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 10,
                        alignItems: 'center'
                    }}
                >
                    <div>
                        <button
                            onClick={() => {
                                setIsShowBulkUserAddModal(true)
                            }}
                            className='btn btn-primary px-4 py-2'
                        >
                            Toplu Ekle
                        </button>
                    </div>
                    <ConfigProvider
                        theme={{
                            token: {
                                colorBgContainer: theme.backgroundColor,
                                colorText: theme.color
                            }
                        }}
                    >
                        <Pagination
                            locale={{ items_per_page: '/ sayfa' }}
                            showSizeChanger
                            onShowSizeChange={(current, pageSize) => {
                                selectedTab === "grayList"
                                    ? grayFetchNextData(current, pageSize)
                                    : blackFetchNextData(current, pageSize)
                            }}
                            pageSizeOptions={[10, 50, 500, 1000, 5000]}
                            defaultCurrent={1}
                            total={selectedTab === "grayList" ? grayPaginateData.totalItems : blackPaginateData.totalItems}
                            pageSize={selectedTab === "grayList" ? grayPaginateData.perpage : blackPaginateData.perpage}
                            className={theme.theme === "light" ? "light-theme" : "dark-theme"}
                            onChange={(page, pageSize) => {
                                selectedTab === "grayList"
                                    ? grayFetchNextData(page, pageSize)
                                    : blackFetchNextData(page, pageSize)
                            }}
                        />
                    </ConfigProvider>
                </div>
            </div>
            {/* end::Body */}

            {isShowUserInfoModal && (
                <UserInfoModal
                    setIsShowUserInfoModal={setIsShowUserInfoModal}
                    selectedUser={selectedUser}
                    selectedTab={selectedTab}
                    deleteGrayListCustomer={deleteGrayListCustomer}
                    deleteBlackListCustomer={deleteBlackListCustomer}
                />
            )}

            {isShowUserInfoModal && <div className='modal-backdrop fade show'></div>}

            {isShowRemoveUserFromListModal && (
                <RemoveUserFromListModal
                    setIsShowRemoveUserFromListModal={setIsShowRemoveUserFromListModal}
                    selectedUser={selectedUser}
                    deleteGrayListCustomer={deleteGrayListCustomer}
                    deleteBlackListCustomer={deleteBlackListCustomer}
                    selectedTab={selectedTab}
                />
            )}

            {isShowRemoveUserFromListModal && <div className='modal-backdrop fade show'></div>}

            {isShowBulkUserAddModal && (
                <BulkUserAdditionModal
                    setIsShowBulkUserAddModal={setIsShowBulkUserAddModal}
                    customers={customers}
                    fetchNextData={fetchNextData}
                    grayListAdded={grayListAdded}
                    blackListAdded={blackListAdded}
                    paginateData={paginateData}
                    customTheme={theme}
                />
            )}

            {isShowBulkUserAddModal && <div className='modal-backdrop fade show'></div>}
        </div>
    )
}

export default GrayandBlackListTable