import React from "react";

const CreditInformation = ({currentCreditData}) => {
    return (
       currentCreditData && (
        <div className={"tab-content"}>
            <div className={"card card-flush mb-6 mb-xl-9"}>
                <div className={"card-header mt-6"}>
                    <div className={"card-title"}>
                        <h2 className={"mb-1"}>Kullanıcı Kredi Bilgileri</h2>
                    </div>
                </div>
                <div className={"card-body p-9 pt-4"}>
                    <div className={"table-responsive"}>
                        <table className={"table align-middle table-row-dashed gy-5"}>
                            <tbody className={"fs-6 fw-semibold text-gray-600"}>
                                <tr>
                                    <td>Harcanan Krediler</td>
                                    <td className={"text-end me-5"}>{currentCreditData.spentCredit}</td>
                                </tr>
                                <tr>
                                    <td>Kalan Krediler</td>
                                    <td className={"text-end me-5"}>{currentCreditData.currentCredit}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
       )
    )
}

export default CreditInformation;