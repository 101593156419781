import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { get_local_storage_item } from '../../../utilities';
import { useDispatch, useSelector } from 'react-redux';
import { updateProfileAction, resetUpdateProfile } from '../../../services/redux/profile/update-profile';
import { isFailure, isLoading, isSuccess } from '../../../utilities/reduxStateHelper';
import { fireToast } from '../../../utilities/toastHelper';
import { userDetailsAction } from '../../../services/redux/profile/user-details';
import { formatPhoneNumber } from '../../../utilities/stringHelper';

const profileDetailsSchema = Yup.object().shape({
  fName: Yup.string().required('İlk isim gereklidir'),
  lName: Yup.string().required('Soyadı gereklidir'),
  contactPhone: Yup.string().required('İletişim telefonu gereklidir'),
})
const ProfileDetails = ({ customTheme }) => {
  const [user, setUser] = useState(get_local_storage_item("user"));
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.profile.updateProfile);
  const [imageBase64, setImageBase64] = useState('');


  const initialValues = {
    avatar: user.avatar,
    fName: user.firstName,
    lName: user.lastName,
    contactPhone: formatPhoneNumber(user && user.phone),
  }

  const [data, setData] = useState(initialValues)

  const updateData = (fieldsToUpdate) => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    dispatch(updateProfileAction({
      userId: user.id,
      avatar: imageBase64 ? imageBase64 : user.avatar,
      firstName: updatedData.fName,
      lastName: updatedData.lName
    }))
  }

  const [loadingUpdate, setLoadingUpdate] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      //update profile details post request
      updateData(values)
    },
  })

  useEffect(() => {
    if (isSuccess(selector)) {
      setLoadingUpdate(false);
      fireToast(selector.data.message, "SUCCESS", customTheme.theme)
      dispatch(userDetailsAction());
    }

    if (isFailure(selector)) {
      setLoadingUpdate(false);
      fireToast(selector.error.message, "FAIL", customTheme.theme)
    }

    if (isLoading(selector)) {
      setLoadingUpdate(true);
    }
    return () => {
      dispatch(resetUpdateProfile());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selector, setLoadingUpdate])

  const compressAndConvertToBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Resmi sıkıştırarak canvas'a çiz
          const maxWidth = 800; // İstediğiniz genişlik değeri
          const maxHeight = 600; // İstediğiniz yükseklik değeri
          const scaleFactor = Math.min(maxWidth / img.width, maxHeight / img.height);
          canvas.width = img.width * scaleFactor;
          canvas.height = img.height * scaleFactor;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          // Canvas'taki resmi base64'e dönüştür
          const compressedBase64 = canvas.toDataURL('image/jpeg'); // JPEG formatında döndürdük
          resolve(compressedBase64);
        };
      };

      reader.readAsDataURL(file);
    });
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];

    if (file) {
      // Resmi sıkıştırmak ve base64'e dönüştürmek için özel bir fonksiyon kullanma
      const compressedBase64 = await compressAndConvertToBase64(file);
      setImageBase64(compressedBase64)
    }
  };

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profil Detayları</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
              <div className='d-flex flex-column flex-sm-row col-lg-8'>
                <label
                  htmlFor="upload-input"
                  className="custom-file-upload"
                  style={{
                    marginTop: 10
                  }}
                >
                  <input
                    id="upload-input"
                    type="file"
                    onChange={handleImageUpload}
                    style={{
                      display: 'none', // Önemi: Stili özelleştirmek için görünürlüğü none yaparız, asıl etkileşimi label ile sağlarız
                    }}
                  />
                  <span style={{
                    padding: '10px 15px',
                    backgroundColor: '#009EF7',
                    color: '#fff',
                    borderRadius: '5px',
                    cursor: 'pointer'
                  }}>Resim Yükle</span>
                </label>
                {imageBase64 && (
                  <div className='mt-10 mt-sm-0 ms-0 ms-sm-10 ' style={{
                    display: "flex",
                    alignItems: 'start',
                  }}>
                    <img
                      className={"w-125px h-125px"}
                      style={{ marginRight: 10 }}
                      src={imageBase64}
                      alt="Uploaded"
                    />
                    <button
                      onClick={() => setImageBase64("")}
                      style={{
                        backgroundColor: "red",
                        border: "none",
                        padding: "3px 15px",
                        borderRadius: 10
                      }}
                    >Sil</button>
                  </div>

                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Ad Soyad</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Ad Soyad'
                      {...formik.getFieldProps('fName')}
                    />
                    {formik.touched.fName && formik.errors.fName && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.fName}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Soyadı'
                      {...formik.getFieldProps('lName')}
                    />
                    {formik.touched.lName && formik.errors.lName && (
                      <div className='fv-plugins-message-container' placeholder='Soyadı'>
                        <div className='fv-help-block'>{formik.errors.lName}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>İletişim Telefonu</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  disabled={true}
                  type='text'
                  className='form-control form-control-lg form-control-solid disabled'
                  placeholder='İletişim Telefonu'
                  {...formik.getFieldProps('contactPhone')}
                />
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loadingUpdate}>
              {!loadingUpdate && 'Kaydet'}
              {loadingUpdate && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Lütfen Bekleyin...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export { ProfileDetails }
