import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react'
import { SessionEditModalHeader } from './SessionEditModalHeader'
import QRCode from "react-qr-code";
import { io } from "socket.io-client";
import { fireToast } from '../../../utilities/toastHelper';
import { KTIcon, toAbsoluteUrl } from "../../../_metronic/helpers";
import { useTheme } from "../../../services/themeService";
import SessionDeleteConfirmationModal from "./SessionDeleteModal";
import { formatPhoneNumber } from '../../../utilities/stringHelper';
import { checkSessionPath, qrCodeSocketPath } from '../../../utilities/requestBuilder';
import SessionSelectModal from '../../Modals/SessionSelectModal';
import {Spin} from "antd"
const Session = ({ user, sessions, getSessions }) => {
    const { theme } = useTheme();
    const [qrCode, setQrCode] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmationModal, setIsConfirmationModal] = useState(false);
    const [selectedSessionName, setSelectedSessionName] = useState("");
    const [isSelectPhoneModal, setIsSelectPhoneModal] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [sessionLoading, setSessionLoading] = useState(false);
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [shouldCheckSession, setShouldCheckSession] = useState(false);
    const [isCheckingSession, setIsCheckingSession] = useState(false);
    const [checkSessionName, setCheckSessionName] = useState("");
    // eslint-disable-next-line react-hooks/exhaustive-deps

    const handleSelectedPhone = () => {
        setIsSelectPhoneModal(false)
        handleOpenModal();
    }
    const qrCodeSocket = io(`${qrCodeSocketPath}`, {
        path: "/socket.io",
        extraHeaders: { "x-api-gateway": "test1" },
        secure: true,
        autoConnect: false
    });
    const checkSessionSocket = io(`${checkSessionPath}`, {
        path: "/socket.io",
        extraHeaders: { "x-api-gateway": "test1" },
        secure: true,
        autoConnect: false
    });
    const onSendQrCode = (data) => {
        setQrCode(data.qr);
    };

    const onWrongNumber = (data, qrSocket) => {
        fireToast(data.message, "FAIL", theme.theme)
        setQrCode(undefined)
        qrSocket.emit("quit")
        qrSocket.disconnect()
        setIsModalOpen(false)
        setLoading(false);
    };

    const onConnectionSuccess = (data, qrSocket) => {
        fireToast(data.message, "SUCCESS", theme.theme)
        setQrCode(undefined)
        qrSocket.emit("quit")
        qrSocket.disconnect()
        setIsModalOpen(false)
        getSessions()
        setLoading(false);
    };
    const onLoading = (data) => {
        if(data.loading !== loading)
            setLoading(data.loading);
    }
    const handleCloseModal = () => {
        setQrCode("");
        window.location.reload()
        setIsModalOpen(false)

    };


    const onSessionAvaliable = (data) => {
        fireToast(data.message, "SUCCESS", theme.theme)
        checkSessionSocket.disconnect();
        setIsCheckingSession(false);
        setShouldCheckSession(false);
        checkSessionSocket.disconnect();
    }
    const onSessionNotAvaliable = (data) => {
        fireToast(data.message, "FAIL", theme.theme)
        checkSessionSocket.disconnect();
        setIsCheckingSession(false);
        setShouldCheckSession(false);
        handleDeleteModal(checkSessionName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        document.body.classList.add('modal-open');
        if (isModalOpen) {
            if (!qrCodeSocket.active) {
                qrCodeSocket.connect();
            }
            if (qrCode === "" || !qrCode) {
                qrCodeSocket.emit("getQrCode", {
                    id: user.id,
                    phone: selectedPhoneNumber && selectedPhoneNumber
                });
            }
            qrCodeSocket.on("sendQrCode", onSendQrCode);
            qrCodeSocket.on("loading", onLoading);
            qrCodeSocket.on("connection-success", (data) => {
                onConnectionSuccess(data, qrCodeSocket)
            })
            qrCodeSocket.on("WrongNumber", (data) => {
                onWrongNumber(data, qrCodeSocket)
            })

        }


    }, [isModalOpen, handleCloseModal]);

    useEffect(() => {
        if (shouldCheckSession) {
            if (!checkSessionSocket.active)
            {
                checkSessionSocket.connect();
                checkSessionSocket.on("loading", (data) => {
                    if (isCheckingSession !== data.loading)
                        setIsCheckingSession(data.loading);
                });
                checkSessionSocket.on("sessionStillAvaliable", onSessionAvaliable);
                checkSessionSocket.on("sessionNotAvaliable", onSessionNotAvaliable);
            }
            if (!isCheckingSession) {
                checkSessionSocket.emit("checkSession", {
                    id: user.id,
                    phone: checkSessionName.split(":")[1]
                });
            }
        }
    }, [shouldCheckSession]);

    const handleOpenModal = () => {
        setIsModalOpen(true); // Function to open the modal
    };


    const handleDeleteModal = (sessionName) => {
        setSelectedSessionName(sessionName); // Seçilen session'ın adını state'e kaydedin
        setIsConfirmationModal(!isConfirmationModal);
    }
    const handleCheckSession = (sessionName) => {
        setCheckSessionName(sessionName);
        if(!shouldCheckSession)
            setShouldCheckSession(true);
    }
    return (
        <div className={"container"}>
            <div className='row justify-content-center'>
                <div className='col-lg-12'>
                    <div className='d-flex flex-column border rounded p-5 card'>
                        <div className='d-flex align-items-center justify-content-center flex-wrap'>
                            {
                                sessions.map((session, index) => (
                                    <Spin size='large' spinning={isCheckingSession} key={index}>
                                        <div
                                        className={"d-flex flex-column align-items-center justify-content-center ps-5 pe-3 pt-5 pb-5 border rounded m-3"}
                                        key={session.sessionName}
                                        >

                                            <img
                                                src={`${toAbsoluteUrl('/media/icons/duotune/social/whatsapp.png')}`}
                                                alt={`svg icons`}
                                                style={{
                                                    maxWidth: '75px',
                                                    maxHeight: '75px',
                                                    marginBottom: '10px',
                                                    objectFit: 'contain',
                                                }}
                                            />
                                            <h3 style={{ margin: '0' }}>{formatPhoneNumber(session.phone)}</h3>
                                            <div style={{display: "flex", justifyContent: "space-between", gap: "3px"}}>
                                                <button className='btn btn-danger mt-3 px-4 py-2' onClick={() => handleDeleteModal(session.sessionName)}>
                                                    Kaldır
                                                </button>
                                                <button className='btn btn-primary mt-3 px-4 py-2' onClick={() => handleCheckSession(session.sessionName)}>
                                                    Kontrol et
                                                </button>
                                            </div>
                                            {
                                                isConfirmationModal && selectedSessionName === session.sessionName && (
                                                    <SessionDeleteConfirmationModal
                                                        type="delete"
                                                        handleCloseModal={() => setIsConfirmationModal(false)}
                                                        theme={theme}
                                                        sessionID={selectedSessionName}
                                                        getSessions={getSessions}
                                                    />
                                                )
                                            }
                                            {isConfirmationModal && <div className='modal-backdrop fade show'></div>}
                                        </div>
                                    </Spin>
                                    
                                ))
                            }
                        </div>
                        {
                            (
                                <button type="button" className="btn btn-primary mt-3" onClick={() => {
                                    setIsSelectPhoneModal(true)
                                }}>
                                    Oturum Oluştur
                                </button>
                            )
                        }

                        {/* Conditional rendering of Modal based on isModalOpen */}
                        {isModalOpen && qrCode && (
                            <>
                                
                                <div
                                className='modal fade show d-block'
                                id='kt_modal_add_draft'
                                role='dialog'
                                tabIndex={-1}
                                aria-modal='true'
                                >
                                    
                                    {/* begin::Modal dialog */}
                                    <div className='modal-dialog modal-dialog-centered mw-650px'>
                                        {/* begin::Modal content */}
                                            <div className='modal-content' style={{ display: 'flex', justifyContent: 'center' }}>
                                                <SessionEditModalHeader
                                                    handleCloseModal={handleCloseModal}
                                                />
                                                {/* begin::Modal body */}

                                                {/* <QRcode valute={qrCode}/> */}
                                                <div className='d-flex flex-column m-5'>
                                                    <>

                                                            <div className={`notice d-flex bg-light-danger border-danger border border-dashed rounded m-5 mx-10 p-6`}>
                                                                <KTIcon iconName="information" className={`fs-2tx me-4 text-danger`} />
                                                                <div className={"d-flex flex-stack flex-grow-1"}>
                                                                    <div className={"fw-semibold"}>
                                                                        <div className={"fs-6 text-gray-700 "}>
                                                                            Oturum oluşturma işlemi birkaç dakika sürebilir, bu nedenle işlem onaylanana kadar bu ekranı kapatmayın.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={`notice d-flex bg-light-primary border-primary border border-dashed rounded mx-10 p-6`}>
                                                                <KTIcon iconName="information" className={`fs-2tx me-4 text-primary`} />
                                                                <div className={"d-flex flex-stack flex-grow-1"}>
                                                                    <div className={"fw-semibold"}>
                                                                        <div className={"fs-6 text-gray-700 "}>
                                                                            Lütfen qr kod okuma işlemlerini light temasında gerçekleştirin!
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <Spin size='large' spinning={loading}>
                                                                <div className='d-flex justify-content-center my-5'>
                                                                    <QRCode value={qrCode} size={256} onPlayCapture={() => setSessionLoading(true)} />
                                                                </div>
                                                            </Spin>

                                                    </>

                                                </div>
                                                {/* end::Modal body */}
                                            </div>
                                        
                                        {/* end::Modal content */}
                                    </div>
                                    {/* end::Modal dialog */}
                                </div>
                            </>
                            
                        )
                        }
                        {isModalOpen && <div className='modal-backdrop fade show'></div>}
                        
                        {
                            isSelectPhoneModal && (
                                <SessionSelectModal
                                    setIsSelectPhoneModal={setIsSelectPhoneModal}
                                    user={user}
                                    sessions={sessions}
                                    selectedPhoneNumber={selectedPhoneNumber}
                                    setSelectedPhoneNumber={setSelectedPhoneNumber}
                                    handleSelectedPhone={handleSelectedPhone}
                                />
                            )
                        }
                        {isSelectPhoneModal && <div className='modal-backdrop fade show'></div>}
                    </div>
                </div>
            </div>
        </div >
    )
}


export { Session }
