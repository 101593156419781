import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const getQueueHistoryAction = createAsyncThunk('GET_QUEUE_HISTORY_ACTION', async (
    {
        userId,
        queue,
        page,
        perPage,
    }, {rejectWithValue}) => {
    try {
        const endpoint = !(page && perPage) ? `${userId}/queue/${queue}/history` : `${userId}/queue/${queue}/history?page=${page}&perpage=${perPage}`;

        return await HttpApi.GetAsync(endpoint, true);
    } catch (err) {
        return rejectWithValue(err);
    }


})

const getQueueHistorySlice = createSlice({
    name: 'queue-history',
    initialState,
    reducers: {
        resetAllQueueHistory: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getQueueHistoryAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(getQueueHistoryAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(getQueueHistoryAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {getQueueHistoryAction};
export const { resetAllQueueHistory } = getQueueHistorySlice.actions;

export default getQueueHistorySlice.reducer;
