import React from "react";
import ConfigurationDetails from "./ConfigurationDetails";

const Configuration = () => {
    return(
        <>
            <ConfigurationDetails />
        </>
    )
}

export default Configuration;