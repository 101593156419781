import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";

const initialState = {
    isLoading: false,
    isFailure: false,
    isSuccess: false,
    data: {},
    error: {}
}

const getSpentCreditDataAction = createAsyncThunk("GET_SPENT_CREDIT_DATA_ACTION", async (
    {
        userId
    }, { rejectWithValue }) => {
    try {
        let endpoint = `admin/users/${userId}/yearly-spent-credit-data`;
        const response = await HttpApi.GetAsync(endpoint, true);
        return response;
    } catch (error) {
        return rejectWithValue(error)
    }
})

const getSpentCreditDataSlice = createSlice({
    name: 'admin-get-spent-credit-data',
    initialState,
    reducers: {
        resetGetSpentCreditData: (state) => { return initialState }
    },
    extraReducers: (builder) => {
        builder.addCase(getSpentCreditDataAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
        .addCase(getSpentCreditDataAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
                state.error = {};
        })
        .addCase(getSpentCreditDataAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
                state.data = {};
        })
    }
});

export {getSpentCreditDataAction};
export const {resetGetSpentCreditData} = getSpentCreditDataSlice.actions;
export default getSpentCreditDataSlice.reducer;