import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {toAbsoluteUrl} from "../../_metronic/helpers";
import { verifyEmailAction, resetVerifyEmailState } from '../../services/redux/auth/verify-email'
import { isFailure, isSuccess, isLoading } from '../../utilities/reduxStateHelper'
import {useDispatch, useSelector} from "react-redux";
import { reactivateAccountAction } from '../../services/redux/account/reactivate-account';
import { reactivateAccountConfirmAction } from '../../services/redux/account/reactivate-account-confirm';
const ReactivateAccount = () => {
    const { id, code } = useParams();
    const [requestMessage, setRequestMessage] = useState("");
    const [requested, setRequested] = useState(false);
    const dispatcher = useDispatch();
    const selector = useSelector((state) => state.account.reactivateAccountConfirm);
    
    useEffect(() => {
        if (!requested) {
            dispatcher(reactivateAccountConfirmAction({userId: id, code: decodeURIComponent(code)}));
            setRequested(true);
        }
    }, [dispatcher, id, requested, code]);
    useEffect(() => {
        if(isLoading(selector))
        {

        }
        if (isSuccess(selector))
        {
            setRequestMessage(selector.data.message);
        }
        if (isFailure(selector))
        {
            setRequestMessage(selector.error.message);
        }
    }, [dispatcher, selector]);
    return (
        <>
            {/* begin::Title */}
            <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Hesabınızı Yeniden Etkinleştirme</h1>
            {/* end::Title */}

            {/* begin::Text */}
            <div className='fw-semibold fs-6 text-gray-500 mb-7'>{requestMessage}</div>
            {/* end::Text */}

            {/* begin::Illustration */}
            <div className='mb-3'>
                <img
                    src={toAbsoluteUrl('/media/auth/please-verify-your-email-dark.webp')}
                    className='mw-100 mh-300px theme-dark-show'
                    alt=''
                />
                <img
                    src={toAbsoluteUrl('/media/auth/please-verify-your-email.webp')}
                    className='mw-100 mh-300px theme-light-show'
                    alt=''
                />
            </div>
            {/* end::Illustration */}

            {/* begin::Link */}
            <div className='mb-0'>
                <Link
                    to="/auth/login"
                    className='btn btn-warning'
                >
                    Devam Et
                </Link>
            </div>
            {/* end::Link */}
        </>
    )
}
export {ReactivateAccount}