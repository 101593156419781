/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Languages } from './Languages'
import { toAbsoluteUrl } from '../../../helpers'
import { useDispatch, useSelector } from 'react-redux'
import { logoutAction, resetLogoutState } from '../../../../services/redux/auth/logout'
import { isSuccess } from '../../../../utilities/reduxStateHelper'
import { get_local_storage_item } from '../../../../utilities'

const translateRole = (role) => {
  switch (role) {
      case 'User':
          return 'Kullanıcı';
      case 'Admin':
          return 'Yönetici';
      default:
          return role;
  }
};

const HeaderUserMenu: FC = () => {
  const dispatch = useDispatch<any>();
  const selector = useSelector<any>((state) => state.auth.logout);
  const profileDetailsState = useSelector<any>((state) => state.profile);
  const [user, setUser] = useState<any>(get_local_storage_item("user"))

  const logout = () => {
    dispatch(logoutAction());
  }

  useEffect(() => {
    if (isSuccess(selector)) {
      window.location.reload();
    }
  }, [selector])

  useEffect(() => {
    const value = get_local_storage_item("user");
    if (value) {
      setUser(value);
    }
  }, [profileDetailsState])

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-325px'
      data-kt-menu='true'
      data-popper-placement='bottom-start'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              alt='Logo'
              src={user.avatar === 'blank' ? toAbsoluteUrl('/media/avatars/blank.png') : user.avatar}
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {user && user.firstName} {' '} {user && user.lastName}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {user && translateRole(user.role)}
            </a>
          </div>
        </div>
      </div>


      <div className='separator my-2'></div>

      {/* <Languages /> */}

      <div className='menu-item px-5 my-1'>
        <Link to='/settings/settings' className='menu-link px-5'>
          Hesap Ayarları
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Çıkış Yap
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
