import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";

const initialState = {
    isLoading: false,
    isFailure: false,
    isSuccess: false,
    data: {},
    error: {}
}

const unblockUserAction = createAsyncThunk("UNBLOCK_USER_ACTION", async (
    {
        userId
    }, { rejectWithValue }) => {
    try {
        let endpoint = `admin/users/${userId}/unblock-user`;
        const response = await HttpApi.PostAsync(endpoint, true, {});
        return response;
    } catch (error) {
        return rejectWithValue(error)
    }
})

const unblockUserSlice = createSlice({
    name: "admin-unblock-user",
    initialState,
    reducers: {
        resetAdminUnblockUser: (state) => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder.addCase(unblockUserAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        }).addCase(unblockUserAction.fulfilled, (state, action) => {
            state.isFailure = false;
            state.isLoading = false;
            state.isSuccess = true;
            state.data = action.payload;
        }).addCase(unblockUserAction.rejected, (state, action) => {
            state.isFailure = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.data = action.payload;
        })
    }
})

export {unblockUserAction};
export const {resetAdminUnblockUser} = unblockUserSlice.actions;
export default unblockUserSlice.reducer;