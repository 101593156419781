import axios, { AxiosResponse } from 'axios';
import { RequestBuilder, get_cookie } from '../../../utilities';

export const requestHistoryExport = async (userId, queue) => {
    const endpoint = RequestBuilder.BuildEndpoint(`${userId}/queue/${queue}/history/export`);
    try {
        const response = await axios.get(endpoint, {
            responseType: 'blob',
            headers: {
                "X-API-GATEWAY": process.env.REACT_APP_API_KEY,
                "X-TOKEN": `Bearer ${get_cookie("session")}`
            }
        });
        
        const blob = new Blob([response.data], { type: 'application/octet-stream' })
        const fileName = "kuyruk_gecmis.xlsx";
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('İstek hatası:', error);
        throw error;
    }
};