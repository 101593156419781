
const REQUEST_TYPES = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
}

const HEADER_TYPES = {
  WITH_AUTH: 'WITH_AUTH',
  BASIC: 'BASIC',
  FORM_AUTH: 'FORM_AUTH'
}

export { REQUEST_TYPES, HEADER_TYPES };

