import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_local_storage_item } from "../../../utilities";
import { notificationSettingsAction } from "../../../services/redux/profile/get-user-notification-settings";
import {
  isFailure,
  isLoading,
  isSuccess,
} from "../../../utilities/reduxStateHelper";
import {
  updateNotificationSettingsAction,
  resetUpdateNotificationSettings,
} from "../../../services/redux/profile/update-user-notification-settings";
import { fireToast } from "../../../utilities/toastHelper";
import { useNavigate, useParams } from "react-router-dom";

const notifications = {
  uploadCreditObj: {
    mail: true,
    wp: true,
    notification_name: "Kredi Yükleme Bildirimi",
  },
  newQueueObj: {
    mail: true,
    wp: true,
    notification_name: "Yeni Kuyruk Bildirimi",
  },
  queueBeginObj: {
    mail: true,
    wp: true,
    notification_name: "Kuyruk Başlangıç Bildirimi",
  },
  queueErrorObj: {
    mail: true,
    wp: true,
    notification_name: "Kuyruk Hata Bildirimi",
  },
  queuePausedObj: {
    mail: true,
    wp: true,
    notification_name: "Kuyruk Duraklatıldı Bildirimi",
  },
  queueFinishedObj: {
    mail: true,
    wp: true,
    notification_name: "Kuyruk Tamamlandı Bildirimi",
  },
  queueStartedAgainObj: {
    mail: true,
    wp: true,
    notification_name: "Kuyruk Yeniden Başlatıldı Bildirimi",
  },
};
const Notifications = ({ isRenderingInAdmin }) => {
  const [user, setUser] = useState(isRenderingInAdmin);
  const dispatch = useDispatch();
  const selectorGet = useSelector((state) => state.profile.getUserNotification);
  const selectorUpdate = useSelector(
    (state) => state.profile.updateUserNotification
  );
  const [loading, setLoading] = useState(false);
  const [requested, setRequested] = useState(false);
  const [data, setData] = useState({});

  const updateData = (key, fieldsToUpdate) => {
    const updatedData = { ...data };
    updatedData[key] = {
      mail: fieldsToUpdate.mail,
      wp: fieldsToUpdate.wp,
      notification_name: fieldsToUpdate.notification_name,
    };
    setData(updatedData);
  };

  useEffect(() => {
    if (!requested) {
      setRequested(true);
      dispatch(notificationSettingsAction({ userId: user }));
    }
  }, [requested, dispatch]);

  useEffect(() => {
    if (isSuccess(selectorGet)) {
      setData(selectorGet.data);
    }
    if (isLoading(selectorGet)) {
    }
    if (isFailure(selectorGet)) {
    }
  }, [requested, dispatch, selectorGet, selectorUpdate]);

  useEffect(() => {
    if (isSuccess(selectorUpdate)) {
      setData(selectorUpdate.data);
      setLoading(false);
      fireToast(selectorUpdate.data.message, "SUCCESS");
      dispatch(notificationSettingsAction({ userId: user }));
    }
    if (isLoading(selectorUpdate)) {
      setLoading(true);
    }
    if (isFailure(selectorUpdate)) {
      fireToast(selectorUpdate.error.message, "FAIL");
      setLoading(false);
    }
    return () => {
      dispatch(resetUpdateNotificationSettings());
    };
  }, [requested, dispatch, selectorUpdate]);

  const click = () => {
    setLoading(true);

    dispatch(
      updateNotificationSettingsAction({
        userId: user,
        uploadCreditObj: data.uploadCreditObj,
        newQueueObj: data.newQueueObj,
        queueBeginObj: data.queueBeginObj,
        queueErrorObj: data.queueErrorObj,
        queuePausedObj: data.queuePausedObj,
        queueFinishedObj: data.queueFinishedObj,
        queueStartedAgainObj: data.queueStartedAgainObj,
      })
    );
  };

  return (
    <div className="card mb-5 mb-xl-10">
      <div
        className="card-header border-0"
        data-bs-target="#kt_account_notifications"
        aria-expanded="true"
        aria-controls="kt_account_notifications"
      >
        <div className="card-title m-0">
          <h3 className="fw-bolder m-0">Bildirimler</h3>
        </div>
      </div>

      <div id="kt_account_notifications" className="collapse show">
        <form className="form">
          <div className="card-body border-top px-9 pt-3 pb-4">
            <div className="table-responsive">
              <table className="table table-row-dashed border-gray-300 align-middle gy-6">
                <tbody className="fs-6 fw-bold">
                  {Object.keys(data).map((key, index) => (
                    <tr key={key}>
                      <td className="min-w-250px fs-4 fw-bolder">
                        {data[key].notification_name}
                      </td>
                      <td className="w-125px">
                        <div className="form-check form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id={`${key}-mail`}
                            defaultChecked={data[key].mail}
                            onChange={() =>
                              updateData(key, {
                                wp: data[key].wp,
                                mail: !data[key].mail,
                                notification_name: data[key].notification_name,
                              })
                            }
                          />
                          <label
                            className="form-check-label ps-2"
                            htmlFor="kt_settings_notification_email"
                          >
                            Email
                          </label>
                        </div>
                      </td>
                      <td className="w-125px">
                        <div className="form-check form-check-solid">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id={`${index}-mail`}
                            defaultChecked={data[key].wp}
                            onChange={() =>
                              updateData(key, {
                                wp: !data[key].wp,
                                mail: data[key].mail,
                                notification_name: data[key].notification_name,
                              })
                            }
                          />
                          <label
                            className="form-check-label ps-2"
                            htmlFor="kt_settings_notification_phone"
                          >
                            WhatsApp
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button className="btn btn-light btn-active-light-primary me-2">
              İptal
            </button>
            <button type="button" onClick={click} className="btn btn-primary">
              {!loading && "Kaydet"}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Lütfen bekleyin...{" "}
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export { Notifications };
