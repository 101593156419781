import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const getDraftsAction = createAsyncThunk('GET_ALL_DRAFTS_ACTION', async (
    {
        userId,
        page,
        perPage
    }, {rejectWithValue}) => {
    try {
        let endpoint = !(page && perPage) ? `${userId}/drafts/list` : `${userId}/drafts/list?page=${page}&perpage=${perPage}`;
        return await HttpApi.GetAsync(endpoint, true);
    } catch (err) {
        return rejectWithValue(err);
    }
})

const getDraftsSlice = createSlice({
    name: 'get-drafts',
    initialState,
    reducers: {
        resetGetAllDraft:(state) => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getDraftsAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(getDraftsAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(getDraftsAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {getDraftsAction};
export default getDraftsSlice.reducer;
export const {resetGetAllDraft} = getDraftsSlice.actions;
