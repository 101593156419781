import React, { Suspense } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { PageTitle } from "../_metronic/layout/core";
import ActiveCustomerList from "../components/Apps/active-customer/ActiveCustomerList";
import UserTableWidget from "../components/Widgets/UserTableWidget";
import UserDetailPage from "./UserDetailPage";
import Events from "../components/UserDetails/Events";

const userDetailBreadCrumbs = [
    {
        title: 'Yönetici Paneli',
        path: '/admin/panel',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const AdminPanelPage = () => {
    return (
        <>
            <div className='row g-5 g-xl-9'>
                <div className='col-xl-stretch mb-8'>
                    <ActiveCustomerList />
                </div>
            </div>
            <div className='row g-5 g-xl-8'>
                <div className='col-xl-stretch mb-8'>
                    <Suspense>
                        <UserTableWidget />
                    </Suspense>
                </div>
            </div>
            <div className='row g-5 g-xl-9'>
                <div className='col-xl-stretch mb-8'>
                    <Events />
                </div>
            </div>  
        </>
    )
}

const AdminWrapper = () => (
    <Routes>
        <Route element={<Outlet />}>
            <Route
                path='panel'
                element={
                    <>
                        <PageTitle breadcrumbs={[]}>Yönetici Paneli</PageTitle>
                        <AdminPanelPage />
                    </>
                }
            />
            <Route
                path="panel/user/:userId"
                element={
                    <>
                        <PageTitle breadcrumbs={userDetailBreadCrumbs}>Kullanıcı Detayı</PageTitle>
                        <UserDetailPage />
                    </>
                }
            />
        </Route>
    </Routes>
)

export default AdminWrapper;