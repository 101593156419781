import React, { useState } from "react";
import ModalHeader from "../Apps/draft-management/modal/ModalHeader";

const StartQueueTimePickerModal = ({ setIsShowStartQueueModal, setIsShowQueueInfoModal, handleSelectTimePicker }) => {
    const [hour, setHour] = useState();
    const [minute, setMinute] = useState();

    const handleNowClick = () => {
        const now = new Date();
        now.setMinutes(now.getMinutes() + 5); 
        const currentHour = `0${now.getHours()}`.slice(-2);
        const currentMinute = `0${now.getMinutes()}`.slice(-2);
        setHour(currentHour);
        setMinute(currentMinute);
    };

    return (
        <div
            className='modal fade show d-block'
            id='kt_modal_add_draft'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
        >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-600px'>
                {/* begin::Modal content */}
                <div className='modal-content' style={{ display: 'flex', justifyContent: 'center' }}>
                    <ModalHeader
                        title={"Zaman Seçici Seçimi"}
                        handleCloseModal={() => setIsShowStartQueueModal(false)}
                    />

                    {/* begin::Modal body */}
                    <div className={"d-flex flex-column p-8"}>
                        <div className={"d-flex align-items-center justify-content-between w-100 col-lg-8"}>
                            <div className={"d-flex align-items-center"}>
                                <select
                                    value={hour}
                                    onChange={(event) => setHour(event.target.value)}
                                    className={"p-3 border rounded me-3"}
                                >
                                    {[...Array(24).keys()].map((h) => (
                                        <option key={h} value={h < 10 ? `0${h}` : `${h}`}>
                                            {h < 10 ? `0${h}` : `${h}`}
                                        </option>
                                    ))}
                                </select>
                                <h6>
                                    :
                                </h6>
                                <select
                                    value={minute}
                                    onChange={(event) => setMinute(event.target.value)}
                                    className={"p-3 border rounded ms-3 me-5"}
                                >
                                    {[...Array(60).keys()].map((m) => (
                                        <option key={m} value={m < 10 ? `0${m}` : `${m}`}>
                                            {m < 10 ? `0${m}` : `${m}`}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='d-flex justify-content-end'>
                                <button
                                    className='btn btn-primary ps-4 pe-4 pt-2 pb-2 me-5'
                                    type="button"
                                    onClick={handleNowClick}
                                >
                                    +5 dakika
                                </button>
                            </div>
                        </div>
                        <div className={"d-flex justify-content-end mt-10"}>
                            <button
                                className='btn btn-primary ps-4 pe-4 pt-2 pb-2 me-5'
                                type="button"
                                onClick={() => {
                                    handleSelectTimePicker(hour, minute)
                                    setIsShowStartQueueModal(false)
                                    setIsShowQueueInfoModal(false)
                                }}
                            >
                                Kaydet ve Kuyruğu Başlat
                            </button>
                        </div>
                    </div>
                    {/* end::Modal body */}

                </div>
                {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}

        </div>
    )
}

export default StartQueueTimePickerModal;