import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const getDraftByIdAction = createAsyncThunk('GET_DRAFT_BY_ID_ACTION', async (
    {
        userId,
        draftId
    }, {rejectWithValue}) => {
    try {
        const endpoint = `${userId}/drafts/${draftId}/get`;
        return await HttpApi.GetAsync(endpoint, true);
    } catch (err) {
        return rejectWithValue(err);
    }
})

const getDraftByIdSlice = createSlice({
    name: 'get-draft-by-id',
    initialState,
    reducers: {
        resetDraftGetByID: (state) => {return initialState}
    },
    extraReducers: (builder) => {
        builder.addCase(getDraftByIdAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(getDraftByIdAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(getDraftByIdAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {getDraftByIdAction};
export const {resetDraftGetByID} = getDraftByIdSlice.actions;

export default getDraftByIdSlice.reducer;
