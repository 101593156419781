import React, { useEffect, useState } from "react";
import "../../Account/index.css";
import { KTIcon } from "../../../_metronic/helpers";
import { Pagination, theme } from "antd";
import InfoDraftModal from "../modals/InfoDraftModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationSettingsAction,
  resetGetNotificationSettings,
} from "../../../services/redux/admin/get-notification-settings";
import {
  isFailure,
  isLoading,
  isSuccess,
} from "../../../utilities/reduxStateHelper";
import { updateDraftAction } from "../../../services/redux/drafts/update-draft";
import {
  resetUpdateNotificationSettings,
  updateNotificationSettingsAction,
} from "../../../services/redux/admin/update-notification-settings";
import { fireToast } from "../../../utilities/toastHelper";
import { useTheme } from "../../../services/themeService";
import { resetAdmintoggleServiceActivity, toggleServiceActivityAction } from "../../../services/redux/admin/toggle-service-activity";

const dummyObj = {
  id: "",
  title: "title",
  message: "CONTENT TEXT",
  info: "info",
};

let dummyData = {
  registerObj: {
    title: "REGISTER TITLE",
    message: "REGISTER MESSAGE",
    info: "REGISTER INFO",
    sent_type: "ALL",
  },
  uploadCreditObj: {
    title: "UPLOAD CREDIT TITLE",
    message: "UPLOAD CREDIT MESSAGE",
    info: "UPLOAD CREDIT INFO",
    sent_type: "ALL",
  },
  newQueueObj: {
    title: "NEW QUEUE TITLE",
    message: "NEW QUEUE MESSAGE",
    info: "NEW QUEUE INFO",
    sent_type: "ALL",
  },
  queueBeginObj: {
    title: "QUEUE BEGIN TITLE",
    message: "QUEUE BEGIN MESSAGE",
    info: "QUEUE BEGIN INFO",
    sent_type: "ALL",
  },
  queuePausedObj: {
    title: "QUEUE PAUSED TITLE",
    message: "QUEUE PAUSED MESSAGE",
    info: "QUEUE PAUSED INFO",
    sent_type: "ALL",
  },
  queueFinishedObj: {
    title: "QUEUE FINISHED TITLE",
    message: "QUEUE FINISHED MESSAGE",
    info: "QUEUE FINISHED INFO",
    sent_type: "ALL",
  },
  queueStartedObj: {
    title: "QUEUE STARTED AGAIN TITLE",
    message: "QUEUE STARTED AGAIN MESSAGE",
    info: "QUEUE STARTED AGAIN INFO",
    sent_type: "ALL",
  },
  queueErrorObj: {
    title: "QUEUE ERROR TITLE",
    message: "QUEUE ERROR MESSAGE",
    info: "QUEUE ERROR AGAIN INFO",
    sent_type: "ALL",
  },
  serviceStatus: "ACTIVE",
};

const NotificationSettings = () => {
  const { theme } = useTheme();
  const dispatcher = useDispatch();
  const selectorUpdate = useSelector((state) => state.admin.updateNotification);
  const selectorActivity = useSelector((state)=> state.admin.toggleServiceActivity);
  const selectorGet = useSelector(
    (state) => state.admin.getNotificationSettings
  );
  const [loading, setLoading] = useState(false);
  const [activity, setActivity] = useState("INACTIVE");
  const [requested, setRequested] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [draftList, setDraftList] = useState({});
  // const [theme,setTheme] = useState('light');

  const itemsPerPage = 5;
  const [page, setPage] = useState(1);
  const [isAddModalShow, setIsAddModalShow] = useState(false);
  const [modalType, setModalType] = useState("");
  const [editingDraftId, setEditingDraftId] = useState(0); // Editlenen taslağın ID'sini tutacak state
  const [currentKey, setCurrentKey] = useState(null);
   

  const paginateData = {
    totalItems: draftList.length,
    perpage: itemsPerPage,
  };

  const handleSwitchChange = () => {
    let currentActivity = "";
    if (activity === "ACTIVE")
    {
      currentActivity = "INACTIVE"  
    }
    else {
      currentActivity = "ACTIVE"
    }

    dispatcher(toggleServiceActivityAction({activity: currentActivity}))
  }
  const fetchNextData = (page, pageSize) => {
    console.log("Fetching data for page:", page, "pageSize:", pageSize);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchNextData(page, itemsPerPage);
  };
  const updateDraft = (key, updatedDraft) => {
    const updatedDraftList = { ...draftList };
    updatedDraftList[key] = JSON.stringify(updatedDraft);
    setDraftList(updatedDraftList);
    dispatcher(updateNotificationSettingsAction(updatedDraftList));
    setIsAddModalShow(false);
    setModalType("");
  };

  const handleEdit = (id) => {
    setModalType("edit");
    setIsAddModalShow(true);
    setCurrentKey(id);
  };

  useEffect(() => {
    if (!requested) {
      dispatcher(getNotificationSettingsAction());
      setRequested(true);
    }
  }, [requested, dispatcher]);
  useEffect(() => {
    if (isSuccess(selectorGet)) {
      setDraftList(selectorGet.data.data);
      setActivity(selectorGet.data.data.serviceStatus)
      setLoading(false);
    }
    if (isLoading(selectorGet)) {
      setLoading(true);
    }
    if (isFailure(selectorGet)) {
      setLoading(false);
    }
    return () => {
      dispatcher(resetGetNotificationSettings());
    };
  }, [selectorGet, draftList, dispatcher, selectorUpdate]);

  useEffect(() => {
    if (isSuccess(selectorActivity)) {
      setDraftList(selectorActivity.data.data);
      dispatcher(getNotificationSettingsAction())
      setLoading(false);
    }
    if (isLoading(selectorActivity)) {
      setLoading(true);
    }
    if (isFailure(selectorActivity)) {
      setLoading(false);
    }
    return () => {
      dispatcher(resetAdmintoggleServiceActivity());
    };
  }, [dispatcher, selectorActivity, loading]);
  useEffect(() => {
    if (isSuccess(selectorUpdate)) {
      fireToast(selectorUpdate.data.message, "SUCCESS", theme.theme);
      dispatcher(getNotificationSettingsAction());
    }
    if (isFailure(selectorUpdate)) {
      fireToast(selectorUpdate.error.message, "FAIL", theme.theme);
    }
    return () => {
      dispatcher(resetUpdateNotificationSettings());
    };
  }, [selectorUpdate, dispatcher, theme.theme]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 10,
        borderRadius: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 20,
          marginBottom: 20,
          paddingLeft: 20,
          paddingRight: 20,
        }}
      >
        <span
          style={{
            fontSize: 21,
            fontWeight: "bold",
            fontFamily: "Inter",
          }}
        >
          Mesaj Taslakları
        </span>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id="isActiveSwitch"
            checked={activity === "INACTIVE" ? false : true}
            onChange={handleSwitchChange}
          />
          <label className="form-check-label" htmlFor="isActiveSwitch">
            Aktiflik Durumu
          </label>
        </div>
      </div>
      <div className="draft-list-container">
        {
          Object.keys(draftList).length === 0 ? (
            <div
              className={
                "d-flex flex-column p-20 w-100 justify-content-center align-items-center border rounded"
              }
            >
              <KTIcon iconName="cross-circle" className={"bigger-icon mb-4"} />
              <h3 className={"mb-0"}>
                Oluşturulan Mesaj Taslağı Bulunmamaktadır!
              </h3>
            </div>
          ) : (
            Object.keys(draftList).map((key) => {
              let data = draftList[key];
              let currentItem = key !== "serviceStatus" ? JSON.parse(data) : undefined;
              return (
                <React.Fragment key={`${key}-0`}>
                  {key !== "serviceStatus" && currentItem !== undefined && (
                    <div
                      key={key}
                      id={key}
                      className={
                        "d-flex p-20 w-100 justify-content-between align-items-center border rounded"
                      }
                      style={{
                        marginBottom: 10,
                        padding: 10,
                        borderRadius: 10,
                        paddingLeft: 20,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <h3 style={{ marginRight: 10 }}>
                          {currentItem.title ? currentItem.title : ""}
                        </h3>
                        <p>{currentItem.title ? currentItem.title : ""}</p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <p>{currentItem.message ? currentItem.message : ""}</p>{" "}
                        {/* Mesajı burada göster */}
                        <div className="d-flex">
                          <button
                            onClick={() => handleEdit(key)}
                            style={{
                              marginRight: 5,
                              backgroundColor: "transparent",
                              border: "none",
                            }}
                            className="d-flex align-items-center"
                          >
                            <KTIcon
                              iconName={"message-edit"}
                              iconType="duotone"
                              className={"bigger-icon"}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })
          )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 20,
        }}
      >
        <Pagination
          showSizeChanger={false}
          pageSize={itemsPerPage}
          defaultCurrent={1}
          total={paginateData.totalItems}
          onChange={handlePageChange}
          className={"pagination-custom"}
        />
      </div>
      {isAddModalShow && (
        <InfoDraftModal
          setIsAddModalShow={setIsAddModalShow}
          modalType={modalType}
          updateDraft={updateDraft}
          initialObject={JSON.parse(draftList[currentKey])}
          currentKey={currentKey}
        />
      )}
      {isAddModalShow && <div className="modal-backdrop fade show"></div>}
    </div>
  );
};

export default NotificationSettings;
