import { useEffect, useState } from 'react';
import DefaultUserSettings from './DefaultUserSettings';
import KvkkText from './KvkkText';
import { useDispatch, useSelector } from 'react-redux';
import { isFailure, isLoading, isSuccess } from '../../../utilities/reduxStateHelper';
import { getSettingsDefaultAction, resetGetSettingsDefault } from '../../../services/redux/admin/settings-default';
import ExtraPhones from './ExtraPhones';
import { resetUpdateCreditAmounts, updateCreditAmountsAction } from '../../../services/redux/admin/update-credit-amount';
import { resetUpdatePhoneAmounts, updatePhoneAmountsAction } from '../../../services/redux/admin/update-phone-amount';
import { fireToast } from '../../../utilities/toastHelper';
import { useTheme } from '../../../services/themeService';
import { resetUpdatePrivacyText, updatePrivacyTextAction } from '../../../services/redux/admin/privacy-text';
import { resetUpdateRegisterText, updateRegisterTextAction } from '../../../services/redux/admin/register-text';
import RegisterText from './RegisterText';

const ConfigurationDetails = () => {
    const {theme} = useTheme();
    const dispatch = useDispatch();
    const getSettingsDefaultState = useSelector((state) => state.admin.getSettingsDefault)
    const updateCreditAmountState = useSelector((state) => state.admin.updateCreditAmount)
    const updatePhonesAmountState = useSelector((state) => state.admin.updatePhoneAmount)
    const privacyTextState = useSelector((state) => state.admin.updatePrivacyText)
    const registerTextState = useSelector((state) => state.admin.updateRegisterText)

    const [loading, setLoading] = useState(false)
    const [defaultSettingsData, setDefaultSettingsData] = useState({})
    const [requested, setRequested] = useState(false);
    const [type, setType] = useState("")

    const onSubmitExtraPhones = (phonesAmount) => {
        dispatch(updatePhoneAmountsAction({
            phoneAmount: phonesAmount
        }))
    }

    const onSubmitCreditAmounts = (creditAmount) => {
        dispatch(updateCreditAmountsAction({
            creditAmount: creditAmount
        }))
    }

    const onSubmitPrivacyText = (privacyText) => {
        dispatch(updatePrivacyTextAction({
            text: privacyText
        }))
    }
    const onSubmitRegisterText = (registerText) => {
        dispatch(updateRegisterTextAction({
            text: registerText
        }))
    }

    useEffect(() => {
        if (!requested) {
            dispatch(getSettingsDefaultAction())
            setRequested(true);
        }
    }, [requested, dispatch])

    useEffect(() => {
        if (isSuccess(getSettingsDefaultState)) {
            setDefaultSettingsData(getSettingsDefaultState.data.data)
            setLoading(false)
        }
        if (isLoading(getSettingsDefaultState)) {
            setLoading(true)

        }
        if (isFailure(getSettingsDefaultState)) {
            setLoading(false)
        }
        return () => {
            dispatch(resetGetSettingsDefault())
        }
    }, [getSettingsDefaultState, defaultSettingsData, dispatch, updatePhonesAmountState, updateCreditAmountState, privacyTextState, registerTextState])

    useEffect(() => {
        if(isSuccess(updateCreditAmountState)){
            fireToast(updateCreditAmountState.data.message, "SUCCESS", theme.theme)
            dispatch(getSettingsDefaultAction())
        }
        if(isFailure(updateCreditAmountState)){
            fireToast(updateCreditAmountState.error.message, "FAIL", theme.theme)
        }
        return () => {
            dispatch(resetUpdateCreditAmounts())
        }
    }, [updateCreditAmountState, dispatch, theme.theme])


    useEffect(() => {
        if(isSuccess(updatePhonesAmountState)){
            fireToast(updatePhonesAmountState.data.message, "SUCCESS", theme.theme)
            dispatch(getSettingsDefaultAction())
        }
        if(isFailure(updatePhonesAmountState)){
            fireToast(updatePhonesAmountState.error.message, "FAIL", theme.theme)
        }
        return () => {
            dispatch(resetUpdatePhoneAmounts())
        }
    }, [updatePhonesAmountState, dispatch, theme.theme])
    

    useEffect(() => {
        if(isSuccess(privacyTextState)){
            fireToast(privacyTextState.data.message, "SUCCESS", theme.theme)
            dispatch(getSettingsDefaultAction())

        }
        if(isFailure(privacyTextState)){
            fireToast(privacyTextState.error.message, "FAIL", theme.theme)
        }
        
        return () => {
            dispatch(resetUpdatePrivacyText())
        }
    }, [privacyTextState,dispatch, theme.theme])

    useEffect(()=>{
        if(isSuccess(registerTextState)){
            fireToast(registerTextState.data.message, "SUCCESS", theme.theme)
        }
        if(isFailure(registerTextState)){
            fireToast(registerTextState.error.message, "FAIL", theme.theme)
            dispatch(getSettingsDefaultAction())

        }
        return ()=>{
            dispatch(resetUpdateRegisterText())
        }
    },[registerTextState,dispatch, theme.theme])

    return (
       (!loading && (
        <div className={"row g-5 g-xl-5"}>
            <div className='col-xl-stretch'>
                <DefaultUserSettings defaultData={defaultSettingsData.defaultCreditAmount} onSubmitCreditAmounts={onSubmitCreditAmounts} />
            </div>
            <div className='col-xl-stretch'>
                <ExtraPhones defaultData={defaultSettingsData.defaultPhonePermissionCount} onSubmitExtraPhones={onSubmitExtraPhones} />
            </div>
            <div className='col-xl-stretch mb-5'>
                <KvkkText defaultPrivacyText={defaultSettingsData.privacy_text} onSubmitPrivacyText={onSubmitPrivacyText} />
            </div>
            <div className='col-xl-stretch mb-5'>
                <RegisterText defaultPrivacyText={defaultSettingsData.register_text} onSubmitPrivacyText={onSubmitRegisterText} />
            </div>
        </div>
       ))
    )
}

export default ConfigurationDetails;