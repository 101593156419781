import React, { useCallback, useEffect, useState } from 'react'
import { KTIcon } from '../../_metronic/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '../../services/themeService';
import { getGrayListCustomersAction, resetGetGrayListCustomers } from '../../services/redux/customers/graylist/get-graylist';
import { getBlacklistCustomersAction, resetGetBlackListCustomers } from '../../services/redux/customers/blacklist/get-blacklist';
import { isFailure, isLoading, isSuccess } from '../../utilities/reduxStateHelper';
import { fireToast } from '../../utilities/toastHelper';
import { ConfigProvider, Pagination } from 'antd';
import CustomerInfoModal from '../Modals/UserDetailsModal/CustomerInfoModal';

const GrayAndBlackList = ({ userId }) => {
    const { theme } = useTheme();
    const dispatch = useDispatch();
    const getGrayListCustomersState = useSelector((state) => state.grayList.getGrayListCustomers)
    const getBlackListCustomerState = useSelector((state) => state.blackList.getBlackListCustomers)

    const [selectedTab, setSelectedTab] = useState("grayList");
    const [selectedUser, setSelectedUser] = useState({});

    const [grayList, setGrayList] = useState([]);
    const [blackList, setBlackList] = useState([]);
    const [grayPaginateData, setGrayPaginateData] = useState({});
    const [blackPaginateData, setBlackPaginateData] = useState({});
    const [loading, setLoading] = useState(false);
    const [requested, setRequested] = useState(false);
    const [defaultPerPage, setDefaultPerPage] = useState(10);
    const [isShowCustomerInfoModal, setIsShowCustomerInfoModal] = useState(false);

    const grayFetchNextData = useCallback((page, perPage) => {
        dispatch(getGrayListCustomersAction({ userId: userId, page: page, perPage: perPage ? perPage : defaultPerPage }))
    })

    const blackFetchNextData = useCallback((page, perPage) => {
        dispatch(getBlacklistCustomersAction({ userId: userId, page: page, perPage: perPage ? perPage : defaultPerPage }))
    })

    useEffect(() => {
        if (!requested) {
            dispatch(getGrayListCustomersAction({
                userId: userId,
                page: 1,
                perPage: defaultPerPage
            }))
            dispatch(getBlacklistCustomersAction({
                userId: userId,
                page: 1,
                perPage: defaultPerPage
            }))
            setRequested(true)
        }
    }, [defaultPerPage, dispatch, requested])

    useEffect(() => {
        if (isSuccess(getGrayListCustomersState)) {
            setGrayList(getGrayListCustomersState.data.data);
            setGrayPaginateData(getGrayListCustomersState.data.meta);
            setLoading(false)
        }
        if (isLoading(getGrayListCustomersState)) {
            setLoading(true)
        }
        if (isFailure(getGrayListCustomersState)) {
            fireToast("Islemde bir problem oldu.", "FAIL", theme.theme)
            setLoading(false);
        }
        return () => {
            dispatch(resetGetGrayListCustomers())
        }
    }, [grayList, defaultPerPage, dispatch, getGrayListCustomersState])

    useEffect(() => {
        if (isSuccess(getBlackListCustomerState)) {
            setBlackList(getBlackListCustomerState.data.data)
            setBlackPaginateData(getBlackListCustomerState.data.meta)
            setLoading(false)
        }
        if (isLoading(getBlackListCustomerState)) {
            setLoading(true)
        }
        if (isFailure(getBlackListCustomerState)) {
            fireToast("Islemde bir problem oldu.", "FAIL", theme.theme)
            setLoading(false);
        }
        return () => {
            dispatch(resetGetBlackListCustomers())
        }
    }, [blackList, defaultPerPage, dispatch, getBlackListCustomerState])

    return (
        <div className='card card-xl-stretch mb-xl-8 border rounded mt-5'>

            {/* begin::Header */}
            <div className='card-header align-items-center border-0 mt-4'>
                <div className={"card-title"}>
                    <div className={"d-flex align-items-center my-1"}>
                        <h2>
                            {selectedTab === "grayList" ? "Gri Liste" : "Kara Liste"}
                        </h2>
                    </div>
                </div>
                <div>
                    <button
                        className={`btn me-3 ${selectedTab === "grayList" ? "btn-primary p-3" : "btn-light p-3"}`}
                        onClick={() => {
                            setSelectedTab("grayList")
                        }}
                    >
                        Gri Liste
                    </button>
                    <button
                        className={`btn ${selectedTab === "blackList" ? "btn-primary p-3" : "btn-light p-3"}`}
                        onClick={() => {
                            setSelectedTab("blackList")
                        }}
                    >
                        Kara Liste
                    </button>
                </div>
            </div>
            {/* end::Header */}

            <div className={"card-body py-4 mb-3"}>
                <div className={"dataTables_wrapper dt-bootstrap4 no-footer"}>
                    <div className={"table-responsive"}
                        style={{
                            maxHeight: 350,
                            overflowY: "auto"
                        }}
                    >
                        <table className={"table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"}>
                            <thead>
                                <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                                    <th
                                        className='min-w-125px sorting'
                                        tabIndex="0"
                                        rowSpan="1"
                                        colSpan="1"
                                        style={{ width: "150px" }}
                                    >
                                        Ad
                                    </th>
                                    <th
                                        className='min-w-125px sorting'
                                        tabIndex="0"
                                        rowSpan="1"
                                        colSpan="1"
                                        style={{ width: "150px" }}
                                    >
                                        Soyad
                                    </th>
                                    <th
                                        className={"text-end min-w-100px sorting_disabled"}
                                        tabIndex="0"
                                        rowSpan="1"
                                        colSpan="1"
                                        style={{ width: "122.575px" }}
                                    >
                                        Aksiyonlar
                                    </th>
                                </tr>
                            </thead>
                            <tbody className={"text-gray-600 fw-semibold"}>
                                {
                                    selectedTab === "grayList" ? (
                                        grayList.length === 0
                                            ? (
                                                <tr className='mt-5 fw-bold'>
                                                    <td colSpan="3" className='mt-5 fw-bold'>
                                                        Gri listeye eklenmiş birisi bulunmamaktadır.
                                                    </td>
                                                </tr>
                                            ) : (
                                                grayList.map((data) => (
                                                    <tr key={data.id}>
                                                        <td>
                                                            <div>{data.name}</div>
                                                        </td>
                                                        <td>
                                                            <div>{data.lastName}</div>
                                                        </td>
                                                        <td className={"text-end"}>
                                                            <div>
                                                                <button
                                                                    className='btn btn-icon btn-active-light-primary w-30px h-30px ms-auto'
                                                                    type='button'
                                                                    onClick={() => {
                                                                        setSelectedUser(data)
                                                                        setIsShowCustomerInfoModal(true);
                                                                    }}
                                                                >
                                                                    <KTIcon iconName="information" className={"fs-2"} />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            )
                                    ) : (
                                        blackList.length === 0
                                            ? (
                                                <tr className='mt-5 fw-bold'>
                                                    <td colSpan="3" className='mt-5 fw-bold'>
                                                        Kara listeye eklenmiş birisi bulunmamaktadır.
                                                    </td>
                                                </tr>
                                            )
                                            : (
                                                blackList.map((data) => (
                                                    <tr key={data.id}>
                                                        <td>
                                                            <div>{data.name}</div>
                                                        </td>
                                                        <td>
                                                            <div>{data.lastName}</div>
                                                        </td>
                                                        <td className={"text-end"}>
                                                            <div>
                                                                <button
                                                                    className='btn btn-icon btn-active-light-primary w-30px h-30px ms-auto'
                                                                    type='button'
                                                                    onClick={() => {
                                                                        setSelectedUser(data)
                                                                        setIsShowCustomerInfoModal(true);
                                                                    }}
                                                                >
                                                                    <KTIcon iconName="information" className={"fs-2"} />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            )
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 10,
                    }}
                >
                    <ConfigProvider
                        theme={{
                            token: {
                                colorBgContainer: theme.backgroundColor,
                                colorText: theme.color
                            }
                        }}
                    >
                        <Pagination
                            locale={{ items_per_page: '/ sayfa' }}
                            showSizeChanger
                            onShowSizeChange={(current, pageSize) => {
                                selectedTab === "grayList"
                                    ? grayFetchNextData(current, pageSize)
                                    : blackFetchNextData(current, pageSize)
                            }}
                            pageSizeOptions={[10, 50, 500, 1000, 5000]}
                            defaultCurrent={1}
                            total={selectedTab === "grayList" ? grayPaginateData.totalItems : blackPaginateData.totalItems}
                            pageSize={selectedTab === "grayList" ? grayPaginateData.perpage : blackPaginateData.perpage}
                            className={theme.theme === "light" ? "light-theme" : "dark-theme"}
                            onChange={(page) => {
                                selectedTab === "grayList"
                                    ? grayFetchNextData(page)
                                    : blackFetchNextData(page)
                            }}
                        />
                    </ConfigProvider>
                </div>
            </div>

            {
                isShowCustomerInfoModal && (
                    <CustomerInfoModal
                        setIsShowCustomerInfoModal={setIsShowCustomerInfoModal}
                        selectedCustomer={selectedUser}
                    />
                )
            }
            {isShowCustomerInfoModal && <div className='modal-backdrop fade show'></div>}
        </div>
    )
}

export default GrayAndBlackList