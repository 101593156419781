import React, { useState } from 'react'
import { fireToast } from '../../../utilities/toastHelper'
import { useTheme } from '../../../services/themeService';

const DefaultUserSettings = ({ defaultData, onSubmitCreditAmounts }) => {
    const {theme} = useTheme();
    const [loadingUpdate, setLoadingUpdate] = useState(false)
    const [creditAmount, setCreditAmount] = useState(defaultData || "")

    const onFinish = () => {
        if (creditAmount === "") {
            fireToast("Kredi miktarini bos birakamazsiniz.", "FAIL", theme.theme)
            return;
        } else {
            onSubmitCreditAmounts(creditAmount);
        }
    }

    return (
        <div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0'
                data-bs-target='#kt_default_user_settings'
                aria-expanded='true'
                aria-controls='kt_default_user_settings'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Varsayılan Kredi Miktarı</h3>
                </div>
            </div>

            <div id='kt_account_profile_details' className='collapse show px-8'>
                <div className={"mt-3"}>
                    <h4>Varsayılan Kredi Miktarı</h4>
                    <input
                        type="number"
                        name="creditAmount"
                        value={creditAmount}
                        onChange={(e) => setCreditAmount(e.target.value)}
                        required
                        className={"form-control form-control-solid w-100 mb-5 mt-2"}
                        placeholder={"VArsayılan Kredi Miktarı"}
                    />
                </div>

                <div className='d-flex justify-content-end mb-5'>
                    <button
                        className='btn btn-primary fw-bold'
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            onFinish();
                        }}
                    >
                        Kaydet
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DefaultUserSettings