import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { KTIcon } from "../../../_metronic/helpers";
import ConfirmationModal from "../modals/ConfirmationModal";
import { useDispatch, useSelector } from 'react-redux';
import { get_local_storage_item } from '../../../utilities';
import { deleteAccountAction } from '../../../services/redux/account/delete-account';
import { logoutAction } from '../../../services/redux/auth/logout';
import { isFailure, isLoading, isSuccess } from '../../../utilities/reduxStateHelper';
import { fireToast } from '../../../utilities/toastHelper';
import { deleteUserAction } from '../../../services/redux/admin/delete-user';
import { useNavigate } from 'react-router-dom';



const DeleteAccountAdmin = ({userId}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selector = useSelector((state) => state.admin.deleteUser);
    const [loading, setLoading] = useState(false);
    const onSubmit = () => {
        dispatch(deleteUserAction({ userId: userId }))
    }
    useEffect(() => {
        if (isSuccess(selector)) {
            setLoading(true);
            fireToast(selector.data.message, "SUCCESS");
            
            setTimeout(() => {
            }, (3000));
            navigate("/")

        }
        else if (isFailure(selector)) {
            fireToast(selector.error.message, "FAIL");
            setLoading(false);
        }
        else if (isLoading(selector)) {
            setLoading(true);
        }
    }, [selector, dispatch])
    return (
        <div className='card mt-5 mt-lg-0 mb-6 mb-xl-9'>
            <div
                className='card-header border-0'
                data-bs-target='#kt_account_delete'
                aria-expanded='true'
                aria-controls='kt_account_delete'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Hesabı Sil</h3>
                </div>
            </div>

            <div id='kt_account_delete' className='collapse show'>
                <form onSubmit={onSubmit} id='kt_account_delete_form' className='form'>
                    <div className='card-body border-top p-9'>
                        <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6'>
                            <KTIcon iconName='information-5' className='fs-2tx text-warning me-4' />

                            <div className='d-flex flex-stack flex-grow-1'>
                                <div className='fw-bold'>
                                    <h4 className='text-gray-800 fw-bolder'>Bu kullanıcının hesabını siliyorsunuz.</h4>
                                    <div className='fs-6 text-gray-600'>
                                        Bu kullanıcı tamamen sistemden silinecektir. Sistemde bu kullanıcıya ait bütün veriler kaybolacaktır.
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Delete Button Start */}
                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button
                            id='kt_account_delete_account_submit'
                            type='submit'
                            className='btn btn-danger fw-bold'
                        >
                            {!loading && 'Hesabı Sil'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Lütfen bekleyiniz...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                    {/* Delete Button End */}

                </form>
            </div>
        </div>
    )
}

export { DeleteAccountAdmin }
