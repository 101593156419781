import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { PasswordMeterComponent } from "../../_metronic/assets/ts/components";
import { resetRegisterState, registerAction } from '../../services/redux/auth/register'
import { isSuccess, isLoading, isFailure } from "../../utilities/reduxStateHelper";
import { fireToast } from "../../utilities/toastHelper";
import { useDispatch, useSelector } from "react-redux";
import PrivacyModal from '../Modals/PrivacyModal'
import { useTheme } from '../../services/themeService'
import { KTIcon } from '../../_metronic/helpers'

const initialValues = {
    avatar: '',
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    changepassword: '',
    phoneNumber: ''
}

function isValidPhoneNumber(phoneNumber) {
    // Telefon numarasının minimum ve maksimum uzunluğunu kontrol et

    if (phoneNumber.length <= 9) {
        return false;
    }

    if (phoneNumber.length >= 15) {
        return false;
    }

    // Geçerli bir telefon numarası
    return true;
}

const registrationSchema = Yup.object().shape({
    firstname: Yup.string()
        .min(3, 'En az 3 sembol')
        .max(50, 'Maximum 50 symbols')
        .required('İsim Alanının doldurulması zorunludur'),
    email: Yup.string()
        .email('Email formatı yanlış')
        .min(3, 'En az 8 sembol')
        .max(50, 'Maksimum 50 sembol')
        .required('Email Alanının doldurulması zorunludur'),
    lastname: Yup.string()
        .min(3, 'En az 3 sembol')
        .max(50, 'Maksimum 50 sembol')
        .required('Soyisim Alanının doldurulması zorunludur'),
    password: Yup.string()
        .min(8, 'En az 8 sembol')
        .max(50, 'Maksimum 50 sembol')
        .required('Şifre Alanının doldurulması zorunludur'),
    changepassword: Yup.string()
        .min(8, 'En az 8 sembol')
        .max(50, 'Maksimum 50 sembol')
        .required('Şifre Onayı Alanının doldurulması zorunludur')
        .oneOf([Yup.ref('password')], 'Şifreler eşleşmiyor'),
    phoneNumber: Yup.string()
        .required('Telefon Numarası Alanının doldurulması zorunludur')
        .test('is-valid-phone-number', 'Geçerli bir telefon numarası giriniz', isValidPhoneNumber)
})

const Registration = () => {
    const { theme } = useTheme();
    const [loading, setLoading] = useState(false)
    const dispatcher = useDispatch();
    const registerSelector = useSelector((state) => state.auth.register);
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);
    const [isShowPrivacyModal, setIsShowPrivacyModal] = useState(false);
    const [passwordVisibleOne, setPasswordVisibleOne] = useState(true)
    const [passwordVisibleTwo, setPasswordVisibleTwo] = useState(true)

    const formik = useFormik({
        initialValues,
        validationSchema: registrationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            if (isChecked === true) {
                const full_phone_number = `${values.checkAreaCode ? values.areaCode : formatPhoneNumber(values.phoneNumber)}`
                const body = {
                    avatar: 'blank',
                    firstName: values.firstname,
                    lastName: values.lastname,
                    email: values.email,
                    password: values.password,
                    phone: full_phone_number
                }
                dispatcher(registerAction(body));
                setSubmitting(true);
            } else {
                fireToast("KVKK metnini onaylamaniz gerekmekte!", 'FAIL', theme.theme)
                return;
            }
        },
    })

    formik.status = formik.status || {}

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    function formatPhoneNumber(phoneNumber) {
        // Convert phoneNumber to string
        const phoneNumberString = phoneNumber.toString();

        // Boşlukları ve parantezleri temizle
        const cleanedNumber = phoneNumberString.replace(/[()\s+-]/g, '');

        // Türkiye kodu olan '90' eklenmiş mi kontrol et
        if (cleanedNumber.startsWith('90')) {
            return cleanedNumber;
        }

        // 0 ile başlıyorsa Türkiye kodu olan '90' ekleyerek döndür
        if (cleanedNumber.startsWith('0')) {
            return `90${cleanedNumber.substring(1)}`;
        }

        if (cleanedNumber.startsWith('00')) {
            return `90${cleanedNumber.slice(2)}`;
        }

        // Diğer durumlarda sadece '90' ekleyerek döndür
        return `90${cleanedNumber}`;
    }

    useEffect(() => {
        PasswordMeterComponent.bootstrap()
    }, [])

    useEffect(() => {
        if (isLoading(registerSelector)) {
            setLoading(true);
        }
        if (isSuccess(registerSelector)) {
            fireToast(registerSelector.data.message, "SUCCESS");
            setLoading(false);
            formik.setStatus({ sent: true, msg: registerSelector.data.message });
            dispatcher(resetRegisterState());
            setTimeout(() => {
                navigate('/auth/login');
            }, (3000));
        }
        if (isFailure(registerSelector)) {
            fireToast(registerSelector.error.message, "FAIL");
            formik.setStatus({ sent: false, msg: registerSelector.error.message });
            setLoading(false);
        }
        return () => {
        }
    }, [registerSelector, dispatcher, navigate]);

    return (
        <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_signup_form'
            onSubmit={formik.handleSubmit}
        >
            {/* begin::Heading */}
            <div className='text-center mb-11'>
                {/* begin::Title */}
                <h1 className='text-dark fw-bolder mb-3' style={{ fontSize: "32px" }}>Kayıt Ol</h1>
                {/* end::Title */}
            </div>
            {/* end::Heading */}

            {/* end::Login options */}

            {Object.keys(formik.status).length !== 0 && (
                <div className={`mb-lg-15 alert ${formik.status.sent ? 'alert-success' : 'alert-danger'}`}>
                    <div className='alert-text font-weight-bold'>{formik.status.msg}</div>
                </div>
            )}

            {/* begin::Form group Firstname */}
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-dark fs-5'>İsim</label>
                <input
                    placeholder='İsim'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('firstname')}
                    className={clsx(
                        'form-control bg-transparent',
                        {
                            'is-invalid': formik.touched.firstname && formik.errors.firstname,
                        },
                        {
                            'is-valid': formik.touched.firstname && !formik.errors.firstname,
                        }
                    )}
                />
                {formik.touched.firstname && formik.errors.firstname && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.firstname}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}
            <div className='fv-row mb-8'>
                {/* begin::Form group Lastname */}
                <label className='form-label fw-bolder text-dark fs-5'>Soyisim</label>
                <input
                    placeholder='Soyisim'
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('lastname')}
                    className={clsx(
                        'form-control bg-transparent',
                        {
                            'is-invalid': formik.touched.lastname && formik.errors.lastname,
                        },
                        {
                            'is-valid': formik.touched.lastname && !formik.errors.lastname,
                        }
                    )}
                />

                {formik.touched.lastname && formik.errors.lastname && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.lastname}</span>
                        </div>
                    </div>
                )}
                {/* end::Form group */}
            </div>

            <div className='fv-row mb-8'>
                <div className='d-flex justify-content-between align-items-center'>
                    <label className='form-label fw-bolder text-dark fs-5'>Telefon Numarası</label>
                    <div className='form-check form-switch'>
                        <input
                            className='form-check-input'
                            type='checkbox'
                            id='checkAreaCode'
                            checked={formik.values.checkAreaCode}
                            onChange={() => formik.setFieldValue('checkAreaCode', !formik.values.checkAreaCode)}
                        />
                        <label className='form-check-label' htmlFor='checkAreaCode'>Yabancı Numara</label>
                    </div>
                </div>
                {formik.values.checkAreaCode && (
                    <input
                        className='form-control bg-transparent mb-3'
                        type='text'
                        placeholder='Alan Kodu'
                        {...formik.getFieldProps('areaCode')}
                    />
                )}
                <input
                    className='form-control bg-transparent'
                    type='text'
                    placeholder='Telefon Numarası'
                    {...formik.getFieldProps('phoneNumber')}
                />
                {/* Hata mesajları ve diğer input alanları aynı şekilde devam edecek */}
            </div>

            {/* begin::Form group Email */}
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-dark fs-5'>Email</label>
                <input
                    placeholder='Email'
                    type='email'
                    autoComplete='off'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.email && formik.errors.email },
                        {
                            'is-valid': formik.touched.email && !formik.errors.email,
                        }
                    )}
                />
                {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.email}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            {/* begin::Form group Password */}
            <div className='fv-row mb-8' data-kt-password-meter='true'>
                <div className='mb-1'>
                    <label className='form-label fw-bolder text-dark fs-5'>Şifre</label>
                    <div className='position-relative mb-3'>
                        <div className='d-flex align-items-center col-lg-6 fv-row w-100'>
                            <input
                                type={!passwordVisibleOne ? 'text' : 'password'}
                                placeholder='Şifre'
                                autoComplete='off'
                                {...formik.getFieldProps('password')}
                                className={clsx(
                                    'form-control bg-transparent',
                                    {
                                        'is-invalid': formik.touched.password && formik.errors.password,
                                    },
                                    {
                                        'is-valid': formik.touched.password && !formik.errors.password,
                                    }
                                )}
                            />
                            <span
                                className='btn btn-icon btn-light mb-3 mb-lg-0'
                                onClick={() => {
                                    setPasswordVisibleOne(!passwordVisibleOne)
                                }}
                            >
                                <KTIcon iconName={passwordVisibleOne ? 'eye' : 'eye-slash'} />
                            </span>
                        </div>
                        {formik.touched.password && formik.errors.password && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.password}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* begin::Meter */}
                    <div
                        className='d-flex align-items-center mb-3'
                        data-kt-password-meter-control='highlight'
                    >
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                        <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                    </div>
                    {/* end::Meter */}
                </div>
                <div className='text-muted'>
                    Harf, sayı ve simgelerin karışımından oluşan 8 veya daha fazla karakter kullanın.
                </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group Confirm password */}
            <div className='fv-row mb-5'>
                <label className='form-label fw-bolder text-dark fs-5'>Şifre Onayı</label>
                <div className='d-flex align-items-center col-lg-6 fv-row w-100'>
                    <input
                        type={!passwordVisibleTwo ? 'text' : 'password'}
                        placeholder='Şifre Onayı'
                        autoComplete='off'
                        {...formik.getFieldProps('changepassword')}
                        className={clsx(
                            'form-control bg-transparent',
                            {
                                'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                            },
                            {
                                'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                            }
                        )}
                    />
                    <span
                        className='btn btn-icon btn-light mb-3 mb-lg-0'
                        onClick={() => {
                            setPasswordVisibleTwo(!passwordVisibleTwo)
                        }}
                    >
                        <KTIcon iconName={passwordVisibleTwo ? 'eye' : 'eye-slash'} />
                    </span>
                </div>
                {formik.touched.changepassword && formik.errors.changepassword && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.changepassword}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}

            <div className='fv-row mb-5 d-flex align-items-center'>
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    className='w-20px h-20px'
                />
                <button
                    className='btn btn-link ms-2 fs-5'
                    type='button'
                    onClick={() => setIsShowPrivacyModal(true)}
                >
                    KVKK metnini okudum, onaylıyorum.
                </button>
            </div>

            {/* begin::Form group */}
            <div className='text-center'>
                <button
                    type='submit'
                    id='kt_sign_up_submit'
                    className='btn btn-lg btn-warning w-100 mb-5'
                    disabled={formik.isSubmitting || !formik.isValid}
                >
                    {!loading && <span className='indicator-label'>Gönder</span>}
                    {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                            Lütfen Bekleyin...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                    )}
                </button>
                <Link to='/auth/login'>
                    <button
                        type='button'
                        id='kt_login_signup_form_cancel_button'
                        className='btn btn-lg btn-light-danger w-100 mb-5'
                    >
                        İptal
                    </button>
                </Link>
            </div>
            {/* end::Form group */}

            {
                isShowPrivacyModal && (
                    <PrivacyModal
                        setIsShowPrivacyModal={setIsShowPrivacyModal}
                        isChecked={isChecked}
                        setIsChecked={setIsChecked}
                    />
                )
            }
            {isShowPrivacyModal && <div className='modal-backdrop fade show'></div>}
        </form>
    )
}
export { Registration }