import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const addToGroupAction = createAsyncThunk("ADD_TO_GROUP_ACTION", async(
    {
        userId,
        groupId,
        customers,
    }, {rejectWithValue}) =>
{
    try {
        let endpoint = `${userId}/customers/groups/add/${groupId}`;
        const body = {customers}
        return await HttpApi.PostAsync(endpoint, true, body);
    }
    catch(err)
    {
        return rejectWithValue(err);
    }
});

const addToGroupSlice = createSlice({
    name: "add-to-group",
    initialState,
    reducers: {
        resetAddToGroup: (state) => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(addToGroupAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        }).addCase(addToGroupAction.fulfilled, (state, action) => {
            state.isFailure = false;
            state.isLoading = false;
            state.isSuccess = true;
            state.data = action.payload;
        }).addCase(addToGroupAction.rejected, (state, action) => {
            state.isFailure = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.error = action.payload;
        })
    }
})
export {addToGroupAction};
export const { resetAddToGroup } = addToGroupSlice.actions;
export default addToGroupSlice.reducer;