import { useEffect, useState } from "react";
import ModalHeader from "../Apps/draft-management/modal/ModalHeader";
import { ConfigProvider, Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getCustomersAction, resetGetCustomers } from "../../services/redux/customers/get-customers";
import { get_local_storage_item } from "../../utilities";
import { isFailure, isLoading, isSuccess } from "../../utilities/reduxStateHelper";
import { addToGroupAction, resetAddToGroup } from "../../services/redux/customers/groups/add-to-group";
import { fireToast } from "../../utilities/toastHelper";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const GroupCustomerAddModal = ({ groupId, setIsShowGroupAddCustomerModal, customTheme }) => {

    const dispatch = useDispatch()
    const externalCustomersState = useSelector((state) => state.customer.getCustomers);
    const addToGroupState = useSelector((state) => state.group.addToGroup);

    const [externalCustomers, setExternalCustomers] = useState();
    const [paginateData, setPaginateData] = useState()
    const [defaultPageSize, setDefaultPageSize] = useState(10);
    const [requested, SetRequested] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const fetchNextData = (page, pageSize) => {
        dispatch(getCustomersAction({ userId: get_local_storage_item("user").id, page: page, perPage: pageSize ? pageSize : defaultPageSize }));
    }

    const addCustomersToGroup = (items) => {
        dispatch(addToGroupAction({
            userId: get_local_storage_item('user').id,
            groupId: groupId,
            customers: items
        }))
    }

    const handleSelectAllCheckbox = () => {
        const allUserIds = externalCustomers.map(user => user.id);
        if (!selectAll) {
            setSelectedUsers(allUserIds);
        } else {
            setSelectedUsers([]);
        }
        setSelectAll(!selectAll);
    };

    // Tek tek checkboxlarin secildigi fonksiyon.
    const handleSingleCheck = (userId) => {
        let updatedSelectedUsers = [...selectedUsers];

        if (updatedSelectedUsers.includes(userId)) {
            updatedSelectedUsers = updatedSelectedUsers.filter(id => id !== userId);
        } else {
            updatedSelectedUsers.push(userId);
        }

        setSelectedUsers(updatedSelectedUsers);
    };

    useEffect(() => {
        if (!requested) {
            dispatch(getCustomersAction({
                userId: get_local_storage_item('user').id,
                page: 1,
                perPage: defaultPageSize
            }))
            SetRequested(true);
        }

    }, [defaultPageSize, dispatch, requested])

    useEffect(() => {
        if (isSuccess(externalCustomersState)) {
            setExternalCustomers(externalCustomersState.data.data);
            setPaginateData(externalCustomersState.data.meta);
        }
        if (isFailure(externalCustomersState)) {
        }
        if (isLoading(externalCustomersState)) {

        }
        return () => {
            dispatch(resetGetCustomers());
        }
    }, [externalCustomers, dispatch, externalCustomersState])

    useEffect(() => {
        if (isSuccess(addToGroupState)) {
            fireToast(addToGroupState.data.message, "SUCCESS")
            setIsShowGroupAddCustomerModal(false);
        }
        if (isFailure(addToGroupState)) {
            fireToast(addToGroupState.error.message, "FAIL")
        }
        if (isLoading(addToGroupState)) {

        }
        return () => {
            dispatch(resetAddToGroup());
        }
    }, [dispatch, addToGroupState, setIsShowGroupAddCustomerModal])

    const formattedPhoneNumber = (phoneNumber) => {
        const phoneNumberWithPlus = '+' + phoneNumber; // Başına + ekleyerek olası bir ülke kodu oluştur
        const parsedNumber = parsePhoneNumberFromString(phoneNumberWithPlus);

        if (!parsedNumber) {
            // Numara geçersizse veya hala analiz edilememişse, orijinal numarayı döndür
            return phoneNumber;
        }

        const formattedNumber = parsedNumber.formatInternational();
        return formattedNumber;
    }

    return (
        <div
            className='modal fade show d-block'
            id='kt_modal_add_draft'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
        >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-800px'>
                {/* begin::Modal content */}
                <div className='modal-content' style={{ display: 'flex', justifyContent: 'center' }}>
                    <ModalHeader
                        title={"Müşteri Ekleme"}
                        handleCloseModal={() => {
                            setIsShowGroupAddCustomerModal(false)
                            setExternalCustomers([]);
                            setPaginateData({});
                        }}
                    />

                    {/* begin::Modal body */}
                    <div className={"d-flex justify-content-center mb-xl-8 mt-5"}>
                        <div className={"d-flex flex-column pb-5 w-auto card w-700px h-auto"}>
                            {/* Customers Start */}
                            <div className={"card body px-3 py-2 border"}>
                                <div className={"dataTables_wrapper dt-bootstrap4 no-footer"}>
                                    <div className={"table-responsive"}>
                                        <table className={"table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"}>
                                            <thead>
                                                <tr className={"text-start text-muted fw-bold fs-7 text-uppercase gs-0"}>
                                                    <th className={"w-10px pe-2 sorting_disabled"} rowSpan="1" colSpan="1" style={{ width: "27.6px" }}>
                                                        <div className={"form-check form-check-sm form-check-custom form-check-solid me-3"}>
                                                            <input
                                                                className={"form-check-input"}
                                                                type="checkbox"
                                                                value="1"
                                                                onChange={handleSelectAllCheckbox}
                                                                checked={selectAll}
                                                            />
                                                        </div>
                                                    </th>
                                                    <th
                                                        className={"min-w-125px sorting"}
                                                        tabIndex="0"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        style={{ width: "261.138px" }}
                                                    >
                                                        Ad Soyad
                                                    </th>
                                                    <th
                                                        className={"min-w-125px sorting"}
                                                        tabIndex="0"
                                                        rowSpan="1"
                                                        colSpan="1"
                                                        style={{ width: "261.138px" }}
                                                    >
                                                       Numara
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className={"text-gray-600 fw-semibold"}>
                                                {
                                                    externalCustomers && externalCustomers.map((user) => (
                                                        <tr key={user.id}>
                                                            <td>
                                                                <div
                                                                    className={"form-check form-check-sm form-check-custom form-check-solid"}
                                                                >
                                                                    <input
                                                                        className={"form-check-input"}
                                                                        type="checkbox"
                                                                        value={user.id}
                                                                        onChange={() => handleSingleCheck(user.id)}
                                                                        checked={selectedUsers.includes(user.id)}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td>{user.name} {user.lastName}</td>
                                                            <td>{formattedPhoneNumber(user.phone)}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        marginTop: 30,
                                        marginBottom: 15
                                    }}
                                >
                                    <ConfigProvider
                                        theme={{
                                            token: {
                                                colorBgContainer: customTheme.backgroundColor,
                                                colorText: customTheme.color
                                            }
                                        }}
                                    >
                                        <Pagination
                                            locale={{ items_per_page: '/ sayfa' }}
                                            showSizeChanger
                                            onShowSizeChange={(current, pageSize) => {
                                                fetchNextData(1, pageSize)
                                            }}
                                            pageSizeOptions={[10,50, 500, 1000, 5000]}
                                            defaultCurrent={paginateData ? paginateData.page : 1}
                                            total={paginateData ? paginateData.totalItems : 1}
                                            pageSize={paginateData ? paginateData.perpage : defaultPageSize}
                                            className={customTheme.theme === "light" ? "light-theme" : "dark-theme"}
                                            onChange={(page, pageSize) => {
                                                fetchNextData(page, pageSize);
                                            }}
                                        />
                                    </ConfigProvider>
                                </div>
                            </div>
                            <div className={"d-flex justify-content-end mt-5"}>
                                <button className={"btn btn-primary"} onClick={() => {
                                    addCustomersToGroup(selectedUsers)
                                    setIsShowGroupAddCustomerModal(false)
                                }}>
                                    Ekle
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* end::Modal body */}

                </div>
                {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}

        </div>
    )
}

export default GroupCustomerAddModal;