import React from 'react'
import ModalHeader from './ModalHeader'
import { KTIcon } from '../../_metronic/helpers'

const RemoveUserFromListModal = ({ setIsShowRemoveUserFromListModal, selectedUser, deleteGrayListCustomer, deleteBlackListCustomer, selectedTab }) => {
    
    return (
        <div
            className='modal fade show d-block'
            id='kt_modal_remove_user_from_list'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
        >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-700px'>
                {/* begin::Modal content */}
                <div className='modal-content' style={{ display: 'flex', justifyContent: 'center' }}>
                    <ModalHeader
                        title="Kullanıcıyı Listeden Çıkar"
                        handleCloseModal={() => setIsShowRemoveUserFromListModal(false)}
                    />

                    {/* begin::Modal body */}
                    <div className={"modal-body scroll-y mx-5 mx-xl-15 my-7"}>

                        {/* begin::notice */}
                        <div className={`notice d-flex bg-light-primary border-primary border border-dashed rounded mb-5 p-6`}>
                            <KTIcon iconName="information" className={`fs-2tx me-4 text-primary`} />
                            <div className={"d-flex flex-stack flex-grow-1"}>
                                <div className={"fw-semibold"}>
                                    <div className={"fs-6 text-gray-700 "}>
                                    Devam ederseniz, kullanıcı ilgili listeden kaldırılacaktır.
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end::notice */}

                        {/* begin::buttons */}
                        <div className={"text-center pt-10"}>
                            <button
                                className={"btn btn-light me-5"}
                                onClick={() => {
                                    setIsShowRemoveUserFromListModal(false)
                                }}
                            >
                                İptal
                            </button>
                            {
                                selectedTab === "grayList"
                                    ? (
                                        <button
                                            className={`btn btn-primary`}
                                            onClick={() => {
                                                deleteGrayListCustomer(selectedUser.id)
                                                setIsShowRemoveUserFromListModal(false)
                                            }}
                                        >
                                            Kullanıcıyı Gri Listeden Kaldır
                                        </button>
                                    )
                                    : (
                                        <button
                                            className={`btn btn-primary`}
                                            onClick={() => {
                                                deleteBlackListCustomer(selectedUser.id)
                                                setIsShowRemoveUserFromListModal(false)
                                            }}
                                        >
                                            Kullanıcıyı Kara Listeden Çıkarma
                                        </button>
                                    )
                            }
                        </div>
                        {/* end::buttons */}

                    </div>
                    {/* end::Modal body */}

                </div>
                {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}

        </div>
    )
}

export default RemoveUserFromListModal