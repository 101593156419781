import { useEffect, useState } from 'react';
import { get_local_storage_item } from '../../../../utilities'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

/* eslint-disable jsx-a11y/anchor-is-valid */
const translateRole = (role) => {
    switch (role) {
        case 'User':
            return 'Kullanıcı';
        case 'Admin':
            return 'Yönetici';
        default:
            return role;
    }
};


const AsideToolbar = () => {
    const [user, setUser] = useState<any>(get_local_storage_item("user"));
    const selector = useSelector<any>((state) => state.profile.profileDetails);
    useEffect(() => {
        const value = get_local_storage_item("user");
        if (value)
            setUser(value);
       
    }, [selector ])
    return (
        <>
            {/*begin::User*/}
            <div className='aside-user d-flex align-items-sm-center justify-content-center py-5'>
                {/*begin::Symbol*/}
                {user && user.avatar ? (
                    <>
                        <div className='symbol symbol-50px'>
                            <img src={user.avatar === 'blank' ? toAbsoluteUrl('/media/avatars/blank.png') : user.avatar} alt=''/>
                        </div>
                    </>
                ):(
                    <></>
                )}
                {/*end::Symbol*/}

                {/*begin::Wrapper*/}
                <div className='aside-user-info flex-row-fluid flex-wrap ms-5'>
                    {/*begin::Section*/}
                    <div className='d-flex'>
                        {/*begin::Info*/}
                        <div className='flex-grow-1 me-2'>
                            {/*begin::Username*/}
                            <Link to={"/settings"} className='text-white text-hover-primary fs-6 fw-bold'>
                                {user ? (
                                    <>
                                        {`${user.firstName as unknown as string} ${user.lastName as unknown as string}`}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </Link>
                            {/*end::Username*/}

                            {/*begin::Description*/}
                            {user ? (
                                <>
                                    <span className='text-gray-600 fw-bold d-block fs-8 mb-1'>
                                        {translateRole(user.role as unknown as string)}
                                    </span>
                                </>
                            ) : (<>
                            </>)}
                            {/*end::Description*/}
                        </div>
                        {/*end::Info*/}

                        {/*begin::User menu*/}
                        <div className='me-n2'>
                            {/*begin::Action*/}
                            <a
                                href='#'
                                className='btn btn-icon btn-sm btn-active-color-primary mt-n2'
                                data-kt-menu-trigger='click'
                                data-kt-menu-placement='bottom-start'
                                data-kt-menu-overflow='false'
                            >
                                <KTIcon iconName='setting-2' className='text-muted fs-1' />
                            </a>

                            <HeaderUserMenu />
                            {/*end::Action*/}
                        </div>
                        {/*end::User menu*/}
                    </div>
                    {/*end::Section*/}
                </div>

                {/*end::Wrapper*/}
            </div>
            {/*end::User*/}

        </>
    )
}

export {AsideToolbar}