import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";


const initialState = {
  isLoading: false,
  isSuccess: false,
  isFailure: false,
  data: {},
  error: {}
};

const deleteAccountAction = createAsyncThunk('DELETE_ACCOUNT_ACTION', async ({ userId, password }, { rejectWithValue }) => {
  try {
    const endpoint = `auth/${userId}/account/delete-account`;
    return await HttpApi.PostAsync(endpoint, true, {password});
  }
  catch (err) {
    return rejectWithValue(err);
  }
})


const deleteAccountSlice = createSlice({
  name: 'delete-account',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(deleteAccountAction.pending, (state) => {
      state.isFailure = false;
      state.isLoading = true;
      state.isSuccess = false;
      state.data = {};
    })
      .addCase(deleteAccountAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isFailure = false;
        state.data = action.payload;
      })
      .addCase(deleteAccountAction.rejected, (state, action) => {
        state.isFailure = true;
        state.isSuccess = false;
        state.isLoading = false;
        state.error = action.payload;
      })
  }
})

export { deleteAccountAction };
export default deleteAccountSlice.reducer;
