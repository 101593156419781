import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};
const getGrayListCustomersAction = createAsyncThunk('GET_GRAY_LIST_CUSTOMERS_ACTION', async (
    {
        userId,
        page,
        perPage
    }, {rejectWithValue}) => {
    try {
        let endpoint = !(page && perPage) ? `${userId}/graylist/customers` : `${userId}/graylist/customers?page=${page}&perpage=${perPage}`;
        return await HttpApi.GetAsync(endpoint, true);
    } catch (err) {
        return rejectWithValue(err);
    }
})

const getGrayListCustomersSlice = createSlice({
    name: 'get-graylist-customers',
    initialState,
    reducers: {
        resetGetGrayListCustomers: (state) => {return initialState}
    },
    extraReducers: (builder) => {
        builder.addCase(getGrayListCustomersAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(getGrayListCustomersAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(getGrayListCustomersAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {getGrayListCustomersAction};
export const {resetGetGrayListCustomers} = getGrayListCustomersSlice.actions;
export default getGrayListCustomersSlice.reducer;
