import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";

const initialState = {
    isLoading: false,
    isFailure: false,
    isSuccess: false,
    data: {},
    error: {}
}

const getUserByIdAction = createAsyncThunk("GET_USER_BY_ID_ACTION", async (
    {
        userId
    }, { rejectWithValue }) => {
    try {
        let endpoint = `admin/users/${userId}/details`;
        const response = await HttpApi.GetAsync(endpoint, true);
        return response;
    } catch (error) {
        return rejectWithValue(error)
    }
})

const getUserByIdSlice = createSlice({
    name: 'admin-get-user-by-id',
    initialState,
    reducers: {
        resetGetUserById: (state) => { return initialState }
    },
    extraReducers: (builder) => {
        builder.addCase(getUserByIdAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
        .addCase(getUserByIdAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
                state.error = {};
        })
        .addCase(getUserByIdAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
                state.data = {};
        })
    }
});

export {getUserByIdAction}
export const {resetGetUserById} = getUserByIdSlice.actions;
export default getUserByIdSlice.reducer;