import React, { useCallback, useEffect, useState } from "react";
import ModalHeader from "../Apps/draft-management/modal/ModalHeader";
import { CustomListItem } from "../Apps/customer-management/table/columns/CustomListItem";
import CustomButton from "../Editor/CustomButton";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { ConfigProvider, Empty, Pagination, Table } from "antd";
import GroupCustomerAddModal from "./GroupCustomerAddModal";
import { useDispatch, useSelector } from "react-redux";
import { getGroupCustomersAction, resetGroupCustomers } from "../../services/redux/customers/groups/get-group-customer";
import { isFailure, isLoading, isSuccess } from "../../utilities/reduxStateHelper";
import { removeFromGroupAction, resetRemoveFromGroup } from "../../services/redux/customers/groups/remove-from-group";
import { get_local_storage_item } from "../../utilities";
import { fireToast } from "../../utilities/toastHelper";
import { addToGrayListAction, resetAddGrayListCustomer } from "../../services/redux/customers/graylist/add-to-graylist";
import { addToBlackListAction, resetAddBlackListCustomer } from "../../services/redux/customers/blacklist/add-to-blacklist";
import CustomerAddedGroupModal from "../Apps/customer-management/modal/CustomerAddedGroupModal";

const GroupInfoModal = ({ setIsShowGroupInfoModal, selectedGroup, customTheme }) => {
    const dispatch = useDispatch();
    const groupCustomersState = useSelector((state) => state.group.getGroupCustomers);
    const removeFromGroupState = useSelector((state) => state.group.removeFromGroup);
    const addToGroupState = useSelector((state) => state.group.addToGroup);
    const addGrayListCustomer = useSelector((state) => state.grayList.addGrayListCustomer);
    const addBlackListCustomerState = useSelector((state) => state.blackList.addBlackListCustomer);
    const [customers, setCustomers] = useState([]);
    const [customersPaginateDate, setCustomersPaginateData] = useState();
    const [defaultPageSize, setDefaultPaginateSize] = useState(10);
    const [requested, setRequested] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedUser, setSelectedUser] = useState()

    const [isShowGroupAddCustomerModal, setIsShowGroupAddCustomerModal] = useState(false);
    const [isAddGroupModal, setIsAddGroupModal] = useState(false);

    const emptyText = <span style={{ color: customTheme.color }}>No Data</span>;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleClose = () => {
        setCustomers([]);
        setDefaultPaginateSize({});
        dispatch(resetRemoveFromGroup());
        dispatch(resetGroupCustomers());
        setIsShowGroupInfoModal(false)
    }

    const fetchNextData = useCallback((page, perPage) => {
        dispatch(getGroupCustomersAction({
            userId: selectedGroup.userId,
            groupId: selectedGroup._id,
            page: page ? page : undefined,
            perPage: perPage ? perPage : defaultPageSize
        }));
    })

    const formatPhoneNumber = (phoneNumber) => {
        const phoneNumberWithPlus = '+' + phoneNumber; // Başına + ekleyerek olası bir ülke kodu oluştur
        const parsedNumber = parsePhoneNumberFromString(phoneNumberWithPlus);

        if (!parsedNumber) {
            // Numara geçersizse veya hala analiz edilememişse, orijinal numarayı döndür
            return phoneNumber;
        }

        const formattedNumber = parsedNumber.formatInternational();
        return formattedNumber;
    };

    const removeFromGroup = (item) => {
        dispatch(removeFromGroupAction({
            userId: get_local_storage_item('user').id,
            groupId: selectedGroup._id,
            customerId: item.id
        }));
    }

    const addToGrayListCustomer = (customerId) => {
        dispatch(addToGrayListAction({ userId: get_local_storage_item("user").id, customerId: customerId }))
    }

    const addToBlackListCustomer = (customerId) => {
        dispatch(addToBlackListAction({ userId: get_local_storage_item("user").id, customerId: customerId }))
    }

    useEffect(() => {
        if (!requested) {
            dispatch(getGroupCustomersAction({
                userId: selectedGroup.userId,
                groupId: selectedGroup._id,
                page: 1,
                perPage: defaultPageSize
            }))
            setRequested(true);
        }
    }, [defaultPageSize, dispatch, customersPaginateDate, requested])

    useEffect(() => {
        dispatch(getGroupCustomersAction({
            userId: selectedGroup.userId,
            groupId: selectedGroup._id,
            page: undefined,
            perPage: undefined
        }));
    }, [dispatch, selectedGroup._id, selectedGroup.userId, addToGroupState]);

    useEffect(() => {
        if (isSuccess(groupCustomersState)) {
            setCustomers(groupCustomersState.data.data);
            setCustomersPaginateData(groupCustomersState.data.meta);
        }
        if (isFailure(groupCustomersState)) {
            // Handle failure if needed
        }
        if (isLoading(groupCustomersState)) {
            // Handle loading state if needed
        }
    }, [groupCustomersState, dispatch, fetchNextData, defaultPageSize]);

    useEffect(() => {
        if (isSuccess(removeFromGroupState)) {
            fireToast(removeFromGroupState.data.message, "SUCCESS")
            fetchNextData(customersPaginateDate.page ? customersPaginateDate.page : 1)
        }
        if (isFailure(removeFromGroupState)) {
            fireToast(removeFromGroupState.error.message, "FAIL")
        }
        if (isLoading(removeFromGroupState)) {

        }
        return () => {
            dispatch(resetRemoveFromGroup());
        }
    }, [customersPaginateDate, dispatch, fetchNextData, removeFromGroupState])

    useEffect(() => {
        if (isSuccess(addGrayListCustomer)) {
            setLoading(false)
            fireToast(addGrayListCustomer.data.message, 'SUCCESS', customTheme.theme)
            setIsShowGroupInfoModal(false)
        }
        if (isLoading(addGrayListCustomer)) {
            setLoading(true)
        }
        if (isFailure(addGrayListCustomer)) {
            setLoading(false)
            fireToast(addGrayListCustomer.error.message, 'FAIL', customTheme.theme)
        }
        return () => {
            dispatch(resetAddGrayListCustomer())
        }
    }, [addGrayListCustomer])

    useEffect(() => {
        if (isSuccess(addBlackListCustomerState)) {
            setLoading(false)
            fireToast(addBlackListCustomerState.data.message, 'SUCCESS', customTheme.theme)
            setIsShowGroupInfoModal(false)
        }
        if (isFailure(addBlackListCustomerState)) {
            setLoading(true)
        }
        if (isFailure(addBlackListCustomerState)) {
            setLoading(false)
            fireToast(addBlackListCustomerState.data.message, 'FAIL', customTheme.theme)
        }
        return () => {
            dispatch(resetAddBlackListCustomer())
        }
    }, [addBlackListCustomerState])

    let columns = [];

    const handleTable = () => {
        if (customers && customers.length > 0) {
            const columnsKey = Object.keys(customers[0]).filter((item) => (item !== 'id' && item !== 'groupId'));
            columns = columnsKey.map(key => ({
                title: key.charAt(0).toUpperCase() + key.slice(1),
                dataIndex: key,
                key: key,
                width: (key === "phone") ? "3%" : "6%",
                render: (_, record) => {
                    return (
                        <>
                            {key === 'phone' ? (
                                <>
                                    <CustomListItem item={formatPhoneNumber(record[key])} />
                                </>
                            ) : (
                                <>
                                    <CustomListItem item={record[key]} />
                                </>
                            )}
                        </>
                    )
                }
            }));
            columns.push({
                title: 'Aksiyonlar',
                dataIndex: 'action',
                width: "1%",
                render: (_, record) => (
                    <div className="d-flex">
                        <CustomButton
                            iconName="trash-square"
                            onPress={() => { removeFromGroup(record) }}
                        />
                        <CustomButton
                            iconName="dots-square"
                            onPress={() => {
                                setSelectedUser(record.id)
                                setIsAddGroupModal(true)
                            }}
                        />
                    </div>

                ),
            });
        }
    }

    handleTable();

    return (
        <>
            {customers ? (
                <>
                    <div
                        className='modal fade show d-block'
                        id='kt_modal_add_draft'
                        role='dialog'
                        tabIndex={-1}
                        aria-modal='true'
                    >
                        {/* begin::Modal dialog */}
                        <div className='modal-dialog modal-dialog-centered mw-1000px'>

                            {/* begin::Modal content */}
                            <div className='modal-content' style={{ display: 'flex', justifyContent: 'center' }}>
                                <ModalHeader
                                    title={"Grup Bilgileri"}
                                    handleCloseModal={() => handleClose()}
                                />

                                {/* begin::Modal body */}
                                <div className={"d-flex flex-column p-8"}>
                                    <div className={"d-flex align-items-center justify-content-between mb-8 px-3"}>
                                        <h3>{selectedGroup.groupName}</h3>
                                        <button className={"btn btn-primary"} onClick={() => setIsShowGroupAddCustomerModal(true)}>
                                            Müşteri Ekleme
                                        </button>
                                    </div>
                                    <ConfigProvider
                                        theme={{
                                            token: {
                                                colorBgContainer: customTheme.backgroundColor,
                                                colorText: customTheme.color,
                                            }
                                        }}
                                    >
                                        <Table
                                            style={{
                                                backgroundColor: customTheme.backgroundColor,
                                                borderRadius: '10px',
                                                display: "flex",
                                                flexDirection: 'column',
                                            }}
                                            dataSource={customers}
                                            columns={columns}
                                            rowKey={"id"}
                                            scroll={{
                                                x: 'auto',
                                            }}
                                            pagination={false}
                                            locale={{
                                                emptyText:
                                                    <Empty
                                                        description={emptyText}
                                                    />
                                            }}
                                        />
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                marginTop: 30,
                                                marginBottom: 15
                                            }}
                                        >
                                            <ConfigProvider
                                                theme={{
                                                    token: {
                                                        colorBgContainer: customTheme.backgroundColor,
                                                        colorText: customTheme.color
                                                    }
                                                }}
                                            >
                                                <Pagination
                                                    locale={{ items_per_page: '/ sayfa' }}
                                                    showSizeChanger
                                                    onShowSizeChange={(current, pageSize) => {
                                                        fetchNextData(1, pageSize)
                                                    }}
                                                    pageSizeOptions={[10, 50, 500, 1000, 5000]}
                                                    defaultCurrent={customersPaginateDate ? customersPaginateDate.page : 1}
                                                    total={customersPaginateDate ? customersPaginateDate.totalItems : customers.length}
                                                    pageSize={customersPaginateDate ? customersPaginateDate.perpage : defaultPageSize}
                                                    className={customTheme.theme === "light" ? "light-theme" : "dark-theme"}
                                                    onChange={(page, pageSize) => {
                                                        fetchNextData(page, pageSize);
                                                    }}
                                                />
                                            </ConfigProvider>
                                        </div>
                                    </ConfigProvider>
                                </div>
                                {/* end::Modal body */}

                            </div>
                            {/* end::Modal content */}
                        </div>
                        {/* end::Modal dialog */}

                        {
                            isShowGroupAddCustomerModal && (
                                <GroupCustomerAddModal
                                    setIsShowGroupAddCustomerModal={setIsShowGroupAddCustomerModal}
                                    customTheme={customTheme}
                                    groupId={selectedGroup._id}
                                />
                            )
                        }
                        {isShowGroupAddCustomerModal && <div className='modal-backdrop fade show'></div>}

                        {/* begin::Adding users to the grey list or black list */}
                        {
                            isAddGroupModal && (
                                <CustomerAddedGroupModal
                                    setIsAddGroupModal={setIsAddGroupModal}
                                    addToGrayListCustomer={addToGrayListCustomer}
                                    addToBlackListCustomer={addToBlackListCustomer}
                                    customerId={selectedUser}
                                />
                            )
                        }
                        {isAddGroupModal && <div className='modal-backdrop fade show'></div>}
                        {/* end::Adding users to the grey list or black list */}

                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    )
}

export default GroupInfoModal;