import ModalHeader from "../Apps/draft-management/modal/ModalHeader";
import {KTIcon} from "../../_metronic/helpers";

const UserBannedModal = ({setIsShowUserBannedModal, selectedUser, blockTheUser, unblockTheUser}) => {

    return(
        <div
            className='modal fade show d-block'
            id='kt_modal_add_draft'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
        >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-700px'>
                {/* begin::Modal content */}
                <div className='modal-content' style={{display: 'flex', justifyContent: 'center'}}>
                    <ModalHeader
                        title={selectedUser.isSuspend ? "Kullanıcının Yasağını Kaldır" : "Kullanıcıyı Banla"}
                        handleCloseModal={() => setIsShowUserBannedModal(false)}
                    />

                    {/* begin::Modal body */}
                    <div className={"modal-body scroll-y mx-5 mx-xl-15 my-7"}>

                        {/* begin::notice */}
                        <div className={`notice d-flex ${selectedUser.isSuspend ? "bg-light-primary border-primary" : "bg-light-danger border-danger" } border border-dashed rounded mb-5 p-6`}>
                            <KTIcon iconName="information" className={`fs-2tx me-4 ${selectedUser.isSuspend ? "text-primary" : "text-danger"}`}  />
                            <div className={"d-flex flex-stack flex-grow-1"}>
                                <div className={"fw-semibold"}>
                                    <div className={"fs-6 text-gray-700 "}>
                                        {
                                            selectedUser.isSuspend
                                                ? "Bu yasaklı kullanıcının engelini kaldırmak istediğinizden emin misiniz?"
                                                : "Bu kullanıcıyı yasaklamak istediğinizden emin misiniz?"
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end::notice */}

                        {/* begin::buttons */}
                        <div className={"text-center pt-10"}>
                            {
                                selectedUser.isSuspend
                                    ? (
                                        <button
                                            className={`btn btn-primary`}
                                            onClick={() => {
                                                unblockTheUser(selectedUser.id)
                                                setIsShowUserBannedModal(false)
                                            }}
                                        >
                                            Ban Kaldır
                                        </button>
                                    ) : (
                                        <button
                                            className={"btn btn-danger"}
                                            onClick={() => {
                                                blockTheUser(selectedUser.id)
                                                setIsShowUserBannedModal(false)
                                            }}
                                        >
                                            Kullancıyı Banla
                                        </button>
                                    )
                            }
                            <button
                                className={"btn btn-light ms-5"}
                                onClick={() => setIsShowUserBannedModal(false)}
                            >
                                İptal
                            </button>
                        </div>
                        {/* end::buttons */}

                    </div>
                    {/* end::Modal body */}

                </div>
                {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}

        </div>
    )
}

export default UserBannedModal;