import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const updateCustomerAction = createAsyncThunk('UPDATE_CUSTOMER_ACTION', async (
    {
        userId,
        customerId,
        name,
        lastName,
        phone
    }, {rejectWithValue}) => {
    try {
        const endpoint = `${userId}/customers/update/${customerId}`;
        const body = {name, lastName, phone};
        return await HttpApi.PutAsync(endpoint, true, body);
    } catch (err) {
        return rejectWithValue(err);
    }
})

const updateCustomerSlice = createSlice({
    name: 'update-customer',
    initialState,
    reducers: {
        resetUpdateCustomer: (state) => {return initialState;}
    },
    extraReducers: (builder) => {
        builder.addCase(updateCustomerAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(updateCustomerAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(updateCustomerAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {updateCustomerAction};
export const { resetUpdateCustomer } = updateCustomerSlice.actions;
export default updateCustomerSlice.reducer;
