import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const automationSettingsAction = createAsyncThunk('AUTOMATION_SETTINGS_ACTION', async (
    {
        userId
    }, { rejectWithValue }) => {
    try {
        const endpoint = `${userId}/profile/automation-settings/`;
        return await HttpApi.GetAsync(endpoint, true);
    }
    catch (err) {
        return rejectWithValue(err);
    }
})


const automationSettingsSlice = createSlice({
    name: 'active-sessions',
    initialState,
    reducers: {
        resetAutomationSettings: (state) => {return initialState;}
    },
    extraReducers: (builder) => {
        builder.addCase(automationSettingsAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(automationSettingsAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(automationSettingsAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
})

export { automationSettingsAction };
export const { resetAutomationSettings } = automationSettingsSlice.actions;
export default automationSettingsSlice.reducer;
