import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const updateAutomationSettingsAction = createAsyncThunk('UPDATE_AUTOMATION_SETTINGS_ACTION', async (
    {
        userId,
        startHour,
        startMinute,
        endHour,
        endMinute,
        min_message_delay,
        max_message_delay,
        useSpamCode,
        defaultAreaCode,
    }, { rejectWithValue }) => {
    try {
        const endpoint = `${userId}/profile/automation-settings/update/`;
        const body  = {startHour, startMinute, endHour, endMinute, min_message_delay, max_message_delay, useSpamCode, defaultAreaCode};
        return await HttpApi.PatchAsync(endpoint, true, body);
    }
    catch (err) {
        return rejectWithValue(err);
    }
})


const updateAutomationSettingsSlice = createSlice({
    name: 'update-profile',
    initialState,
    reducers: {
        resetUpdateAutoSettings: (state) => {return initialState;}
    },
    extraReducers: (builder) => {
        builder.addCase(updateAutomationSettingsAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(updateAutomationSettingsAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(updateAutomationSettingsAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
})

export { updateAutomationSettingsAction };
export const { resetUpdateAutoSettings } = updateAutomationSettingsSlice.actions;
export default updateAutomationSettingsSlice.reducer;
