import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const addToGrayListAction = createAsyncThunk("ADD_TO_GRAYLIST_ACTION", async (
    {
        userId,
        customerId,
    }, { rejectWithValue }) => {
    try {
        let endpoint = `${userId}/graylist/customers/add/${customerId}`;
        return await HttpApi.PostAsync(endpoint, true, {});
    }
    catch (err) {
        return rejectWithValue(err);
    }
});

const addToGrayListSlice = createSlice({
    name: "add-to-graylist",
    initialState,
    reducers: {
        resetAddGrayListCustomer: (state) => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder.addCase(addToGrayListAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(addToGrayListAction.fulfilled, (state, action) => {
                state.isFailure = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.data = action.payload;
            })
            .addCase(addToGrayListAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isLoading = false;
                state.isSuccess = false;
                state.data = action.payload;
            })
    }
})
export { addToGrayListAction };
export const { resetAddGrayListCustomer } = addToGrayListSlice.actions;
export default addToGrayListSlice.reducer;