import { HEADER_TYPES, HttpProccess, REQUEST_TYPES, RequestBuilder, get_cookie } from "../utilities"

export class HttpApi {
  static PostAsync = async (endpoint, is_auth_necessary, body) => {
    return await HttpApi.HandleRequest(
      REQUEST_TYPES.POST,
      endpoint,
      is_auth_necessary,
      body ? body : {}
    );
  }
  static PostFormData = async ({endpoint, is_auth_necessary, form}) => {
    return await HttpApi.HandleRequestFormData(
      REQUEST_TYPES.POST,
      is_auth_necessary,
      endpoint,
      form ? form : {}
    );
  }
  static GetAsync = async (endpoint, is_auth_necessary) => {
    return await HttpApi.HandleRequest(
      REQUEST_TYPES.GET,
      endpoint,
      is_auth_necessary,
      {}
    );
  }

  static PutAsync = async (endpoint, is_auth_necessary, body) => {
    return await HttpApi.HandleRequest(
      REQUEST_TYPES.PUT,
      endpoint,
      is_auth_necessary,
      body ? body : {}
    );
  }
  static PatchAsync = async (endpoint, is_auth_necessary, body) => {
    return await HttpApi.HandleRequest(
      REQUEST_TYPES.PATCH,
      endpoint,
      is_auth_necessary,
      body ? body : {}
    );
  }
  static DeleteAsync = async (endpoint, is_auth_necessary, body) => {
    return await HttpApi.HandleRequest(
      REQUEST_TYPES.DELETE,
      endpoint,
      is_auth_necessary,
      body ? body : {}
    );
  }

  static HandleRequest = async (reqType, endpoint, is_auth_necessary, body) => {
    let final_endpoint = RequestBuilder.BuildEndpoint(endpoint);
    let final_header = RequestBuilder.BuildHeader(
      is_auth_necessary === true ? HEADER_TYPES.WITH_AUTH : HEADER_TYPES.BASIC,
      is_auth_necessary ? get_cookie('session') : ""
    );
    console.log(final_header);
    return await HttpProccess.MakeRequest(reqType,
      final_endpoint,
      final_header,
      body
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      HttpProccess.HandleRejection(is_auth_necessary, error.response.data);
      throw error.response.data;
    });
  }
  static HandleRequestFormData = async (reqType, is_auth_necessary, endpoint, form) => {
    let final_endpoint = RequestBuilder.BuildEndpoint(endpoint);
    let final_header = RequestBuilder.BuildHeader(HEADER_TYPES.FORM_AUTH, get_cookie('session'));
    return await HttpProccess.MakeRequest(reqType,
      final_endpoint,
      final_header,
      form
    ).then((response) => {
      return response.data;
    }).catch((error) => {
      HttpProccess.HandleRejection(is_auth_necessary, error.response.data);
      throw error.response.data;
    });
  }
}
