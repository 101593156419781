import React, { useState } from 'react'
import ModalHeader from './ModalHeader';
import { KTIcon } from '../../_metronic/helpers';

const AddExtraPhoneModal = ({ handleSubmitExtraPhoneNumber, setIsShowExtraPhoneNumberModal, setIsInternational, isInternational }) => {
    const [extraPhoneNumber, setExtraPhoneNumber] = useState('');
    const [areaCode, setAreaCode] = useState('90');
    
    // Telefon numarasındaki boşlukları kaldıran fonksiyon
    const removeSpaces = (phoneNumber) => {
        return phoneNumber.replace(/\s/g, ''); // Tüm boşlukları kaldırır
    };

    const handleChangePhoneNumber = (e) => {
        let inputPhoneNumber = e.target.value;
    
        // "+" işareti varsa, numaranın başında veya sonunda olabilir, başa al
        inputPhoneNumber = inputPhoneNumber.replace(/^\+/, '');
    
        // Boşlukları kaldır
        inputPhoneNumber = inputPhoneNumber.replace(/\s/g, '');
    
        // Başında "90" yoksa ve numara 10 haneliyse, başına "90" ekle
        if (!inputPhoneNumber.startsWith("90") && inputPhoneNumber.length === 10) {
            inputPhoneNumber = "90" + inputPhoneNumber;
        }

            // Başında "00" varsa, "0" ile değiştirerek döndür
            if (inputPhoneNumber.startsWith('00')) {
                return `90${inputPhoneNumber.slice(2)}`;
            }

            // 0 ile başlıyorsa Türkiye kodu olan '90' ekleyerek döndür
            if (inputPhoneNumber.startsWith('0')) {
                return `90${inputPhoneNumber.substring(1)}`;
            }
    
        // Numarayı güncelle
        setExtraPhoneNumber(inputPhoneNumber);
    };
    


    function isValidPhoneNumber(phoneNumber) {
        // Telefon numarasının uzunluğunu kontrol ederek basit bir doğrulama yapar
        // Burada, uluslararası numaralar için genel geçer bir uzunluk aralığı kabul edilmiştir (10 ile 15 karakter arası)
        return phoneNumber.length >= 10 && phoneNumber.length <= 15;
    }

    const handleAddPhone = () => {
        let formattedPhoneNumber = extraPhoneNumber;

        // Boşlukları kaldır
        formattedPhoneNumber = removeSpaces(formattedPhoneNumber);

        // Proceed with the rest of the function as before
        if (isValidPhoneNumber(formattedPhoneNumber)) {
            handleSubmitExtraPhoneNumber(formattedPhoneNumber);
            setIsShowExtraPhoneNumberModal(false); // Close the modal
        } else {
            alert("Please enter a valid phone number.");
        }
    };

    return (
        <div className='modal fade show d-block' id='kt_modal_add_draft' role='dialog' tabIndex={-1} aria-modal='true'>
            <div className='modal-dialog modal-dialog-centered mw-700px'>
                <div className='modal-content' style={{ display: 'flex', justifyContent: 'center' }}>
                    <ModalHeader title={"Ekstra Telefon Numarası Ekleme"} handleCloseModal={() => setIsShowExtraPhoneNumberModal(false)} />

                    <div className={"modal-body scroll-y mx-5 mx-xl-15 my-7"}>
                        <div className={`notice d-flex bg-light-primary border-primary border border-dashed rounded mb-10 p-6`}>
                            <KTIcon iconName="information" className={`fs-2tx me-4 text-primary`} />
                            <div className={"d-flex flex-stack flex-grow-1"}>
                                <div className={"fw-semibold"}>
                                    <div className={"fs-6 text-gray-700"}>
                                        Buraya fazladan eklemek istediğiniz telefon numarasını giriniz. Daha sonra kendiniz silemez ve düzenleyemezsiniz!
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-check form-switch mb-5">
                            <input className="form-check-input" type="checkbox" id="isInternationalSwitch" checked={isInternational} onChange={() => setIsInternational(!isInternational)} />
                            <label className="form-check-label" htmlFor="isInternationalSwitch">Yabancı Numara</label>
                        </div>

                        {isInternational && (
                            <div className={"fv-row mb-7"}>
                                <label className={"fs-6 fw-semibold form-label mb-2"}>
                                    <span>Alan Kodu</span>
                                </label>
                                <input
                                    type={"text"}
                                    className={"form-control form-control-solid"}
                                    value={areaCode}
                                    onChange={(e) => setAreaCode(e.target.value)}
                                    placeholder={"Alan kodunu giriniz."}
                                />
                            </div>
                        )}

                        <div className={"fv-row mb-7"}>
                            <label className={"fs-6 fw-semibold form-label mb-2"}>
                                <span className={"required"}>Telefon Numarası</span>
                            </label>
                            <input
                                type={"text"}
                                className={"form-control form-control-solid"}
                                value={extraPhoneNumber}
                                onChange={handleChangePhoneNumber}
                                placeholder={isInternational ? "Tam telefon numarasını giriniz." : "Telefon numarasını 90 ile başlayacak şekilde giriniz."}
                            />
                        </div>

                        <div className={"text-center pt-10"}>
                            <button className={"btn btn-light me-5"} onClick={() => setIsShowExtraPhoneNumberModal(false)}>İptal</button>
                            <button className={`btn btn-primary`} onClick={handleAddPhone}>Ekle</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddExtraPhoneModal;