import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";

const initialState = {
    isLoading: false,
    isFailure: false,
    isSuccess: false,
    data: {},
    error: {}
}

const getUsersAction = createAsyncThunk("GET_ALL_USERS_ACTION", async (
    {
        page,
        perPage,
        search
    }, { rejectWithValue }) => {
    try {
        let endpoint = !(page && perPage) ? `admin/get-users` : `admin/get-users?page=${page}&perpage=${perPage}`;
        endpoint = (search && search != "") ? (endpoint+ `&search=${search}`) : endpoint;
        const response = await HttpApi.GetAsync(endpoint, true);
        return response;
    } catch (error) {
        return rejectWithValue(error)
    }
})

const getUsersSlice = createSlice({
    name: 'admin-get-users',
    initialState,
    reducers: {
        resetGetUsers: (state) => { return initialState }
    },
    extraReducers: (builder) => {
        builder.addCase(getUsersAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
        .addCase(getUsersAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
                state.error = {};
        })
        .addCase(getUsersAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
                state.data = {};
        })
    }
});

export { getUsersAction };
export const { resetGetUsers } = getUsersSlice.actions;
export default getUsersSlice.reducer;