import {useEffect} from 'react'
import {Link, Outlet} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from "../_metronic/helpers";
const AuthLayout = () => {
    useEffect(() => {
        const root = document.getElementById('root')
        if (root) {
            root.style.height = '100%'
        }
        return () => {
            if (root) {
                root.style.height = 'auto'
            }
        }
    }, [])

    return (
        <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
            {/* begin::Body */}
            <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
                {/* begin::Form */}
                <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
                    {/* begin::Wrapper */}
                    <div className='w-lg-500px p-10'>
                        <Outlet />
                    </div>
                    {/* end::Wrapper */}
                </div>
                {/* end::Form */}

                {/* begin::Footer */}
                <div className='d-flex flex-center flex-wrap px-5'>
                    {/* begin::Links */}
                    <div className='d-flex align-items-center fw-semibold mb-1'>
                        <span className=''>
                            <KTIcon iconName='whatsapp' className='text-success bigger-icon' />
                        </span>
                        <a
                            className='btn btn-link px-5 fs-1 ms-3'
                            href={"https://api.whatsapp.com/send?phone=905468468462&text=Merhaba%20Pro%20Whatsapp%20Web%20i%C3%A7in%20"}
                        >
                            İletişim için
                        </a>
                    </div>
                    {/* end::Links */}
                </div>
                {/* end::Footer */}
            </div>
            {/* end::Body */}

            {/* begin::Aside */}
            <div
                className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
                style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.webp')})`}}
            >
                {/* begin::Content */}
                <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
                    {/* begin::Logo */}
                    <img alt='Logo' src={toAbsoluteUrl('/media/logos/custom-1.png')} className='h-75px' />
                    {/* end::Logo */}

                    {/* begin::Image */}
                    <img
                        className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20'
                        src={toAbsoluteUrl('/media/misc/auth-screens.webp')}
                        alt=''
                    />
                    {/* end::Image */}

                    {/* begin::Title */}
                    <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
                        Hızlı, Verimli ve Üretken
                    </h1>
                    {/* end::Title */}

                    {/* begin::Text */}
                    <div className='text-white fs-base text-center'>
                        Tek Tek Mesaj Atma Derdine Son Pro Whatsapp Mesaj Atsın Siz Rahat Edin
                    </div>
                    {/* end::Text */}
                </div>
                {/* end::Content */}
            </div>
            {/* end::Aside */}
        </div>
    )
}

export {AuthLayout}