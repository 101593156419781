import React from "react";
import {Steps} from 'antd';
import '../index.css'

const Stepper = ({step, steps, customTheme}) => {

    const items = (steps || []).map((item, index) => ({
        key: item.title,
        title: (
            <span style={{ color: customTheme.theme === "light" ? "#000" : "#FFF" }}>
                {item.title}
            </span>
        ),
        status: ((customTheme.theme === "dark") && (step === index))
            ? "process"
            : ((step > index) && (customTheme.theme === "dark"))
                ? "finish"
                : ((step === index) && (customTheme.theme === "light"))
                    ? "process"
                    : ((step > index) && (customTheme.theme === "light"))
                        ? "finish"
                        : "wait",
    }));

    return (
        <div className={"mb-3 mb-sm-10 mb-md-16"}>
            <Steps current={step} items={items} />
        </div>
    )
}

export default Stepper;