import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../../api";


const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const removeFromBlackListAction = createAsyncThunk("REMOVE_FROM_BLACKLIST_ACTION", async(
    {
        userId,
        customerId,
    }, {rejectWithValue}) =>
{
    try {
        let endpoint = `${userId}/blacklist/customers/remove/${customerId}`;
        return await HttpApi.PostAsync(endpoint, true, {});
    }
    catch(err)
    {
        return rejectWithValue(err);
    }
});

const removeFromBlackListSlice = createSlice({
    name: "remove-from-blacklist",
    initialState,
    reducers: {
        resetRemoveBlackListCustomer: (state) => {
            return initialState
        }
    },
    extraReducers: (builder) => {
        builder.addCase(removeFromBlackListAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        }).addCase(removeFromBlackListAction.fulfilled, (state, action) => {
            state.isFailure = false;
            state.isLoading = false;
            state.isSuccess = true;
            state.data = action.payload;
        }).addCase(removeFromBlackListAction.rejected, (state, action) => {
            state.isFailure = true;
            state.isLoading = false;
            state.isSuccess = false;
            state.data = action.payload;
        })
    }
})
export {removeFromBlackListAction};
export const {resetRemoveBlackListCustomer} = removeFromBlackListSlice.actions;
export default removeFromBlackListSlice.reducer;