import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {HttpApi} from "../../api";
import { HEADER_TYPES, RequestBuilder, get_cookie } from "../../../utilities";

const initialState = {
    isLoading: false,
    isSuccess: false,
    isFailure: false,
    data: {},
    error: {}
};

const deleteFileQueueAction = createAsyncThunk('DELETE_FILE_QUEUE_ACTION', async (
    {
        user,
        queue,
        file,
        
    }, {rejectWithValue}) => {
        try {
            const endpoint = `${user}/queue/delete/${queue}/file`;
            const body = {file}
            return await HttpApi.PostAsync(endpoint, true, body);
        } catch (err) {
            return rejectWithValue(err);
        }


})

const deleteFileQueueSlice = createSlice({
    name: 'delete-file-queue',
    initialState,
    reducers: {
        resetRemoveCustomerQueue: (state) => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(deleteFileQueueAction.pending, (state) => {
            state.isFailure = false;
            state.isLoading = true;
            state.isSuccess = false;
            state.data = {};
        })
            .addCase(deleteFileQueueAction.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isFailure = false;
                state.data = action.payload;
            })
            .addCase(deleteFileQueueAction.rejected, (state, action) => {
                state.isFailure = true;
                state.isSuccess = false;
                state.isLoading = false;
                state.error = action.payload;
            })
    }
});
export {deleteFileQueueAction};
export const { resetRemoveCustomerQueue } = deleteFileQueueSlice.actions;

export default deleteFileQueueSlice.reducer;
