import React from "react";

export const CustomListItem = ({item}) => {
    return(
        <p
            style={{
                margin: '0',
                fontFamily: 'Inter',
                paddingLeft: "5px",
                paddingRight: "5px"
            }}
        >
            {item}
        </p>
    )
}
