import React, {useState} from 'react';
import ModalHeader from "../Apps/draft-management/modal/ModalHeader";
import {KTIcon} from "../../_metronic/helpers";

const UserDetailEditModal = ({setIsShowDetailEditModal, selectedData, selectedInputType, handleSubmitEmail, handleSubmitPhoneNumber, handleChangeExtraPhoneNumbers}) => {
    const [email, setEmail] = useState(selectedData.mail || "");
    const [phoneNumber, setPhoneNumber] = useState(selectedData.phoneNumber || "");
    const [extraPhoneNumber, setExtraPhoneNumber] = useState('')

    return(
        <div
            className='modal fade show d-block'
            id='kt_modal_add_draft'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
        >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-700px'>
                {/* begin::Modal content */}
                <div className='modal-content' style={{display: 'flex', justifyContent: 'center'}}>
                    <ModalHeader
                        title={"Kullanıcı Bilgilerini Güncelle"}
                        handleCloseModal={() => setIsShowDetailEditModal(false)}
                    />

                    {/* begin::Modal body */}
                    <div className={"modal-body scroll-y mx-5 mx-xl-15 my-7"}>

                        {/* begin::notice */}
                        <div className={`notice d-flex bg-light-primary border-primary border border-dashed rounded mb-10 p-6`}>
                            <KTIcon iconName="information" className={`fs-2tx me-4 text-primary`}  />
                            <div className={"d-flex flex-stack flex-grow-1"}>
                                <div className={"fw-semibold"}>
                                    <div className={"fs-6 text-gray-700"}>
                                        {
                                            selectedInputType === "email"
                                                ? "E-posta doğrulamasını tamamlamak için geçerli bir e-posta adresinin gerekli olduğunu lütfen unutmayın."
                                                : selectedInputType === "phone" 
                                                    ? "Telefon numarası doğrulamasını tamamlamak için geçerli bir telefon numarasının gerekli olduğunu lütfen unutmayın." 
                                                    : "Lütfen bu kullanıcı için tanımlamak istediğiniz extra telefon numarası miktarını giriniz."
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end::notice */}

                        {/* begin::input */}
                        <div className={"fv-row mb-7 fv-plugins-icon-container fv-plugins-bootstrap5-row-invalid"}>
                            <label className={"fs-6 fw-semibold form-label mb-2"}>
                                <span className={"required"}>
                                    {
                                        selectedInputType === "email" 
                                            ? 'E-posta Adresi' 
                                            : selectedInputType === "phone" 
                                                ? 'Telefon Numarası' 
                                                : 'Ekstra Telefon Numaraları'
                                    }
                                    </span>
                            </label>
                            <input
                                type={selectedInputType === "extraPhoneNumbers" ? "number" : "text"}
                                className={"form-control form-control-solid"}
                                value={selectedInputType === "email" ? email : selectedInputType === "phone" ? phoneNumber : extraPhoneNumber}
                                onChange={(e) => {
                                    selectedInputType === "email"
                                        ? setEmail(e.target.value)
                                        : selectedInputType === "phone" 
                                            ? setPhoneNumber(e.target.value) 
                                            : setExtraPhoneNumber(e.target.value)
                                }}
                                placeholder={"Lütfen giriş alanını doldurunuz..."}
                            />
                        </div>
                        {/* end::input */}

                        {/* begin::buttons */}
                        <div className={"text-center pt-10"}>
                            <button
                                className={"btn btn-light me-5"}
                                onClick={() => setIsShowDetailEditModal(false)}
                            >
                                İptal
                            </button>
                            <button
                                className={`btn btn-primary`}
                                onClick={() => {
                                    selectedInputType === "email"
                                        ? handleSubmitEmail(email)
                                        : selectedInputType === "phone" 
                                            ? handleSubmitPhoneNumber(phoneNumber) 
                                            : handleChangeExtraPhoneNumbers(extraPhoneNumber)
                                    setIsShowDetailEditModal(false)
                                }}
                            >
                                Gönder
                            </button>
                        </div>
                        {/* end::buttons */}

                    </div>
                    {/* end::Modal body */}

                </div>
                {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}

        </div>
    )
}

export default UserDetailEditModal;