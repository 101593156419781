import React, { useState } from 'react'
import ModalHeader from './ModalHeader'

const CreditLoadingModal = ({ setIsShowCreditLoadingModal, CreditLoad }) => {
    const [credit, setCredit] = useState(0);

    return (
        <div
            className='modal fade show d-block'
            id='kt_modal_credit_loading'
            role='dialog'
            tabIndex={-1}
            aria-modal='true'
        >
            {/* begin::Modal dialog */}
            <div className='modal-dialog modal-dialog-centered mw-700px'>
                {/* begin::Modal content */}
                <div className='modal-content' style={{ display: 'flex', justifyContent: 'center' }}>
                    <ModalHeader
                        title="Yük Kredisi"
                        handleCloseModal={() => setIsShowCreditLoadingModal(false)}
                    />

                    {/* begin::Modal body */}
                    <div className={"modal-body scroll-y mx-5 mx-xl-7"}>

                        {/* begin::form */}
                        <div className={"pt-2"}>
                            <div className='mt-3'>
                                <h4>Kredi Tutarı</h4>
                                <input
                                    type='number'
                                    name='credit'
                                    value={credit}
                                    onChange={(e) => setCredit(e.target.value)}
                                    required
                                    className={"form-control form-control-solid w-100 mb-5 mt-2"}
                                    placeholder='Kredi Tutarı'
                                />
                            </div>
                            <div className='d-flex justify-content-center'>
                                <button
                                    onClick={() => setIsShowCreditLoadingModal(false)}
                                    className='btn btn-light me-3'
                                >
                                    İptal
                                </button>
                                <button
                                    onClick={() => {
                                        CreditLoad(credit)
                                        setCredit(0)
                                        setIsShowCreditLoadingModal(false)

                                    }}
                                    className='btn btn-primary'
                                >
                                    Kredi Yükle
                                </button>
                            </div>

                        </div>
                        {/* end::form */}

                    </div>
                    {/* end::Modal body */}

                </div>
                {/* end::Modal content */}
            </div>
            {/* end::Modal dialog */}

        </div>
    )
}

export default CreditLoadingModal