import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HttpApi } from "../../api";


const initialState = {
  isLoading: false,
  isSuccess: false,
  isFailure: false,
  data: {},
  error: {}
};

const forgotPasswordConfirmAction = createAsyncThunk('FORGOT_PASSWORD_CONFIRM_ACTION', async ({
  password,
  hash_token,

}, { rejectWithValue }) => {
  try {
    const endpoint = `auth/forgot-password-confirm/${hash_token}`;
    const body = { password };
    return await HttpApi.PatchAsync(endpoint, false, body);
  }
  catch (err) {
    return rejectWithValue(err);
  }


})

const forgotPasswordConfirmSlice = createSlice({
  name: 'forgot-password-confirm',
  initialState,
  reducers: {
    resetForgotPasswordConfirm: (state) => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(forgotPasswordConfirmAction.pending, (state) => {
      state.isFailure = false;
      state.isLoading = true;
      state.isSuccess = false;
      state.data = {};
    })
      .addCase(forgotPasswordConfirmAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isFailure = false;
        state.data = action.payload;
      })
      .addCase(forgotPasswordConfirmAction.rejected, (state, action) => {
        state.isFailure = true;
        state.isSuccess = false;
        state.isLoading = false;
        state.error = action.payload;
      })
  }
});
export { forgotPasswordConfirmAction };
export const { resetForgotPasswordConfirm } = forgotPasswordConfirmSlice.actions;
export default forgotPasswordConfirmSlice.reducer;
